import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext from "../../components/context/appContext";
import { updateBusinessData } from "../../modules/actions/index";
import {
  callApiGetBusinessData,
  callApiGetBusinessUsersList,
  callApiUpdateCheckScanReportsData,
  callApiGetCheckScanReportsDataUsingBusinessId,
  callApiGetUserData,
  callApiGetAdminEmails,
} from "../../services/index";

import { createBusinessNotes } from "../../modules/actions";

class CustomerInfoNoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business: {},
      businessId: "",
      businessUsersList: [],
      sendNotificationToCustomer: false,
      roles: {
        admin: false,
        manager: false,
        staff: false,
      },
      note: "",
      userId: "",
      noteuser: {},
      adminEmails: [],
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { userData, currentBusinessId } = this.context;

    if (currentBusinessId) {
      if (prevState.businessId !== currentBusinessId) {
        const businessData = await callApiGetBusinessData(currentBusinessId);

        if (
          businessData &&
          businessData.notes &&
          businessData.notes.length > 0
        ) {
          businessData.notes.map(async (ev, ei) => {
            let noteUserData = await callApiGetUserData({ userId: ev.userId });
            let noteUserId = ev.userId;
            if (noteUserData) {
              ev["userName"] =
                noteUserData.firstName + " " + noteUserData.lastName;
            } else {
              ev["userName"] = "";
            }
          });

          this.setState({
            business: businessData,
          });
        }

        const adminEmails = await callApiGetAdminEmails(currentBusinessId);

        if (adminEmails && adminEmails.data && adminEmails.data.length > 0) {
          this.setState({
            adminEmails: adminEmails.data,
          });
        }

        this.setState({
          businessId: currentBusinessId,
        });
      }

      const businessUsersList = await callApiGetBusinessUsersList({
        businessId: currentBusinessId,
      });

      if (prevState.userId !== userData._id) {
        this.setState({
          userId: userData._id,
        });
      }

      if (
        prevState.businessUsersList.length !== businessUsersList.data.length
      ) {
        this.setState({
          businessUsersList: businessUsersList.data,
        });
      }
    }
  }

  closeModal() {
    this.setState({
      note: "",
      reReview: "",
      customDate: null,
    });
  }

  async doNotSendNotification() {
    this.setState({
      sendNotificationToCustomer: false,
      roles: {
        admin: false,
        manager: false,
        staff: false,
      },
    });
  }

  setRoles(type, status) {
    let userRoles = this.state.roles;
    //eslint-disable-next-line
    this.state.roles[type] = status;

    userRoles = this.state.roles;

    this.setState({
      roles: userRoles,
    });
  }

  onClickAddNotes = async () => {
    let businessId = this.state.businessId;

    if (businessId) {
      const noteData = {
        userId: this.state.userId,
        note: this.state.note,
        noteType: "info",
        createdDate: new Date(),
      };

      const thisPayload = {
        businessId: businessId,
        noteData: noteData,
        sendNotificationToCustomer: this.state.sendNotificationToCustomer,
        roles: this.state.roles,
        fromAdmin: "yes",
      };

      this.props.createBusinessNotes(thisPayload);

      this.doNotSendNotification();

      this.setState(
        {
          note: "",
        },
        () => {}
      );

      document.getElementById("closeModal").click();
    }
  };

  render() {
    const { currentBusinessId } = this.context;
    const {
      business,
      businessId,
      businessUsersList,
      sendNotificationToCustomer,
      roles,
      note,
      adminEmails,
    } = this.state;

    return (
      <div id="customerInfo" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Customer Info / Notes</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ul
                className="nav nav-tabs"
                role="tablist"
                style={{ marginTop: "25px" }}
              >
                <li role="presentation" className="active">
                  <a
                    href="#info"
                    aria-controls="info"
                    role="tab"
                    data-toggle="tab"
                  >
                    Info
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#notes"
                    aria-controls="info"
                    role="tab"
                    data-toggle="tab"
                  >
                    Notes
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#emails"
                    aria-controls="emails"
                    role="tab"
                    data-toggle="tab"
                    /* onClick={() => {
                        this.getBusinessEmailHistories();
                      }} */
                  >
                    Emails
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div
                  role="tabpanel"
                  className="tab-pane fade in active"
                  id="info"
                >
                  <div className="col-lg-12 col-md-12">
                    <h4>Customers Information</h4>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            <label>Email</label>
                          </th>
                          <th>
                            <label>Email Body</label>
                          </th>
                          <th>
                            <label>Status</label>
                          </th>
                          <th>
                            <label>Created Date</label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {businessUsersList && businessUsersList.length ? (
                          businessUsersList.map((eachValue, eachIndex) => {
                            return (
                              <tr key={eachIndex}>
                                <td>
                                  {eachValue.firstName +
                                    " " +
                                    eachValue.lastName}
                                </td>
                                <td>{eachValue.email}</td>
                                <td>
                                  {eachValue.businessDetails.map((v, i) => {
                                    return v.businessId === currentBusinessId
                                      ? v.groupType
                                      : "";
                                  })}
                                </td>
                                <td>
                                  {moment(eachValue.createdAt).format("LLL")}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="4">No records.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div role="tabpanel" className="tab-pane fade in" id="notes">
                  <div className="col-lg-12 col-md-12">
                    <h4>Notes</h4>
                    {businessUsersList && businessUsersList.length ? (
                      <div>
                        <span
                          className="pull-left"
                          style={
                            sendNotificationToCustomer
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <label
                            className="containerCheck"
                            style={{
                              marginLeft: "10px",
                              top: "-7px",
                              left: "-2px",
                            }}
                          >
                            <input
                              id="chkSendNotificationToCustomer"
                              type="checkbox"
                              checked={sendNotificationToCustomer}
                              onChange={() => {
                                this.setState({
                                  sendNotificationToCustomer: !this.state
                                    .sendNotificationToCustomer,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            <span className="checkmark" />
                          </label>{" "}
                          <label
                            htmlFor="sendToCust"
                            onClick={() => {
                              this.setState({
                                sendNotificationToCustomer: !this.state
                                  .sendNotificationToCustomer,
                              });
                            }}
                            style={{ marginLeft: "30px" }}
                          >
                            &nbsp;&nbsp;&nbsp;Send Customer Notification
                          </label>
                        </span>

                        <span
                          className="pull-left"
                          style={
                            sendNotificationToCustomer
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <input
                            style={{ marginLeft: 30 }}
                            type="checkbox"
                            id="chkAdmin"
                            checked={roles.admin}
                            onChange={() => {
                              this.setRoles("admin", !roles.admin);
                            }}
                          />{" "}
                          <label
                            onClick={() => {
                              this.setRoles("admin", !roles.admin);
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;Admin
                          </label>
                          <input
                            style={{ marginLeft: 30 }}
                            type="checkbox"
                            id="chkManager"
                            checked={roles.manager}
                            onChange={() => {
                              this.setRoles("manager", !roles.manager);
                            }}
                          />{" "}
                          <label
                            onClick={() => {
                              this.setRoles("manager", !roles.manager);
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;Managers
                          </label>
                          <input
                            style={{ marginLeft: 30 }}
                            type="checkbox"
                            id="chkStaff"
                            checked={roles.staff}
                            onChange={() => {
                              this.setRoles("staff", !roles.staff);
                            }}
                          />{" "}
                          <label
                            onClick={() => {
                              this.setRoles("staff", !roles.staff);
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;Staff
                          </label>
                          <label style={{ marginLeft: 30, fontWeight: "bold" }}>
                            <button
                              className="tg-dashboard-link"
                              onClick={() => {
                                this.doNotSendNotification();
                              }}
                              style={{ color: "red" }}
                            >
                              Don't send notification
                            </button>
                          </label>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-lg-9 col-md-9 text-left">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add Note"
                      value={note}
                      onChange={(evt) => {
                        this.setState({ note: evt.target.value });
                      }}
                    />
                  </div>
                  <div
                    className="col-lg-3 col-md-3 text-right"
                    style={{ padding: "0px" }}
                  >
                    <button
                      className="btn-tgdark2 btn-140-width btn-md"
                      onClick={() => {
                        this.onClickAddNotes();
                      }}
                    >
                      Add
                    </button>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            <label>Details</label>
                          </th>
                          <th>
                            <label>User</label>
                          </th>
                          <th>
                            <label>Sent To</label>
                          </th>
                          <th>
                            <label>Date</label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {business && business.notes && business.notes.length ? (
                          business.notes.map((note, noteIndex) => {
                            return (
                              <tr key={note._id}>
                                <td>{note.note}</td>
                                <td>
                                  {note.userName !== "" ? note.userName : ""}{" "}
                                </td>
                                <td style={{ width: "150px" }}>
                                  {note.roles && note.roles.admin ? (
                                    <span>
                                      Admin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      &nbsp;&nbsp;[
                                      <i
                                        classname="fa fa-check"
                                        style={{ color: "#1e7e34" }}
                                        aria-hidden="true"
                                      />]
                                    </span>
                                  ) : (
                                    <span>
                                      Admin&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                      [
                                      <i
                                        classname="fa fa-times"
                                        style={{ color: "#E94E1B" }}
                                        aria-hidden="true"
                                      />]
                                    </span>
                                  )}
                                  <br />
                                  {note.roles && note.roles.manager ? (
                                    <span>
                                      Manager &nbsp;&nbsp; [
                                      <i
                                        classname="fa fa-check"
                                        style={{ color: "#1e7e34" }}
                                        aria-hidden="true"
                                      />]
                                    </span>
                                  ) : (
                                    <span>
                                      Manager &nbsp;&nbsp; [
                                      <i
                                        classname="fa fa-times"
                                        style={{ color: "#E94E1B" }}
                                        aria-hidden="true"
                                      />]
                                    </span>
                                  )}
                                  <br />
                                  {note.roles && note.roles.staff ? (
                                    <span>
                                      Staff&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      &nbsp;&nbsp; [
                                      <i
                                        classname="fa fa-check"
                                        style={{ color: "#1e7e34" }}
                                        aria-hidden="true"
                                      />]
                                    </span>
                                  ) : (
                                    <span>
                                      Staff&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      &nbsp;&nbsp; [
                                      <i
                                        classname="fa fa-times"
                                        style={{ color: "#E94E1B" }}
                                        aria-hidden="true"
                                      />]
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {note.createdDate
                                    ? moment(note.createdDate).format("lll")
                                    : "N/A"}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="4">No records.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div role="tabpanel" className="tab-pane fade" id="emails">
                  <div className="col-lg-12 col-md-12">
                    <h4>Emails</h4>
                    <div className="">
                      <table className="table-responsive">
                        <thead>
                          <tr>
                            <th>
                              <label>Email</label>
                            </th>
                            <th width="50%">
                              <label>Email Body</label>
                            </th>
                            <th>
                              <label>Status</label>
                            </th>
                            <th>
                              <label>Created Date</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {adminEmails && adminEmails.length > 0 ? (
                            adminEmails.map((v, i) => {
                              return (
                                <tr key={Math.random()}>
                                  <td>{v.emailData.emailTag}</td>
                                  <td>{v.emailData.body}</td>
                                  <td>{v.status}</td>
                                  <td>{moment(v.createdAt).format("LLL")}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                No record.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                id="btnCancelDelayFunctionModal"
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CustomerInfoNoteModal.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updateBusinessData, createBusinessNotes },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerInfoNoteModal);
