import { api } from "./api";
import { callApi } from "../helpers/index";

const callOnGetPciScanData = (id) => {
  return api.service("api/scanhistory").find({
    query: {
      businessId: id,
      purpose: "get_pci_histories",
      limit: 10,
    },
  });
};

export { callOnGetPciScanData };
