import React from "react";
import { Route } from "react-router-dom";
function MalwareCleanData({
  index,
  primaryDomain,
  totalIgnoredFiles,
  vulnerabilities,
  businessId,
  reportUrl,
  malwareHistoryId,
  malwareRules,
}) {
  return (
    <>
      <div index={index} className="container-fluid vulnerabilityInfoData">
        <div className="row">
          <div className="col-sm-6">
            <strong>{primaryDomain}</strong>
            <br />
            <p>
              <i
                class="text-success fas fa-exclamation-circle"
                aria-hidden="true"
              />{" "}
              No Vulnerabilities Found
            </p>
            {totalIgnoredFiles >= 1 ? (
              <p>
                <a href="#">{totalIgnoredFiles} Files Ignored</a>
              </p>
            ) : null}
          </div>

          <div className="col-sm-6">
            <Route
              render={({ history }) => (
                <a
                  href="#"
                  style={{
                    textAlign: "right",
                    display: "block",
                    marginTop: "1.3em",
                  }}
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() =>
                    history.push({
                      pathname: "/malware-scanned-files",
                      state: {
                        vulnerabilities: vulnerabilities,
                        domainHost: primaryDomain,
                        businessId: businessId,
                        reportUrl: reportUrl,
                        malwareHistoryId: malwareHistoryId,
                        malwareRules: malwareRules,
                      },
                    })
                  }
                >
                  View All Scanned Files
                </a>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MalwareCleanData;
