import React from "react";
import _ from "lodash";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "../header";
import AppContext from "../../components/context/appContext";

import { updateBusinessData } from "../../modules/actions";

class AccountBusinessData extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userData: {},
      userBusinessData: [],
      businessId: "",
      businessUsers: [],
      primaryDomain: "",
      businessName: "",
      supportPhone: "",
      supportEmail: "",
      billingEmail: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      isEditable: false,
    };

    this.onChangeHandelUserInput = this.onChangeHandelUserInput.bind(this);
  }

  onChangeHandelUserInput(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    this.setState({ [name]: value, isEditable: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData, userBusinessData } = this.context;

    if (
      prevState.userData !== userData &&
      prevState.userBusinessData !== userBusinessData
    ) {
      this.setState(
        {
          userData: userData,
          userBusinessData: userBusinessData,
          businessId:
            userBusinessData && userBusinessData.length > 0
              ? userBusinessData[0]._id
              : "",
        },
        () => {
          this.context.updateCurrentBusinessId(this.state.businessId);
        }
      );

      if (userBusinessData && userBusinessData.length > 0) {
        this.setCurrentBusinessData();
      }
    }
  }

  setCurrentBusinessData() {
    const businessData = this.state.userBusinessData.filter((v, i) => {
      return v._id === this.state.businessId ? v : null;
    });

    if (businessData && businessData.length > 0) {
      this.setState({
        primaryDomain: businessData[0].primaryDomain,
        businessName: businessData[0].businessName,
        supportPhone: businessData[0].supportPhone,
        supportEmail: businessData[0].supportEmail,
        billingEmail: businessData[0].billingEmail,
        address: businessData[0].address,
        address2: businessData[0].address2,
        city: businessData[0].city,
        state: businessData[0].state,
        country: businessData[0].country,
        zip: businessData[0].zip,
      });
    }

    this.context.updateCurrentBusinessId(this.state.businessId);
  }

  updateBusinessData() {
    this.props.updateBusinessData(this.state.businessId, {
      purpose: "accountData",
      primaryDomain: this.state.primaryDomain,
      businessName: this.state.businessName,
      supportPhone: this.state.supportPhone,
      supportEmail: this.state.supportEmail,
      billingEmail: this.state.billingEmail,
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zip: this.state.zip,
    });

    this.setState({
      isEditable: false,
    });
  }

  render() {
    const {
      userData,
      userBusinessData,
      businessId,
      businessUsers,
      primaryDomain,
      businessName,
      supportPhone,
      supportEmail,
      billingEmail,
      address,
      address2,
      city,
      state,
      country,
      zip,
      isEditable,
    } = this.state;

    return (
      <>
        {/* <div className="col-md-2 col-sm-2 col-xs-12 pull-right">
          <select
            id="dateRange"
            className="form-control"
            onChange={(e) => this.onClickSetBusiness(e)}
          >
            <option>Select</option>
          </select>
        </div> */}

        <table id="dash-overview-table" className="table table-striped">
          <tbody className="scan-status-list">
            <tr>
              <td width="60%" />
              <td width="40%" colSpan="2">
                <select
                  id="dateRange"
                  className="form-control"
                  onChange={(evt) =>
                    this.setState(
                      {
                        businessId: evt.target.value,
                      },
                      () => {
                        this.setCurrentBusinessData();
                      }
                    )
                  }
                  value={businessId}
                >
                  {userBusinessData.map((v, i) => {
                    return (
                      <option value={v._id} key={i}>
                        {v.primaryDomain}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
          </tbody>
        </table>

        <div id="PCI-scan">
          <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
            <div className="inner-ptab tab-content">
              <div id="seal" className="tab-pane fade in active">
                <div className="Rtable Rtable--6cols Rtable--collapse">
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">
                      Website Name (Main URL)
                    </span>
                    <br />
                    <input
                      type="text"
                      className="form-control p-sm"
                      name="primaryDomain"
                      value={primaryDomain}
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">Business Name</span>
                    <br />

                    <input
                      type="text"
                      value={businessName}
                      className="form-control p-sm"
                      name="businessName"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">Support Phone</span>
                    <br />
                    <input
                      type="tel"
                      value={supportPhone}
                      className="form-control p-sm"
                      name="supportPhone"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">Support Email</span>
                    <br />
                    <input
                      type="email"
                      value={supportEmail}
                      className="form-control p-sm"
                      name="supportEmail"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">Billing Email</span>
                    <br />
                    <input
                      type="email"
                      value={billingEmail}
                      className="form-control p-sm"
                      name="billingEmail"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                </div>

                <div
                  className="Rtable Rtable--6cols Rtable--collapse"
                  style={{ marginBottom: 50 }}
                >
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">
                      Business Address
                    </span>
                    <br />
                    <input
                      type="text"
                      value={address}
                      className="form-control p-sm"
                      name="address"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">
                      Business Address 2
                    </span>
                    <br />
                    <input
                      type="text"
                      value={address2}
                      className="form-control p-sm"
                      name="address2"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">City</span>
                    <br />
                    <input
                      type="text"
                      value={city}
                      className="form-control p-sm"
                      name="city"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">State</span>
                    <br />
                    <input
                      type="text"
                      value={state}
                      className="form-control p-sm"
                      name="state"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">Country</span>
                    <br />
                    <input
                      type="text"
                      value={country}
                      className="form-control p-sm"
                      name="country"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                  <div className="Rtable-cell p-sm">
                    <span className="Rparent-label-mobile">Zip</span>
                    <br />
                    <input
                      type="text"
                      value={zip}
                      className="form-control p-sm"
                      name="zip"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </div>
                </div>

                {isEditable === true ? (
                  <button
                    className="btn-success btn-140-width btn-md pull-right"
                    onClick={() => {
                      this.updateBusinessData();
                    }}
                  >
                    Save
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AccountBusinessData.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBusinessData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountBusinessData);
