import React from "react";
import { isMobile } from "react-device-detect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "../header";
import ManageYourDomains from "../../components/dashboard/manageYourDomains";
import WelcomePopup from "./welcomePopup";
import ScheduleAppointment from "./scheduleAppointment";
class ManageYourScans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header titlePage="Dashboard" bgColor="#ffffff" />

        <div
          id={
            isMobile ? "mobile-content-wrapper-dash" : "mcontent-wrapper-dash"
          }>
          <div id={isMobile ? "" : "dash-malware-table"}>
            <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
              <div className="inner-ptab tab-content">
                <ManageYourDomains />
              </div>
            </div>
          </div>
        </div>

        <ScheduleAppointment />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageYourScans);
