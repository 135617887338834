import React from "react";
import "./rectangle.css";

const spinnerRect = props => (
  <div
    className="spinner"
    style={{ margin: props.styles, marginBottom: "5px" }}
  >
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
    <div className="rect4" />
    <div className="rect5" />
  </div>
);

export default spinnerRect;
