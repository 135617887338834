import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import Auth from "./authReducer";
import Account from "./accountReducer";
import Business from "./businessReducer";
import Domain from "./domainReducer";
import Insurance from "./insuranceReducer";
import Login from "./loginReducer";
import ScanHistory from "./scanHistoryReducer";
import User from "./userReducer";

export default combineReducers({
  router: routerReducer,
  authStore: Auth,
  accountStore: Account,
  businessStore: Business,
  domainStore: Domain,
  insuranceStore: Insurance,
  loginStore: Login,
  ScanHistoryStore: ScanHistory,
  userStore: User,
});
