import React from "react";
import { Line } from "react-chartjs-2";
import Config from "../../helpers/config";

const charts = ({ xLabels, yData, changeColor, changeColorByIndex }) => {
  let dataSets = [];

  for (let y = 0; y < yData.length; y++) {
    const configData = { ...Config.chart.lineOption };
    configData["label"] = yData[y].label;
    configData["data"] = yData[y].data;

    if (changeColor !== null && changeColorByIndex !== null) {
      if (changeColorByIndex.includes(y)) {
        const colorIndexSelected = `rgb(${changeColor[y]})`;

        configData["backgroundColor"] = colorIndexSelected.toString();
        configData["borderColor"] = colorIndexSelected.toString();
        configData["pointBorderColor"] = colorIndexSelected.toString();
        configData["pointBackgroundColor"] = colorIndexSelected.toString();
        configData["pointHoverBackgroundColor"] = colorIndexSelected.toString();
      }
    }

    dataSets.push(configData);
  }

  const data = {
    labels: xLabels,
    datasets: dataSets,
  };
  return <Line data={data} />;
};

export default charts;
