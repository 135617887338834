import React, { Component } from "react";

class BlackListedDomains extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blacklist: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ blacklist: nextProps.report });
  }

  blacklistInfo() {
    const reports = [...this.state.blacklist.domains];
    let blacklists = null;
    if (reports !== null) {
      blacklists = reports.map((r, key) => {
        return (
          <tr key={key}>
            <td>{r.host}</td>
            <td>{r.status}</td>
          </tr>
        );
      });
    }

    if (reports.length === 0) {
      if (this.state.blacklist.status === "GenericMaliciousObject") {
        return (
          <tr>
            <td>{this.props.host}</td>
            <td>GenericMaliciousObject</td>
          </tr>
        );
      }
    }
    return blacklists;
  }

  render() {
    let info = null;

    const { open } = this.props;

    if (open) {
      info = (
        <div className="container" style={{ padding: "0px" }}>
          <div className="row">
            <div className="col col-sm-3" style={{ padding: "0px" }}>
              <table
                className="table table-borderless"
                style={{
                  border: "0px solid #efefef",
                  borderCollapse: " collapse",
                  borderRadius: "5px",
                  overflow: " hidden",
                }}>
                <thead className="thead-dark">
                  <tr>
                    <th>Host</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>{this.blacklistInfo()}</tbody>
              </table>
            </div>
            <div className="col col-sm-4" />
          </div>
        </div>
      );
    }

    return info;
  }
}

export default BlackListedDomains;
