import { api } from "./api";
import { callApi } from "../helpers/index";

export const callApiGetCheckScanReportsPendingDomainData = async () => {
  return callApi().then((token) => {
    return api.service("check-scan-report").find({
      query: {
        status: "pending",
        checkDate: { $lt: new Date() },
        details: { $elemMatch: { resolvedBy: null } },
        $sort: { createdAt: -1 },
      },
    });
  });
};

export const callApiGetCheckScanReportsDataUsingBusinessId = async (
  businessId
) => {
  return callApi().then((token) => {
    return api
      .service("check-scan-report")
      .find({ query: { businessId: businessId } });
  });
};

export const callApiUpdateCheckScanReportsData = async (id, data) => {
  return callApi().then((token) => {
    return api.service("check-scan-report").patch(id, data);
  });
};
