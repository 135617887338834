import { api } from "./api";
import { callApi } from "../helpers/index";

const callOnGetBusinessReport = (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        type: param,
      },
    };
    return await api.service("reporting").find(query);
  });
};
const callOnGetReportCancelledSites = (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        type: param,
      },
    };
    return await api.service("beyondsecurity").get(null, query);
  });
};

const callOnGetResultMissingScans = async (param) => {
  let domainID = null;
  if (param.domainID === null) {
    const domain = await api.service("domains").find({
      query: { businessId: param.businessID, isSubDomain: { $ne: true } },
    });
    domainID = domain.data[0]._id;
  }
  return callApi().then(async (response) => {
    const query = {
      query: {
        type: "missing",
        businessID: param.businessID,
        domainID: param.domainID === null ? domainID : param.domainID,
        daysMissed: param.daysMissed,
      },
    };
    return await api.service("beyondsecurity").get(null, query);
  });
};

const callOnGetNonExistentScans = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        type: "nonexistent",
      },
    };
    return await api.service("beyondsecurity").get(null, query);
  });
};

const callOnGetNoSealGeneratedCode = async () => {
  return callApi().then(async (response) => {
    return await api.service("business").find({
      query: {
        sealCodeGenerated: false,
        status: { $ne: "canceled" },
        lastMalwareScan: { $ne: null },
        lastPciScanDate: { $ne: null },
      },
    });
  });
};

const callOnUpdateBSTestScan = async (id, label) => {
  return callApi().then(async (response) => {
    return await api.service("beyondsecurityscans").patch(id, {
      label: label,
      updatedAt: Date.now(),
    });
  });
};

const callOnMarkDeleteBSTestScan = async (id) => {
  return callApi().then(async (response) => {
    return await api.service("beyondsecurityscans").patch(id, {
      deleted: true,
      updatedAt: Date.now(),
    });
  });
};

const callDeletableSealsReport = async () => {
  return callApi().then(async (response) => {
    return await api.service("deletable-seals").find({});
  });
};

const callDeleteSeal = async (id) => {
  return callApi().then(async (response) => {
    return await api.service("deletable-seals").remove(id);
  });
};

export {
  callOnGetBusinessReport,
  callOnGetReportCancelledSites,
  callOnGetResultMissingScans,
  callOnGetNonExistentScans,
  callOnGetNoSealGeneratedCode,
  callOnUpdateBSTestScan,
  callOnMarkDeleteBSTestScan,
  callDeletableSealsReport,
  callDeleteSeal,
};
