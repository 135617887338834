import React, { Component } from "react";
import { convertTimeTo } from "../../../helpers/timeZone";
import {
  callOnGetBusinessById,
  callOnModifyBusinessStatus,
} from "../../../services/businessServiceCopy";

class ScanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: null,
      scanStatus: null,
      scanNote: "",
      scanDetailsLog: null,
      scanTimeZone: "America/Denver",
    };
  }

  loadBusinessDataState = async (newProps) => {
    const business = await callOnGetBusinessById(newProps.selectedBusinessId);

    if (business) {
      this.setState({
        businessId: this.props.selectedBusinessId,
        scanStatus: business.pciStatus,
        scanTimeZone:
          business.pciScanTimeZone !== null
            ? business.pciScanTimeZone
            : "America/Denver",
        scanDetailsLog: business.hasOwnProperty("scanDetailsLog")
          ? business.scanDetailsLog
          : null,
      });
    }
  };

  async componentWillReceiveProps(newProps) {
    await this.loadBusinessDataState(newProps);
  }

  noteHandler = (e) => {
    this.setState({
      scanNote: e.target.value,
    });
  };
  scanStatusHandler = (e) => {
    this.setState({
      scanStatus: e.target.value,
    });
  };

  modifyScanDetailHandler = async () => {
    await callOnModifyBusinessStatus(
      this.state.businessId,
      this.state.scanStatus,
      this.state.scanNote,
      this.props.userId
    );

    await this.loadBusinessDataState(this.state.businessId);

    this.setState({
      businessId: null,
      scanStatus: null,
      scanNote: "",
      scanDetailsLog: null,
    });
  };

  closeModal = () => {
    this.setState({
      businessId: null,
      scanStatus: null,
      scanNote: "",
      scanDetailsLog: null,
    });
  };

  render() {
    const { scanStatus, scanNote, scanDetailsLog, scanTimeZone } = this.state;

    return (
      <div className="modal-content col-md-12">
        <div className="modal-header">
          <h4 className="modal-title f800">Modify Scan Details</h4>
          <button
            id="closeCustomerNoteModal"
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => this.closeModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="scanStatus"
                  value="pass"
                  checked={scanStatus === "pass" ? true : false}
                  onChange={(e) => this.scanStatusHandler(e)}
                />&nbsp;&nbsp;
                <label className="form-check-label" htmlFor="exampleRadios1">
                  Pass Scan Status
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  className="form-check-input"
                  type="radio"
                  name="scanStatus"
                  value="fail"
                  onChange={(e) => this.scanStatusHandler(e)}
                  checked={scanStatus === "fail" ? true : false}
                />&nbsp;&nbsp;
                <label className="form-check-label" htmlFor="exampleRadios1">
                  Fail Scan Status
                </label>
              </div>

              <div className="form-check" />
            </div>
            <div className="col">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="put your note here..."
                  value={scanNote}
                  onChange={this.noteHandler}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">Current Status</th>
                  <th scope="col">Note</th>
                  <th scope="col">Updated by</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                {scanDetailsLog !== null
                  ? scanDetailsLog.length > 0
                    ? scanDetailsLog.map((detail, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row" />
                            <td>{detail.currentStatus}</td>
                            <td>{detail.notes}</td>
                            <td>{detail.changedByUser}</td>
                            <td>
                              {convertTimeTo(detail.updatedAt, scanTimeZone)}
                            </td>
                          </tr>
                        );
                      })
                    : null
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn-danger pull-right btn-140-width btn-md"
            data-dismiss="modal"
            onClick={() => this.modifyScanDetailHandler()}
          >
            Update
          </button>
        </div>
      </div>
    );
  }
}

export default ScanDetails;
