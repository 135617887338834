import React from "react";
import { malwareRowStatus } from "../../../../../helpers/general";
import ModalMalwareVulnerability from "../../modals/modalMalwareVunerabilityInfo";
import MalwareReportButton from "../buttons/malwareReportButton";
import moment from "moment";

const malwareDomainsRow = ({
  domains,
  hosts,
  malwareInfoData,
  malware,
  index,
}) => {
  let malwareDomains = null;
  let showMalwareRow = null;

  if (domains.length > 0) {
    malwareDomains = domains.map((domain, i) => {
      const malwareStatus = malwareRowStatus(domain.status);
      return (
        <tr style={{ background: "rgb(249, 248, 248)", padding: "10px" }}>
          <td />
          <td>{moment(domain.scanStart).format("YYYY/MM/DD h:m a")}</td>
          <td>{hosts[i]}</td>
          <td>
            <span style={malwareStatus.styleStatus}>
              <i class={malwareStatus.iconStatus} /> &nbsp;
              {domain.status}
            </span>
          </td>
          <td>
            <MalwareReportButton
              status={domain.status}
              targetID={malware._id}
              getMalwareInfo={() => {}}
            />
            {/* <ModalMalwareVulnerability
              malwareInfoData={malwareInfoData}
              targetID={malware._id}
              index={index}
            /> */}
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    });
  }
  if (domains.length > 0) {
    showMalwareRow = (
      <>
        <tr style={{ background: "rgb(249, 248, 248)", padding: "10px" }}>
          <th />
          <th>Last Scanned</th>
          <th>Domain</th>
          <th>Detection</th>
          <th>Status</th>
          <th />
          <th />
          <th />
        </tr>
        {malwareDomains}
      </>
    );
  }

  return showMalwareRow;
};
export default malwareDomainsRow;
