import { api } from "./api";
import { callApi } from "../helpers/index";
import moment from "moment-timezone";
import { validateDomain } from "../helpers/general";

const callOnGetBusinessById = (id) => {
  return callApi().then((token) => {
    if (id) {
      return api.service("business").get(id);
    } else {
      return null;
    }
  });
};

const callOnModifyBusinessStatus = (id, status, note, userId) => {
  return callApi().then(async (token) => {
    const query = {
      query: {
        businessId: id,
        scanDate: { $ne: null },
        status: { $nin: ["pending", "running", "finalizing"] },
        $sort: { createdAt: -1 },
        $limit: 1,
      },
    };

    const scanResult = await api.service("scanhistory").find(query);
    await api.service("scanhistory").patch(
      scanResult.data[0]._id,
      {
        status: status,
      },
      query
    );

    const user = await api.service("users").find(userId);

    const scanStatusDetails = {
      currentStatus: status,
      previousStatus: status === "pass" ? "fail" : "pass",
      notes: note,
      changedByUser: user.data[0].firstName + " " + user.data[0].lastName,
      updatedAt: new Date(),
    };

    await api.service("business").patch(id, {
      pciStatus: status,
      lastPciStatus: status,
      $push: { scanDetailsLog: scanStatusDetails },
    });
  });
};

const callOnUpdateBusinessFrequency = (data) => {
  return callApi().then(async (token) => {
    const id = data._id;
    const business = data.business;
    business["_id"] = id;
    const userId = data.userId;
    const scanTimeZone =
      data.timeZone !== null || data.timeZone !== undefined
        ? data.timeZone
        : "America/Denver";
    const scanTimeHour =
      data.timeHour !== null || data.timeHour !== undefined ? data.timeHour : 8;

    const record = {
      scanFrequency: data.scanFrequency,
      pciScanTimeZone: scanTimeZone,
      pciScanTime: scanTimeHour,
      pciNextScheduleScanDate: data.pciNextScheduleScanDate,
    };
    const updateFrequencyAction = await api
      .service("beyondsecurity")
      .update(1, business);

    await api.service("users").patch(userId, {
      timezone: scanTimeZone,
      pciScanTime: scanTimeHour,
    });
    api.service("business").patch(id, record);
    if (!updateFrequencyAction.success) {
      return updateFrequencyAction;
    }
  });
};

const callOnUpdateBusinessBeyondSecurityTimePrefs = (data) => {
  return callApi().then(async (token) => {
    if (
      data.scanTimeDetails !== undefined &&
      data.scanTimeDetails !== undefined
    ) {
      const businessId = data._id;
      const scanTime = data.scanTimeDetails.scanTime;
      const scanTimeZone = data.scanTimeDetails.scanTimeZone;

      const updateRecord = {
        pciScanTime: scanTime,
        pciScanTimeZone: scanTimeZone,
      };

      const malwareBusinessInfo = await api.service("business").get(businessId);

      if (malwareBusinessInfo.malwareStatusReport != null) {
        let frequency = "daily";
        switch (malwareBusinessInfo.malwareFrequency) {
          case "monthly":
            frequency = "months";
            break;
          case "weekly":
            frequency = "weeks";
            break;
          case "quarterly":
            frequency = "quarters";
            break;
          default:
            frequency = "daily";
            break;
        }

        const beyondSecurityUserScanTime =
          moment()
            .add(1, "days")
            .format("YYYY-MM-DD") +
          " " +
          (scanTime < 10 ? "0" : "") +
          scanTime +
          ":00:00";

        const newMalwareScanDateTime = moment
          .tz(beyondSecurityUserScanTime, scanTimeZone)
          .add(1, frequency);
        updateRecord["nextMalwareScan"] = newMalwareScanDateTime;
      }

      await api.service("business").patch(businessId, updateRecord);
    }
  });
};

const callOnUpdateBusinessReportOptions = (data) => {
  return callApi().then((token) => {
    const id = data._id;
    const record = {
      availableReports: data.availableReports,
    };

    return api.service("business").patch(id, record);
  });
};

const callOnUpdateBusinessCodeSealGenerated = (data) => {
  return callApi().then(async (token) => {
    const id = data._id;
    const record = {
      sealCodeGenerated: true,
      lastGeneratedDate: Date.now(),
      businessId: id,
    };
    return api.service("business").patch(id, record);
  });
};

const callOnUpdateBusinessPrimaryDomain = (data) => {
  return callApi().then(async (token) => {
    const id = data._id;

    let domainData = await api
      .service("domains")
      .find({ query: { businessId: data._id } });

    await api
      .service("domains")
      .patch(domainData.data[0]._id, { host: data.primaryDomain });

    const params = {
      primaryDomain: data.primaryDomain,
      pciProgressStatus: null,
    };

    let newBusinessNameDomain = data.businessName;
    const businessNameIsURL = validateDomain(data.businessName);
    if (businessNameIsURL) {
      newBusinessNameDomain = data.primaryDomain;
      params["businessName"] = newBusinessNameDomain;
    }

    return api.service("business").patch(id, params, {
      query: {
        patchBSdomain: true,
        id: id,
        primaryDomain: data.primaryDomain,
        scanVendor: data.scanVendor,
        businessName: newBusinessNameDomain,
      },
    });
  });
};

const callOnGetBusinessByOwner = (id) => {
  return callApi().then((token) => {
    return api.service("business").find({
      query: {
        "scanVendor.owner": id,
        $limit: 1,
        $sort: { createdAt: -1 },
      },
    });
  });
};

const callOnGetUserByBusinessId = (id) => {
  return callApi().then((token) => {
    return api.service("users").find({
      query: {
        "businessDetails.businessId": id,
        $limit: 1,
        $sort: { createdAt: 1 },
      },
    });
  });
};

const callOnRemoveBusinessMalwareRule = (data) => {
  return callApi().then((token) => {
    return api.service("business").update(data.id, {
      $pull: {
        malwareRules: {
          rule: data.rule.rule,
        },
      },
    });
  });
};

const callOnUpdateBusinessMalwareRule = (data) => {
  return callApi().then((token) => {
    let param = {
      $set: {
        "malwareRules.$[].rule": data.rule,
      },
    };
    if (data.newRule) {
      param = {
        $push: {
          malwareRules: {
            rule: data.rule,
            dumpMD5: data.dump,
            activated: true,
            createdAt: new Date(),
          },
        },
      };
    }
    return api.service("business").update(data.id, param);
  });
};

const callOnUpdateBusinessData = (businessId, data) => {
  return callApi().then((token) => {
    return api.service("business").patch(businessId, data);
  });
};

const callOnGetAdditionalScanInformation = (id) => {
  return callApi().then((token) => {
    return {
      /*eslint-disable*/
      hostData: {
        region_code: null,
        ip: "41.21.249.170",
        area_code: null,
        country_name: "South Africa",
        hostnames: [],
        postal_code: null,
        dma_code: null,
        country_code: "ZA",
        data: [
          {
            product: "Siemens HiPath 3000 telnetd",
            os: null,
            timestamp: "2014-01-12T18:25:41.370550",
            isp: "Vodacom",
            asn: "AS36994",
            banner:
              "\r************************\n\r*  HiPath 3000 Telnet  *\n\r*  ------------------  *\n\r*                      *\n\r*  Adjust the Telnet   *\n\r*  window to the       *\n\r*  visible frame       *\n\r*                      *\n\r************************\n\r",
            hostnames: [],
            devicetype: "firewall",
            location: {
              city: null,
              region_code: null,
              area_code: null,
              longitude: 24.0,
              country_code3: "ZAF",
              country_name: "South Africa",
              postal_code: null,
              dma_code: null,
              country_code: "ZA",
              latitude: -29.0,
            },
            ip: "41.21.249.170",
            domains: [],
            org: "Vodacom",
            port: 23,
            opts: {},
          },
          {
            os: null,
            timestamp: "2014-01-01T01:28:55.903352",
            isp: "Vodacom",
            asn: "AS36994",
            banner: "SNMP agent for HiPath 3000/5000 V5.x",
            hostnames: [],
            location: {
              city: null,
              region_code: null,
              area_code: null,
              longitude: 24.0,
              country_code3: "ZAF",
              country_name: "South Africa",
              postal_code: null,
              dma_code: null,
              country_code: "ZA",
              latitude: -29.0,
            },
            ip: "41.21.249.170",
            domains: [],
            org: "Vodacom",
            port: 161,
            opts: {},
          },
        ],
        city: null,
        longitude: 24.0,
        country_code3: "ZAF",
        latitude: -29.0,
        os: null,
        ports: [23, 161],
      },
      domanData: {
        domain: "cnn.com",
        tags: ["ipv6", "spf"],
        data: [
          {
            subdomain: "",
            type: "NS",
            value: "ns-47.awsdns-05.com",
            last_seen: "2019-06-22T18:48:21.302051+00:00",
          },
          {
            subdomain: "",
            type: "A",
            value: "151.101.129.67",
            last_seen: "2019-06-29T17:47:30.649004+00:00",
          },
        ],
      },
      scanData: {
        scan: {
          id: "R2XRT5HH6X67PFAB",
          count: 1,
          credits_left: 5119,
        },
        /*eslint-enable*/
        scanStatus: {
          id: "R2XRT5HH6X67PFAB",
          count: 1,
          status: "PROCESSING",
        },
      },
    };
  });
};

const callOnGetScanProgress = (id, showCurrentProgress) => {
  return api.service("scanprogress").find({
    query: {
      businessId: id,
      userdata: null,
      showCurrentProgress: showCurrentProgress,
    },
  });
};

export {
  callOnGetBusinessById,
  callOnModifyBusinessStatus,
  callOnUpdateBusinessFrequency,
  callOnUpdateBusinessReportOptions,
  callOnUpdateBusinessCodeSealGenerated,
  callOnGetBusinessByOwner,
  callOnGetUserByBusinessId,
  callOnUpdateBusinessBeyondSecurityTimePrefs,
  callOnUpdateBusinessPrimaryDomain,
  callOnGetAdditionalScanInformation,
  callOnGetScanProgress,
  callOnUpdateBusinessMalwareRule,
  callOnUpdateBusinessData,
  callOnRemoveBusinessMalwareRule,
};
