import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";

import { callApiGetCheckScanReportsPendingDomainData } from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class InvalidSslScanReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      display: "none",
    };
  }

  async componentDidMount() {}

  render() {
    const { display } = this.state;

    return (
      <>
        <div className="reportButton">
          <button
            className="btn-success btn-sm"
            onClick={() => {
              this.setState({
                display: display === "block" ? "none" : "block",
              });
            }}
          >
            Invalid Scans
          </button>
        </div>
        <p>Invalid Scans</p>

        <hr />

        <div className="table-responsive" style={{ display: display }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Domain/Subdomains/IPs</th>
                <th>Last SSL Validation</th>
                <th>Frequency</th>
                <th>SSL Provider</th>
                <th>Expiration</th>
                <th>Status</th>
                <th>Email Client</th>
              </tr>
            </thead>
            <tbody className="scan-status-list">
              <tr>
                <td colSpan="7">No records.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

InvalidSslScanReport.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvalidSslScanReport);
