import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Header from "../header";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../charts/line";

import AppContext from "../../components/context/appContext";
import BuyerProtectionForm from "./buyerProtectionForm";

class BpStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userBusinessData: [],
      businessId: "",
      ordersData: [0, 0, 0, 0, 0, 0, 0],
      registeredData: [0, 0, 0, 0, 0, 0, 0],
      last30DaysOrders: 0,
      last30DaysRegistered: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userBusinessData } = this.context;

    if (prevState.userBusinessData !== userBusinessData) {
      this.setState(
        {
          userBusinessData: userBusinessData,
          businessId:
            userBusinessData && userBusinessData.length > 0
              ? userBusinessData[0]._id
              : "",
        },
        () => {
          this.context.updateCurrentBusinessId(this.state.businessId);
        }
      );
    }
  }
  render() {
    const {
      statisticsActive,
      ordersData,
      registeredData,
      last30DaysOrders,
      last30DaysRegistered,
    } = this.state;

    const xLabels = [
      moment(
        moment()
          .subtract(6, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(5, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(4, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment().format("MMMM"),
    ];

    const yData = [
      {
        label: "Orders",
        data: last30DaysOrders,
      },
      {
        label: "Registered",
        data: last30DaysRegistered,
      },
    ];
    return (
      <>
        <div>
          <h4>Last 30 days</h4>
          <h4>Orders: {last30DaysOrders}</h4>
          <h4>Registered: {last30DaysRegistered}</h4>
        </div>
        <hr />
        <h4>
          Statistics{" "}
          <button className="tg-dashboard-link">
            {statisticsActive ? (
              <i className="fa fa-angle-up" />
            ) : (
              <i className="fa fa-angle-down" />
            )}
          </button>
        </h4>
        <>
          <Line
            xLabels={xLabels}
            yData={yData}
            changeColor={[null, "222,214,162"]}
            changeColorByIndex={[1]}
          />
        </>
      </>
    );
  }
}

BpStatistics.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BpStatistics);
