import React from "react";
import DelayFunctionModal from "../modals/delayFunctionModal";
import CustomerInfoNoteModal from "../modals/customerInfoNoteModal";
import DeleteTrustsealsModal from "../modals/deleteTrustsealsModal";
import EnableBuyerProtectionModal from "../modals/enableBuyerProtectionModal";
import AdminRefreshSealsModal from "../modals/adminRefreshSealsModal";
import RetriveMissingScansModal from "../modals/retriveMissingScansModal";
import ModifyScanDetailsModal from "../modals/modifyScanDetailsModal";
import PrimaryDomainUpdateModal from "../modals/primaryDomainUpdateModal";
import InsuranceDocumentsModal from "../modals/insuranceDocumentsModal";
import ActivateInsuranceModal from "../modals/activateInsuranceModal";
import ActivateOrCancelBusinessModal from "../modals/activateOrCancelBusinessModal";

class ControlPanelModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <DelayFunctionModal />
        <CustomerInfoNoteModal />
        <DeleteTrustsealsModal />
        <EnableBuyerProtectionModal />
        <AdminRefreshSealsModal />
        <RetriveMissingScansModal />
        <ModifyScanDetailsModal />
        <PrimaryDomainUpdateModal />
        <InsuranceDocumentsModal />
        <ActivateInsuranceModal />
        <ActivateOrCancelBusinessModal />
      </>
    );
  }
}

export default ControlPanelModals;
