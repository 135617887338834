import React from "react";
import Modal from "../../../ui/modal/modal";
const modalDetailsScanOpt = ({}) => {
  return (
    <Modal targetID="scanDetailsUpdateModal" title="Refresh Seals">
      <div className="pt-3 pb-5 text-left">
        <strong>
          You can modify scan status from "Pass to Fail" and vice versa.
        </strong>
      </div>

      <div className="modal-footer pt-4 px-0" set="modal-footer">
        <button
          type="button"
          className="btn-success btn-md pull-right btn-140-width"
          data-dismiss="modal"
        >
          Modify
        </button>
      </div>
    </Modal>
  );
};

export default modalDetailsScanOpt;
