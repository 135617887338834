import React from "react";
import ReportsTab from "./reportsTab";
import AdvancedSearchTab from "./advancedSearchTab";
import CreateAccountTab from "./createAccountTab";
import EmailTab from "./emailTab";
import OneTimeEmailTab from "./oneTimeEmailTab";
import SealScriptsTab from "./sealScriptsTab";

class ControlPanelTabBody extends React.Component {
  state = {};
  render() {
    return (
      <div id="mcontent-wrapper">
        <div id="dash-malware-table">
          <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
            <div className="inner-ptab tab-content">
              <ReportsTab />
              <AdvancedSearchTab />
              <CreateAccountTab />
              <EmailTab />
              <OneTimeEmailTab />
              <SealScriptsTab />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ControlPanelTabBody;
