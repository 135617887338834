import {
  api,
  callOnSignUpUser,
  callOnCreateUserAccount,
  callOnUpdateContact,
  callGetBusiness,
  callGetAllUsers,
  callCheckUserEmailExistOrNot,
  callUpdateExistUserWithNewBusiness,
  callGetAdminDetails,
  callGetBusinessDetailsById,
  callGetUserById,
  callGetUserDetails,
  callUpdateUserProfile,
  callChangeUserPassword,
  callOnSaveNotification,
  callGetDataSearchBy,
  callCreateDomain,
  callGetDomains,
  callDomainSearchByBusiness,
  callUpdateEditedBusiness,
  callDeleteSubDomain,
  callOnClickSendMail,
  callCreateNotification,
  callCancelAndActiveBusiness,
  callGetBusinesses,
  callGetFailedScanDomains,
  callUpdateBusinessCertificate,
  callOnCreateEmail,
  callGetAllEmails,
  callOnUpdateEmail,
  callOnDeleteEmail,
  callCreateDeletedEamil,
  callGetDomainsUsingStatus,
  callGetCheckScanReports,
  callOnGetUserListUsingBusinessIdAndRoles,
  callOnGetDomainDataUsingBusinessPrimaryDomain,
  callOnGetEmailDataUsingEmailTag,
  callOnCreateEmailHistory,
  callOnGetUserListUsingBusinessId,
  callCreateBatchNotification,
  callOnFindBusinessTrustSeal,
  callOnCreateTrustSeal,
  callOnUpdateTrustSeal,
  callOnClickRemoveTrustSealsSettingsDate,
  callGetBusinessDetailsByPrimaryDomain,
  callUpdateBusinessCertificateContact,
  callGetEmailHistories,
  callSkippedActionByMember,
  callUpdateUserWelcomePopup,
  callGetEmailHistoryDetail,
  callGetDeleteUser,
  callOnClickSaveResolvedNote,
  callOnClickSaveResolvedBy,
  callGetBusinessUsers,
  callUpdateUserData,
  callUpdateBusinessAccountType,
  callInsuranceIndustryList,
  callPostInsuranceData,
  callGetInsuranceHistories,
  callCreateCssHash,
  callCreateJsHash,
  callGetCssJsHashList,
  callCreateOneTimeEmail,
  callGetOneTimeEmails,
  callGetAllBusinessUsersForOtherMessages,
  callGet30DaysOldFreeBusinesses,
  callRegeneraeAllSeals,
  callGetSmsHistories,
  callGetSmsHistoryDetail,
  callGetSslRenewalDomains,
  callUpdateSslRenewalDomain,
  callUpdateOnboardingPopupData,
  callsave4xIntegrationStatus,
  callSaveSmsTiming,
  callCreate4xAccount,
  callActionAgainstCancelledTrustSeals,
} from "../../services/index";

import {
  CREATE_USER_ACCOUNT_SUCCESS,
  CREATE_USER_ACCOUNT_FAILED,
  CREATE_ACCOUNT_FAILED,
  GET_ALL_BUSINESS_SUCCESS,
  GET_ALL_BUSINESS_FAILED,
  RESET_MESSAGES,
  USER_CREATE_FAILED,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILED,
  GET_ONE_BUSINESS_SUCCESS,
  GET_ONE_BUSINESS_FAILED,
  GET_ONE_USER_SUCCESS,
  USER_ROLES_UPDATE_SUCCESS,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PASSWORD_SUCCESS,
  SEARCH_BY_ITEM_SUCCESS,
  SEARCH_BY_ITEM_FAILED,
  CREATE_DOMAIN_SUCCESS,
  GET_DOMAINS_SUCCESS,
  UPDATE_BUSINESS_SUCCESS,
  DELETE_SUBDOMAIN_SUCCESS,
  GET_BUSINESSES_SUCCESS,
  GET_BUSINESSES_FAILED,
  NOTE_CREATED_SUCCESS,
  NOTE_CREATED_FAILED,
  UPDATE_BUSINESS_CERTIFICATE_SUCCESS,
  UPDATE_BUSINESS_CERTIFICATE_FAILED,
  GET_PENDING_SCAN_BUSINESS_SUCCESS,
  EMAIL_CREATED_SUCCESS,
  EMAIL_CREATED_FAILED,
  GET_ALL_EMAILS_SUCCESS,
  GET_ALL_EMAILS_FAILED,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILED,
  INACTIVE_EMAIL_SUCCESS,
  GET_DOMAINS_USING_STATUS_SUCCESS,
  GET_DOMAINS_USING_STATUS_FALIURE,
  GET_CHECK_SCAN_REPORTS_SUCCESS,
  GET_CHECK_SCAN_REPORTS_FALIURE,
  GET_USER_LIST_USING_BUSINESS_ID_SUCCESS,
  GET_USER_LIST_USING_BUSINESS_ID_FALIURE,
  UPDATE_SKIPPED_MEMBER_SUCCESS,
  UPDATE_SKIPPED_MEMBER_FALIURE,
  GET_TRUSTSEAL_SUCCESS,
  GET_TRUSTSEAL_FALIURE,
  CREATE_TRUSTSEAL_SUCCESS,
  CREATE_TRUSTSEAL_FALIURE,
  UPDATE_TRUSTSEAL_SUCCESS,
  UPDATE_TRUSTSEAL_FALIURE,
  SEALS_DATA_UPDATED_SUCCESS,
  SEALS_DATA_UPDATED_FAILURE,
  GET_BUSINESS_USING_PRIMARY_DOMAIN_SUCCESS,
  GET_BUSINESS_USING_PRIMARY_DOMAIN_FAILED,
  UPDATE_BUSINESS_CERTIFICATE_CONTACT_SUCCESS,
  UPDATE_BUSINESS_CERTIFICATE_CONTACT_FAILED,
  GET_EMAIL_HISTORIES_SUCCESS,
  GET_EMAIL_HISTORIES_FALIURE,
  UPDATE_WELCOME_POPUP_SUCCESS,
  UPDATE_WELCOME_POPUP_FAILURE,
  GET_EMAIL_HISTORY_DETAIL_SUCCESS,
  GET_EMAIL_HISTORY_DETAIL_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  RESOLVED_NOTE_SAVE_SUCCESS,
  RESOLVED_NOTE_SAVE_FAILURE,
  BUSINESS_USERS_SUCCESS,
  BUSINESS_USERS_FAILURE,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
  UPDATE_BUSINESS_DATA_SUCCESS,
  UPDATE_BUSINESS_DATA_FAILURE,
  GET_INSURANCE_INDUSTRY_LIST_SUCCESS,
  GET_INSURANCE_INDUSTRY_LIST_FAILURE,
  POST_INSURANCE_DATA_SUCCESS,
  POST_INSURANCE_DATA_FAILURE,
  UPDATE_APP_SIDEBAR_MENUS,
  GET_INSURANCE_HISTORIES_SUCCESS,
  GET_INSURANCE_HISTORIES_FALIURE,
  CREATE_CSS_HASH_SUCCESS,
  CREATE_CSS_HASH_FAILURE,
  CREATE_JS_HASH_SUCCESS,
  CREATE_JS_HASH_FAILURE,
  SEND_REPORT_EMAIL_SUCCESS,
  SEND_REPORT_EMAIL_FAILURE,
  GET_ALL_CSS_JS_HASH_SUCCESS,
  GET_ALL_CSS_JS_HASH_FAILURE,
  CREATE_ONE_TIME_EMAIL_SUCCESS,
  CREATE_ONE_TIME_EMAIL_FAILURE,
  GET_ONE_TIME_EMAIL_SUCCESS,
  GET_ONE_TIME_EMAIL_FAILURE,
  GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_SUCCESS,
  GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_FAILURE,
  GET_OLD_FREE_BUSINESS_LIST_SUCCESS,
  GET_OLD_FREE_BUSINESS_LIST_FAILURE,
  REGENERATE_ALL_SEALS_SUCCESS,
  REGENERATE_ALL_SEALS_FAILURE,
  GET_SMS_HISTORIES_SUCCESS,
  GET_SMS_HISTORIES_FAILURE,
  GET_SMS_HISTORY_DETAIL_SUCCESS,
  GET_SMS_HISTORY_DETAIL_FAILURE,
  GET_ALL_SSL_RENEWAL_DOMAINS_SUCCESS,
  GET_ALL_SSL_RENEWAL_DOMAINS_FAILURE,
  UPDATE_SSL_RENEWAL_DOMAINS_SUCCESS,
  UPDATE_SSL_RENEWAL_DOMAINS_FAILURE,
  UPDATE_ONBOARDING_POPUP_DATA_SUCCESS,
  UPDATE_ONBOARDING_POPUP_DATA_FAILURE,
  SEND_TEST_EMAIL_SUCCESS,
  SEND_TEST_EMAIL_FAILURE,
  UPDATE_ACCOUNT_4X_DETAILS_SUCCESS,
  UPDATE_ACCOUNT_4X_DETAILS_FAILURE,
  SAVE_SMS_TIMING_SUCCESS,
  SAVE_SMS_TIMING_FAILURE,
  DELETE_TRUSTSEALS_SUCCESS,
  DELETE_TRUSTSEALS_FAILURE,
} from "../types/index";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setStorageItem, removeStorageItem } from "../../helpers/storage";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

export const resetStatus = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_MESSAGES,
    });
  };
};

export const onCreateAccount = (data) => {
  return async (dispatch) => {
    return callOnSignUpUser(data)
      .then((userData) => {
        if (userData.success) {
          dispatch({
            type: CREATE_USER_ACCOUNT_SUCCESS,
          });
        } else {
          dispatch({
            type: CREATE_USER_ACCOUNT_FAILED,
            message: userData.errors
              ? userData.errors.website
                ? userData.errors.website
                : ""
              : "",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATE_USER_ACCOUNT_FAILED,
          message: err.message,
        });
      });
  };
};

export const onFalseWelcomePopup = (id, data) => {
  return async (dispatch) => {
    return callUpdateUserWelcomePopup(id, data)
      .then((user) => {
        dispatch({
          type: UPDATE_WELCOME_POPUP_SUCCESS,
          payload: user,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_WELCOME_POPUP_FAILURE,
          message: err.message,
        });
      });
  };
};

export const getBusiness = () => {
  return async (dispatch) => {
    return callGetBusiness()
      .then((business) => {
        dispatch({
          type: GET_ALL_BUSINESS_SUCCESS,
          payload: business,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_BUSINESS_FAILED,
        });
      });
  };
};

export const getDomainsUsingStatus = (domainQuery) => {
  return async (dispatch) => {
    return callGetDomainsUsingStatus(domainQuery)
      .then((domainList) => {
        dispatch({
          type: GET_DOMAINS_USING_STATUS_SUCCESS,
          payload: domainList,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_DOMAINS_USING_STATUS_FALIURE,
        });
      });
  };
};

export const getEmailHistories = (startDate = null, endDate = null) => {
  return async (dispatch) => {
    return callGetEmailHistories(startDate, endDate)
      .then((data) => {
        dispatch({
          type: GET_EMAIL_HISTORIES_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_EMAIL_HISTORIES_FALIURE,
        });
      });
  };
};

export const getSmsHistories = (startDate = null, endDate = null) => {
  return async (dispatch) => {
    return callGetSmsHistories(startDate, endDate)
      .then((data) => {
        dispatch({
          type: GET_SMS_HISTORIES_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_SMS_HISTORIES_FAILURE,
        });
      });
  };
};

export const getInsuranceHistories = (startDate = null, endDate = null) => {
  return async (dispatch) => {
    return callGetInsuranceHistories(startDate, endDate)
      .then((data) => {
        dispatch({
          type: GET_INSURANCE_HISTORIES_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INSURANCE_HISTORIES_FALIURE,
        });
      });
  };
};

export const getEmailHistoryDetail = (
  emailTag = null,
  startDate = null,
  endDate = null
) => {
  return async (dispatch) => {
    return callGetEmailHistoryDetail(emailTag, startDate, endDate)
      .then((data) => {
        dispatch({
          type: GET_EMAIL_HISTORY_DETAIL_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_EMAIL_HISTORY_DETAIL_FAILURE,
        });
      });
  };
};

export const getSmsHistoryDetail = (
  emailTag = null,
  startDate = null,
  endDate = null
) => {
  return async (dispatch) => {
    return callGetSmsHistoryDetail(emailTag, startDate, endDate)
      .then((data) => {
        dispatch({
          type: GET_SMS_HISTORY_DETAIL_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_SMS_HISTORY_DETAIL_FAILURE,
        });
      });
  };
};

export const getUserById = (_id) => {
  return async (dispatch) => {
    return callGetUserById(_id)
      .then((user) => {
        dispatch({
          type: GET_ONE_USER_SUCCESS,
          payload: user,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_USERS_FAILED,
        });
      });
  };
};

export const getAllUsers = (query) => {
  return async (dispatch) => {
    return callGetAllUsers(query)
      .then((users) => {
        dispatch({
          type: GET_ALL_USERS_SUCCESS,
          payload: users.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_USERS_FAILED,
        });
      });
  };
};

export const onCreateUserAccount = (userData) => {
  return async (dispatch) => {
    if (userData) {
      const query = {
        query: {
          email: userData.email,
        },
      };
      return callCheckUserEmailExistOrNot(query)
        .then((checkEmail) => {
          let createMailHistory;
          if (checkEmail.data.length > 0) {
            let _id = checkEmail.data.map((item) => item._id);
            return callGetUserDetails(_id)
              .then((data) => {
                let businessDetails = data.businessDetails;
                let userRole = businessDetails.filter((role) => {
                  return (
                    role.businessId === userData.businessDetails.businessId
                  );
                });
                if (userRole.length > 0) {
                  toast.info("User already exists.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  businessDetails.push({
                    businessId: userData.businessDetails.businessId,
                    groupType: userData.businessDetails.groupType,
                    emailNotifications: [],
                  });
                  const createExistUser = {
                    businessDetails: businessDetails,
                  };
                  return callUpdateExistUserWithNewBusiness(
                    _id,
                    createExistUser
                  )
                    .then((data) => {
                      createMailHistory = {
                        action: "create_email_history",
                        emailTag: "new-staff",
                        businessId: userData.businessDetails.businessId,
                        query: {
                          email: data.email,
                        },
                      };

                      return callOnClickSendMail(createMailHistory)
                        .then((code) => {
                          dispatch({
                            type: USER_ROLES_UPDATE_SUCCESS,
                            payload: data,
                          });
                        })
                        .catch((err) => {
                          dispatch({
                            type: USER_CREATE_FAILED,
                            message: err.message,
                          });
                        });
                    })
                    .catch((err) => {
                      dispatch({
                        type: USER_CREATE_FAILED,
                        message: err.message,
                      });
                    });
                }
              })
              .catch((err) => {
                dispatch({
                  type: USER_CREATE_FAILED,
                  message: err.message,
                });
              });
          } else {
            return callOnCreateUserAccount(userData)
              .then((data) => {
                if (data) {
                  createMailHistory = {
                    action: "create_email_history",
                    emailTag: "new-staff",
                    businessId: userData.businessDetails.businessId,
                    query: {
                      email: data.email,
                    },
                  };
                  return callOnClickSendMail(createMailHistory)
                    .then((code) => {
                      dispatch({
                        type: CREATE_USER_ACCOUNT_SUCCESS,
                        payload: data,
                      });
                    })
                    .catch((err) => {
                      dispatch({
                        type: USER_CREATE_FAILED,
                        message: err.message,
                      });
                    });
                }
              })
              .catch((err) => {
                dispatch({
                  type: USER_CREATE_FAILED,
                  message: err.message,
                });
              });
          }
        })
        .catch((err) => {
          dispatch({
            type: USER_CREATE_FAILED,
            message: err.message,
          });
        });
    }
  };
};

export const getBusinessDetailsById = (_id) => {
  return async (dispatch) => {
    return callGetBusinessDetailsById(_id)
      .then((data) => {
        dispatch({
          type: GET_ONE_BUSINESS_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ONE_BUSINESS_FAILED,
          message: err.message,
          code: err.code,
        });
      });
  };
};

export const getBusinessDetailsByPrimaryDomain = (primaryDomain) => {
  return async (dispatch) => {
    let businessData = {
      action: "get-certificate-business-data",
      primaryDomain: primaryDomain,
    };

    return callGetBusinessDetailsByPrimaryDomain(businessData)
      .then((data) => {
        dispatch({
          type: GET_BUSINESS_USING_PRIMARY_DOMAIN_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BUSINESS_USING_PRIMARY_DOMAIN_FAILED,
          message: err.message,
          code: err.code,
        });
      });
  };
};

export const changeUserPassword = (
  email,
  password,
  newPassword,
  businessId
) => {
  return async (dispatch) => {
    return callChangeUserPassword(email, password)
      .then((data) => {
        if (data.accessToken) {
          api.passport.verifyJWT(data.accessToken).then((tokenData) => {
            if (tokenData.exp) {
              api
                .service("users")
                .get(tokenData.userId)
                .then((userData) => {
                  const changePassword = {
                    password: newPassword,
                  };
                  return callUpdateUserProfile(userData._id, changePassword)
                    .then((newPasswordData) => {
                      if (newPasswordData) {
                        let notifications = userData.notifications;
                        notifications.push({
                          businessId: businessId ? businessId : null,
                          title: "Password updated",
                          details:
                            "Password updated by " +
                            userData.firstName +
                            " " +
                            userData.lastName,
                          regarding: "Password changed",
                          status: "unseen",
                          date: new Date(),
                        });
                        const createUserNotification = {
                          notifications: notifications,
                        };
                        return callCreateNotification(
                          userData._id,
                          createUserNotification
                        )
                          .then((data) => {
                            dispatch({
                              type: UPDATE_USER_PASSWORD_SUCCESS,
                              payload: data,
                              message: "Password Updated Successfully",
                            });
                          })
                          .catch((err) => {
                            dispatch({
                              type: UPDATE_USER_PROFILE_FAILED,
                              message: err.message,
                            });
                          });
                      }
                    })
                    .catch((err) => {
                      dispatch({
                        type: UPDATE_USER_PROFILE_FAILED,
                        message: err.message,
                      });
                    });
                });
            }
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_USER_PROFILE_FAILED,
          message: "Wrong password",
        });
      });
  };
};

export const updateUserProfile = (_id, profileData, businessId) => {
  return async (dispatch) => {
    let doNotShowMessage = profileData.hasOwnProperty("doNotShowMessage")
      ? profileData.doNotShowMessage
      : false;
    return callUpdateUserProfile(_id, profileData)
      .then((userData) => {
        removeStorageItem("userData");
        const encryptedString = cryptr.encrypt(
          JSON.stringify({
            _id: userData._id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            groupType: userData.userDetails.groupType,
            isAdmin: userData.userDetails.isAdmin,
            roles: userData.userDetails.roles,
            businessDetails: userData.businessDetails,
          })
        );

        setStorageItem("userData", encryptedString);

        if (!doNotShowMessage) {
          dispatch({
            type: UPDATE_USER_PROFILE_SUCCESS,
            payload: userData,
            message: "Profile Updated Successfully",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_USER_PROFILE_FAILED,
          message: err.message,
        });
      });
  };
};

export const saveSmsTiming = (_id, smsTimings) => {
  return async (dispatch) => {
    return callSaveSmsTiming(_id, smsTimings)
      .then((userData) => {
        if (userData) {
          dispatch({
            type: SAVE_SMS_TIMING_SUCCESS,
            payload: userData,
            message: "SMS timing Updated Successfully",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: SAVE_SMS_TIMING_FAILURE,
          message: err.message,
        });
      });
  };
};

export const onSaveNotification = (_id, data, businessId) => {
  return async (dispatch) => {
    return callGetAdminDetails(_id).then((details) => {
      let businessDetails = details.businessDetails;
      let findBusinessDetailsById = businessDetails.filter((Id) => {
        return Id.businessId === businessId;
      });

      for (var i = 0; i < businessDetails.length; i++) {
        if (businessDetails[i].businessId === businessId) {
          businessDetails.splice(i, 1);
        }
      }
      let role = findBusinessDetailsById.map((r) => r.roles);
      businessDetails.push({
        businessId: businessId,
        groupType: findBusinessDetailsById.map((g) => g.groupType),
        roles: role.length > 1 ? role : [],
        allEmailNotification: data.allEmailNotification,
        allSmsNotification: data.allSmsNotification,
        emailNotifications: data.emailNotifications,
        smsNotifications: data.smsNotifications,
      });

      const usersBusinessDetailsData = {
        businessDetails: businessDetails,
      };

      return callOnSaveNotification(_id, usersBusinessDetailsData)
        .then((notification) => {
          dispatch({
            payload: notification,
            type: UPDATE_USER_PASSWORD_SUCCESS,
            message: "Notifications Updated Successfully",
          });
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_USER_PROFILE_FAILED,
            message: err.message,
          });
        });
    });
  };
};

export const getDataSearchBy = (dbField, data) => {
  let filterData = [];

  return async (dispatch) => {
    let searchData = {
      query: {
        action: "control-panel-advanced-search",
        fieldName: dbField,
        fieldValue: data,
      },
    };

    return callGetDataSearchBy(searchData)
      .then((domainList) => {
        switch (dbField) {
          case "host":
            filterData = domainList.data.filter((domain) => {
              return domain.host.indexOf(data) !== -1;
            });
            break;

          case "_id":
            filterData = domainList.data.filter((domain) => {
              return domain._id.indexOf(data) !== -1;
            });
            break;

          default:
            return "";
        }

        dispatch({
          type: SEARCH_BY_ITEM_SUCCESS,
          payload: filterData,
        });
      })
      .catch((err) => {
        dispatch({
          type: SEARCH_BY_ITEM_FAILED,
          message: err.message,
        });
      });
  };
};

export const domainSearchByBusiness = (dbField, data) => {
  let filterData = [];
  let query = [];
  return async (dispatch) => {
    return callDomainSearchByBusiness()
      .then((businesses) => {
        if (businesses) {
          if (dbField === "address") {
            filterData = businesses.data.filter((business) => {
              return business.address.indexOf(data) !== -1;
            });
          }
          if (dbField === "supportEmail") {
            filterData = businesses.data.filter((business) => {
              return business.supportEmail.indexOf(data) !== -1;
            });
          }
          if (dbField === "supportPhone") {
            filterData = businesses.data.filter((business) => {
              return business.supportPhone.indexOf(data) !== -1;
            });
          }
          return callGetDataSearchBy()
            .then((item) => {
              for (var i = 0; i < filterData.length; i++) {
                for (var e = 0; e < item.data.length; e++) {
                  if (filterData[i]._id === item.data[e].businessId)
                    query.push(item.data[i]);
                }
              }
              dispatch({
                type: SEARCH_BY_ITEM_SUCCESS,
                payload: query,
              });
            })
            .catch((err) => {
              dispatch({
                type: SEARCH_BY_ITEM_FAILED,
                message: err.message,
              });
            });
        }
      })
      .catch((err) => {
        dispatch({
          type: SEARCH_BY_ITEM_FAILED,
          message: err.message,
        });
      });
  };
};

export const getDomains = () => {
  return async (dispatch) => {
    return callGetDomains()
      .then((domain) => {
        dispatch({
          type: GET_DOMAINS_SUCCESS,
          payload: domain.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SEARCH_BY_ITEM_FAILED,
          message: err.message,
        });
      });
  };
};

export const getBusinesses = (businessQuery) => {
  return async (dispatch) => {
    return callGetBusinesses(businessQuery)
      .then((businesses) => {
        dispatch({
          type: GET_BUSINESSES_SUCCESS,
          payload: businesses.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BUSINESSES_FAILED,
          message: err.message,
        });
      });
  };
};

export const updateEditedBusinessDomain = (businessData, bID) => {
  return async (dispatch) => {
    return callUpdateEditedBusiness(bID, businessData)
      .then(async (business) => {
        dispatch({
          type: UPDATE_BUSINESS_SUCCESS,
          payload: business,
        });
        let businessExtraData = { ...business };
        businessExtraData["action"] = "update_bs_info";
        await callOnUpdateContact(businessExtraData);
      })
      .catch((err) => {
        dispatch({
          type: SEARCH_BY_ITEM_FAILED,
          message: err.message,
        });
      });
  };
};

export const createSubDomain = (subDomainData) => {
  return async (dispatch) => {
    return callCreateDomain(subDomainData)
      .then((data) => {
        dispatch({
          type: CREATE_DOMAIN_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_ACCOUNT_FAILED,
          message: err.message,
        });
      });
  };
};
export const deleteSubDomain = (_id) => {
  return async (dispatch) => {
    return callDeleteSubDomain(_id)
      .then((data) => {
        dispatch({
          type: DELETE_SUBDOMAIN_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_ACCOUNT_FAILED,
          message: err.message,
        });
      });
  };
};

export const cancelOrActiveDomain = (id, userId, currentBusinessStatus) => {
  return async (dispatch) => {
    return callGetBusinessDetailsById(id).then((business) => {
      let pendingStatusLog = business.statusLog.filter((status) => {
        return status.currentStatus === "pending";
      });

      let statusLog = business.statusLog,
        currentStatus = null,
        newStatus = null,
        updateStatusLog = false,
        toastMessage = null;

      switch (currentBusinessStatus) {
        case "active":
          if (business.statusLog.length > 0 && pendingStatusLog.length > 0) {
            toastMessage =
              "Account scheduled for cancelation. Please check back in a few minutes.";
          } else {
            updateStatusLog = true;
            currentStatus = "pending";
            newStatus = "canceled";
          }
          break;

        case "canceled":
          if (
            business.statusLog.length > 0 &&
            pendingStatusLog.length > 0 &&
            pendingStatusLog.newStatus !== business.status
          ) {
            toastMessage =
              "Account scheduled for activation. Please check back in a few minutes.";
          } else {
            updateStatusLog = true;
            currentStatus = "pending";
            newStatus = "active";
          }
          break;

        default:
          break;
      }

      if (updateStatusLog) {
        statusLog.push({
          currentStatus: currentStatus,
          newStatus: newStatus,
          userId: userId,
          notes: "",
          actionDate: new Date(),
          date: new Date(),
        });

        const businessStatus = {
          statusLog: statusLog,
        };

        return callCancelAndActiveBusiness(id, businessStatus).then((data) => {
          toast.success(
            "Account scheduled for cancellation/activation. Please check back in a few minutes.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
      } else {
        toast.info(toastMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
};

export const create4xAccount = (id) => {
  return async (dispatch) => {
    return callGetBusinessDetailsById(id).then((business) => {
      return callGetBusinessUsers(business._id).then(async (userData) => {
        if (business && userData && userData.data && userData.data.length > 0) {
          let userDetails;

          /*eslint-disable*/
          userData.data.map((v) => {
            v.businessDetails.map((iv) => {
              if (iv.businessId === business._id && iv.primaryUser === true) {
                userDetails = v;
              }
            });
          });
          /*eslint-enable*/

          let query = {
            action: "create-4x-account",
            query: {
              businessData: {
                businessId: business._id,
                name: business.businessName,
                website: business.primaryDomain,
                /* eslint-disable */
                contact_name:
                  userDetails.firstName + " " + userDetails.lastName,
                contact_email: userDetails.email,
                contact_phone: userDetails.phone,
                /* eslint-enable */
              },
            },
          };

          await callCreate4xAccount(query);

          toast.success("Buyer protection account created successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    });
  };
};

export const skippedActionByMember = (businessId, userId) => {
  return async (dispatch) => {
    return callGetBusinessDetailsById(businessId).then((business) => {
      let statusLogData = business.statusLog;

      if (statusLogData && statusLogData.length > 0) {
        statusLogData.filter((eachRow) => {
          if (eachRow.currentStatus === "pending") {
            eachRow.currentStatus = "completed";
            eachRow.skippedMemberId = userId;
          }

          return true;
        });

        return callSkippedActionByMember(businessId, statusLogData)
          .then((business) => {
            dispatch({
              type: UPDATE_SKIPPED_MEMBER_SUCCESS,
              payload: business,
            });
          })
          .catch((err) => {
            dispatch({
              type: UPDATE_SKIPPED_MEMBER_FALIURE,
              message: err.message,
            });
          });
      }
    });
  };
};

export const createNote = (
  businessId,
  noteData,
  sendNotificationToCustomer,
  roles
) => {
  return async (dispatch) => {
    return callGetBusinessDetailsById(businessId)
      .then((business) => {
        let notes = business.notes;
        if (notes) {
          notes.push({
            userId: noteData.userId,
            note: noteData.note,
            noteType: noteData.noteType,
            roles: roles,
            createdDate: noteData.createdDate,
          });
          const createNotes = {
            notes: notes,
          };
          return callUpdateEditedBusiness(businessId, createNotes)
            .then((businessNotes) => {
              if (sendNotificationToCustomer) {
                let domainQuery = {
                  query: {
                    host: business.primaryDomain,
                  },
                };

                return callOnGetDomainDataUsingBusinessPrimaryDomain(
                  domainQuery
                )
                  .then((domainData) => {
                    let domainId = domainData.data[0]._id;
                    let orQuery = [];
                    let roleData = {
                      admin: "Account Admin",
                      manager: "Account Manager",
                      staff: "Scan Report Access",
                    };

                    for (let thisKey in roles) {
                      if (roles[thisKey]) {
                        orQuery.push({ groupType: roleData[thisKey] });
                      }
                    }

                    if (orQuery.length > 0) {
                      let query = {
                        query: {
                          businessDetails: {
                            $elemMatch: {
                              businessId: businessId,
                              $or: orQuery,
                            },
                          },
                        },
                      };

                      return callOnGetUserListUsingBusinessIdAndRoles(query)
                        .then((thisData) => {
                          thisData.data.map((eachValue, index) => {
                            let notifications = eachValue.notifications;

                            notifications.push({
                              businessId: businessId,
                              domainId: domainId,
                              createdBy: noteData.userId,
                              title: "Message from staff",
                              details: noteData.note,
                              status: "unseen",
                              date: new Date(),
                            });

                            const createUserNotification = {
                              notifications: notifications,
                            };

                            return callCreateBatchNotification(
                              eachValue._id,
                              createUserNotification
                            )
                              .then((data) => {
                                let emailQuery = {
                                  query: {
                                    emailTag: "customer-note-added",
                                  },
                                };

                                return callOnGetEmailDataUsingEmailTag(
                                  emailQuery
                                )
                                  .then((emailData) => {
                                    let emailHistoryData = {
                                      emailId: emailData.data[0]._id,
                                      userId: eachValue._id,
                                      status: "pending",
                                      customMessage: "",
                                      sentDate: "",
                                      createdAt: new Date(),
                                      updatedAt: new Date(),
                                      deleteAt: new Date(),
                                    };

                                    return callOnCreateEmailHistory(
                                      emailHistoryData
                                    )
                                      .then((r) => {
                                        console.log(r);
                                      })
                                      .catch((e) => {
                                        console.log(e);
                                      });
                                  })
                                  .catch((e) => {
                                    console.log(e);
                                  });
                              })
                              .catch((err) => {
                                dispatch({
                                  type: NOTE_CREATED_FAILED,
                                });
                              });
                          });

                          dispatch({
                            type: NOTE_CREATED_SUCCESS,
                            payload: businessNotes,
                          });
                        })
                        .catch((thisError) => {
                          console.log(thisError);
                        });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                dispatch({
                  type: NOTE_CREATED_SUCCESS,
                  payload: businessNotes,
                });
              }
            })
            .catch((err) => {
              dispatch({
                type: NOTE_CREATED_FAILED,
              });
            });
        } else {
        }
      })
      .catch((err) => {
        dispatch({
          type: NOTE_CREATED_FAILED,
          message: err.message,
        });
      });
  };
};

export const getFailedScanDomains = (query) => {
  return async (dispatch) => {
    return callGetFailedScanDomains(query)
      .then((data) => {
        dispatch({
          type: GET_PENDING_SCAN_BUSINESS_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: NOTE_CREATED_FAILED,
          message: err.message,
        });
      });
  };
};

export const sendTestEmail = (thisData) => {
  return async (dispatch) => {
    return callOnCreateEmailHistory(thisData)
      .then((data) => {
        dispatch({
          type: SEND_TEST_EMAIL_SUCCESS,
          payload: data,
        });
      })
      .catch((e) => {
        dispatch({
          type: SEND_TEST_EMAIL_FAILURE,
          message: e.message,
        });
      });
  };
};

export const get30DaysOldFreeBusinesses = () => {
  return async (dispatch) => {
    return callGet30DaysOldFreeBusinesses()
      .then((data) => {
        dispatch({
          type: GET_OLD_FREE_BUSINESS_LIST_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_OLD_FREE_BUSINESS_LIST_FAILURE,
          message: err.message,
        });
      });
  };
};

export const updateBusinessCertificate = (businessID, certificateData) => {
  return async (dispatch) => {
    return callUpdateBusinessCertificate(businessID, certificateData)
      .then((business) => {
        dispatch({
          type: UPDATE_BUSINESS_CERTIFICATE_SUCCESS,
          payload: business,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_BUSINESS_CERTIFICATE_FAILED,
          message: err.message,
        });
      });
  };
};

export const updateBusinessCertificateContact = (
  businessID,
  certificateData
) => {
  return async (dispatch) => {
    return callUpdateBusinessCertificateContact(businessID, certificateData)
      .then((business) => {
        dispatch({
          type: UPDATE_BUSINESS_CERTIFICATE_CONTACT_SUCCESS,
          payload: business,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_BUSINESS_CERTIFICATE_CONTACT_FAILED,
          message: err.message,
        });
      });
  };
};

export const onCreateEmail = (emailData) => {
  return async (dispatch) => {
    return callOnCreateEmail(emailData)
      .then((email) => {
        dispatch({
          type: EMAIL_CREATED_SUCCESS,
          payload: email,
        });
      })
      .catch((err) => {
        dispatch({
          type: EMAIL_CREATED_FAILED,
          message: err.message,
        });
      });
  };
};

export const getAllEmails = (query) => {
  return async (dispatch) => {
    return callGetAllEmails(query)
      .then((email) => {
        dispatch({
          type: GET_ALL_EMAILS_SUCCESS,
          payload: email,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_EMAILS_FAILED,
          message: err.message,
        });
      });
  };
};

export const onUpdateEmail = (id, updateData) => {
  return async (dispatch) => {
    return callOnUpdateEmail(id, updateData)
      .then((email) => {
        toast.success("Email updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch({
          type: UPDATE_EMAIL_SUCCESS,
          payload: email,
        });
      })
      .catch((err) => {
        toast.success("Email updated failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch({
          type: UPDATE_EMAIL_FAILED,
          message: err.message,
        });
      });
  };
};

export const onDeleteEmail = (_id, userId) => {
  return async (dispatch) => {
    return callOnDeleteEmail(_id)
      .then((email) => {
        if (email) {
          const createDeleteEmail = {
            deletedBy: userId,
            emailTag: email.emailTag,
            name: email.name,
            from: email.from,
            subject: email.subject,
            body: email.body,
            status: "inactive",
          };
          return callCreateDeletedEamil(createDeleteEmail).then(
            (deleteEmail) => {
              toast.success("Email deleted successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
              dispatch({
                type: INACTIVE_EMAIL_SUCCESS,
                payload: email,
              });
            }
          );
        }
      })
      .catch((err) => {
        toast.success("Email delete failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch({
          type: UPDATE_EMAIL_FAILED,
          message: err.message,
        });
      });
  };
};

export const getCheckScanReports = () => {
  return async (dispatch) => {
    return callGetCheckScanReports()
      .then((data) => {
        dispatch({
          type: GET_CHECK_SCAN_REPORTS_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CHECK_SCAN_REPORTS_FALIURE,
          message: err.message,
        });
      });
  };
};

export const getUserListUsingBusinessId = (businessId) => {
  return async (dispatch) => {
    let query = {
      query: {
        businessDetails: {
          $elemMatch: {
            businessId: businessId,
          },
        },
      },
    };

    return callOnGetUserListUsingBusinessId(query)
      .then((thisData) => {
        dispatch({
          type: GET_USER_LIST_USING_BUSINESS_ID_SUCCESS,
          payload: thisData.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: GET_USER_LIST_USING_BUSINESS_ID_FALIURE,
          message: e.message,
        });
      });
  };
};

export const findBusinessTrustSeal = (businessId) => {
  return async (dispatch) => {
    let query = {
      query: {
        businessId: businessId,
      },
    };

    return callOnFindBusinessTrustSeal(query)
      .then((data) => {
        dispatch({
          type: GET_TRUSTSEAL_SUCCESS,
          payload: data.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_TRUSTSEAL_SUCCESS,
          payload: err.message,
        });
      });
  };
};

export const createOrUpdateTrustSeal = (
  trustSealId = null,
  businessId,
  purpose,
  sealData,
  sealSettingsId
) => {
  return async (dispatch) => {
    let updateBusinessSeal = false;

    if (purpose && typeof purpose !== "undefined") {
      switch (purpose) {
        case "create":
        case "update":
        case "remove":
          updateBusinessSeal = true;
          break;
        default:
          updateBusinessSeal = false;
          break;
      }
    }

    let query = {
      query: {
        businessId: businessId,
      },
    };

    return callOnFindBusinessTrustSeal(query)
      .then((data) => {
        if (data.total > 0 && updateBusinessSeal === true) {
          let businessSeal = data.data[0];

          if (businessSeal && businessSeal.hasOwnProperty("sealSettings")) {
            if (sealSettingsId) {
              sealData = {
                ...sealData,
                ...{ removableSealSettingsId: sealSettingsId },
              };
            }

            return callOnUpdateTrustSeal(trustSealId, sealData)
              .then((thisData) => {
                dispatch({
                  type: UPDATE_TRUSTSEAL_SUCCESS,
                  payload: thisData,
                  message: "Preset " + purpose + "d successfully",
                });
              })
              .catch((err) => {
                dispatch({
                  type: UPDATE_TRUSTSEAL_FALIURE,
                  message: err.message,
                });
              });
          }
        } else if (data.total === 0) {
          return callOnCreateTrustSeal(sealData)
            .then((thisData) => {
              dispatch({
                type: CREATE_TRUSTSEAL_SUCCESS,
                payload: thisData,
              });
            })
            .catch((err) => {
              dispatch({
                type: CREATE_TRUSTSEAL_FALIURE,
                message: err.message,
              });
            });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_TRUSTSEAL_FALIURE,
          message: err.message,
        });
      });
  };
};

export const onClickRemoveTrustSealsSettingsDate = (query) => {
  return async (dispatch) => {
    return callOnClickRemoveTrustSealsSettingsDate(query)
      .then((sealsData) => {
        if (sealsData) {
          dispatch({
            type: SEALS_DATA_UPDATED_SUCCESS,
            payload: sealsData,
          });
        } else {
          dispatch({
            type: SEALS_DATA_UPDATED_FAILURE,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: SEALS_DATA_UPDATED_FAILURE,
          message: err.message,
        });
      });
  };
};

export const onClickSaveResolvedNote = (
  businessId,
  note,
  reason,
  reportId,
  checkScanReportId
) => {
  return async (dispatch) => {
    return callOnClickSaveResolvedNote(businessId, note, reason)
      .then((data) => {
        return callOnClickSaveResolvedBy(
          businessId,
          reason,
          note.userId,
          reportId,
          checkScanReportId
        ).then((thisData) => {
          dispatch({
            type: RESOLVED_NOTE_SAVE_SUCCESS,
            payload: thisData,
          });
        });
      })
      .catch((err) => {
        dispatch({
          type: RESOLVED_NOTE_SAVE_FAILURE,
          message: err.message,
        });
      });
  };
};

export const onClickDeleteUser = (userId, businessId) => {
  return async (dispatch) => {
    return callGetDeleteUser(userId, businessId)
      .then((users) => {
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload: users.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_USER_FAILURE,
        });
      });
  };
};

export const getBusinessUsers = (businessId) => {
  return async (dispatch) => {
    return callGetBusinessUsers(businessId)
      .then((users) => {
        dispatch({
          type: BUSINESS_USERS_SUCCESS,
          payload: users.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: BUSINESS_USERS_FAILURE,
        });
      });
  };
};

export const updateUserDetails = (userId, userData) => {
  return async (dispatch) => {
    return callUpdateUserData(userId, userData)
      .then((users) => {
        dispatch({
          type: UPDATE_USER_DATA_SUCCESS,
          payload: users.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_USER_DATA_FAILURE,
        });
      });
  };
};

export const updateBusinessAccountType = (
  userId,
  businessId,
  currentStatus,
  newStatus
) => {
  return async (dispatch) => {
    return callUpdateBusinessAccountType(
      userId,
      businessId,
      currentStatus,
      newStatus
    )
      .then((business) => {
        dispatch({
          type: UPDATE_BUSINESS_DATA_SUCCESS,
          payload: business.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_BUSINESS_DATA_FAILURE,
        });
      });
  };
};

export const getInsuranceIndustryList = () => {
  return async (dispatch) => {
    return callInsuranceIndustryList()
      .then((data) => {
        dispatch({
          type: GET_INSURANCE_INDUSTRY_LIST_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INSURANCE_INDUSTRY_LIST_FAILURE,
        });
      });
  };
};

export const postInsuranceData = (data) => {
  return async (dispatch) => {
    return callPostInsuranceData(data)
      .then((data) => {
        if (data.success === true) {
          dispatch({
            type: POST_INSURANCE_DATA_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: POST_INSURANCE_DATA_FAILURE,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: POST_INSURANCE_DATA_FAILURE,
        });
      });
  };
};

export const updateSidebar = (data) => {
  return async (dispatch) => {
    if (data.showAccountSettingsMenu) {
      dispatch({
        type: UPDATE_APP_SIDEBAR_MENUS,
        payload: data,
      });
    }
  };
};

export const createCssHash = (data) => {
  return async (dispatch) => {
    return callCreateCssHash(data)
      .then((data) => {
        if (data.success === true) {
          dispatch({
            type: CREATE_CSS_HASH_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: CREATE_CSS_HASH_FAILURE,
            payload: data,
            purpose: data.purpose,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATE_CSS_HASH_FAILURE,
        });
      });
  };
};

export const createJsHash = (data) => {
  return async (dispatch) => {
    return callCreateJsHash(data)
      .then((data) => {
        if (data.success === true) {
          dispatch({
            type: CREATE_JS_HASH_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: CREATE_JS_HASH_FAILURE,
            payload: data,
            purpose: data.purpose,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATE_JS_HASH_FAILURE,
        });
      });
  };
};

export const sendEmailForReport = (data) => {
  return async (dispatch) => {
    if (
      data.businessId &&
      data.businessId != null &&
      data.businessId !== "undefined"
    ) {
      let roles = data.roles;
      let orQuery = [];
      let roleData = {
        admin: "Account Admin",
        manager: "Account Manager",
        staff: "Scan Report Access",
      };

      for (let thisKey in roles) {
        if (roles[thisKey]) {
          orQuery.push({ groupType: roleData[thisKey] });
        }
      }

      if (orQuery.length > 0) {
        let query = {
          query: {
            businessDetails: {
              $elemMatch: {
                businessId: data.businessId,
                $or: orQuery,
              },
            },
          },
        };

        return callOnGetUserListUsingBusinessIdAndRoles(query)
          .then((thisData) => {
            thisData.total > 0
              ? thisData.data.map((eachValue, index) => {
                  let emailHistoryData = {
                    emailId: data.emailId,
                    businessId: data.businessId,
                    userId: eachValue._id,
                    status: "pending",
                    customMessage: "",
                    sentDate: "",
                    fromAdmin: data.fromAdmin ? data.fromAdmin : "no",
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    deleteAt: new Date(),
                  };

                  return callOnCreateEmailHistory(emailHistoryData)
                    .then((r) => {
                      dispatch({
                        type: SEND_REPORT_EMAIL_SUCCESS,
                      });
                    })
                    .catch((e) => {
                      dispatch({
                        type: SEND_REPORT_EMAIL_FAILURE,
                      });
                    });
                })
              : dispatch({
                  type: SEND_REPORT_EMAIL_FAILURE,
                });
          })
          .catch((e) => {});
      }
    }
  };
};

export const cssJsHashList = () => {
  return async (dispatch) => {
    return callGetCssJsHashList()
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_ALL_CSS_JS_HASH_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_ALL_CSS_JS_HASH_FAILURE,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_CSS_JS_HASH_FAILURE,
        });
      });
  };
};

export const onCreateOneTimeEmail = (data) => {
  return async (dispatch) => {
    return callCreateOneTimeEmail(data)
      .then((data) => {
        if (data) {
          dispatch({
            type: CREATE_ONE_TIME_EMAIL_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: CREATE_ONE_TIME_EMAIL_FAILURE,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATE_ONE_TIME_EMAIL_FAILURE,
        });
      });
  };
};

export const oneTimeEmailList = () => {
  return async (dispatch) => {
    return callGetOneTimeEmails()
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_ONE_TIME_EMAIL_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_ONE_TIME_EMAIL_FAILURE,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ONE_TIME_EMAIL_FAILURE,
        });
      });
  };
};

export const getAllBusinessUsersForOtherMessages = () => {
  return async (dispatch) => {
    return callGetAllBusinessUsersForOtherMessages()
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_FAILURE,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_FAILURE,
        });
      });
  };
};

export const regenerateAllSeals = () => {
  return async (dispatch) => {
    return callRegeneraeAllSeals()
      .then((data) => {
        if (data) {
          dispatch({
            type: REGENERATE_ALL_SEALS_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: REGENERATE_ALL_SEALS_FAILURE,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: REGENERATE_ALL_SEALS_FAILURE,
        });
      });
  };
};

export const getSslRenewalDomains = () => {
  return async (dispatch) => {
    return callGetSslRenewalDomains()
      .then((business) => {
        dispatch({
          type: GET_ALL_SSL_RENEWAL_DOMAINS_SUCCESS,
          payload: business,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_SSL_RENEWAL_DOMAINS_FAILURE,
        });
      });
  };
};

export const updateSslRenewalDomain = (id, obj) => {
  return async (dispatch) => {
    return callUpdateSslRenewalDomain(id, obj)
      .then((business) => {
        dispatch({
          type: UPDATE_SSL_RENEWAL_DOMAINS_SUCCESS,
          payload: business,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_SSL_RENEWAL_DOMAINS_FAILURE,
        });
      });
  };
};

export const updateOnboardingPopupData = (businessDetails, status) => {
  return async (dispatch) => {
    return callUpdateOnboardingPopupData(businessDetails, status)
      .then((status) => {
        dispatch({
          type: UPDATE_ONBOARDING_POPUP_DATA_SUCCESS,
          payload: status,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_ONBOARDING_POPUP_DATA_FAILURE,
        });
      });
  };
};

export const save4xIntegrationStatus = (businessId, websiteType) => {
  return async (dispatch) => {
    return callsave4xIntegrationStatus(businessId, websiteType)
      .then((business) => {
        dispatch({
          type: UPDATE_ACCOUNT_4X_DETAILS_SUCCESS,
          payload: business,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_ACCOUNT_4X_DETAILS_FAILURE,
        });
      });
  };
};

export const actionAgainstCancelledTrustSeals = (
  sealId,
  primaryDomain,
  purpose
) => {
  return async (dispatch) => {
    let decision;
    return callActionAgainstCancelledTrustSeals(sealId, primaryDomain, purpose)
      .then((data) => {
        dispatch({
          type: DELETE_TRUSTSEALS_SUCCESS,
          payload: data,
        });

        decision = purpose == "hide" ? "hidden" : "deleted" + " successfully.";

        toast.success("Trustseal " + decision, 2500);
      })
      .catch((err) => {
        dispatch({
          type: DELETE_TRUSTSEALS_FAILURE,
        });

        decision =
          purpose == "hide" ? "hidden" : "deletion" + " was unsuccessfull.";

        toast.error("Trustseal " + decision, 2500);
      });
  };
};
