import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { ToastContainer } from "react-toastify";
import Aux from "../../components/hoc/containerAux";
import { images } from "../../helpers/images";
import { onClickResetPassword, resetStatus } from "../../modules/actions/index";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      notifyType: "",
      notifyMessage: "",
      placeholder: "Email",
      btnDisabled: false,
    };

    this.onChangeHandelUserInput = this.onChangeHandelUserInput.bind(this);
    this.onTextKeyPress = this.onTextKeyPress.bind(this);
  }

  componentWillReceiveProps(newProps) {
    setTimeout(() => {
      this.props.goToLogin();
    }, 2500);
  }

  onChangeHandelUserInput(evt) {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState({
      [name]: value,
    });
  }

  onClickResetPassword = () => {
    this.props.onClickResetPassword({
      email: this.state.email,
    });

    this.setState(
      {
        btnDisabled: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            btnDisabled: false,
          });
        }, 2500);
      }
    );
  };

  onTextKeyPress(evt) {
    if (evt.key === "Enter") {
      this.onClickResetPassword(evt);
    }
  }

  render() {
    const {
      email,
      notifyType,
      notifyMessage,
      placeholder,
      btnDisabled,
    } = this.state;

    return (
      <Aux>
        <div
          id="login-bg"
          style={{
            backgroundImage:
              "url(" + require("../../static/images/login-bg-server.jpg") + ")",
          }}
        />
        <div id="login-content">
          <div id="login-panel-wrapper">
            <div id="login-panel" className="col-md-12 text-center">
              <ToastContainer />

              <img
                alt="tgRegularDark"
                id="login-logo"
                src={images.tgRegularDark}
              />
              <br />
              <input
                type="text"
                placeholder={placeholder}
                className="form-control"
                name="email"
                value={email}
                onChange={this.onChangeHandelUserInput}
                onKeyPress={this.onTextKeyPress}
                required
              />

              <br />

              <br />
              <button
                className="btn"
                id="login-btn"
                onClick={() => this.onClickResetPassword()}
                disabled={btnDisabled}
              >
                Submit
              </button>
              <div className="col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad text-left">
                <p
                  className="login-links"
                  onClick={() => {
                    window.open("https://trustguard.com", "_blank");
                  }}
                >
                  Don't have an account?
                </p>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6  no-pad text-right">
                <p
                  onClick={() => {
                    this.props.history.push("/login");
                  }}
                  className="login-links"
                >
                  Login
                </p>
              </div>
              <div
                id="login-copy"
                className="col-md-12 col-sm-12 col-xs-12 no-pad text-center"
              >
                <p>
                  By logging in you agree to the{" "}
                  <span className="login-links">Terms of Use</span> and{" "}
                  <span className="login-links">
                    Privacy Policy<br />{" "}
                  </span>
                  &copy; {new Date().getFullYear()} Trust-Guard. All Rights
                  Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    res: state.authStore.res,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onClickResetPassword,
      resetStatus,
      goToLogin: () => push("/login"),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
