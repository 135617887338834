import {
  callDoLogin,
  callAuthLogin,
  api,
  callResetPasswordCode,
  callFailedLoggedIn,
  callFindUsersInBusiness,
  callUnlockUserAccount,
  callUpdateUserProfile,
  callOnClickSendMailForForgetPassword,
} from "../../services/index";

import {
  RESET_MESSAGES,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_REDIRECT_TO_CONTROL_PANEL,
  LOGIN_FAILED,
  GET_FORGET_PASSWORD_CODE_SUCCESS,
  GET_FORGET_PASSWORD_CODE_FAILED,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  FIND_LOCKED_USERS_SUCCESS,
  FIND_LOCKED_USERS_FAILED,
  USER_UNLOCKED_SUCCESS,
  USER_UNLOCKED_FAILED,
  USER_ACCOUNT_LOCKED_SUCCESS,
} from "../types/index";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setStorageItem, removeAllStorage } from "../../helpers/storage";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

export const resetStatus = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_MESSAGES,
    });
  };
};

/* export const doLogin = (email, password) => {
  removeAllStorage();
  return async (dispatch) => {
    api.logout();
    return callDoLogin(email.trim().toLowerCase(), password)
      .then((data) => {
        if (data.accessToken) {
          setStorageItem("accessToken", data.accessToken);
          api.passport.verifyJWT(data.accessToken).then((tokenData) => {
            if (tokenData.exp) {
              api
                .service("users")
                .get(tokenData.userId)
                .then((userData) => {
                  if (
                    userData.failedLoginAttempts < 100 &&
                    userData.userStatus === "active"
                  ) {
                    const encryptedString = cryptr.encrypt(
                      JSON.stringify({
                        _id: userData._id,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        email: userData.email,
                        groupType: userData.userDetails.groupType,
                        isAdmin: userData.userDetails.isAdmin,
                        roles: userData.userDetails.roles,
                        businessDetails: userData.businessDetails,
                        timezone: userData.timezone,
                        pciScanTime: userData.pciScanTime,
                        notifyAppoinmentAlertCount: 0,
                      })
                    );
                    setStorageItem("userData", encryptedString);
                    const data = {
                      smsInfoPopup: true,
                      failedLoginAttempts: 0,
                    };

                    api.service("users").patch(userData._id, data);

                    if (
                      userData.businessDetails.length > 0 &&
                      userData.userDetails.groupType !== "staff"
                    ) {
                      dispatch({
                        type: LOGIN_SUCCESS,
                        data: userData,
                      });
                    }

                    if (userData.userDetails.groupType === "staff") {
                      dispatch({
                        type: LOGIN_SUCCESS_REDIRECT_TO_CONTROL_PANEL,
                        data: userData,
                      });
                    }
                  } else {
                    localStorage.clear();
                    dispatch({
                      type: LOGIN_FAILED,
                      message:
                        "Your account has been locked. please contact support.",
                    });
                  }
                });
            }
          });
        }
      })
      .catch((err) => {
        let query = {
          action: "invalid_login",
          query: { email: email },
        };
        return callFailedLoggedIn(query).then((data) => {
          let count = 100 - data;
          if (count > 0) {
            dispatch({
              type: LOGIN_FAILED,
              message: err.message,
            });
          } else {
            dispatch({
              type: LOGIN_FAILED,
              message: err.message,
            });
          }
        });
      });
  };
}; */

/* export const authLogin = (email, socialId, socialToken) => {
  removeAllStorage();
  return async (dispatch) => {
    api.logout();
    return callAuthLogin(email, socialId, socialToken)
      .then((data) => {
        if (data.accessToken) {
          setStorageItem("accessToken", data.accessToken);
          api.passport.verifyJWT(data.accessToken).then((tokenData) => {
            if (tokenData.exp) {
              api
                .service("users")
                .get(tokenData.userId)
                .then((userData) => {
                  if (
                    userData.failedLoginAttempts < 100 &&
                    userData.userStatus === "active"
                  ) {
                    const encryptedString = cryptr.encrypt(
                      JSON.stringify({
                        _id: userData._id,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        email: userData.email,
                        groupType: userData.userDetails.groupType,
                        isAdmin: userData.userDetails.isAdmin,
                        roles: userData.userDetails.roles,
                        businessDetails: userData.businessDetails,
                        timezone: userData.timezone,
                        pciScanTime: userData.pciScanTime,
                      })
                    );
                    setStorageItem("userData", encryptedString);
                    const data = {
                      smsInfoPopup: true,
                      failedLoginAttempts: 0,
                    };
                    api.service("users").patch(userData._id, data);
                    if (
                      userData.businessDetails.length > 0 &&
                      userData.userDetails.groupType !== "staff"
                    ) {
                      dispatch({
                        type: LOGIN_SUCCESS,
                        data: userData,
                      });
                    }
                    if (userData.userDetails.groupType === "staff") {
                      dispatch({
                        type: LOGIN_SUCCESS_REDIRECT_TO_CONTROL_PANEL,
                        data: userData,
                      });
                    }
                  } else {
                    localStorage.clear();
                    dispatch({
                      type: LOGIN_FAILED,
                      message:
                        "Your account has been locked. please contact support.",
                    });
                  }
                });
            }
          });
        }
      })
      .catch((err) => {
        let query = {
          action: "invalid_login",
          query: { email: email },
        };
        return callFailedLoggedIn(query).then((data) => {
          let count = 5 - data;
          if (count > 0) {
            dispatch({
              type: LOGIN_FAILED,
              message: err.message,
            });
          } else {
            dispatch({
              type: LOGIN_FAILED,
              message: err.message,
            });
          }
        });
      });
  };
}; */

export const onClickSendMail = (sendToMail) => {
  return async (dispatch) => {
    return callOnClickSendMailForForgetPassword(sendToMail)
      .then((code) => {
        dispatch({
          type: GET_FORGET_PASSWORD_CODE_SUCCESS,
          payload: code,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_FORGET_PASSWORD_CODE_FAILED,
        });
      });
  };
};

/* export const onUpdatePassword = (code, password, newSignUpStatus) => {
  return async (dispatch) => {
    const query = {
      action: "reset_password",
      password: password,
      query: {
        code: code,
      },
      newSignUp: newSignUpStatus,
    };
    return callResetPasswordCode(query)
      .then((codeData) => {
        if (codeData === "CODE_ALREADY_USED") {
          dispatch({
            type: UPDATE_PASSWORD_FAILED,
            message: "This link is used. Please request a new link.",
          });
        } else if (codeData === "CODE_EXPIRED") {
          dispatch({
            type: UPDATE_PASSWORD_FAILED,
            message: "This link is expired. Please request a new link.",
          });
        } else if (codeData === "CODE_NOT_MATCHED") {
          dispatch({
            type: UPDATE_PASSWORD_FAILED,
            message: "This link is invalid. Please request a new link.",
          });
        } else {
          dispatch({
            type: UPDATE_PASSWORD_SUCCESS,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_PASSWORD_FAILED,
          message: err.message,
        });
      });
  };
}; */

export const findUsersInBusiness = (query) => {
  return async (dispatch) => {
    return callFindUsersInBusiness(query)
      .then((data) => {
        dispatch({
          type: FIND_LOCKED_USERS_SUCCESS,
          payload: data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FIND_LOCKED_USERS_FAILED,
        });
      });
  };
};

export const unlockUserAccount = (userId) => {
  return async (dispatch) => {
    api
      .service("users")
      .get(userId)
      .then((userData) => {
        let notificationDetails =
          userData.failedLoginAttempts < 100
            ? "Your account was locked."
            : "Your account has been locked due to too many login attempts.";
        let notifications = userData.notifications;
        notifications.push({
          title: "Unlock account",
          details: notificationDetails,

          regarding: "Unlock account",
          status: "unseen",
          date: new Date(),
        });
        const unlockData = {
          userStatus: "active",
          failedLoginAttempts: 0,
          notifications: notifications,
        };
        return callUnlockUserAccount(userId, unlockData)
          .then((data) => {
            toast.info("User Account unlocked successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            dispatch({
              type: USER_UNLOCKED_SUCCESS,
              payload: data,
            });
          })
          .catch((err) => {
            dispatch({
              type: USER_UNLOCKED_FAILED,
            });
          });
      });
  };
};

export const lockAndUnlockUserAccount = (userId, status) => {
  return async (dispatch) => {
    const userData = {
      userStatus: status,
    };
    return callUpdateUserProfile(userId, userData)
      .then((data) => {
        let msg = data.userStatus === "locked" ? "locked" : "unlocked";

        toast.success("User account " + msg + " successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch({
          type: USER_ACCOUNT_LOCKED_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: USER_UNLOCKED_FAILED,
        });
      });
  };
};
