import React from "react";
import Aux from "../hoc/containerAux";

const layoutBody = (props) => (
  <Aux>
    <div id="mcontent-wrapper">
      <div id="dash-malware-table">
        <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
          <div className="inner-ptab tab-content">{props.children}</div>
        </div>
      </div>
    </div>
  </Aux>
);

export default layoutBody;
