import { getStorageItem, setStorageItem } from "./storage";
import { callOnClickSendMail } from "../services/index";
import fetch from "node-fetch";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

function getUserDecryptedData() {
  const userData = getStorageItem("userData");

  if (userData) {
    let userString = cryptr.decrypt(userData);
    let user = JSON.parse(userString);

    return user;
  }
}

function getBusinessDecryptedData() {
  const businessData = getStorageItem("businessId");

  if (businessData) {
    let businessId = cryptr.decrypt(businessData);
    return businessId;
  }
}

function createEmailHistory(createMailHistory) {
  return callOnClickSendMail(createMailHistory)
    .then((code) => {
      return true;
    })
    .catch((err) => {
      return false;
    });
}

function viewUserBusinessAccountDashboard(businessId, accountType) {
  let userData = getStorageItem("userData");
  if (userData) {
    let userString = cryptr.decrypt(userData);
    let user = JSON.parse(userString);

    user.businessDetails = [
      {
        roles: [],
        emailNotifications: [],
        smsNotifications: [],
        _id: "",
        businessId: businessId,
        businessAccountType: accountType,
        groupType: "admin",
        allEmailNotification: false,
        allSmsNotification: false,
      },
    ];

    setStorageItem("userData", cryptr.encrypt(JSON.stringify(user)));
    setStorageItem("displayDashboard", cryptr.encrypt("true"));
  }
}

function validateDomain(domain) {
  const primaryDomain = domain;
  let validDomain = false;

  const regexRulewww = new RegExp(
    "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$",
    "g"
  );
  const siteWithWww = regexRulewww.test(primaryDomain);

  if (siteWithWww) {
    validDomain = true;
  }

  return validDomain;
}

function removeWWWfromURL(url) {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
}

function urlWithWww(url) {
  let domainWithWWW = false;
  const regexRulewww = /(www)./gm;
  const siteWithWww = regexRulewww.test(url);
  if (siteWithWww) {
    domainWithWWW = true;
  }
  return domainWithWWW;
}

async function getClientIpAddress() {
  return fetch("https://geoip-db.com/json/redux")
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error occurred.");
    });
}

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

function emailFromTrustguard(data) {}

function checkIfWordContainsRule(rules, stringContent) {
  if (rules !== null) {
    let extractedRules = [];
    let patterns = [];
    if (rules.length > 0) {
      for (let x = 0; x < rules.length; x++) {
        const data = rules[x];
        if (data.activated) {
          const rule = data.rule;
          extractedRules.push(`${rule}|`);
          patterns.push({
            rule: rule,
            dumpMD5: data.dumpMD5,
          });
        }
      }
      const regexRemoveCommaRule = /[,]/g;

      const stringToEvaluate = extractedRules
        .toString()
        .replace(regexRemoveCommaRule, "");
      const regexRemoveEndPipe = /\|$/gm;
      const finalMalwareRule = stringToEvaluate.replace(regexRemoveEndPipe, "");
      const regexRule = new RegExp(`(${finalMalwareRule})`, "gm");
      const details = stringContent;
      const foundPattern = regexRule.test(details);
      if (foundPattern) {
        return {
          hasRule: true,
          rule: `${finalMalwareRule}`,
          dataRule: patterns,
        };
      } else {
        return false;
      }
    } else {
      return { hasRule: false, rule: null, dataRule: null };
    }
  }
}

function malwareRowStatus(status) {
  let styleStatus = null;
  let iconStatus = null;

  if (status === "fail" || status === "invalid" || status === "malicious") {
    styleStatus = {
      color: "#C01818",
      textTransform: "capitalize",
    };
    iconStatus = "text-danger fas fa-exclamation-circle ";
  }

  if (status === "running") {
    styleStatus = {
      color: "#33981f",
      textTransform: "capitalize",
    };
  }

  if (status === "potentially suspicious" || status === "suspicious") {
    styleStatus = {
      color: "orange",
      textTransform: "capitalize",
    };
    iconStatus = "text-warning fas fa-exclamation-circle ";
  }

  if (status === "clean") {
    styleStatus = {
      color: "rgb(51, 152, 31)",
      textTransform: "capitalize",
    };
    iconStatus = "text-success fas fa-check-circle";
  }

  return { styleStatus, iconStatus };
}
export {
  getUserDecryptedData,
  getBusinessDecryptedData,
  createEmailHistory,
  viewUserBusinessAccountDashboard,
  getClientIpAddress,
  validateDomain,
  removeWWWfromURL,
  urlWithWww,
  camelize,
  emailFromTrustguard,
  checkIfWordContainsRule,
  malwareRowStatus,
};
