import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import config from "../../helpers/config";

class TrustSealsCreatePreset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPresetName: "",
      isDisabled: false,
    };
  }

  createSealPreset() {
    this.setState({
      isDisabled: true,
    });

    this.props.createSealPreset(this.state.newPresetName);

    setTimeout(() => {
      this.setState({
        newPresetName: "",
        isDisabled: false,
      });
    }, 2500);
  }

  render() {
    const { newPresetName } = this.state;
    return (
      <div id="modalCreatePreset" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog " role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4>Preset Title</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-0">
              <div className="pt-2 pb-5 row">
                <p className="col-8 col-lg-offset-2">
                  <label style={{ width: "20%" }}>Title:</label>
                  <input
                    style={{ width: "80%" }}
                    type="text"
                    name="newPresetName"
                    value={newPresetName}
                    onChange={(evt) =>
                      this.setState({ newPresetName: evt.target.value })
                    }
                  />
                </p>
              </div>
              <div className="modal-footer text-right pt-4 px-0">
                <button
                  id="hideModalOnSuccess"
                  type="button"
                  className="btn-tglight2 btn-140-width mr-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-tgdark2 btn-140-width"
                  onClick={() => {
                    this.createSealPreset();
                  }}
                  disabled={this.state.isDisabled}
                >
                  Create Preset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrustSealsCreatePreset);
