import React, { Component } from "react";
import Modal from "../../../ui/modal/modal";
import AppContext from "../../../../components/context/appContext";
import {
  callFindMalwareVulnerabilityByReportUrl,
  callGetS3Data,
  callOnMalwareCreateEmailHistory,
  callUpdatereviewMalwareVulnerability,
} from "../../../../services/index";
import MalwareVulnerabilitiesData from "./partials/malwareVulnerabilitiesData";
import MalwareCleanData from "./partials/malwareCleanData";
import MalwareContentLoader from "./partials/malwareContentLoader";
import updateMalwareStatus from "../../../../helpers/malwareUpdateStatus";
import _ from "lodash";
import { isArray } from "util";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class ModalMalwareVulnerabilityInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      malwareData: [],
      vulnerabilities: 0,
      malwareInfoData: [],
      reportUrls: [],
      viewCodeDump: "none",
      updated: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.reportUrls !== this.state.reportUrls) {
      return true;
    }

    return false;
  }

  async componentWillReceiveProps(nextProps) {
    let reportUrls = [];
    if (nextProps.malwareInfoData !== this.state.malwareData) {
      for (let x = 0; x < nextProps.malwareInfoData.length; x++) {
        const reportUrl = nextProps.malwareInfoData[x].reportUrl;
        reportUrls.push(reportUrl);
      }

      this.setState({
        malwareData: nextProps.malwareInfoData,
        reportUrls: reportUrls,
        updated: reportUrls.length > 0 ? true : false,
      });
    }
  }

  async componentDidUpdate(prevProps, state) {
    if (
      state.reportUrls !== this.state.reportUrls &&
      state.malwareData !== this.state.malwareData
    ) {
      if (this.state.reportUrls.length > 0) {
        const response = await callFindMalwareVulnerabilityByReportUrl(
          this.state.reportUrls,
          this.props.selectedMalwareHistoryId
        );
      }
    }
  }

  async onSubmitMalwareForReview(actionVul, reportUrl) {
    let actionVulToast = actionVul;

    let vulReport = await callFindMalwareVulnerabilityByReportUrl(reportUrl);

    if (vulReport.data.length > 0) {
      let actionVulnerability = false;

      if (actionVul === "Sent for review") {
        actionVulnerability = true;
      } else {
        actionVulnerability = false;
      }

      const malwareVulnerabilityData = {
        sentForReview: actionVulnerability,
        dateSentForReview: Date.now,
      };

      callUpdatereviewMalwareVulnerability(
        vulReport.data[0]._id,
        malwareVulnerabilityData
      );
    }

    let userId = null;
    let userData = window.localStorage.getItem("userData");
    let userString = cryptr.decrypt(userData);
    let user = JSON.parse(userString);
    if (userData) {
      userId = user._id;
    }

    const awsAddress =
      "https://tg3malware.s3.amazonaws.com/" + this.state.reportUrl;
    let emailHistoryData = {
      action: "create_email_history_from_malware",
      businessId: this.props.businessID,
      userId: userId,
      customMessage: awsAddress,
    };

    await callOnMalwareCreateEmailHistory(emailHistoryData).then(
      (response) => {}
    );
    // }

    if (actionVulToast === "Sent for review") {
      this.setState({
        submitted: true,
        actionValue: "Support " + actionVulToast,
      });
    } else {
      this.setState({
        submitted: false,
        actionValue: "",
      });
    }
  }

  async onChangeSetMalwareVul(
    e,
    reportUrl,
    threat,
    fileName,
    scanStatus,
    malwareHistoryID,
    domainId,
    dumpMD5,
    businessId
  ) {
    await updateMalwareStatus(
      e,
      reportUrl,
      threat,
      fileName,
      scanStatus,
      malwareHistoryID,
      domainId,
      dumpMD5,
      businessId
    );

    window.location.reload();
  }

  render() {
    const { targetID, index } = this.props;
    const { malwareData } = this.state;
    const { userBusinessData } = this.context;
    const business = userBusinessData[index];

    let content = <MalwareContentLoader />;

    if (malwareData.length > 0) {
      content = malwareData.map(
        (malware, i) =>
          malware.status === "clean" ? (
            <MalwareCleanData
              index={i}
              totalIgnoredFiles={malware.vulnerabilityInfo.totalIgnoredFiles}
              primaryDomain={malware.host}
              vulnerabilities={malware.vulnerabilityInfo}
              reportUrl={malware.reportUrl}
              malwareHistoryId={targetID}
              malwareRules={
                business.hasOwnProperty("malwareRules") !== undefined
                  ? business.malwareRules
                  : null
              }
              businessId={business._id}
              domainId={malware.domainId}
            />
          ) : (
            <MalwareVulnerabilitiesData
              businessId={business._id}
              primaryDomain={malware.host}
              domainId={malware.domainId}
              malwareHistoryId={targetID}
              reportUrl={malware.reportUrl}
              status={malware.status}
              totalIgnoredFiles={malware.vulnerabilityInfo.totalIgnoredFiles}
              index={i}
              malwareInfoData={this.state.malwareData}
              malwareRules={
                business.hasOwnProperty("malwareRules") !== undefined
                  ? business.malwareRules
                  : null
              }
              vulnerabilities={malware}
              updateMalwareStatus={this.onChangeSetMalwareVul}
            />
          )
      );
    }

    return (
      <Modal
        targetID={"malware-modal-" + targetID}
        title="Vulnerabilities"
        largeModal
      >
        <div className="scrollbar" id="style-1">
          <div className="force-overflow">{content}</div>
        </div>
      </Modal>
    );
  }
}

ModalMalwareVulnerabilityInfo.contextType = AppContext;
export default ModalMalwareVulnerabilityInfo;
