import React from "react";

const tableHead = (props) => {
  let tdEmpty = [];
  for (let i = 0; i < parseInt(props.emptyHeaderColumnsCount, 10); i++) {
    tdEmpty.push(<td key={i} />);
  }
  let emptyColumns = null;

  if (tdEmpty.length > 0) {
    emptyColumns = tdEmpty.map((td, i) => td);
  }

  return (
    <thead>
      <tr>
        {emptyColumns}
        <td className="bg-torq-light" />
        <td className="bg-torq-light">{props.title}</td>
        <td className="bg-torq-light" />
      </tr>
    </thead>
  );
};

export default tableHead;
