import React from "react";

class ReportsTabHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <ul className="nav nav-tabs" id="reportTab" role="tablist">
        <li className="nav-item active">
          <a
            className="nav-link"
            id="verification-report-tab"
            data-toggle="tab"
            href="#verification-report"
            role="tab"
            aria-controls="verification-report-tab"
            aria-selected="true"
          >
            Verification
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            id="pci-scan-report-tab"
            data-toggle="tab"
            href="#pci-scan-report"
            role="tab"
            aria-controls="pci-scan-report-tab"
            aria-selected="true"
          >
            PCI Scan
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            id="external-malware-scan-tab"
            data-toggle="tab"
            href="#external-malware-scan"
            role="tab"
            aria-controls="external-malware-scan-tab"
            aria-selected="true"
          >
            Malware Scan
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            id="seals-report-tab"
            data-toggle="tab"
            href="#seals-report"
            role="tab"
            aria-controls="seals-report-tab"
            aria-selected="true"
          >
            Seals
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="ssl-scan-report-tab"
            data-toggle="tab"
            href="#ssl-scan-report"
            role="tab"
            aria-controls="ssl-scan-report-tab"
            aria-selected="true"
          >
            SSL Scan
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="active-old-free-businesses-tab"
            data-toggle="tab"
            href="#active-old-free-businesses"
            role="tab"
            aria-controls="active-old-free-businesses-tab"
            aria-selected="true"
          >
            Other
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="email-statistics-tab"
            data-toggle="tab"
            href="#email-statistics"
            role="tab"
            aria-controls="email-statistics-tab"
            aria-selected="true"
          >
            Email Statistics
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="sms-statistics-tab"
            data-toggle="tab"
            href="#sms-statistics"
            role="tab"
            aria-controls="sms-statistics-tab"
            aria-selected="true"
          >
            SMS Statistics
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="insurance-statistics-tab"
            data-toggle="tab"
            href="#insurance-statistics"
            role="tab"
            aria-controls="insurance-statistics-tab"
            aria-selected="true"
          >
            Insurance Statistics
          </a>
        </li>
      </ul>
    );
  }
}

export default ReportsTabHeader;
