import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { images } from "../../helpers/images";
import AppContext from "../../components/context/appContext";

import {
  callApiGetBusinessData,
  callApiUpdateOnboardingPopupData,
} from "../../services/index";

class ScheduleAppointment extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { businessDetails: [] };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.context;

    if (userData && userData.businessDetails) {
      if (
        prevState.businessDetails.length !== userData.businessDetails.length
      ) {
        this.setState(
          {
            businessDetails: userData.businessDetails,
          },
          () => {
            if (this.state.businessDetails.length > 0) {
              this.saveOnboardingPopupData();
            }
          }
        );
      }
    }
  }

  async saveOnboardingPopupData() {
    const currentBusinessData = this.state.businessDetails[0];
    let updateDecision = false;

    const businessData = await callApiGetBusinessData(
      currentBusinessData.businessId
    );

    if (businessData && !businessData.hasOwnProperty("onboardingPopup")) {
      updateDecision = true;
    } else {
      if (businessData && businessData.onboardingPopup.views < 3) {
        if (businessData.onboardingPopup.status === "view") {
          updateDecision = true;
        } else {
          updateDecision = false;
        }
      } else {
        updateDecision = false;
      }
    }

    if (updateDecision) {
      document.getElementById("customPopupAvatar").style.display = "block";
      await callApiUpdateOnboardingPopupData(
        this.state.businessDetails,
        "view"
      );
    }
  }

  async closePopup() {
    document.getElementById("customPopupAvatar").style.display = "none";
    await callApiUpdateOnboardingPopupData(
      this.state.businessDetails,
      "closed"
    );
  }

  async calenderMeeting() {
    document.getElementById("btnScheduleAppoinment").innerHTML = "Loading...";
    document.getElementById("btnScheduleAppoinment").disabled = true;

    document.getElementById("customPopupAvatar").style.display = "none";
    window.open(
      "https://calendly.com/shayne-trustguard/sample-30min",
      "_blank"
    );

    await callApiUpdateOnboardingPopupData(
      this.state.businessDetails,
      "clicked"
    );
  }

  render() {
    return (
      <>
        <div
          id="customPopupAvatar"
          style={{
            position: "fixed",
            top: "40px",
            right: "-600px",
            width: "600px",
            border: "4px solid #e5e5e5",
            borderRadius: "4px",
            padding: "15px 15px 15px 0",
            zIndex: "1500",
            background: "#ffffff",
            WebkitAnimation: "slide 0.5s forwards",
            WebkitAnimationDelay: "2s",
            animation: "slide 0.5s forwards",
            animationDelay: "2s",
            display: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <i
              className="fa fa-times-circle fa-2x"
              aria-hidden="true"
              style={{
                position: "relative",
                top: "-70px",
                left: "555px",
                cursor: "pointer",
              }}
              onClick={() => {
                this.closePopup();
              }}
            />
            <img
              src={images.customPopupAvatar}
              style={{ height: "150px", margin: "0 60px 0 30px" }}
              alt="Schedule appointment"
            />
            <span style={{}}>
              <p
                style={{
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Need Help Getting Started?
              </p>
              <p
                style={{
                  fontSize: "11px",
                  color: "#3c3c3c",
                }}
              >
                Need Help Getting Started? Our Onboarding Specialists are ready
                to help. Click here to schedule your FREE 30 minute onboarding
                call >>>
              </p>

              <button
                id="btnScheduleAppoinment"
                className="btn btn-primary"
                style={{
                  background: "#0D137C",
                  border: "#0D137C",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "150px",
                }}
                onClick={() => this.calenderMeeting()}
              >
                Schedule Appoinment
              </button>
            </span>
          </div>
        </div>
      </>
    );
  }
}

ScheduleAppointment.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleAppointment);
