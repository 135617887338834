import React from "react";
import { isArray } from "util";
import Aux from "../hoc/containerAux";
import MalwareModalData from "./partials/malwareModalData";
import { convertTimeTo } from "../../helpers/timeZone";
import { capitalize } from "../../helpers/capitalize";
import { getUserDecryptedData } from "../../helpers/general";
import { malwareStatus } from "../../helpers/malwareStatus";
import "./../dashboard/partials/reportButton.css";
import {
  callGetDomainHost,
  callGetMalwares,
  callOnMalwareScan,
  callFindMalwareVulnerabilityByReportUrl,
  callMalwareSearchByBusinessIdAndLatestDate,
} from "../../services";

class MalwareScanResults extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      malwareInfoData: [],
      malwareInfoDataNotClean: [],
      malwareStats: "running",
      showSlideMalware: true,
      innerTable: "none",
      innerTables: false,
      viewMoreText: "View More",
      slideOpen: true,
      openM: true,
      domainCountTotal: "",
      vieMoreStatus: this.props.showViewMoreText,
    };
  }

  hideInnerTable() {
    if (!this.state.innerTables === false) {
      this.setState({
        innerTable: "none",
        viewMoreText: "View More",
        slideOpen: false,
      });
    } else {
      this.setState({
        innerTable: "",
        viewMoreText: "View Less",
        slideOpen: true,
      });
    }

    this.setState({ innerTables: !this.state.innerTables });
  }

  async componentDidMount() {
    this._isMounted = true;
    let malwareAPIdata = await callGetMalwares(this.props.malwareHistoryID);
    if (malwareAPIdata.success) {
      let malwareData = malwareAPIdata.data.domains;
      let malwareInfoData = [];
      let domainNumber = 0;
      let malwareData2 = malwareData
        .sort((a, b) => {
          return (
            new Date(a.scanFinish).getTime() - new Date(b.scanFinish).getTime()
          );
        })
        .reverse();
      for (let j = 0; j < malwareData2.length; j++) {
        let malInfo = { _id: malwareData2._id };
        const domainInfo = malwareData2[j];
        let domainName = "";
        const dataMalwareInfo = await callGetDomainHost(domainInfo.domainId);

        domainName = dataMalwareInfo.host;

        let response = await callFindMalwareVulnerabilityByReportUrl(
          domainInfo.reportUrl
        );
        let sendForReview = null;
        if (response.total > 0) {
          sendForReview = response.data[0].sentForReview;
        }

        domainNumber = j + 1;
        malInfo["domainID"] = domainInfo.domainId;
        malInfo["status"] = domainInfo.status;
        malInfo["detection"] = domainInfo.status;
        malInfo["scanFinish"] = domainInfo.scanFinish;
        malInfo["domainNumber"] = domainNumber;
        malInfo["domainInfoID"] = domainInfo._id;
        malInfo["domainName"] = domainName;
        malInfo["reportUrl"] = domainInfo.reportUrl;
        malInfo["sendForReview"] = sendForReview;
        malInfo["malwareHistoryID"] = this.props.malwareHistoryID;
        malwareInfoData.push(malInfo);

        if (domainNumber <= 1) {
          if (this._isMounted) {
            this.setState({
              viewMoreStatus: "none",
            });
          }
        }
      }
      if (this._isMounted) {
        this.setState({
          malwareInfoData,
        });
      }

      const businessId = this.props.businessId;
      const malwareHistory = await callMalwareSearchByBusinessIdAndLatestDate(
        businessId
      );
      let undefCounter = 0;
      if (malwareHistory.data > 0) {
        const malwareScanData = malwareHistory.data.data[0];

        if (malwareScanData.hasOwnProperty("domains")) {
          if (isArray(malwareScanData.domains)) {
            if (malwareScanData.domains.length > 0) {
              for (let i = 0; i < malwareScanData.domains.length; i++) {
                const domain = malwareScanData.domains[i];
                if (domain.status === "running") {
                  undefCounter++;
                }
              }
            }
          }
        }
      }

      if (this.props.hasOwnProperty("businessDetail")) {
        if (undefCounter === 0) {
          this.setState({
            malwareStats: this.props.businessDetail.malwareStatusReport,
          });
        } else {
          this.setState({
            malwareStats: "running",
          });
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  statusBox(status, targetID) {
    const {
      malwareStatusReport,
      businessDetailStatus,
      lastMalwareScanResult,
      malwareBusinessAccountType,
      businessID,
      history,
    } = this.props;
    let boxColor = "";
    let btnStatus = "";
    let label = "";
    if (
      status === "suspicious" ||
      status === "potentially suspicious" ||
      status === "malicious"
    ) {
      btnStatus = "btnFail";
      label = "Failed Scan Report";
    } else if (status === "clean") {
      btnStatus = "btnPass";
      label = "Passed Scan Report";
    } else if (status === "running") {
      btnStatus = "btnRunning";
      label = "Running";
    }

    boxColor = (
      <>
        <button
          className={btnStatus}
          data-toggle="modal"
          data-target={targetID}>
          {label}
        </button>
      </>
    );
    return boxColor;
  }

  displayRescanButton(num1) {
    let {
      businessID,
      malwareStatusReport,
      malwareBusinessAccountType,
      businessDetailStatus,
      lastMalwareScanResult,
    } = this.props;

    let scanMalwareContent = null;

    if (this.props.showRescan === true) {
      if (num1 === 1) {
        if (
          malwareStatusReport === "running" ||
          businessDetailStatus === "inactive" ||
          lastMalwareScanResult === "running"
        ) {
          scanMalwareContent = (
            <button
              className="btn btn-sm btn-success disabled non-pointer"
              style={{ fontSize: "12px", marginTop: "5px" }}>
              Rescan
            </button>
          );
        } else {
          if (malwareBusinessAccountType === true) {
            scanMalwareContent = (
              <button
                className="btn btn-md btn-success"
                style={{ fontSize: "12px", marginTop: "5px" }}
                onClick={() => {
                  window.open("https://trustguard.com", "_blank");
                }}>
                Rescan
              </button>
            );
          } else {
            scanMalwareContent = (
              <button
                className="btn btn-sm btn-success"
                style={{ fontSize: "12px", marginTop: "5px" }}
                data-toggle="modal"
                data-target={"#" + businessID}>
                <i className="fas fa-search" />&nbsp; Rescan
              </button>
            );
          }
        }
      }
      return scanMalwareContent;
    } else {
      return scanMalwareContent;
    }
  }

  render() {
    const userData = getUserDecryptedData();
    const {
      malwareInfoData,
      innerTable,
      viewMoreText,
      vieMoreStatus,
    } = this.state;

    let {
      businessID,
      malwareStatusReport,
      number,
      scanStatus,
      malwareHistoryID,
      scanStart,
      frequency,
      detection,
      numberOfDomains,
      changeClassName,
      history,
    } = this.props;

    let timeZone = "";

    if (userData !== undefined && userData !== null) {
      timeZone = userData.hasOwnProperty("timezone") ? userData.timezone : null;
    } else {
      timeZone = "America/Denver";
    }

    let lastScannedText = convertTimeTo(
      scanStart,
      timeZone,
      "MM/DD/YYYY hh:mm a",
      false
    );
    let viewMoreButtonLink = (
      <span
        style={{ display: vieMoreStatus }}
        onClick={this.hideInnerTable.bind(this)}
        className="pointer">
        {viewMoreText}
      </span>
    );
    let frequencyText = frequency !== null ? capitalize(frequency) : "";
    let detectionText = malwareStatus(detection);

    let reportMalwareScanButton = (
      <div className="input-group">
        <button
          className="btn btn-sm btn-outline-success"
          data-toggle="modal"
          disabled={true}
          style={{
            borderRadius: "0.2rem 0px 0px 0.2rem",
            padding: "0.25rem 0.4rem",
            fontSize: "12px",
          }}>
          Loading...
        </button>
        <div className=" bg-success dash-status-ico">
          <i className="fas fa-sync-alt" aria-hidden="true" />
        </div>
      </div>
    );

    if (malwareStatusReport === "running" && number === 1) {
      lastScannedText = "N/A";
      frequencyText = "Scanning";
      detectionText = "N/A";
      viewMoreButtonLink = null;
      reportMalwareScanButton = (
        <div className="input-group">
          <button
            className="btn btn-sm btn-outline-success"
            data-toggle="modal"
            disabled={true}
            style={{
              borderRadius: "0.2rem 0px 0px 0.2rem",
              padding: "0.25rem 0.4rem",
              fontSize: "12px",
            }}>
            Running
          </button>
          <div className=" bg-success dash-status-ico">
            <i className="fas fa-sync-alt" aria-hidden="true" />
          </div>
        </div>
      );
    } else {
      reportMalwareScanButton = this.statusBox(
        scanStatus,
        "#" + malwareHistoryID
      );
    }

    return (
      <Aux>
        <tr>
          <td> </td>
          <td>{lastScannedText}</td>
          <td>{frequencyText}</td>
          <td>{detectionText}</td>
          <td>
            <div className="input-group">{reportMalwareScanButton}</div>
          </td>
          <td className="text-center"> {numberOfDomains}</td>
          <td className="text-center text-success">{viewMoreButtonLink}</td>
          <td>{this.displayRescanButton(number)}</td>
        </tr>

        <tr
          style={{
            display: innerTable,
            background: "#f9f8f8",
            padding: "10px",
          }}>
          <td className="text-center">&nbsp;</td>
          <td>
            <strong>Last Scanned</strong>
          </td>
          <td>
            <strong>Domain</strong>
          </td>
          <td>
            <strong>Detection</strong>
          </td>
          <td>
            <strong>Status</strong>
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <MalwareModalData
          changeClassNames={changeClassName}
          history={history}
          malwareInfoData={malwareInfoData}
          innerTable={innerTable}
        />
      </Aux>
    );
  }
}

export default MalwareScanResults;
