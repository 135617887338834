import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Aux from "../../components/hoc/containerAux";
import LayoutContainer from "../../components/layout/layoutContainer";
import TrustGuardAnimation from "../../components/animation/trustGuardAnimation";
import { images } from "../../helpers/images";
import ToastMessage from "../../components/toast/toastMessage";
import SealComponent from "./sealComponent";
import { getStorageItem } from "../../helpers/storage";
import config from "../../helpers/config";
import {
  getBusinessDetailsById,
  updateBusinessCertificate,
  updateBusinessCertificateContact,
  findBusinessTrustSeal,
  createOrUpdateTrustSeal,
  resetStatus,
  cssJsHashList,
} from "../../modules/actions/index";
import { callOnGetBusinessInfo } from "../../services/beyondSecurityService";
import { callOnUpdateBusinessCodeSealGenerated } from "../../services/businessServiceCopy";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class TrustSeals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      codeGenerated: false,
      smallArrowUp: false,
      smallValue: [],
      comments: "",
      copied: false,
      businessCertificateData: [
        {
          title: "Website is Security Scanned",
          subTitle: "Security Scan Passed: 01/02/2019",
          icon: "tg-security-scanned-shield-icon",
          tag: "securityScannedActive",
          slug: "website-security",
          image: "tgSecurityIco",
          status: false,
        },
        {
          title: "No Malware Detected",
          subTitle: "Malware Scan Passed: 01/02/2019",
          icon: "tg-malware-icon",
          tag: "malwareOrPhishingActive",
          slug: "no-malware",
          image: "tgMalwareIco",
          status: false,
        },
        {
          title: "Active SSL Certificate",
          subTitle: "Encrypted By: CloudFlare, Inc.",
          icon: "tg-ssl-cert-icon",
          tag: "sslCertificateActive",
          slug: "active-ssl",
          image: "tgSslIco",
          status: false,
        },
      ],
      businessId: "",
      checkDisplayIcons: true,
      displayIcons: true,
      business: "",
      businessTrustSealOriginalPath: "",
      certificateURL: "CERTIFICATE_PATH/certificate/PRIMARY_DOMAIN",
      fixedPlacement: true,
      controlFloatBottomAlignment: "left fa fa-toggle-off",
      controlDistanceFromEdge: 0,
      controlWidth: 225,
      inputDisabled: "",
      /*eslint-disable*/
      sealCode: {
        fixed:
          '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART <div class="tgfloat _ONE"_POSITION>_STANDALONE_ANCHOR_START<img alt="Trust Guard Security Scanned" _IMG_ID class="tgfloat-inner" src="_THREE" style="border: 0; _SIZE " oncontextmenu="var d = new Date(); alert(\'Copying Prohibited by Law - This image and all included logos are copyrighted by Trust Guard \'+d.getFullYear()+\'.\'); return false;" />_STANDALONE_ANCHOR_END</div> _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART',

        custom:
          '<a name="trustlink" href="_CERTIFICATE_URL" rel="nofollow" target="_blank" onclick="var nonwin=navigator.appName!=\'Microsoft Internet Explorer\'?\'yes\':\'no\'; window.open(this.href,\'welcome\',\'location=\'+nonwin+\',scrollbars=yes,width=517,height=\'+screen.availHeight+\',menubar=no,toolbar=no\'); return false;" oncontextmenu="var d = new Date(); alert(\'Copying Prohibited by Law - This image and all included logos are copyrighted by trust-guard \\251 \'+d.getFullYear()+\'.\'); return false;" > <img name="trustseal" alt="Security Seals" style="border: 0;" src="_BUSINESS_SEAL" /></a>',

        textboxCode: "",
      },
      /*eslint-enable*/
      trustSeal: null,
      presetType: null,
      presetName: "",
      label: "",
      color: "green",
      sealType: "outer",
      floatSide: "left",
      width: "225",
      distanceFromEdge: "0",
      colorSettings: {
        green: {
          placeholderShieldOuter: "#333333",
          placeholderShieldInner: "#FFFFFF",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#33981f",
          placeholderBodyRight: "#43b12a",
          placeholderStatusColor: "#ffffff",
          placeholderDateColor: "#ffffff",
          placeholderLabelColor: "#ffffff",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#ffffff",
          placeholderSecuredText: "#ffffff",
          placeholderShieldBg: "#33981F",
          placeholderShieldTop: "#33981F",
          placeholderPrimaryLayer: "#ffffff",
          placeholderSecondaryLayer: "#ffffff",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#ffffff",
          placeholderSealBorder: "#33981F",
        },
        black: {
          placeholderShieldOuter: "#ffffff",
          placeholderShieldInner: "#333333",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#333333",
          placeholderBodyRight: "#434343",
          placeholderStatusColor: "#ffffff",
          placeholderDateColor: "#ffffff",
          placeholderLabelColor: "#ffffff",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#ffffff",
          placeholderSecuredText: "#ffffff",
          placeholderShieldBg: "#333333",
          placeholderShieldTop: "#333333",
          placeholderPrimaryLayer: "#ffffff",
          placeholderSecondaryLayer: "#ffffff",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#333333",
          placeholderSealBorder: "#333333",
        },
        white: {
          placeholderShieldOuter: "#333333",
          placeholderShieldInner: "#FFFFFF",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#f2f2f2",
          placeholderBodyRight: "#ffffff",
          placeholderStatusColor: "#333333",
          placeholderDateColor: "#333333",
          placeholderLabelColor: "#333333",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#333333",
          placeholderSecuredText: "#33981f",
          placeholderShieldBg: "#FFFFFF",
          placeholderShieldTop: "#FFFFFF",
          placeholderPrimaryLayer: "#333333",
          placeholderSecondaryLayer: "#333333",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#ffffff",
          placeholderSealBorder: "#f5f5f5",
        },
      },
      selectedPresetId: null,
      newPresetName: "",
      isSealSaved: false,
      notificationType: null,
      notificationMessage: null,
      trustSealPath: null,
      demoTrustSealPath: null,
      userInfoText: null,
      businessPrimaryDomain: null,
      trustSealPathForWebsiteCode: null,
      isTextElementChanged: false,
      isScanFailed: null,
      companyParagraph: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      contactPhone: "",
      contactEmail: "",
      supportUrl: "",
      supportEmail: "",
      isCopied: false,
      trustsealHost: null,
      displayBlock: "none",
      changingSealParameter: "",
      conditionalNotificationParameters: [
        "sealType",
        "floatSide",
        "distanceFromEdge",
      ],
      sealScriptsCss: "",
      sealScriptsJs: "",
      disableFloatSide: false,
      displayOnChangeSealModal: true,
      sealMaxWidth: 175,
      sealMaxWidthOnMobile: 145,
      showMobileSeal: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  async getBusinessInfo(id) {
    return await callOnGetBusinessInfo(id);
  }

  async componentDidMount() {
    let ID = getStorageItem("businessID");
    let businessId = "";

    if (this.props.location.state && this.props.location.state._id) {
      businessId = this.props.location.state._id;
    } else {
      businessId = cryptr.decrypt(ID);
    }

    await this.setState({
      businessId: businessId,
    });

    this.props.cssJsHashList();
    this.props.getBusinessDetailsById(businessId);

    this.props.findBusinessTrustSeal(businessId);
    const businessInfo = await this.getBusinessInfo(businessId);

    if (businessInfo && businessInfo.status === "canceled") {
      this.props.history.push("/");
    } else {
      this.setState({
        businessId: businessId,
        trustsealHost: config.seals.sealsHost,
        lastGeneratedDate: businessInfo.lastGeneratedDate,
        companyParagraph:
          businessInfo.companyName !== null ? businessInfo.companyName : "",
        address: businessInfo.address !== null ? businessInfo.address : "",
        address2: businessInfo.address2 !== null ? businessInfo.address2 : "",
        city: businessInfo.city !== null ? businessInfo.city : "",
        state: businessInfo.state !== null ? businessInfo.state : "",
        zip: businessInfo.zip !== null ? businessInfo.zip : "",
        country: businessInfo.country !== null ? businessInfo.country : "",
        contactPhone:
          businessInfo.supportPhone !== null ? businessInfo.supportPhone : "",
        contactEmail:
          businessInfo.billingEmail !== null ? businessInfo.billingEmail : "",
        supportEmail:
          businessInfo.supportEmail !== null ? businessInfo.supportEmail : "",
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.business) {
      let certificatePathUrl = config.seals.certificateHost;

      if (newProps.business && newProps.business.lastPciStatus !== "pass") {
        this.setState({
          displayBlock: "block",
        });
      } else {
        this.setState({
          displayBlock: "none",
        });
      }

      this.setState(
        {
          business: newProps.business,
          certificateURL: this.state.certificateURL.replace(
            "PRIMARY_DOMAIN",
            newProps.business.primaryDomain
          ),
          label: newProps.business.primaryDomain,
          businessPrimaryDomain: newProps.business.primaryDomain,
        },
        () => {
          this.setState({
            certificateURL: this.state.certificateURL.replace(
              "CERTIFICATE_PATH",
              certificatePathUrl
            ),
          });

          if (this.state.business.certificate) {
            const { businessCertificateData, business } = this.state;
            const { certificate } = business;

            businessCertificateData.forEach((element) => {
              element.status = false;
              const thisCertificate = certificate.filter(
                (certificateElement) => {
                  return certificateElement.slug === element.slug;
                }
              );
              if (thisCertificate.length > 0) {
                element.status = true;
              }
            });

            this.setState({ businessCertificateData });
          }
        }
      );

      if (newProps.business.hasOwnProperty("certificateContact")) {
        this.setState({
          companyParagraph:
            newProps.business.certificateContact.companyParagraph,
          address: newProps.business.certificateContact.address,
          address2: newProps.business.certificateContact.address2,
          city: newProps.business.certificateContact.city,
          state: newProps.business.certificateContact.state,
          zip: newProps.business.certificateContact.zip,
          country: newProps.business.certificateContact.country,
          contactPhone: newProps.business.certificateContact.contactPhone,
          contactEmail: newProps.business.certificateContact.contactEmail,
          supportUrl: newProps.business.certificateContact.supportUrl,
        });
      }
    }

    if (newProps.businesData && newProps.businesData !== null) {
      this.setState(
        {
          business: newProps.businesData,
        },
        () => {
          if (this.state.business.certificate) {
            const { businessCertificateData, business } = this.state;
            const { certificate } = business;

            businessCertificateData.forEach((element) => {
              element.status = false;
              const thisCertificate = certificate.filter(
                (certificateElement) => {
                  return certificateElement.slug === element.slug;
                }
              );
              if (thisCertificate.length > 0) {
                element.status = true;
              }
            });

            this.setState({ businessCertificateData });
          }
        }
      );
    }

    if (newProps.trustSeals) {
      if (newProps.trustSeals.hasOwnProperty("sealSettings")) {
        let targetIndex = null;
        let selectedPresetId = null;

        targetIndex = newProps.trustSeals.sealSettings.length - 1;
        selectedPresetId = newProps.trustSeals.sealSettings[targetIndex]._id;

        if (this.state.selectedPresetId) {
          selectedPresetId = this.state.selectedPresetId;

          if (newProps.trustSeals.sealSettings.length) {
            for (let i = 0; i < newProps.trustSeals.sealSettings.length; i++) {
              if (
                newProps.trustSeals.sealSettings[i]._id ===
                this.state.selectedPresetId
              ) {
                targetIndex = i;
              }
            }
          }
        }

        this.setState({
          trustSeal: newProps.trustSeals,
          selectedPresetId: selectedPresetId,
          trustSealPath: null,
          demoTrustSealPath: null,
          presetType: newProps.trustSeals.sealSettings[targetIndex].presetType,
          presetName: newProps.trustSeals.sealSettings[targetIndex].presetName,
          label: newProps.trustSeals.sealSettings[targetIndex].label,
          color: newProps.trustSeals.sealSettings[targetIndex].color.title,
          sealType: newProps.trustSeals.sealSettings[targetIndex].sealType,
          floatSide: newProps.trustSeals.sealSettings[targetIndex].floatSide,
          width: newProps.trustSeals.sealSettings[targetIndex].width,
          distanceFromEdge:
            newProps.trustSeals.sealSettings[targetIndex].distanceFromEdge,
          isSealSaved: false,
        });

        setTimeout(() => {
          let tempPresetSealImage =
            newProps.trustSeals.sealSettings[targetIndex]._id +
            ".svg?v=" +
            new Date().getTime();

          let tempPresetSealImageForWebsiteCode =
            newProps.trustSeals.sealSettings[targetIndex]._id + ".svg";

          this.setState(
            {
              trustSealPath:
                this.state.trustsealHost +
                "/sites/" +
                this.state.businessPrimaryDomain +
                "/" +
                tempPresetSealImage,
              trustSealPathForWebsiteCode:
                this.state.trustsealHost +
                "/sites/" +
                this.state.businessPrimaryDomain +
                "/" +
                tempPresetSealImageForWebsiteCode,
              demoTrustSealPath:
                this.state.trustsealHost +
                "/sites/" +
                this.state.businessPrimaryDomain +
                "/" +
                "demo-" +
                tempPresetSealImage,
            },
            () => {
              this.generateSealWebsiteCode();
            }
          );
        }, 2500);
      }
    } else {
      this.setState({
        presetName: "Default",
      });

      this.populateSealsData();
    }

    if (newProps.messageType) {
      if (
        this.state.conditionalNotificationParameters.indexOf(
          this.state.changingSealParameter
        ) === -1
      ) {
        this.setState(
          {
            notificationType: newProps.messageType,
            notificationMessage:
              newProps.message + " " + this.state.changingSealParameter,
          },
          () => this.setState({ notificationType: "", notificationMessage: "" })
        );
      }

      this.props.resetStatus();
    }

    if (newProps.sealScripts && newProps.sealScripts.total > 0) {
      let cssData =
        newProps.sealScripts.total > 0
          ? newProps.sealScripts.data[0].css
          : null;
      let jsData =
        newProps.sealScripts.total > 0 ? newProps.sealScripts.data[0].js : null;

      this.setState({
        sealScriptsCss: cssData[cssData.length - 1],
        sealScriptsJs: jsData[jsData.length - 1],
      });
    }
  }

  onClickPushCheckBoxValue = (slug, currentStatus) => {
    let selectedBusinessCertificate = [];
    const { businessCertificateData } = this.state;

    businessCertificateData.filter((certificate) => {
      if (certificate.slug === slug) {
        certificate.status = !currentStatus;
      }

      return true;
    });

    this.setState({ businessCertificateData });

    for (let i = 0; i < businessCertificateData.length; i++) {
      if (businessCertificateData[i].status === true) {
        selectedBusinessCertificate.push({
          slug: businessCertificateData[i].slug,
          status: true,
        });
      }
    }

    this.props.updateBusinessCertificate(
      this.state.businessId,
      selectedBusinessCertificate
    );

    this.props.getBusinessDetailsById(this.state.businessId);
  };

  onClickDisplayIcons() {
    if (!this.state.checkDisplayIcons === false) {
      this.setState({
        displayIcons: false,
      });
    } else {
      this.setState({
        displayIcons: true,
      });
    }

    this.setState({ checkDisplayIcons: !this.state.checkDisplayIcons });
  }

  handleSwitchChange(event) {
    this.setState({ showMobileSeal: !this.state.showMobileSeal });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let thisUserInfoText = "";

    if (name === "sealType") {
      this.setState({
        sealMaxWidth: 175,
      });
    }

    if (name === "sealType" && value === "checkout") {
      this.setState({
        disableFloatSide: true,
        floatSide: "none",
        sealMaxWidth: 225,
      });
    } else {
      this.setState({
        disableFloatSide: false,
      });
    }

    if (this.state.conditionalNotificationParameters.indexOf(name) !== -1) {
      thisUserInfoText =
        "Your seal has been updated. Please note you must update the code on your website for the seal to display properly.";
    }

    this.setState(
      {
        [name]: value,
        userInfoText: thisUserInfoText,
        isTextElementChanged: true,
        changingSealParameter: name,
      },
      () => {
        switch (name) {
          case "color":
          case "sealType":
          case "floatSide":
            this.saveSeal();
            break;
          default:
            break;
        }

        if (name === "sealType" || name === "floatSide") {
          document.getElementById("btnDisplayModalSealInfo").click();
        }
      }
    );
  }

  showActivePresetContent = (sealSettings) => {
    this.generateSealWebsiteCode();

    var ul = document.getElementById("sealPresetUl");
    var items = ul.getElementsByTagName("li");

    for (var i = 0; i < items.length; ++i) {
      items[i].classList.remove("active");
    }

    this.setState(
      {
        presetType: sealSettings.presetType,
        presetName: sealSettings.presetName,
        trustSealPath:
          this.state.trustsealHost +
          "/sites/" +
          this.state.businessPrimaryDomain +
          "/" +
          sealSettings._id +
          ".svg?v=" +
          new Date().getTime(),
        trustSealPathForWebsiteCode:
          this.state.trustsealHost +
          "/sites/" +
          this.state.businessPrimaryDomain +
          "/" +
          sealSettings._id +
          ".svg",
        demoTrustSealPath:
          this.state.trustsealHost +
          "/sites/" +
          this.state.businessPrimaryDomain +
          "/" +
          "demo-" +
          sealSettings._id +
          ".svg",
        selectedPresetId: sealSettings._id,
        label: sealSettings.label,
        color: sealSettings.color.title,
        sealType: sealSettings.sealType,
        floatSide: sealSettings.floatSide,
        width: sealSettings.width,
        distanceFromEdge: sealSettings.distanceFromEdge,
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );

    document.getElementById(sealSettings._id).classList.add("active");
  };

  createSeal = () => {
    if (
      this.state.newPresetName &&
      typeof this.state.newPresetName !== "undefined"
    ) {
      this.populateSealsData(this.state.newPresetName, "create");

      document.getElementById("hideModalOnSuccess").click();

      this.setState({
        newPresetName: "",
        selectedPresetId: "",
      });
    } else {
      alert("Please enter preset name.");
    }
  };

  saveSeal = () => {
    this.setState({
      isSealSaved: true,
      trustSealPath: null,
    });

    this.populateSealsData(
      this.state.presetName,
      "update",
      this.state.selectedPresetId
    );
  };

  removeSeal = () => {
    let confirm = window.confirm("Are you sure to remove this preset?");

    if (confirm) {
      this.populateSealsData(
        this.state.presetName,
        "remove",
        this.state.selectedPresetId
      );
    }
  };

  generateSealWebsiteCode = () => {
    let websiteSealCode = null;

    switch ("fixed") {
      case "fixed":
        websiteSealCode = this.state.sealCode.fixed;
        let sealOffset = "";
        let tgScript = "tgscript-v" + this.state.sealScriptsJs.version;
        let tgScriptHash = "sha384-" + this.state.sealScriptsJs.hash;

        let tgCss = config.seals.seal_css_version.replace(
          "_STYLE",
          "cert-style-v" + this.state.sealScriptsCss.version + ".css"
        );
        let tgCssHash = "sha384-" + this.state.sealScriptsCss.hash;
        let sealPlacement = "";

        if (
          (this.state.floatSide === "left" ||
            this.state.floatSide === "right") &&
          this.state.floatSide !== "standAlone"
        ) {
          /*eslint-disable*/
          if (this.state.sealType !== "checkout") {
            websiteSealCode = websiteSealCode.replace(
              "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART",
              '<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js" integrity="sha384-tsQFqpEReu7ZLhBV2VZlAu7zcOV+rXbYlF2cqB8txI/8aZajjp4Bqd+V6D5IgvKT" crossorigin="anonymous"></script><script>tg_jq = jQuery.noConflict( true );</script>'
            );
          }

          websiteSealCode = websiteSealCode.replace(
            "_EXTERNAL_STYLE_AND_SCRIPT_REAR_PART",
            '<div id="tgSlideContainer"></div><script src="https://tgscript.s3.amazonaws.com/_TG_SCRIPT" integrity="_TG_SCRIPT_HASH" crossorigin="anonymous"></script><script>document.write(_TG_SCRIPT_FOR_DOCUMENT_WRITE);tg_load_animations("_BUSINESS_ID", "_PLACEMENT_TYPE");</script><link rel="stylesheet" rel="preload" href="_TG_CSS" integrity="_TG_CSS_HASH" crossorigin="anonymous"><style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx !important; }}</style>'
          );
          /*eslint-enable*/

          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT",
            tgScript + ".js"
          );
          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_HASH",
            tgScriptHash
          );
          websiteSealCode = websiteSealCode.replace(
            "_BUSINESS_ID",
            this.state.businessId
          );

          websiteSealCode = websiteSealCode.replace("_TG_CSS", tgCss);
          websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);
          websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);
          websiteSealCode = websiteSealCode.replace("_CERTIFICATE_URL", "");

          let floatSideType = this.state.floatSide;
          let documentScript = "";
          let placementType = "";

          switch (this.state.sealType) {
            case "outer":
              if (this.state.floatSide === "left") {
                floatSideType = "bleft";
                sealPlacement = "tgBleft";
                documentScript = "tg_script_bottom_left";
                placementType = "bottom_left";
              } else if (this.state.floatSide === "right") {
                floatSideType = "bright";
                sealPlacement = "tgBright";
                documentScript = "tg_script_bottom_right";
                placementType = "bottom_right";
              }

              break;
            case "inner":
              if (this.state.floatSide === "left") {
                floatSideType = "bleft";
                sealPlacement = "tgBleft";
                documentScript = "tg_script_bottom_left";
                placementType = "bottom_left";
              } else if (this.state.floatSide === "right") {
                floatSideType = "bright";
                sealPlacement = "tgBright";
                documentScript = "tg_script_bottom_right";
                placementType = "bottom_right";
              }

              break;

            case "side":
              if (this.state.floatSide === "left") {
                floatSideType = "left";
                sealPlacement = "tgLeft";
                documentScript = "tg_script_side_left";
                placementType = "side_left";
              } else if (this.state.floatSide === "right") {
                floatSideType = "right";
                sealPlacement = "tgRight";
                documentScript = "tg_script_side_right";
                placementType = "side_right";
              }

              break;

            default:
              break;
          }

          websiteSealCode = websiteSealCode.replace(
            "_PLACEMENT_TYPE",
            placementType
          );

          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_IMG_ID",
            'id="tg_1-' + floatSideType + '"'
          );

          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_FOR_DOCUMENT_WRITE",
            documentScript
          );
        } else {
          if (this.state.sealType !== "checkout") {
            websiteSealCode = websiteSealCode.replace(
              "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ",
              '<style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx; }}</style>'
            );
          } else {
            websiteSealCode = websiteSealCode.replace(
              "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ",
              ""
            );
          }
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace(
            " _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART",
            ""
          );

          websiteSealCode = websiteSealCode.replace(
            "_CERTIFICATE_URL",
            this.state.certificateURL
          );

          websiteSealCode = websiteSealCode.replace(" _IMG_ID", "");
        }

        if (this.state.sealType !== "checkout") {
          websiteSealCode = websiteSealCode.replace(
            "_SEAL_WIDTH_FOR_MOBILE",
            this.state.sealMaxWidthOnMobile
          );
        }

        if (this.state.sealType !== "side") {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, "width");
        } else {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, "height");
        }

        websiteSealCode = websiteSealCode.replace("_ONE", sealPlacement);

        if (this.state.floatSide === "left") {
          websiteSealCode = websiteSealCode.replace("_TWO", "left");
          websiteSealCode = websiteSealCode.replace(
            "_THREE",
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace("_FOUR", "left:");
          websiteSealCode = websiteSealCode.replace(
            "_FIVE",
            this.state.distanceFromEdge + "px;"
          );
          websiteSealCode = websiteSealCode.replace(
            "_SIX",
            this.state.width + "px;"
          );

          sealOffset = "left:" + this.state.distanceFromEdge + "px;";
        } else {
          websiteSealCode = websiteSealCode.replace("_TWO", "right");
          websiteSealCode = websiteSealCode.replace(
            "_THREE",
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace("_FOUR", "right:");
          websiteSealCode = websiteSealCode.replace(
            "_FIVE",
            this.state.distanceFromEdge + "px;"
          );
          websiteSealCode = websiteSealCode.replace(
            "_SIX",
            this.state.width + "px;"
          );

          sealOffset = "right:" + this.state.distanceFromEdge + "px;";
        }

        if (
          (this.state.sealType === "outer" ||
            this.state.sealType === "inner") &&
          this.state.floatSide === "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "width: " + this.state.sealMaxWidth + "px;"
          );
        }

        if (
          (this.state.sealType === "outer" ||
            this.state.sealType === "inner") &&
          this.state.floatSide !== "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            ""
          );
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            ""
          );
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "width: " + this.state.sealMaxWidth + "px;"
          );
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_POSITION",
            ' style="position:fixed;bottom:0px;z-index: 999999;text-align:left;' +
              sealOffset +
              '"'
          );
        }

        if (
          this.state.sealType === "side" &&
          this.state.floatSide !== "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "height: " + this.state.sealMaxWidth + "px"
          );
          websiteSealCode = websiteSealCode.replace(
            "_POSITION",
            ' style="position:fixed;top:150px;z-index:999999;text-align:left;' +
              sealOffset +
              '"'
          );
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            ""
          );
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            ""
          );
        }

        if (
          this.state.sealType === "side" &&
          this.state.floatSide === "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "height: " + this.state.sealMaxWidth + "px"
          );
        }

        if (this.state.floatSide === "standAlone") {
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            "</a>"
          );
          websiteSealCode = websiteSealCode.replace("_POSITION", "");
          websiteSealCode = websiteSealCode.replace("_SIZE ", "");
        }

        if (this.state.sealType === "checkout") {
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            "</a>"
          );
          websiteSealCode = websiteSealCode.replace("_POSITION", "");
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "width: " + this.state.sealMaxWidth + "px;"
          );
        }

        this.setState({
          sealCode: { ...this.state.sealCode, textboxCode: websiteSealCode },
        });

        break;
      case "custom":
        break;

      default:
        break;
    }
  };

  populateSealsData(presetName = null, purpose = null, sealSettingsId = null) {
    let trustSealId = null;
    let sealData = null;
    let presetSettings = {
      presetType: null,
      presetName: "Default",
      label: this.state.business
        ? this.state.business.primaryDomain.toUpperCase()
        : "",
      sealType: "outer",
      floatSide: "left",
      width: "225",
      distanceFromEdge: 0,
      color: {
        title: "green",
        placeholderShieldOuter: "#333333",
        placeholderShieldInner: "#FFFFFF",
        placeholderShieldLeft: "#66CC3E",
        placeholderShieldRight: "#33981F",
        placeholderBodyLeft: "#33981f",
        placeholderBodyRight: "#43b12a",
        placeholderStatusColor: "#ffffff",
        placeholderDateColor: "#ffffff",
        placeholderLabelColor: "#ffffff",
        placeholderKeyHole: "#ffffff",
        placeholderTrustGuardText: "#ffffff",
        placeholderSecuredText: "#ffffff",
        placeholderShieldBg: "#33981F",
        placeholderShieldTop: "#33981F",
        placeholderPrimaryLayer: "#ffffff",
        placeholderSecondaryLayer: "#ffffff",
        placeholderCheckoutSeparator: "#e6e6e5",
        placeholderShieldInnerBorder: "#ffffff",
        placeholderSealBorder: "#33981F",
      },
      updateDate: new Date().toISOString,
    };

    let sealSettingsData = null;

    if (this.state.sealType === "checkout") {
      this.setState({
        floatSide: "none",
      });
    }

    switch (purpose) {
      case "create":
        trustSealId = this.state.trustSeal._id;
        presetSettings.presetName = this.state.newPresetName;
        sealSettingsData = [
          ...this.state.trustSeal.sealSettings,
          presetSettings,
        ];
        break;

      case "update":
        let sealLabel = this.state.label;
        if (sealLabel === "") {
          sealLabel = this.state.business.primaryDomain;
        }

        let tgSealOuterShieldColor = null;
        if (
          this.state.floatSide === "right" &&
          this.state.sealType === "side" &&
          (this.state.color === "black" || this.state.color === "whiteAlt")
        ) {
          tgSealOuterShieldColor = "#dddddd";
        } else {
          tgSealOuterShieldColor = this.state.colorSettings[this.state.color]
            .placeholderShieldOuter;
        }

        trustSealId = this.state.trustSeal._id;
        sealSettingsData = this.state.trustSeal.sealSettings.map((v, i) => {
          if (v._id === sealSettingsId) {
            v.presetName = this.state.presetName;
            v.label = sealLabel.toUpperCase();
            v.sealType = this.state.sealType;
            v.floatSide =
              this.state.sealType !== "checkout"
                ? this.state.floatSide !== "none"
                  ? this.state.floatSide
                  : "left"
                : "none";
            v.width = "225";
            v.distanceFromEdge = this.state.distanceFromEdge;
            v.color = {
              title: this.state.color,
              placeholderShieldOuter: tgSealOuterShieldColor,
              placeholderShieldInner: this.state.colorSettings[this.state.color]
                .placeholderShieldInner,
              placeholderShieldLeft: this.state.colorSettings[this.state.color]
                .placeholderShieldLeft,
              placeholderShieldRight: this.state.colorSettings[this.state.color]
                .placeholderShieldRight,
              placeholderBodyLeft: this.state.colorSettings[this.state.color]
                .placeholderBodyLeft,
              placeholderBodyRight: this.state.colorSettings[this.state.color]
                .placeholderBodyRight,
              placeholderStatusColor: this.state.colorSettings[this.state.color]
                .placeholderStatusColor,
              placeholderDateColor: this.state.colorSettings[this.state.color]
                .placeholderDateColor,
              placeholderLabelColor: this.state.colorSettings[this.state.color]
                .placeholderLabelColor,
              placeholderKeyHole: this.state.colorSettings[this.state.color]
                .placeholderKeyHole,
              placeholderTrustGuardText: this.state.colorSettings[
                this.state.color
              ].placeholderTrustGuardText,
              placeholderSecuredText: this.state.colorSettings[this.state.color]
                .placeholderSecuredText,
              placeholderShieldBg: this.state.colorSettings[this.state.color]
                .placeholderShieldBg,
              placeholderShieldTop: this.state.colorSettings[this.state.color]
                .placeholderShieldTop,
              placeholderPrimaryLayer: this.state.colorSettings[
                this.state.color
              ].placeholderPrimaryLayer,
              placeholderSecondaryLayer: this.state.colorSettings[
                this.state.color
              ].placeholderSecondaryLayer,
              placeholderCheckoutSeparator: this.state.colorSettings[
                this.state.color
              ].placeholderCheckoutSeparator,
              placeholderShieldInnerBorder: this.state.colorSettings[
                this.state.color
              ].placeholderShieldInnerBorder,
              placeholderSealBorder: this.state.colorSettings[this.state.color]
                .placeholderSealBorder,
            };
            v.updateDate = new Date().toISOString();
          }

          return v;
        });
        break;

      case "remove":
        trustSealId = this.state.trustSeal._id;
        sealSettingsData = this.state.trustSeal.sealSettings.filter(
          (eachData) => {
            return eachData._id !== sealSettingsId;
          }
        );
        break;

      default:
        presetSettings.presetType = "Default";
        sealSettingsData = presetSettings;
        break;
    }

    sealData = {
      businessId: this.state.businessId,
      lastGeneratedDate: null,
      nextGenerateDate: null,
      status: "active",
      sealSettings: sealSettingsData,
    };

    this.props.createOrUpdateTrustSeal(
      trustSealId,
      this.state.businessId,
      purpose,
      sealData,
      sealSettingsId,
      this.state.businessPrimaryDomain
    );
  }

  onSaveCertificateContact() {
    let certificateContact = {
      companyParagraph: this.state.companyParagraph,
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      country: this.state.country,
      contactPhone: this.state.contactPhone,
      contactEmail: this.state.contactEmail,
      supportUrl: this.state.supportUrl,
    };

    this.props.updateBusinessCertificateContact(
      this.state.businessId,
      certificateContact
    );
  }

  generateSealCode = async () => {
    await callOnUpdateBusinessCodeSealGenerated({
      _id: this.state.businessId,
    });
    this.setState({ codeGenerated: true, lastGeneratedDate: Date.now() });
  };

  onUpdate = (values) => {
    this.setState(
      {
        sealMaxWidth: values[0],
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );
  };

  onUpdateMobile = (values) => {
    this.setState(
      {
        sealMaxWidthOnMobile: values[0],
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );
  };

  render() {
    const tablinks = [
      { name: "Seal Options", target: "seal" },
      { name: "Certificate", target: "certificate" },
    ];

    const {
      businessCertificateData,
      businessId,
      certificateURL,
      newPresetName,
      notificationType,
      notificationMessage,
      companyParagraph,
      address,
      address2,
      city,
      state,
      zip,
      country,
      contactPhone,
      contactEmail,
      supportUrl,
      changingSealParameter,
      conditionalNotificationParameters,
    } = this.state;

    return (
      <Aux>
        <LayoutContainer titlePage="Trust Seals" tabs={tablinks}>
          <Aux>
            {conditionalNotificationParameters.indexOf(
              changingSealParameter
            ) !== -1 ? (
              <ToastMessage
                type={notificationType}
                notifyMessage={notificationMessage}
                autoClose={20000}
              />
            ) : (
              <ToastMessage
                type={notificationType}
                notifyMessage={notificationMessage}
              />
            )}

            <div id="mcontent-wrapper">
              <div
                id="seal"
                className="seal-content-wrapper tab-pane fade in active"
              >
                <SealComponent businessId={businessId} />
              </div>

              <div
                id="certificate"
                className="seal-content-wrapper tab-pane fade"
              >
                <h4>CERTIFICATE CUSTOMIZATION</h4>
                <p style={{ marginBottom: "25px" }}>
                  When your customers click on your seal they will see the popup
                  certificate. The certificate tells them about the different
                  security measures and other services you have initiated on
                  their behalf. Customizing your certificate is easy. Simply
                  select from the options on the right by checking the box, to
                  add or remove them from your popup and full certificate. Also,
                  below you may add certain information about your business that
                  will be displayed on your full certificate page.
                </p>

                <div id="dash-malware-table">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 no-pad">
                      <div className="row py-5">
                        <div className="col-md-5 col-md-offset-1 col-sm-5 col-xs-12 col-xxs-12">
                          <div
                            className="text-center"
                            style={{
                              boxShadow: "none",
                              fontSize: "18px",
                              paddingBottom: "20px",
                            }}
                          >
                            Popup Certificate Preview
                          </div>

                          <div
                            id="sec-scanning"
                            className="well-tgcert text-center"
                            style={{
                              margin: "0 auto",
                            }}
                          >
                            <a
                              target="_blank"
                              onClick={() =>
                                window.open(certificateURL, "_blank")
                              }
                              href="https://app.trustguard.com/certificate/shopperapproved.com"
                              id="tg_moreDetails"
                              rel="noopener noreferrer"
                            >
                              More Details <i className="tg-arrow-right" />
                            </a>
                            <img
                              id="tg-cert-logo"
                              src={images.tgRegularDark}
                              alt="logo"
                            />
                            <span id="cert-domain" className="cert-domain">
                              Demosite.com
                            </span>
                            <span className="cert-title">
                              SECURED 01-02-2019
                            </span>
                            <ul id="sortable" className="list-unstyled">
                              {businessCertificateData
                                ? businessCertificateData.map(
                                    (element, index) => {
                                      if (element.status === true) {
                                        return (
                                          <TrustGuardAnimation
                                            key={index}
                                            element={element}
                                            index={index}
                                          />
                                        );
                                      }

                                      return null;
                                    }
                                  )
                                : ""}
                            </ul>
                            <p id="tg_certFooterNote">
                              <span className="tg_domain_name">
                                localreviews.com
                              </span>{" "}
                              has successfully completed and passed a recent PCI
                              DSS Security Scan, an Internal Malware Scan, and
                              has had their SSL Certificate Validated by Trust
                              Guard.
                            </p>
                          </div>
                        </div>

                        <div className="col-md-5 col-sm-5 col-xs-12 col-xxs-12 no-pad text-center">
                          <div
                            id="sec-scanning"
                            className="well-tgcert-params text-center"
                            style={{
                              margin: "55px 0 45px 0",
                            }}
                          >
                            <ul className="list-unstyled">
                              {businessCertificateData.length > 0
                                ? businessCertificateData.map(
                                    (element, index) => {
                                      return (
                                        <li
                                          key={index}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            className={
                                              element.status === true
                                                ? "tg-check-square fa fa-check-square checkBoxColor"
                                                : "tg-check-square fa fa-check-square"
                                            }
                                            onClick={() => {
                                              this.onClickPushCheckBoxValue(
                                                element.slug,
                                                element.status
                                              );
                                            }}
                                            style={{
                                              fontSize: "38px",
                                              marginTop: "-15px",
                                            }}
                                          />
                                          <div
                                            style={{
                                              padding: "0px 5px",
                                              width: "485px",
                                              height: "auto",
                                              border: "1px solid #e5e5e5",
                                              borderRadius: "6px",
                                              display: "flex",
                                              alignItems: "center",
                                              marginLeft: "35px",
                                              marginBottom: "15px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                float: "left",
                                              }}
                                            >
                                              <img
                                                src={images[element.image]}
                                                alt="Certificate"
                                                className="cert-badge"
                                              />
                                            </div>
                                            <div
                                              style={{
                                                float: "left",
                                                fontSize: "18px",
                                              }}
                                            >
                                              {element.title}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    }
                                  )
                                : ""}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-pad text-center">
                      <button
                        className="btn btn-link btn-link-custom"
                        style={{
                          boxShadow: "none",
                          color: "#33981f",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                        onClick={() => window.open(certificateURL, "_blank")}
                      >
                        Click here to see your full certificate page.
                      </button>
                      <hr />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>Company Paragraph:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="companyParagraph"
                            value={companyParagraph}
                            onChange={(evt) =>
                              this.setState({
                                companyParagraph: evt.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <br />
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>Address:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="address"
                            value={address}
                            onChange={(evt) =>
                              this.setState({
                                address: evt.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <br />
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>Address2:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="address2"
                            value={address2}
                            onChange={(evt) =>
                              this.setState({
                                address2: evt.target.value,
                              })
                            }
                          />
                        </div>

                        <br />
                        <br />
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>City:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            value={city}
                            onChange={(evt) =>
                              this.setState({
                                city: evt.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <br />
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>State:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            value={state}
                            onChange={(evt) =>
                              this.setState({
                                state: evt.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <br />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>Zip:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="zip"
                            value={zip}
                            onChange={(evt) =>
                              this.setState({
                                zip: evt.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <br />
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>Country:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            value={country}
                            onChange={(evt) =>
                              this.setState({
                                country: evt.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <br />
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>Contact Phone:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="contactPhone"
                            value={contactPhone}
                            onChange={(evt) =>
                              this.setState({
                                contactPhone: evt.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <br />
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>Contact Email:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="contactEmail"
                            value={contactEmail}
                            onChange={(evt) =>
                              this.setState({
                                contactEmail: evt.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <br />
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <label>Support Url:</label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control"
                            name="supportUrl"
                            value={supportUrl}
                            onChange={(evt) =>
                              this.setState({
                                supportUrl: evt.target.value,
                              })
                            }
                          />
                          <button
                            data-toggle="modal"
                            data-target="#email-ver"
                            className="btn-success btn-md btn-140-width pull-right mt-4"
                            onClick={() => {
                              this.onSaveCertificateContact();
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <br />
                        <br />
                      </div>

                      <br />
                      <br />
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" />
                      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="modalCreatePreset"
              className="modal"
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog " role="document">
                <div className="modal-content col-md-12">
                  <div className="modal-header">
                    <h4>Preset Title</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body pt-5 pb-0">
                    <div className="pt-2 pb-5 row">
                      <p className="col-8 col-lg-offset-2">
                        <label style={{ width: "20%" }}>Title:</label>
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          name="newPresetName"
                          value={newPresetName}
                          onChange={(evt) =>
                            this.setState({ newPresetName: evt.target.value })
                          }
                        />
                      </p>
                    </div>
                    <div className="modal-footer text-right pt-4 px-0">
                      <button
                        id="hideModalOnSuccess"
                        type="button"
                        className="btn-tglight2 btn-140-width mr-2"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn-tgdark2 btn-140-width"
                        onClick={() => {
                          this.createSeal();
                        }}
                      >
                        Create Preset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Aux>
        </LayoutContainer>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businesData: state.accountStore.businesData,
    business: state.accountStore.currentBusiness,
    trustSeals: state.accountStore.trustSeals,
    messageType: state.accountStore.messageType,
    message: state.accountStore.message,
    sealScripts: state.accountStore.sealScripts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBusinessDetailsById,
      updateBusinessCertificate,
      updateBusinessCertificateContact,
      findBusinessTrustSeal,
      createOrUpdateTrustSeal,
      resetStatus,
      cssJsHashList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrustSeals);
