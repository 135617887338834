import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import * as Sentry from "@sentry/browser";

import { unregister } from "./modules/sw";
import store, { history } from "./modules/store";
import App from "./app";

const target = document.querySelector("#root");

//eslint-disable-next-line
function noop() {}

if (process.env.REACT_APP_ENV_TYPE === "live") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App store={store} history={history} />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
);

Sentry.init({
  dsn: "https://f8e8afe6ca9a45299fc75ca34d03a475@sentry.io/1394450",
});

unregister();
