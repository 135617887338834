import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createNote } from "../../modules/actions/index";
import moment from "moment/moment";
import {
  callGetInsuranceData,
  callGetBusinessDetailsById,
} from "../../services";
import "react-toastify/dist/ReactToastify.css";

class InsuranceDocsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      reReview: null,
      customerNotification: null,
      notifyType: null,
      notifyMessage: null,
      customDate: null,
      insuranceData: null,
      businessData: null,
    };
  }

  async componentWillReceiveProps(newProps) {
    if (newProps.selectedBusinessId) {
      let currentBusinessData = await callGetBusinessDetailsById(
        newProps.selectedBusinessId
      );

      this.setState({
        businessData: currentBusinessData,
      });

      if (currentBusinessData && currentBusinessData.insurance) {
        const insuranceData = await callGetInsuranceData({
          type: "insurance-data",
          uuid: currentBusinessData.insurance.uuid,
        });

        this.setState({
          insuranceData: insuranceData,
        });
      } else {
        this.setState({
          insuranceData: null,
        });
      }
    }
  }

  render() {
    return (
      <Aux>
        <div className="modal-content col-md-12">
          <div className="modal-header">
            <h4 className="modal-title f800">Insurance Document Links</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-md-12 col-sm-12 col-xs-12">
              {this.state.businessId}
              {this.state.insuranceData ? (
                this.state.insuranceData.documents &&
                this.state.insuranceData.documents.length > 0 ? (
                  this.state.insuranceData.documents.map((v, i) => {
                    return (
                      <div
                        className="well text-left"
                        style={{ fontSize: "12px" }}
                        key={Math.random()}
                      >
                        <p>
                          <b>Name : </b>
                          {v.name}
                        </p>
                        <p>
                          <b>Pdf Type : </b>
                          {v.pdf_type}
                        </p>
                        <p>
                          <b>Updated At : </b>
                          {moment(v.updated_at).format("LLL")}
                        </p>
                        <p>
                          <b>PDF URL : </b>
                          {v.url}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  ""
                )
              ) : (
                <div className="well text-center">
                  {this.state.businessData && this.state.businessData.insurance
                    ? "Loading ..."
                    : "No records."}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer text-right">
            <button
              type="button"
              className="btn-tglight2 btn-140-width btn-md"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Aux>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userAccount: state.accountStore.userAccount,
    businessAccount: state.accountStore.businessAccount,
    messageType: state.accountStore.messageType,
    message: state.accountStore.message,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createNote,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceDocsModal);
