import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createNote } from "../../modules/actions/index";
import { callGetAdminEmails } from "../../services/index";

class CustomerNoteModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      notesInBusiness: null,
      note: "",
      notifyType: "",
      notifyMessage: "",
      sendNotificationToCustomer: false,
      roles: {
        admin: false,
        manager: false,
        staff: false,
      },
      businessUserList: [],
      customerNoteBusinessId: null,
      customerNoteBusiness: null,
      businessEmailHistories: [],
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      notesInBusiness: null,
      businessId: newProps.businessId,
    });
  }

  setRoles(type, status) {
    let userRoles = this.state.roles;
    //eslint-disable-next-line
    this.state.roles[type] = status;

    userRoles = this.state.roles;

    this.setState({
      roles: userRoles,
    });
  }

  onClickAddNotes = async () => {
    let businessId = this.props.selectedBusinessId;

    if (businessId) {
      if (this.state.note.length === 0) {
        toast.error("Please enter some text in add notes.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const noteData = {
          userId: this.props.userId,
          note: this.state.note,
          noteType: "info",
          createdDate: new Date(),
        };

        this.props.createNote(
          businessId,
          noteData,
          this.state.sendNotificationToCustomer,
          this.state.roles
        );

        this.doNotSendNotification();

        this.setState(
          {
            note: "",
          },
          () => {}
        );

        document.getElementById("closeCustomerNoteModal").click();
      }
    }
  };

  async doNotSendNotification() {
    this.setState({
      sendNotificationToCustomer: false,
      roles: {
        admin: false,
        manager: false,
        staff: false,
      },
    });
  }

  async getBusinessEmailHistories() {
    const emailHistories = await callGetAdminEmails(
      this.props.selectedBusinessId
    );

    this.setState({
      businessEmailHistories:
        emailHistories && emailHistories.data && emailHistories.data.length > 0
          ? emailHistories.data
          : [],
    });
  }

  render() {
    const {
      sendNotificationToCustomer,
      roles,
      businessEmailHistories,
      note,
    } = this.state;
    const {
      usersInBusiness,
      selectedBusinessId,
      selectedBusinessData,
    } = this.props;

    return (
      <Aux>
        <div className="modal-content col-md-12">
          <div className="modal-header">
            <h4 className="modal-title f800">Customer Info/Notes</h4>
            <button
              id="closeCustomerNoteModal"
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body customerNoteModal">
            <div>
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <a
                    href="#info"
                    aria-controls="info"
                    role="tab"
                    data-toggle="tab"
                  >
                    Info/Notes
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#emails"
                    aria-controls="emails"
                    role="tab"
                    data-toggle="tab"
                    onClick={() => {
                      this.getBusinessEmailHistories();
                    }}
                  >
                    Emails
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div
                  role="tabpanel"
                  className="tab-pane fade in active"
                  id="info"
                >
                  {usersInBusiness && usersInBusiness.length ? (
                    <div className="table-responsive col-md-12">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>
                              <label>Name</label>
                            </th>
                            <th>
                              <label>Email</label>
                            </th>
                            <th>
                              <label>Role</label>
                            </th>
                            <th>
                              <label>Created At</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {usersInBusiness.map((eachValue, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {eachValue.firstName +
                                    " " +
                                    eachValue.lastName}
                                </td>
                                <td>{eachValue.email}</td>
                                <td>
                                  {eachValue.businessDetails.map((v, i) => {
                                    return v.businessId === selectedBusinessId
                                      ? v.groupType
                                      : "";
                                  })}
                                </td>
                                <td>
                                  {moment(eachValue.createdAt).format("LLL")}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className=" table-responsive  col-md-12">
                    <div className="">
                      <div>
                        <span className="pull-left">
                          <label>Notes</label>
                        </span>

                        {usersInBusiness && usersInBusiness.length ? (
                          <div>
                            <span
                              className="pull-left"
                              style={
                                sendNotificationToCustomer
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                            >
                              <label
                                className="containerCheck"
                                style={{
                                  marginLeft: "10px",
                                  top: "-7px",
                                  left: "-2px",
                                }}
                              >
                                <input
                                  id="chkSendNotificationToCustomer"
                                  type="checkbox"
                                  checked={sendNotificationToCustomer}
                                  onChange={() => {
                                    this.setState({
                                      sendNotificationToCustomer: !this.state
                                        .sendNotificationToCustomer,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                <span className="checkmark" />
                              </label>{" "}
                              <label
                                htmlFor="sendToCust"
                                onClick={() => {
                                  this.setState({
                                    sendNotificationToCustomer: !this.state
                                      .sendNotificationToCustomer,
                                  });
                                }}
                                style={{ marginLeft: "30px" }}
                              >
                                Send Customer Notification
                              </label>
                            </span>

                            <span
                              className="pull-left"
                              style={
                                sendNotificationToCustomer
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <input
                                style={{ marginLeft: 30 }}
                                type="checkbox"
                                id="chkAdmin"
                                checked={roles.admin}
                                onChange={() => {
                                  this.setRoles("admin", !roles.admin);
                                }}
                              />{" "}
                              <label
                                onClick={() => {
                                  this.setRoles("admin", !roles.admin);
                                }}
                              >
                                Admin
                              </label>
                              <input
                                style={{ marginLeft: 30 }}
                                type="checkbox"
                                id="chkManager"
                                checked={roles.manager}
                                onChange={() => {
                                  this.setRoles("manager", !roles.manager);
                                }}
                              />{" "}
                              <label
                                onClick={() => {
                                  this.setRoles("manager", !roles.manager);
                                }}
                              >
                                Managers
                              </label>
                              <input
                                style={{ marginLeft: 30 }}
                                type="checkbox"
                                id="chkStaff"
                                checked={roles.staff}
                                onChange={() => {
                                  this.setRoles("staff", !roles.staff);
                                }}
                              />{" "}
                              <label
                                onClick={() => {
                                  this.setRoles("staff", !roles.staff);
                                }}
                              >
                                Staff
                              </label>
                              <label
                                style={{ marginLeft: 30, fontWeight: "bold" }}
                              >
                                <button
                                  className="tg-dashboard-link"
                                  onClick={() => {
                                    this.doNotSendNotification();
                                  }}
                                  style={{ color: "red" }}
                                >
                                  Don't send notification
                                </button>
                              </label>
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className="col-lg-9 col-md-9 text-left"
                      style={{ padding: "0px", marginRight: "7px" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add Note"
                        value={note}
                        onChange={(evt) => {
                          this.setState({ note: evt.target.value });
                        }}
                      />
                    </div>
                    <div
                      className="col-lg-2 col-md-2 text-right"
                      style={{ padding: "0px" }}
                    >
                      <button
                        className="btn-tgdark2 btn-140-width btn-md"
                        onClick={() => {
                          this.onClickAddNotes();
                        }}
                        style={{ width: "85px" }}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  {selectedBusinessData &&
                  selectedBusinessData.notes.length > 0 ? (
                    <div className="col-md-12" style={{ marginTop: "35px" }}>
                      <div className="">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Details</th>
                              <th scope="col">User</th>
                              <th scope="col">Sent To</th>
                              <th scope="col">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedBusinessData.notes
                              .map((note) => {
                                return (
                                  <tr key={note._id}>
                                    <td>{note.note}</td>
                                    <td>{note.user}</td>
                                    <td style={{ width: "150px" }}>
                                      {note.roles && note.roles.admin ? (
                                        <span>
                                          Admin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          &nbsp;&nbsp;[
                                          <i
                                            classname="fa fa-check"
                                            style={{ color: "#1e7e34" }}
                                            aria-hidden="true"
                                          />]
                                        </span>
                                      ) : (
                                        <span>
                                          Admin&nbsp;&nbsp;&nbsp;&nbsp;
                                          &nbsp;&nbsp; [
                                          <i
                                            classname="fa fa-times"
                                            style={{ color: "#E94E1B" }}
                                            aria-hidden="true"
                                          />]
                                        </span>
                                      )}
                                      <br />
                                      {note.roles && note.roles.manager ? (
                                        <span>
                                          Manager &nbsp;&nbsp; [
                                          <i
                                            classname="fa fa-check"
                                            style={{ color: "#1e7e34" }}
                                            aria-hidden="true"
                                          />]
                                        </span>
                                      ) : (
                                        <span>
                                          Manager &nbsp;&nbsp; [
                                          <i
                                            classname="fa fa-times"
                                            style={{ color: "#E94E1B" }}
                                            aria-hidden="true"
                                          />]
                                        </span>
                                      )}
                                      <br />
                                      {note.roles && note.roles.staff ? (
                                        <span>
                                          Staff&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          &nbsp;&nbsp; [
                                          <i
                                            classname="fa fa-check"
                                            style={{ color: "#1e7e34" }}
                                            aria-hidden="true"
                                          />]
                                        </span>
                                      ) : (
                                        <span>
                                          Staff&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          &nbsp;&nbsp; [
                                          <i
                                            classname="fa fa-times"
                                            style={{ color: "#E94E1B" }}
                                            aria-hidden="true"
                                          />]
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {note.createdDate
                                        ? moment(note.createdDate).format("lll")
                                        : "N/A"}
                                    </td>
                                  </tr>
                                );
                              })
                              .reverse()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12" style={{ marginTop: "35px" }}>
                      <div className="alert alert-info">No notes found</div>
                    </div>
                  )}
                </div>
                <div role="tabpanel" className="tab-pane fade" id="emails">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>
                          <label>Email</label>
                        </th>
                        <th>
                          <label>Email Body</label>
                        </th>
                        <th>
                          <label>Status</label>
                        </th>
                        <th>
                          <label>Created Date</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessEmailHistories &&
                      businessEmailHistories.length > 0 ? (
                        businessEmailHistories.map((v, i) => {
                          return (
                            <tr key={Math.random()}>
                              <td>{v.emailData.emailTag}</td>
                              <td>{v.emailData.body}</td>
                              <td>{v.status}</td>
                              <td>{moment(v.createdAt).format("LLL")}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No record.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn-danger pull-right btn-140-width btn-md"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </Aux>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userAccount: state.accountStore.userAccount,
    messageType: state.accountStore.messageType,
    message: state.accountStore.message,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createNote }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerNoteModal);
