import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../charts/line";

import AppContext from "../../components/context/appContext";
import BuyerProtectionForm from "./buyerProtectionForm";

class BpImplementation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userBusinessData: [],
      businessId: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userBusinessData } = this.context;

    if (prevState.userBusinessData !== userBusinessData) {
      this.setState(
        {
          userBusinessData: userBusinessData,
          businessId:
            userBusinessData && userBusinessData.length > 0
              ? userBusinessData[0]._id
              : "",
        },
        () => {
          this.context.updateCurrentBusinessId(this.state.businessId);
        }
      );
    }
  }
  render() {
    return <BuyerProtectionForm />;
  }
}

BpImplementation.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BpImplementation);
