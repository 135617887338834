import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { createBuyerProtectionAccount } from "../../modules/actions/index";
import { callApiGetBusinessUsersList } from "../../services/index";
import AppContext from "../../components/context/appContext";

class EnableBuyerProtectionModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isDisable: false,
      businessId: "",
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      this.setState({
        businessId: currentBusinessId,
      });
    }
  }

  async enableBuyerProtection(businessId) {
    this.props.createBuyerProtectionAccount(businessId);
  }

  render() {
    const { businessId } = this.state;

    return (
      <div
        id="enableBuyerProtection"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title f800">
                Buyer Protection - 4X Account
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                <strong>
                  Are you sure to enable 4X account for this user?
                </strong>
              </p>
            </div>
            <div className="modal-footer text-right">
              <button
                type="button"
                className="btn btn-danger btn-140-width btn-md"
                data-dismiss="modal"
              >
                No
              </button>

              <button
                type="button"
                className="btn btn-success btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.enableBuyerProtection(businessId);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EnableBuyerProtectionModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createBuyerProtectionAccount }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnableBuyerProtectionModal);
