import React, { Component } from "react";
import { convertTimeTo } from "../../../helpers/timeZone";
import config from "../../../helpers/config";
import ToastMessage from "../../../components/toast/toastMessage";
import { callDeleteSeal } from "../../../services/reportService";

class SealReportTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trustSealPath: config.urls.linkUrl,
      isSealRemoved: false,
    };
  }

  async deleteSeal(seal) {
    const result = await callDeleteSeal(seal);
    if (result) {
      this.setState({ isSealRemoved: true });
      setTimeout(() => window.location.reload(), 1500);
    }
  }

  render() {
    const { trustSealPath, isSealRemoved } = this.state;
    const {
      toggle,
      reportName,
      description,
      type,
      index,
      state,
      data,
      viewUserAccountDashboard,
    } = this.props;

    return (
      <div className="tab-pane fade in active reportsTab">
        {isSealRemoved === true ? (
          <ToastMessage
            type={"success"}
            notifyMessage={"All seals regenerated successfully."}
          />
        ) : (
          ""
        )}
        <div className="reportButton">
          <button
            className="btn-success btn-sm"
            onClick={() => toggle(type, index)}
          >
            {reportName}
          </button>
        </div>
        <p>{description}.</p>
        <hr />

        <table className="tg table table-bordered table-hover">
          <thead>
            {state[this.props.index] ? (
              type !== "deletableSeals" ? (
                <tr>
                  <th />
                  <th>Company Name</th>
                  <th>Device (ip/url)</th>
                  <th>Frequency</th>
                  <th>Seal Generated</th>
                  <th>Last PCI Scan Date</th>
                  <th>Last Malware Scan Date</th>
                </tr>
              ) : (
                <tr>
                  <th style={{ width: "30%" }}>Primary Domain</th>
                  <th style={{ width: "70%" }}>Seals</th>
                </tr>
              )
            ) : (
              <tr style={{ display: "none" }}>
                <th>No Header</th>
              </tr>
            )}
          </thead>

          <tbody>
            {state[index] ? (
              type === "noseals" ? (
                data.noseals.data.length > 0 ? (
                  data.noseals.data.map((business, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <button
                          className="tg-dashboard-link"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            viewUserAccountDashboard(e, business._id)
                          }
                        >
                          {business.businessName}
                        </button>
                      </td>
                      <td>{business.primaryDomain}</td>
                      <td>{business.scanFrequency}</td>
                      <td>{business.sealCodeGenerated ? null : "N/A"}</td>
                      <td>
                        {convertTimeTo(
                          business.lastPciScanDate,
                          business.pciScanTimeZone !== null
                            ? business.pciScanTimeZone
                            : "America/Denver"
                        )}
                      </td>
                      <td>
                        {convertTimeTo(
                          business.lastMalwareScan,
                          business.pciScanTimeZone !== null
                            ? business.pciScanTimeZone
                            : "America/Denver"
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      No Results Found
                    </td>
                  </tr>
                )
              ) : type === "generatedseals" ? (
                data.generatedseals.length > 0 ? (
                  data.generatedseals.map((business, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <button
                          className="tg-dashboard-link"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            viewUserAccountDashboard(
                              e,
                              business.data.businessInfo[0]._id
                            )
                          }
                        >
                          {business.data.businessInfo[0].businessName !== ""
                            ? business.data.businessInfo[0].businessName
                            : business.data.businessInfo[0].primaryDomain}
                        </button>
                      </td>
                      <td>{business.data.businessInfo[0].primaryDomain}</td>
                      <td>{business.data.businessInfo[0].scanFrequency}</td>
                      <td>
                        {convertTimeTo(
                          business.data.lastGeneratedDate,
                          business.data.businessInfo[0].pciScanTimeZone !== null
                            ? business.data.businessInfo[0].pciScanTimeZone
                            : "America/Denver"
                        )}
                      </td>
                      <td>
                        {convertTimeTo(
                          business.data.businessInfo[0].lastPciScanDate,
                          business.data.businessInfo[0].pciScanTimeZone !== null
                            ? business.data.businessInfo[0].pciScanTimeZone
                            : "America/Denver"
                        )}
                      </td>
                      <td>
                        {convertTimeTo(
                          business.data.businessInfo[0].lastMalwareScan,
                          business.data.businessInfo[0].pciScanTimeZone !== null
                            ? business.data.businessInfo[0].pciScanTimeZone
                            : "America/Denver"
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      No Results Found
                    </td>
                  </tr>
                )
              ) : type === "deletableSeals" ? (
                data.deletableSeals.data.length > 0 ? (
                  data.deletableSeals.data.map((v, i) => {
                    return (
                      <tr>
                        <td>{v.primaryDomain}</td>
                        <td>
                          {v.seals.length > 0 ? (
                            <table style={{ width: "100%" }}>
                              {v.seals.length > 0 ? (
                                v.seals.map((iv, ii) => {
                                  return (
                                    <tr
                                      style={{
                                        padding: "5px",
                                        borderBottom: "1px solid #e5e5e5",
                                      }}
                                    >
                                      <td
                                        style={{
                                          width: "30%",
                                          textAlign: "center",
                                          padding: "5px",
                                        }}
                                      >
                                        {iv}
                                      </td>
                                      <td
                                        style={{
                                          width: "40%",
                                          textAlign: "center",
                                          padding: "5px",
                                        }}
                                      >
                                        <img
                                          src={
                                            trustSealPath +
                                            "/sites/" +
                                            v.primaryDomain +
                                            "/" +
                                            iv
                                          }
                                          alt={v.primaryDomain}
                                          style={{
                                            width: "150px",
                                            height: "45px",
                                          }}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          width: "30%",
                                          textAlign: "right",
                                          padding: "5px",
                                        }}
                                      >
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => {
                                            this.deleteSeal(
                                              iv + "^" + v.primaryDomain
                                            );
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr style={{ display: "none" }}>
                                  <td>No Records</td>
                                </tr>
                              )}
                            </table>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      No Results Found
                    </td>
                  </tr>
                )
              ) : (
                <tr style={{ display: "none" }}>
                  <td>No Records</td>
                </tr>
              )
            ) : (
              <tr style={{ display: "none" }}>
                <td>No Records</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SealReportTab;
