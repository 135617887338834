import { api } from "./api";
import { callApi } from "../helpers/index";

export const callApiLogin = async (data) => {
  return api.service("api/login").create(data);
};

export const callApiResetPassword = (data) => {
  return api.service("api/reset-password").create(data);
};

export const callApiUpdatePassword = (data) => {
  return api.service("api/update-password").create(data);
};
