import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import moment from "moment";
import { callGetInsurance, callApiGetInsuranceDocument } from "../../services";
import AppContext from "../../components/context/appContext";
import { convertTimeTo } from ".././../helpers/timeZone";

class insuranceDocumentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userId: "",
      businessId: "",
      insuranceDocument: [],
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { userData, currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      this.setState(
        {
          userId: userData._id,
          businessId: currentBusinessId,
        },
        () => {
          this.populateBusinessInsuranceData();
        }
      );
    }
  }

  populateBusinessInsuranceData = async () => {
    const insuranceData = await callGetInsurance(this.state.businessId);

    if (insuranceData && insuranceData.data && insuranceData.data.length > 0) {
      const insuranceDocs = await callApiGetInsuranceDocument(
        insuranceData.data[0].uuid
      );

      if (insuranceDocs) {
        this.setState({
          insuranceDocument: insuranceDocs,
        });
      }
    }
  };

  render() {
    const { businessId, insuranceDocument } = this.state;

    return (
      <div
        id="insuranceDocumentsModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title f800">Insurance Document Links</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  {insuranceDocument &&
                  insuranceDocument.documents &&
                  insuranceDocument.documents.length > 0 ? (
                    insuranceDocument.documents.map((v, i) => {
                      return (
                        <div
                          className="well text-left"
                          style={{ fontSize: "12px" }}
                          key={Math.random()}
                        >
                          <p>
                            <b>Name : </b>
                            {v.name}
                          </p>
                          <p>
                            <b>Pdf Type : </b>
                            {v.pdf_type}
                          </p>
                          <p>
                            <b>Updated At : </b>
                            {moment(v.updated_at).format("LLL")}
                          </p>
                          <p>
                            <b>PDF URL : </b>
                            {v.url}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <div className="well text-center">
                      <p>No records.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                type="button"
                className="btn btn-danger btn-140-width btn-md"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

insuranceDocumentsModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToDashboard: () => push("/"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(insuranceDocumentsModal);
