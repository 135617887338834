import React from "react";
import { Doughnut } from "react-chartjs-2";
import ContentWrapper from "../../components/ui/contentWrapper";

const data = {
  labels: ["Red", "Green", "Yellow"],
  datasets: [
    {
      data: [80, 50, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    },
  ],
};

const charts = (props) => {
  return (
    <ContentWrapper header="Charts Component" title={null}>
      <Doughnut data={data} />
    </ContentWrapper>
  );
};

export default charts;
