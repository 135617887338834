const config = {
  urls: {
    apiUrl: process.env.REACT_APP_API_PATH,
    linkUrl: process.env.REACT_APP_IS_DEV
      ? process.env.REACT_APP_ENV_TYPE === "local"
        ? "http://localhost:3030"
        : "https://apidev.trust-guard.com"
      : "https://api.trustguard.com",
    dashboardUrl:
      process.env.REACT_APP_IS_DEV === "false" &&
      process.env.REACT_APP_ENV_TYPE === "live"
        ? "https://app.trustguard.com"
        : process.env.REACT_APP_IS_DEV === "true" &&
          process.env.REACT_APP_ENV_TYPE === "dev"
          ? "https://appdev.trust-guard.com"
          : "http://localhost:3000",
  },
  messages: {
    loginSuccess: "Login Success",
    loginFailure:
      "Incorrect email or password, please try again or reset your password below",
    createSuccess: "Created Successfully",
    updateSuccess: "Updated Successfully",
    errorMessage: "Couldn't save. Please double check all values.",
    inValidURL: "*Invalid Sub-domain or IP Address.",
    domainExists: "It looks like this domain already exists.",
    changeFrequency:
      "Are you sure you want to change the scan duration? Insurance only applies if the scan is ran daily.",
  },
  timezones: [
    {
      title: "edt",
      timezone: "America/New_York",
    },
    {
      title: "cdt",
      timezone: "America/Chicago",
    },
    {
      title: "mdt",
      timezone: "America/Denver",
    },
    {
      title: "mst",
      timezone: "America/Phoenix",
    },
    {
      title: "pdt",
      timezone: "America/Los_Angeles",
    },
    {
      title: "adt",
      timezone: "America/Anchorage",
    },
    {
      title: "hast",
      timezone: "Pacific/Honolulu",
    },
    {
      title: "hadt",
      timezone: "America/Adak",
    },
  ],
  seals: {
    sealsHost:
      process.env.REACT_APP_IS_DEV === "false" &&
      process.env.REACT_APP_ENV_TYPE === "live"
        ? "https://seal.trustguard.com"
        : process.env.REACT_APP_IS_DEV === "true" &&
          process.env.REACT_APP_ENV_TYPE === "dev"
          ? "https://seal.trust-guard.com"
          : "http://localhost:3030",
    certificateHost:
      process.env.REACT_APP_IS_DEV === "false" &&
      process.env.REACT_APP_ENV_TYPE === "live"
        ? "https://app.trustguard.com"
        : process.env.REACT_APP_IS_DEV === "true" &&
          process.env.REACT_APP_ENV_TYPE === "dev"
          ? "https://appdev.trust-guard.com"
          : "http://localhost:3000",
    /*eslint-disable*/
    seal_script_version:
      process.env.REACT_APP_IS_DEV === "false" &&
      process.env.REACT_APP_ENV_TYPE === "live"
        ? "https://app.trustguard.com"
        : process.env.REACT_APP_IS_DEV === "true" &&
          process.env.REACT_APP_ENV_TYPE === "dev"
          ? "https://appdev.trust-guard.com"
          : "http://localhost:3000",
    seal_script_hash:
      "sha384-HNfON6Rb0M7gvO0ESW4ODCKLKQmkiD2j/bb/IWzGErzd265NU9L7l6NskIojDD7m",
    seal_css_version:
      process.env.REACT_APP_IS_DEV === "false" &&
      process.env.REACT_APP_ENV_TYPE === "live"
        ? "https://tgscript.s3.amazonaws.com/_STYLE"
        : process.env.REACT_APP_IS_DEV === "true" &&
          process.env.REACT_APP_ENV_TYPE === "dev"
          ? "https://tgscript.s3.amazonaws.com/_STYLE"
          : "https://tgscript.s3.amazonaws.com/_STYLE",
    seal_css_hash:
      "sha384-H1FtRzxFJR8neagNij/7VBKE2Y/O15bg7Z4GsdJUPWzkylNu9/vfuOskJQo9OzVl",
    s3Bucket:
      (process.env.REACT_APP_IS_DEV === "true" &&
        process.env.REACT_APP_ENV_TYPE === "local") ||
      (process.env.REACT_APP_IS_DEV === "true" &&
        process.env.REACT_APP_ENV_TYPE === "dev")
        ? "tgscript-dev"
        : "tgscript",
  },
  /*eslint-enable*/
  chart: {
    lineOption: {
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(102,204,62,0.4)",
      borderColor: "rgba(102,204,62,1)",
      borderCapStyle: "round",
      borderDash: [],
      borderDashOffset: 1.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(102,204,62,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(102,204,62,1)",
      pointHoverBorderColor: "rgba(102,204,62,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    },
  },
  createAccountKey: "J9l4j4923n4bcsg7Df466gddjhxdhfn5Okfekoef",
};

export default config;
