import React from "react";

class Slide extends React.Component {
  static defaultProps = { open: false, transition: 300 };

  render() {
    const { open, children } = this.props;
    const styles = {
      height: 0,
      transition: "height 1ms",
      overflow: "hidden",
    };

    if (open) {
      styles.height = "100%";
    }

    return (
      <div ref={(el) => (this.wrapperElement = el)} style={styles}>
        {children}
      </div>
    );
  }
}

export default Slide;
