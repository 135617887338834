import React from "react";
import Modal from "../../../ui/modal/modal";
const modalRefreshSeal = ({}) => {
  return (
    <Modal targetID="refreshModal" title="Refresh Seals">
      <div className="pt-3 pb-5 text-left">
        <strong>
          Your seals will be refreshed and if there are any changes you should
          see them within 1 - 2 minutes.
        </strong>
      </div>

      <div className="modal-footer pt-4 px-0" set="modal-footer">
        <button
          type="button"
          className="btn-success btn-md pull-right btn-140-width"
          data-dismiss="modal"
          onClick={() => {
            {
            }
          }}
        >
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default modalRefreshSeal;
