import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";

import { callApiGetCheckScanReportsPendingDomainData } from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";
import NoSealsReport from "./noSealsReport";
import SealsGeneratedReport from "./sealsGeneratedReport";
import DeletableSealsReport from "./deletableSealsReport";
import RegenerateAllSeals from "./regenerateSealsReport";
import RunningSslScanReport from "./runningSslScanReport";
import InvalidSslScanReport from "./invalidSslScanReport";
import PendingSslScanReport from "./pendingSslScanReport";
import ActiveSslExpiresReport from "./activeSslExpiresReport";
import CanceledSitesSslScanReport from "./canceledSitesSslScanReport";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class SslScanReports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <>
        <div style={{ margin: "15px" }}>
          <hr />

          <RunningSslScanReport />
          <InvalidSslScanReport />
          <PendingSslScanReport />
          <ActiveSslExpiresReport />
          <CanceledSitesSslScanReport />
        </div>
      </>
    );
  }
}

SslScanReports.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SslScanReports);
