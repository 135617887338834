import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import SealReportTab from "./_partials/sealReportTab";
import config from "../../helpers/config";
import { viewUserBusinessAccountDashboard } from "../../helpers/general";
import { regenerateAllSeals } from "../../modules/actions/accountAction";
import ToastMessage from "../../components/toast/toastMessage";
import {
  callOnGetNoSealGeneratedCode,
  callOnGetBusinessReport,
  callDeletableSealsReport,
} from "../../services/reportService";

class SealReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportTypes: ["noseals", "generatedseals", "deletableSeals"],
      showReport: {
        noseals: false,
        generatedseals: false,
        deletableSeals: false,
      },
      data: {
        noseals: [],
        generatedseals: [],
      },
      allSealsRegenerated: false,
      toggledIndex: "",
    };
  }

  viewUserAccountDashboard = (e, businessId) => {
    viewUserBusinessAccountDashboard(businessId);

    window.open(config.urls.dashboardUrl, "_blank");
  };

  async componentDidMount() {
    const businesses = await callOnGetNoSealGeneratedCode();
    if (businesses.data.length > 0) {
      this.setState({
        businesses: businesses.data,
      });
    }
  }

  showReportHandler = async (reportType, index) => {
    const showReport = { ...this.state.showReport };
    const data = { ...this.state.data };
    showReport[reportType] = !this.state.showReport[reportType];
    let params = reportType;

    if (reportType === "noseals") {
      data[reportType] = await callOnGetNoSealGeneratedCode(params);
    }

    if (reportType === "generatedseals") {
      data[reportType] = await callOnGetBusinessReport(params);
    }

    if (reportType === "deletableSeals") {
      data[reportType] = await callDeletableSealsReport();
    }

    this.setState({
      showReport,
      toggledIndex: index,
      data,
    });
  };

  componentWillReceiveProps(newProps) {
    if (newProps.allSealsRegenerated === true) {
      this.setState({
        allSealsRegenerated: true,
      });
    }
  }

  render() {
    const {
      reportTypes,
      showReport,
      data,
      allSealsRegenerated,
      toggledIndex,
    } = this.state;

    return (
      <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
        <div className=" tab-content">
          {reportTypes.map((type, index) => {
            let reportName = null;
            let description = null;
            switch (type) {
              case "noseals":
                reportName = "No Seals";
                description = "Sites that have no seals generated";
                break;
              case "generatedseals":
                reportName = "Seals Generated";
                description = "Sites that have seals generated";
                break;
              case "deletableSeals":
                reportName = "Deletable Seals";
                description = "Seals that must be deleted";
                break;
              default:
                break;
            }

            return (
              <SealReportTab
                key={type + "-" + index}
                type={type}
                index={index}
                reportName={reportName}
                description={description}
                toggledIndex={toggledIndex}
                toggle={this.showReportHandler}
                data={data}
                viewUserAccountDashboard={this.viewUserAccountDashboard}
                state={{
                  "0": showReport.noseals,
                  "1": showReport.generatedseals,
                  "2": showReport.deletableSeals,
                }}
              />
            );
          })}
          {allSealsRegenerated === true ? (
            <ToastMessage
              type={"success"}
              notifyMessage={"All seals regenerated successfully."}
            />
          ) : (
            ""
          )}

          <div className="tab-pane fade in active reportsTab">
            <div className="reportButton">
              <button
                className="btn-primary btn-sm"
                onClick={() => {
                  this.props.regenerateAllSeals();
                }}
                disabled={allSealsRegenerated === true ? "disabled" : ""}
              >
                Regeneate All
              </button>
            </div>
            <p>Regeneate all seals</p>
            <hr />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allSealsRegenerated: state.accountStore.allSealsRegenerated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      goToDashboard: () =>
        push("/", {
          accountAdminView: true,
        }),
      regenerateAllSeals,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealReport);
