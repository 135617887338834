import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class FeedbackAlertModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div
        id="userFeedbackAlertModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Feedback</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 text-left p-0">
                <p>Message sent!</p>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                id="btnCancelDelayFunctionModal"
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackAlertModal);
