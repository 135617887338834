import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  callApiGetUserData,
  callApiGetLoggedInUserData,
  callApiUpdateUserProfileData,
  callApiSendFeedback,
} from "../../services/index";
import {
  GET_LOGGED_IN_USER_DATA_SUCCESS,
  GET_LOGGED_IN_USER_DATA_FAILURE,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  UPDATE_USER_PROFILE_DATA_SUCCESS,
  UPDATE_USER_PROFILE_DATA_FAILURE,
} from "../types/index";

export const getLoggedInUserData = (data) => {
  return async (dispatch) => {
    return callApiGetLoggedInUserData(data)
      .then((resData) => {
        dispatch({
          type: GET_LOGGED_IN_USER_DATA_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_LOGGED_IN_USER_DATA_FAILURE,
        });
      });
  };
};

export const getUserData = (data) => {
  return async (dispatch) => {
    return callApiGetUserData(data)
      .then((resData) => {
        dispatch({
          type: GET_USER_DATA_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_DATA_FAILURE,
        });
      });
  };
};

export const updateUserProfileData = (userId, data) => {
  return async (dispatch) => {
    return callApiUpdateUserProfileData(userId, data)
      .then((resData) => {
        dispatch({
          type: UPDATE_USER_PROFILE_DATA_SUCCESS,
          payload: resData,
        });

        if (resData.success && resData.message != "") {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        } else if (resData.errors) {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_USER_PROFILE_DATA_FAILURE,
        });

        toast.error(err.errors, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      });
  };
};
