import React from "react";
import Header from "../../views/header";
const contentWrapper = props => {
  return (
    <>
      <Header titlePage={props.header} bgColor="#F5F6F8" />
      <div id="mcontent-wrapper">
        <div id="PCI-scan" className="">
          <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
            <div className="inner-ptab tab-content">
              <div id="scan" className="tab-pane fade in active">
                {props.title ? <h4>{props.title}</h4> : null}
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default contentWrapper;
