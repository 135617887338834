import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callApiBuyerProtectionAccount } from "../../services/index";
import {
  CREATE_BUYER_PROTECTION_ACCOUNT_SUCCESS,
  CREATE_BUYER_PROTECTION_ACCOUNT_FAILURE,
} from "../types/index";

export const createBuyerProtectionAccount = (businessId) => {
  return async (dispatch) => {
    return callApiBuyerProtectionAccount(businessId)
      .then((resData) => {
        dispatch({
          type: CREATE_BUYER_PROTECTION_ACCOUNT_SUCCESS,
          payload: resData,
        });

        if (resData.success && resData.code === 204 && resData.message != "") {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        } else if (
          resData.success &&
          resData.code === 200 &&
          resData.message != ""
        ) {
          toast.info(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        } else {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATE_BUYER_PROTECTION_ACCOUNT_FAILURE,
        });

        toast.error(err.errors, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      });
  };
};
