import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  onClickSendMail,
  resetStatus,
  updateBusinessAccountType,
  updateUserDetails,
} from "../../modules/actions/index";

class BusinessPrimaryUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      businessId: "",
      selectedUserId: "",
      selectedUserData: "",
      userAccessRole: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      timezone: "",
      editable: false,
      submitted: false,
      notifyType: "",
      notifyMessage: "",
      businessInfoData: [],
      displayUserDetails: "none",
      editData: false,
      isPrimaryUser: false,
    };
  }

  onClickUpdateAccount = (id) => {
    this.goToUpdateAccount(id);
  };

  async goToUpdateAccount() {
    let targetUserBusinessDetails = this.state.selectedUserData.businessDetails;

    targetUserBusinessDetails.map((v, i) => {
      if (v.businessId === this.props.businessId) {
        v.groupType = this.state.userAccessRole;
      }

      return v;
    });

    let targetData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      timezone: this.state.timezone,
      businessDetails: targetUserBusinessDetails,
    };

    this.props.updateUserDetails(this.state.selectedUserId, targetData);

    this.props.controlPanelUserUpdated();
    this.props.populateBusinessUsersData(this.props.businessId);

    this.setState({
      editData: false,
    });
  }

  onChangeHandelUserInput(evt) {
    let name = evt.target.name;
    let value = evt.target.value;
    this.setState({
      [name]: value,
    });
  }

  resetPassword = (email) => {
    if (email) {
      let sendToMail = {
        action: "create_email_history",
        emailTag: "reset-password",
        query: {
          email: email,
        },
      };
      this.props.onClickSendMail(sendToMail);
    }
  };

  showEditUser(user) {
    let roleData = user.businessDetails.filter((v, i) => {
      return v.businessId === this.props.businessId ? v : null;
    });

    this.setState(
      {
        selectedUserId: user._id,
        selectedUserData: user,
        editData: true,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email,
        timezone: user.timezone,
        userAccessRole:
          roleData[0].groupType === "admin"
            ? "Account Admin"
            : roleData[0].groupType,
        isPrimaryUser: user.primaryUser,
      },
      () => {}
    );
  }

  componentWillReceiveProps(newProps) {
    if (newProps.mailCode === 1) {
      toast.info("Requested link sent to this email", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.props.resetStatus();
    }

    if (newProps.messageType === "control_panel_user_edited_success") {
      this.setState(
        {
          notifyType: "success",
          notifyMessage: newProps.message,
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (newProps.usersInBusiness) {
      newProps.usersInBusiness.map((v, i) => {
        let currentBusiness = v.businessDetails.filter((iv, ii) => {
          return v.businessId === this.props.busnessId ? v : null;
        });

        v.userAccessRole = currentBusiness[0].groupType;
        v.primaryUser = currentBusiness[0].primaryUser;

        return v;
      });
    }
  }

  onChangeAccessRole = (event) => {
    this.setState({
      userAccessRole: event.target.value,
    });
  };

  setSubmittedFalse(id) {
    this.setState({
      submitted: false,
      displayUserDetails: "none",
    });
  }

  changeBusinessAccountType = (accountType) => {
    this.props.updateBusinessAccountType(this.props.businessId, {
      accountType: accountType,
    });
  };
  render() {
    const { editData } = this.state;
    const { businessId, usersInBusiness } = this.props;

    return (
      <Aux>
        <div
          className="modal-content"
          id={businessId}
          style={{ border: "1px solid #000000" }}
        >
          <div className="modal-header">
            <div className="modal-title f800">
              <h3>Business Users</h3>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.setSubmittedFalse()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {usersInBusiness !== undefined && usersInBusiness ? (
            <div className="modal-body">
              <div
                className="table-wrapper-scroll-y"
                style={editData ? { display: "none" } : { display: "block" }}
              >
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Group Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersInBusiness.map((user) => {
                      return (
                        <Aux key={Math.random()}>
                          <tr>
                            <td>
                              {user.firstName} {user.lastName}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.userAccessRole}</td>
                          </tr>
                        </Aux>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn-danger pull-right btn-140-width btn-md"
              data-dismiss="modal"
              onClick={() => this.setSubmittedFalse()}
            >
              Close
            </button>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mailCode: state.loginStore.mailCode,
    usersInBusiness: state.loginStore.usersInBusiness,
    messageType: state.loginStore.messageType,
    message: state.loginStore.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateUserDetails,
      onClickSendMail,
      resetStatus,
      updateBusinessAccountType,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessPrimaryUserModal);
