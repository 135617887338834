import React from "react";
import Spinner from "../../../components/ui/spinner/spinnerRectScan";
function backDropVulnerability({ closeModal, vulnerabilityError }) {
  return (
    <>
      <div
        style={{
          margin: "0px auto",
          width: "50%",
          height: "20%",
          background: "rgb(255, 255, 255)",
          position: "absolute",
          zIndex: 1000,
          left: "25%",
          top: "25%",
          padding: "25px",
          textAlign: "center",
          fontSize: "1.2em",
          fontWeight: "bold",
        }}>
        <p>
          Retrieving Vulnerabilites... <br />
          <Spinner />
          <br />
          <br />
          {vulnerabilityError ? (
            <center>
              <button className="btn btn-warning" onClick={() => closeModal()}>
                Something went wrong, close window.
              </button>
            </center>
          ) : null}
        </p>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: 100,
          left: 0,
          top: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      />
    </>
  );
}

export default backDropVulnerability;
