import React from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment/moment";
import Table from "../../components/ui/table/table";
import Aux from "../../components/hoc/containerAux";
import { removeStorageItem } from "../../helpers/storage";
import { getInsuranceHistories } from "../../modules/actions/index";

class InsuranceStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      startDate: moment(),
      endDate: moment(),
      insuranceHistories: [],
      insuranceHistoryDetails: [],
      statData: null,
      selectedEmailTag: null,
    };

    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date,
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
    });
  }

  componentDidMount() {
    this.props.getInsuranceHistories();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.insuranceHistories) {
      this.setState({
        insuranceHistories: newProps.insuranceHistories,
      });
    }
  }

  getEmailStatistics() {
    this.props.getInsuranceHistories(this.state.startDate, this.state.endDate);
  }

  getEmailStatisticsInDetails(emailTag) {
    this.setState({
      selectedEmailTag: emailTag,
    });
    this.props.getEmailHistoryDetail(
      emailTag,
      this.state.startDate,
      this.state.endDate
    );
  }

  render() {
    const {
      startDate,
      endDate,
      insuranceHistories,
      selectedEmailTag,
      statData,
    } = this.state;

    removeStorageItem("businessID");

    return (
      <Aux>
        <div>
          <h4> Insurance Statistics</h4>
          <div className="row">
            <div
              style={{ display: "block" }}
              className="col-lg-4 col-md-3 col-sm-12"
            >
              <label style={{ display: "block" }}>From - To:</label>
              <div className="input-group">
                <DatePicker
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={this.handleChangeStart}
                  className="form-control"
                />
                &nbsp;&nbsp;&nbsp;
                <DatePicker
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  onChange={this.handleChangeEnd}
                  className="form-control"
                />
              </div>
            </div>
            <div
              style={{ display: "block", marginTop: "25px" }}
              className="col-lg-4 col-md-3 col-sm-12"
            >
              <button
                className="col-lg-2 col-md-2 col-sm-12 btn-140-width btn-success btn-md"
                onClick={() => {
                  this.getEmailStatistics();
                }}
              >
                Go
              </button>
            </div>
          </div>
          <Table
            headers={[
              { name: "Business Id" },
              { name: "Business Name" },
              { name: "Status" },
              { name: "Created AT" },
            ]}
            emptyHeaderColumnsCount="5"
            trclassName="bg-torq-light"
          >
            <tbody className="scan-status-list">
              {insuranceHistories.length > 0 ? (
                insuranceHistories.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {value.businessInfo && value.businessInfo.length > 0
                          ? value.businessInfo[0]._id
                          : ""}
                      </td>
                      <td>
                        {value.businessInfo && value.businessInfo.length > 0
                          ? value.businessInfo[0].businessName
                          : ""}
                      </td>
                      <td>
                        {value.insuranceStatus.charAt(0).toUpperCase() +
                          value.insuranceStatus.slice(1)}
                      </td>
                      <td>{moment(value.createdAt).format("lll")}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">
                    <div className="alert alert-info">No results</div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="modal fade" id="emailStatisticsModal" role="dialog">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" style={{ paddingLeft: "20px" }}>
                    Email Statistics Details for {selectedEmailTag}
                  </h4>
                </div>

                <div
                  className="modal-body"
                  style={{ maxHeight: "450px", overflowY: "scroll" }}
                >
                  <Table
                    headers={[
                      { name: "Name" },
                      { name: "Email" },
                      { name: "Count" },
                    ]}
                    emptyHeaderColumnsCount="5"
                    trclassName="bg-torq-light"
                  >
                    <tbody className="scan-status-list">
                      {statData ? (
                        Object.values(statData).map((v, i) => {
                          return (
                            <tr key={i}>
                              <td>{v[0].name}</td>
                              <td>{v[0].email}</td>
                              <td>{v.length}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6">
                            <div className="alert alert-info">No results</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insuranceHistories: state.accountStore.insuranceHistories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getInsuranceHistories,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceStatistics);
