import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";

import { callApiGetCheckScanReportsPendingDomainData } from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class SealsGeneratedReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      display: "none",
    };
  }

  async componentDidMount() {}

  render() {
    const { display } = this.state;

    return (
      <>
        <div className="reportButton">
          <button
            className="btn-success btn-sm"
            onClick={() => {
              this.setState({
                display: display === "block" ? "none" : "block",
              });
            }}
          >
            Seals Generated
          </button>
        </div>
        <p>Sites that have seals generated.</p>

        <hr />

        <div className="table-responsive" style={{ display: display }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Device (ip/url)</th>
                <th>Frequency</th>
                <th>Seal Generated</th>
                <th>Last PCI Scan Date</th>
                <th>Last Malware Scan Date</th>
              </tr>
            </thead>
            <tbody className="scan-status-list">
              <tr>
                <td colSpan="6">No records.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

SealsGeneratedReport.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealsGeneratedReport);
