import { api } from "./api";
import { callApi } from "../helpers/index";

const callOnGetUserSessionKey = (id) => {
  return callApi().then((token) => {
    return api.service("clonesecurity").get(id);
  });
};

export { callOnGetUserSessionKey };
