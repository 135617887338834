import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PostLogin from "./views/layouts/postLogin";
import ManageYourScans from "./views/home/manageYourScans";
import GetAutoRenewingSsl from "./views/home/getAutoRenewingSsl";
import PCIScanning from "./views/scanning/pciScanning";
import MalwareScanning from "./views/malware/malwareScanning";
import MalwareScannedFiles from "./views/malware/malwareScannedFiles";
import Notifications from "./views/notifications/notifications";
import Insurance from "./views/insurance/insurance";
import InsuranceApplication from "./views/insurance/insuranceApplication";
import TrustSeals from "./views/trust-seals/trustSeals";
import TrustSealsOld from "./views/trust-seals/trustSealsOld";
import AccountSettings from "./views/account/accountSettings";
import ControlPanel from "./views/control-panel/controlPanel";
import ControlPanelOld from "./views/control-panel/controlPanelOld";
import Login from "./views/login/login";
import ResetPassword from "./components/forgot-password/resetPassword";
import UpdatePassword from "./components/forgot-password/updatePassword";
import PageNotFound from "./components/pageNotFound/pageNotFound";
import UserProfile from "./views/user-profile/userProfile";
import Certificate from "./views/certificate/certificate";
import Reports from "./views/reports/reports";
import CreateSAQ from "./components/create-saq/createSAQ";
import Vulnerabilities from "./components/vulnerability/vulnerabilities";
import VulnerabilityDetailed from "./components/vulnerability/vulnerabilityDetailed";
import Account4X from "./views/4x-account/account4X";
import BuyerProtection from "./views/buyer-protection/buyerProtection";
import Charts from "./views/charts/charts";
import { checkAuth } from "./helpers";
import RouteContext from "./components/context/routeContext";
import { getStorageItem } from "./helpers/storage";
import { callOnFindVulnerabilitiesByScanId } from "./services/index";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");
class App extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      goToPageVulnerabilities: this.goToPageVulnerabilities,
      showBackDrop: false,
    };
  }
  goToPageVulnerabilities = async (id, owner, severity, scandate, business) => {
    const index = this.props.index;
    this.setState({ showBackDrop: true });

    const vulnerabilities = await callOnFindVulnerabilitiesByScanId(
      id,
      owner,
      severity,
      scandate
    );
    this.props.history.push({
      pathname: "/vulnerabilities/" + owner,
      state: {
        owner: owner,
        id,
        data: vulnerabilities,
        scanDate: scandate,
        domain: business.primaryDomain,
        businessDetails: business,
        severities: severity,
      },
    });
    this.setState({ showBackDrop: false });
  };

  render() {
    const { businesses } = this.state;
    return (
      <div>
        <main>
          <Switch>
            <LoginRoute exact path="/login" component={Login} />
            <LoginRoute
              exact
              path="/reset-password"
              component={ResetPassword}
            />
            <LoginRoute
              exact
              path="/update-password/:code"
              component={UpdatePassword}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/"
              component={ManageYourScans}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/get-auto-renewing-ssl"
              component={GetAutoRenewingSsl}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/pci-scanning/:id"
              component={PCIScanning}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/vulnerabilities/:id"
              component={Vulnerabilities}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/vulnerabilities/:id/detailed"
              component={VulnerabilityDetailed}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/malware-scanning"
              component={MalwareScanning}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/malware-scanned-files"
              component={MalwareScannedFiles}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/insurance"
              component={Insurance}
            />
            <AuthRoute
              state={this.state}
              path="/insurance-application"
              component={InsuranceApplication}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/trust-seals"
              component={TrustSeals}
            />

            <AuthRoute
              state={this.state}
              exact
              path="/trust-seals-old"
              component={TrustSealsOld}
            />

            <Route
              exact
              path="/certificate/:businessID"
              component={Certificate}
            />
            <AuthRoute
              state={this.state}
              path="/user-profile"
              component={UserProfile}
            />
            <AuthRoute
              state={this.state}
              path="/notifications"
              component={Notifications}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/account-settings"
              component={AccountSettings}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/control-panel"
              component={ControlPanel}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/control-panel-old"
              component={ControlPanelOld}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/charts"
              component={Charts}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/reports"
              component={Reports}
            />

            <AuthRoute
              state={this.state}
              exact
              path="/create-saq"
              component={CreateSAQ}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/buyer-protection"
              component={Account4X}
            />
            <AuthRoute
              state={this.state}
              exact
              path="/buyer-protection-2"
              component={BuyerProtection}
            />
            <Route component={PageNotFound} />
          </Switch>
        </main>
      </div>
    );
  }
}

const AuthRoute = ({ state, component: Component, ...rest }) => {
  const { isAdmin, groupType, roles, isDev } = checkAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        checkAuth() ? (
          groupType === "staff" ||
          groupType === "Scan Report Access" ||
          "admin" ||
          groupType === "Account Admin" ||
          groupType === "Account Manager" ||
          roles === "Account Admin" ||
          roles === "Account Manager" ||
          roles === "Scan Report Access" ||
          (isAdmin === true || isDev === "true") ? (
            <RouteContext.Provider value={state}>
              <PostLogin pageContent={<Component {...props} />} />
            </RouteContext.Provider>
          ) : (
            <PageNotFound />
          )
        ) : (
          <div>
            <Redirect to={{ pathname: "/login" }} />
          </div>
        )
      }
    />
  );
};

const LoginRoute = ({ component: Component, params, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !checkAuth() ? (
        <div>
          <Component {...props} />
        </div>
      ) : (
        <div>
          <Redirect to={{ pathname: "/" }} />
        </div>
      )
    }
  />
);
export default App;
