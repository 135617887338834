import React from "react";
import Slide from "../../../../components/animation/slide";
import SlideContext from "../../../../components/context/slidePanelContexts";
import AppContext from "../../../../components/context/appContext";
import TableHeader from "../../../../components/dashboard/partials/tableContent";
import Spinner from "../../../../components/ui/spinner/spinnerRect";
import PciSlidePanelInvalidDomain from "./pciSlidePanelInvalidDomain";
import StartScanButton from "../startScanButton";
function PciSlidePanel({ index, businessId, children, isLoading }) {
  return (
    <AppContext.Consumer>
      {(appValue) => {
        return (
          <>
            <SlideContext.drawerContext.Consumer>
              {(value) => {
                let willOpen = false;
                if (value.pci.length > 1) {
                  if (value.pci[index] !== undefined) {
                    if (value.pci.length === 1) {
                      willOpen = true;
                    } else {
                      willOpen = value.pci[index].isPciActive
                        ? businessId === value.pci[index].businessId
                          ? true
                          : false
                        : false;
                    }
                  }
                }

                if (value.pci.length === 1) {
                  willOpen = value.pci[index].isPciActive;
                }

                return (
                  <Slide open={willOpen}>
                    <div
                      id="pciscan-details"
                      style={{
                        display: "block",
                        position: "relative",
                        background: "#ffffff",
                      }}>
                      <>
                        {appValue.userBusinessData.length === 0 ? (
                          ""
                        ) : value.pci.length >= 1 &&
                        appValue.userBusinessData[
                          index
                        ].scanVendor.hasOwnProperty("owner") ? (
                          value.pci[index].scanCount > 0 ? (
                            <TableHeader>{children}</TableHeader>
                          ) : isLoading ? (
                            <div id="start-scan-button">
                              <Spinner />
                            </div>
                          ) : (
                            <StartScanButton
                              businessId={businessId}
                              toggledIndex={index}
                              startScanDomainHandler={() =>
                                value.pci[index].startPCIscan(index, "first")
                              }
                              isScanning={value.pci[index].isPciScanning}
                            />
                          )
                        ) : value.pci[index] !== undefined ? (
                          value.pci[index].isPciScanning ? (
                            <div className="row" id="start-scan-button">
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.5em",
                                }}>
                                Please wait while we create a new scan for this
                                site.
                              </p>
                            </div>
                          ) : appValue.userBusinessData[index]
                            .pciProgressStatus === "invalid" ? (
                            <PciSlidePanelInvalidDomain
                              primaryDomain={
                                appValue.userBusinessData[index].primaryDomain
                              }
                              businessId={appValue.userBusinessData[index]._id}
                            />
                          ) : (
                            <StartScanButton
                              businessId={businessId}
                              toggledIndex={index}
                              startScanDomainHandler={() =>
                                value.pci[index].startPCIscan(index, "first")
                              }
                              isScanning={value.pci[index].isPciScanning}
                            />
                          )
                        ) : null}
                      </>
                    </div>
                  </Slide>
                );
              }}
            </SlideContext.drawerContext.Consumer>
          </>
        );
      }}
    </AppContext.Consumer>
  );
}

export default PciSlidePanel;
