import React from "react";
import Modal from "../../../ui/modal/modal";
const modalDomainDeleteScanOpt = ({ onDeleteDomain }) => {
  return (
    <Modal targetID="dash-delete-modal" title="Delete Sub-domain">
      <p className="pt-3 pb-5 text-center" style={{ fontSize: "1.2em" }}>
        <strong>Are you sure you want to delete this subdomain?</strong>
      </p>
      <div className="text-right modal-footer pt-4 px-0" set="ModalFooter">
        <button
          type="button"
          className="btn-tglight2 btn-md btn-140-width mr-2"
          data-dismiss="modal"
          style={{ marginRight: "7px" }}>
          Cancel
        </button>
        <button
          type="button"
          className="btn-success  btn-md btn-140-width"
          onClick={() => onDeleteDomain()}
          data-dismiss="modal">
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default modalDomainDeleteScanOpt;
