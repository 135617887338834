import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import Table from "../../components/ui/table/table";
import ControlButtons from "./controlButtons";
import EmailTemplateReport from "../control-panel/emailTemplateReport";
import { getStorageItem, removeStorageItem } from "../../helpers/storage";
import { viewUserBusinessAccountDashboard } from "../../helpers/general";
import {
  getUserById,
  getDomains,
  cancelOrActiveDomain,
  getBusiness,
  findUsersInBusiness,
  unlockUserAccount,
  createNote,
  resetStatus,
  getFailedScanDomains,
  onClickRemoveTrustSealsSettingsDate,
  onClickSaveResolvedNote,
  sendEmailForReport,
  getSslRenewalDomains,
  updateSslRenewalDomain,
} from "../../modules/actions/index";
import config from "../../helpers/config";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class FailedScans extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      domains: [],
      userId: "",
      firstName: "",
      businessAccount: [],
      taskOnDomain: "",
      usersInBusiness: [],
      selectedBusinessId: "",
      notifyType: "",
      notifyMessage: "",
      note: "",
      notesInBusiness: [],
      customerNotification: false,
      noteType: "",
      reReview: "",
      resolvedNote: "",
      businessId: "",
      reason: "",
      reportId: "",
      doCall: false,
      checkScanReportId: null,
      renewSslDomains: [],
    };
  }

  componentDidMount() {
    removeStorageItem("businessID");
    let user = {};
    let userData = getStorageItem("userData");
    if (userData) {
      let userString = cryptr.decrypt(userData);
      user = JSON.parse(userString);
      this.props.getUserById(user._id);
      this.setState({
        userId: user._id,
      });

      const query = {
        query: {
          status: "pending",
          checkDate: { $lt: new Date() },
          details: { $elemMatch: { resolvedBy: null } },
          $sort: { createdAt: -1 },
        },
      };
      this.props.getFailedScanDomains(query);
      this.props.getSslRenewalDomains();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.scanPendingBusinesses) {
      this.setState({
        domains: newProps.scanPendingBusinesses,
      });
    }

    if (newProps.usersInBusiness) {
      this.setState({
        usersInBusiness: newProps.usersInBusiness,
      });
    }

    if (newProps.renewSslDomains) {
      this.setState({
        renewSslDomains: newProps.renewSslDomains,
      });
    }
  }

  updateSslRenewalDomain = (id, obj) => {
    this.props.updateSslRenewalDomain(id, obj);
    this.props.getSslRenewalDomains();
  };

  onClickSetBusinessId = (businessId, taskOnDomain) => {
    this.setState({
      selectedBusinessId: businessId,
      taskOnDomain: taskOnDomain,
    });
  };

  onClickCancelOrActiveDomain = () => {
    if (this.state.selectedBusinessId) {
      this.props.cancelOrActiveDomain(
        this.state.selectedBusinessId,
        this.state.userId,
        this.state.taskOnDomain
      );
    }
  };

  onClickSetBusinessInfo = (businessId, type) => {
    this.setState({
      noteType: type,
      selectedBusinessId: businessId,
    });
  };

  onClickClearTrustSealsSettingsDate = (businessId) => {
    if (businessId) {
      let query = {
        action: "refresh-seals",
        businessId: businessId,
      };
      this.props.onClickRemoveTrustSealsSettingsDate(query);
    }
  };

  saveResolvedNote = async () => {
    let notes = {
      roles: {
        admin: false,
        manager: false,
        staff: false,
      },
      userId: this.state.userId,
      note: this.state.resolvedNote,
      noteType: "resolved",
    };
    await this.props.onClickSaveResolvedNote(
      this.state.businessId,
      notes,
      this.state.reason,
      this.state.reportId,
      this.state.checkScanReportId
    );

    const query = {
      query: {
        status: "pending",
        checkDate: { $lt: new Date() },
        details: { $elemMatch: { resolvedBy: null } },
      },
    };
    await this.props.getFailedScanDomains(query);

    document.getElementById("resolvedIssueModalCloseButton").click();
  };

  viewUserDashboard = (e, businessId, accountType) => {
    viewUserBusinessAccountDashboard(businessId, accountType);
    window.open(config.urls.dashboardUrl, "_blank");
  };

  sendSslReport = (data) => {
    data.businessId = this.state.selectedBusinessId;
    this.props.sendEmailForReport(data);

    document.getElementById("btnCancelModal").click();
  };

  render() {
    const {
      taskOnDomain,
      renewSslDomains,
      domains,
      userId,
      resolvedNote,
    } = this.state;

    const { populateDelayModalData, populateBusinessUsersData } = this.props;

    removeStorageItem("businessID");
    const FailedScansHeaderColumns = [
      { name: "Business" },
      { name: "Reason" },
      { name: "PCI" },
      { name: "Malware" },
      { name: "SSL" },
      { name: "Insurance" },
      { name: "Email Client" },
      { name: "Actions" },
    ];

    return (
      <Aux>
        <div className="modal fade" id="cancelDomain" role="dialog">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {taskOnDomain === "active"
                    ? "Active Domain"
                    : "Cancel Domain"}
                </h4>
              </div>
              <div className="modal-body">
                {taskOnDomain === "active" ? (
                  <p>Are you sure want to active this domain.</p>
                ) : (
                  <p>Are you sure want to cancel this domain.</p>
                )}
              </div>
              <div className="modal-footer text-right">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-tglight2 mr-2"
                    data-dismiss="modal"
                    style={{ marginRight: "7px" }}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn  btn-tglight2"
                    data-dismiss="modal"
                    onClick={() => {
                      this.onClickCancelOrActiveDomain();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {renewSslDomains && renewSslDomains.total > 0 ? (
          <div className="alert alert-danger">
            <h4>Submitted Applications for SSL Renewals</h4>
            <hr />
            <div className="row">
              {renewSslDomains.data.map((v, i) => {
                return (
                  <div class="col-md-3">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">{v.businessId}</h5>
                        <p className="card-text">Name: {v.name}</p>
                        <p className="card-text">Email: {v.email}</p>
                        <p className="card-text">
                          Hosting Provider: {v.hostingProvider}
                        </p>
                        <p className="card-text">
                          Hosting Package: {v.hostingPackage}
                        </p>
                        <p className="card-text">
                          What type of server do you have?: {v.typeOfServer}
                        </p>
                        <p className="card-text">
                          How should Secure My Website (SMW) convert your site?:{" "}
                          {v.smw}
                        </p>
                        <p className="card-text">
                          Would you like to discuss anything else before we
                          convert your site?:{""}
                          {v.discussBefore}
                        </p>
                        <p className="card-text">
                          Does your hosting plan come with SSH access?:{" "}
                          {v.hostingPlanWithSSH}
                        </p>
                        <p className="card-text">
                          Do you use LastPass Password Manager?:{" "}
                          {v.lastPassPasswordManager}
                        </p>
                        <p>
                          <a
                            href="https://www.securemywebsite.net/trust-guard-start/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007bff", fontWeight: "bold" }}
                          >
                            Go to SMW
                          </a>
                        </p>

                        <p>
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() =>
                              this.updateSslRenewalDomain(v._id, v)
                            }
                          >
                            Mark as Completed
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        <div id="dash-overview-table" className="table-responsive">
          <Table
            headers={FailedScansHeaderColumns}
            emptyHeaderColumnsCount="11"
            trclassName="bg-torq-light"
          >
            <tbody className="scan-status-list">
              {domains &&
              domains.length > 0 &&
              domains.filter((v, i) => {
                // if (v.businessAccount !== undefined) {
                return v.businessData && v.businessData.accountType !== "free";
                // }
              }).length > 0 ? (
                domains.map((domain, index) => {
                  if (
                    domain &&
                    domain.businessData &&
                    domain.businessData.status !== "canceled" &&
                    domain.businessData.accountType !== "free"
                  ) {
                    return (
                      <tr key={index}>
                        <td>
                          {domain.businessData ? (
                            <button
                              className="tg-link"
                              onClick={(e) => {
                                this.viewUserDashboard(
                                  e,
                                  domain.businessData._id,
                                  domain.businessData.accountType
                                );
                              }}
                            >
                              {domain.businessData.primaryDomain}
                            </button>
                          ) : (
                            "N/A"
                          )}
                        </td>

                        <td>
                          {domain.details && domain.details.length > 0
                            ? domain.details && domain.details.length > 0
                              ? domain.details.map((v, i) => {
                                  return (
                                    <p key={i}>
                                      <button
                                        className="tg-link"
                                        data-toggle="modal"
                                        data-target={"#resolvedIssueModal"}
                                        style={{
                                          boxShadow: "none",
                                          color: "#268226",
                                          whiteSpace: "pre-wrap",
                                          textAlign: "left",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            businessId: domain.businessData._id,
                                            reason: v.reason,
                                            reportId: v._id,
                                            checkScanReportId: domain._id,
                                          });
                                        }}
                                      >
                                        {v.reason}
                                      </button>
                                    </p>
                                  );
                                })
                              : ""
                            : ""}
                        </td>

                        <td>
                          <button
                            className={
                              domain.businessData &&
                              domain.businessData.hasOwnProperty(
                                "lastPciStatus"
                              )
                                ? domain.businessData.lastPciStatus ===
                                    "passed" ||
                                  domain.businessData.lastPciStatus === "pass"
                                  ? "btn status-box btn-success"
                                  : domain.businessData.lastPciStatus === "fail"
                                    ? "btn status-box btn-success status-box-red"
                                    : "btn status-box btn-success status-box-grey"
                                : "btn status-box btn-success status-box-grey"
                            }
                          >
                            <i
                              className={
                                domain.businessData &&
                                domain.businessData.hasOwnProperty(
                                  "lastPciStatus"
                                )
                                  ? domain.businessData.lastPciStatus ===
                                      "passed" ||
                                    domain.businessData.lastPciStatus === "pass"
                                    ? "fa fa-check"
                                    : domain.businessData.lastPciStatus ===
                                      "fail"
                                      ? "fa fa-times"
                                      : "fa fa-question"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={
                              domain.businessData &&
                              domain.businessData.hasOwnProperty(
                                "lastMalwareScanResult"
                              )
                                ? domain.businessData.lastMalwareScanResult ===
                                  "clean"
                                  ? "btn status-box btn-success"
                                  : domain.businessData
                                      .lastMalwareScanResult === "malicious" ||
                                    domain.businessData
                                      .lastMalwareScanResult === "suspicious"
                                    ? "btn status-box btn-success status-box-red"
                                    : "btn status-box btn-success status-box-grey"
                                : "btn status-box btn-success status-box-grey"
                            }
                          >
                            <i
                              className={
                                domain.businessData &&
                                domain.businessData.hasOwnProperty(
                                  "lastMalwareScanResult"
                                )
                                  ? domain.businessData
                                      .lastMalwareScanResult === "clean"
                                    ? "fa fa-check"
                                    : domain.businessData
                                        .lastMalwareScanResult ===
                                        "malicious" ||
                                      domain.businessData
                                        .lastMalwareScanResult === "suspicious"
                                      ? "fa fa-times"
                                      : "fa fa-question"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={
                              domain.businessData &&
                              domain.businessData.hasOwnProperty("domains") &&
                              domain.businessData.domains.hasOwnProperty(
                                "sslInfo"
                              )
                                ? domain.businessData.domains.sslInfo.status ===
                                  "active"
                                  ? "btn status-box btn-success"
                                  : domain.businessData.domains.sslInfo
                                      .status === "failed"
                                    ? "btn status-box btn-success status-box-red"
                                    : "btn status-box btn-success status-box-grey"
                                : "btn status-box btn-success status-box-grey"
                            }
                          >
                            <i
                              className={
                                domain.businessData &&
                                domain.businessData.hasOwnProperty("domains") &&
                                domain.businessData.domains.hasOwnProperty(
                                  "sslInfo"
                                )
                                  ? domain.businessData.domains.sslInfo
                                      .status === "active"
                                    ? "fa fa-check"
                                    : domain.businessData.domains.sslInfo
                                        .status === "failed"
                                      ? "fa fa-times"
                                      : "fa fa-question"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={
                              domain.businessData &&
                              domain.businessData.hasOwnProperty(
                                "insuranceData"
                              ) &&
                              domain.businessData.insuranceData
                                .insuranceSatus === "approved"
                                ? "btn status-box btn-success"
                                : "btn status-box status-box-grey btn-success"
                            }
                          >
                            <i
                              className={
                                domain.businessData &&
                                domain.businessData.hasOwnProperty(
                                  "insuranceData"
                                ) &&
                                domain.businessData.insuranceData
                                  .insuranceSatus === "approved"
                                  ? "fa fa-check"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        {/* <td>
                            <button className="btn status-box status-box-grey btn-success">
                              <i className="fa fa-times" />
                            </button>
                          </td>
                          <td>
                            <button className="btn status-box status-box-grey btn-success">
                              <i className="fa fa-times" />
                            </button>
                          </td>
                          <td>
                            <button className="btn status-box status-box-grey btn-success">
                              <i className="fa fa-times" />
                            </button>
                          </td> */}

                        <td>
                          <button
                            className="btn-success"
                            data-toggle="modal"
                            data-target="#failedScanEmailCLient"
                            onClick={() => {
                              this.setState({
                                selectedBusinessId: domain.businessData._id,
                              });
                            }}
                          >
                            <i className="fa fa-envelope" />
                          </button>
                        </td>
                        <td>
                          <ControlButtons
                            business={domain.businessData}
                            userId={userId}
                            populateDelayModalData={
                              this.props.populateDelayModalData
                            }
                            populateBusinessUsersData={
                              this.props.populateBusinessUsersData
                            }
                          />
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <tr>
                  <td colSpan="8">
                    <div className="alert alert-info">No records.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div
            id="resolvedIssueModal"
            className="modal fade"
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog " role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title f800">
                    Is this issue is resolved?
                  </h4>
                  <button
                    id="resolvedIssueModalCloseButton"
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-lg-12 text-left">
                    <label>Notes :</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Add Note"
                      value={resolvedNote}
                      cols={40}
                      rows={10}
                      onChange={(evt) => {
                        this.setState({
                          resolvedNote: evt.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer text-right">
                  <button
                    id="testhbhasdfas"
                    type="button"
                    className="btn-tglight2 btn-140-width btn-md"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn-tgdark2 btn-140-width btn-md"
                    style={{ marginRight: "15px" }}
                    onClick={() => {
                      this.saveResolvedNote();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            id="failedScanEmailCLient"
            className="modal"
            tabIndex="-1"
            role="dialog"
          >
            <EmailTemplateReport sendSslReport={this.sendSslReport} />
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.accountStore.domains,
    loggedInUser: state.accountStore.loggedInUser,
    userAccount: state.accountStore.userAccount,
    usersInBusiness: state.loginStore.usersInBusiness,
    messageType: state.accountStore.messageType,
    message: state.accountStore.message,
    code: state.accountStore.code,
    scanPendingBusinesses: state.accountStore.scanPendingBusinesses,
    renewSslDomains: state.accountStore.renewSslDomains,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDomains,
      getUserById,
      cancelOrActiveDomain,
      getBusiness,
      findUsersInBusiness,
      unlockUserAccount,
      createNote,
      getFailedScanDomains,
      resetStatus,
      onClickRemoveTrustSealsSettingsDate,
      onClickSaveResolvedNote,
      goToDashboard: () =>
        push("/", {
          accountAdminView: true,
        }),
      sendEmailForReport,
      getSslRenewalDomains,
      updateSslRenewalDomain,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FailedScans);
