import React from "react";
import Modal from "../../../ui/modal/modal";
import SwitchButton from "../../../../components/ui/switch/SwitchButton";
import { Table } from "react-bootstrap";
const modalEditDomainScanOpt = ({
  subdomains,
  scanOptions,
  submitted,
  validate,
  isHostReachable,
  onSetUpdateDomain,
  info,
  onChangeInput,
  onLostFocus,
  textInput,
  switchToggleHandler,
}) => {
  return (
    <Modal targetID="dash-edit-modal" title="Edit SubDomain or IP Address">
      <div className="scrollbar" id="style-1">
        <div className="force-overflow">
          <label className="text-left">Subdomain/IP Address</label>
          {validate.error ? (
            <p style={{ color: "red", fontWeight: "bold" }}>{validate.error}</p>
          ) : null}

          <input
            type="text"
            className="form-control"
            placeholder={
              subdomains.length > 0
                ? "." + subdomains[0].host
                : ".yourdomain.com"
            }
            name="host"
            ref={textInput}
            onBlur={(event) => {
              onLostFocus(event);
            }}
            onChange={(event) => {
              onChangeInput(event);
            }}
            value={info.host}
          />
          <small style={{ fontSize: "10px" }}>
            You can add unlilmited subdomains and IPs related to your main
            website (<b>{subdomains.length > 0 ? subdomains[0].host : null}</b>).
            Enter your subdomain / IP Address exactly as you want it to show up
            on your scan.
          </small>
          <br />
          <label>Alias</label>
          <input
            type="text"
            className="form-control"
            placeholder="example"
            name="alias"
            onBlur={(event) => {}}
            onChange={(event) => {}}
            value={info.alias}
            data-testid="inpBusinessSubdomainAlias"
          />
          <br />
          <label>Subdomain/IP Options</label>

          <Table>
            <thead>
              <tr>
                <th style={{ width: "10px" }}>Status</th>
                <th style={{ width: "90%" }}>Services</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <i
                    className={
                      scanOptions.scanActive.pci
                        ? "text-success fas fa-circle mr-2"
                        : "text-muted fas fa-circle mr-2"
                    }
                    style={{
                      textAlign: "center",
                      display: "block",
                    }}
                  />
                </td>
                <td>PCI Scan</td>
                <td>
                  <SwitchButton
                    changeStatus={() => switchToggleHandler("pci")}
                    status={scanOptions.scanActive.pci}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <i
                    className={
                      scanOptions.scanActive.malware
                        ? "text-success fas fa-circle mr-2"
                        : "text-muted fas fa-circle mr-2"
                    }
                    style={{
                      textAlign: "center",
                      display: "block",
                    }}
                  />
                </td>
                <td>Malware Scan</td>
                <td>
                  <SwitchButton
                    changeStatus={() => switchToggleHandler("malware")}
                    status={scanOptions.scanActive.malware}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <i
                    className={
                      scanOptions.scanActive.ssl
                        ? "text-success fas fa-circle mr-2"
                        : "text-muted fas fa-circle mr-2"
                    }
                    style={{
                      textAlign: "center",
                      display: "block",
                    }}
                  />
                </td>
                <td>SSL Scan</td>
                <td>
                  <SwitchButton
                    changeStatus={() => {}}
                    status={scanOptions.scanActive.ssl}
                  />
                </td>
              </tr>
              {/* <tr>
                <td>
                  <i
                    className={
                      scanOptions.scanReport.ping
                        ? "text-success fas fa-circle mr-2"
                        : "text-muted fas fa-circle mr-2"
                    }
                  />
                </td>
                <td className="text-center">
                  <table className="table" style={{ marginBottom: "0" }}>
                    <tbody>
                      <tr>
                        <td style={{ borderRight: "0px" }}>
                          Check if Reachable
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderRight: "0px" }}>
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => {}}>
                            Verify Now
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <SwitchButton
                    changeStatus={() => {}}
                    status={scanOptions.scanReport.ping}
                  />
                </td>
              </tr>
              {isHostReachable !== "" ? (
                <tr>
                  <td colSpan="3" className="text-center">
                    <span
                      className="alert alert-info"
                      style={{ padding: "7px" }}>
                      {isHostReachable}
                    </span>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="3" className="text-center" />
                </tr>
              )} */}
            </tbody>
          </Table>

          <br />
          <div set="modal-footer" className="text-right modal-footer px-0">
            <button
              type="button"
              className="btn-tglight2 h5 btn-md btn-140-width mr-2"
              data-dismiss="modal"
              style={{ marginRight: "7px" }}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success btn-md btn-140-width"
              onClick={() => onSetUpdateDomain()}
              data-dismiss="modal"
              disabled={submitted || validate.valid === false}>
              Update
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default modalEditDomainScanOpt;
