import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { removeStorageItem } from "../../helpers/storage";

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  onClickLogOut = () => {
    let accessToken = removeStorageItem("accessToken");
    let feathersJwt = removeStorageItem("feathers-jwt");
    removeStorageItem("userData");
    removeStorageItem("businessName");
    removeStorageItem("businessID");
    if (!accessToken && !feathersJwt) {
      window.location.reload();
      this.props.goToPageLogin();
    }
  };

  render() {
    return (
      <div className="modal-dialog " role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title f800">Log Out Confirmation</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-lg-12 col-md-12 text-left">
              <h5>Are You Sure Want To Log Out?</h5>
            </div>
          </div>
          <div className="modal-footer text-right">
            <div className="col-md-12">
              <button
                type="button"
                className="btn-tglight2"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn-success"
                onClick={() => this.onClickLogOut()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
