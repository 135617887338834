import {
  UPDATE_USER_ACCOUNT_SUCCESS,
  CREATE_USER_ACCOUNT_SUCCESS,
  CREATE_USER_ACCOUNT_FAILED,
  CREATE_ACCOUNT_FAILED,
  GET_ALL_BUSINESS_SUCCESS,
  GET_ALL_BUSINESS_FAILED,
  RESET_MESSAGES,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILED,
  USER_CREATE_FAILED,
  GET_ONE_BUSINESS_SUCCESS,
  GET_ONE_BUSINESS_FAILED,
  GET_ONE_USER_SUCCESS,
  CREATE_BUSINESS_ACCOUNT_SUCCESS,
  USER_ROLES_UPDATE_SUCCESS,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PASSWORD_SUCCESS,
  SEARCH_BY_ITEM_SUCCESS,
  SEARCH_BY_ITEM_FAILED,
  CREATE_DOMAIN_SUCCESS,
  GET_DOMAINS_SUCCESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_DOMAIN_SUCCESS,
  DELETE_SUBDOMAIN_SUCCESS,
  UPDATE_UNSEEN_NOTIFICATION_TO_SEEN,
  UPDATE_UNSEEN_NOTIFICATION_TO_SEEN_FAILED,
  GET_BUSINESSES_SUCCESS,
  GET_BUSINESSES_FAILED,
  NOTE_CREATED_SUCCESS,
  NOTE_CREATED_FAILED,
  USER_NOTIFICATION_CREATE_SUCCESS,
  GET_PENDING_SCAN_BUSINESS_SUCCESS,
  EMAIL_CREATED_SUCCESS,
  EMAIL_CREATED_FAILED,
  GET_ALL_EMAILS_SUCCESS,
  GET_ALL_EMAILS_FAILED,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILED,
  INACTIVE_EMAIL_SUCCESS,
  GET_DOMAINS_USING_STATUS_SUCCESS,
  GET_DOMAINS_USING_STATUS_FALIURE,
  GET_CHECK_SCAN_REPORTS_SUCCESS,
  GET_CHECK_SCAN_REPORTS_FALIURE,
  GET_USER_LIST_USING_BUSINESS_ID_SUCCESS,
  GET_USER_LIST_USING_BUSINESS_ID_FALIURE,
  UPDATE_BUSINESS_CERTIFICATE_SUCCESS,
  GET_TRUSTSEAL_SUCCESS,
  GET_TRUSTSEAL_FALIURE,
  CREATE_TRUSTSEAL_SUCCESS,
  CREATE_TRUSTSEAL_FALIURE,
  UPDATE_TRUSTSEAL_SUCCESS,
  UPDATE_TRUSTSEAL_FALIURE,
  SEALS_DATA_UPDATED_SUCCESS,
  SEALS_DATA_UPDATED_FAILURE,
  GET_BUSINESS_USING_PRIMARY_DOMAIN_SUCCESS,
  UPDATE_BUSINESS_CERTIFICATE_CONTACT_SUCCESS,
  GET_EMAIL_HISTORIES_SUCCESS,
  UPDATE_SKIPPED_MEMBER_SUCCESS,
  UPDATE_WELCOME_POPUP_SUCCESS,
  GET_EMAIL_HISTORY_DETAIL_SUCCESS,
  DELETE_USER_SUCCESS,
  RESOLVED_NOTE_SAVE_SUCCESS,
  RESOLVED_NOTE_SAVE_FAILURE,
  BUSINESS_USERS_SUCCESS,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_BUSINESS_DATA_SUCCESS,
  GET_INSURANCE_INDUSTRY_LIST_SUCCESS,
  POST_INSURANCE_DATA_SUCCESS,
  POST_INSURANCE_DATA_FAILURE,
  UPDATE_APP_SIDEBAR_MENUS,
  GET_INSURANCE_HISTORIES_SUCCESS,
  CREATE_CSS_HASH_SUCCESS,
  CREATE_CSS_HASH_FAILURE,
  CREATE_JS_HASH_SUCCESS,
  CREATE_JS_HASH_FAILURE,
  SEND_REPORT_EMAIL_SUCCESS,
  SEND_REPORT_EMAIL_FAILURE,
  GET_ALL_CSS_JS_HASH_SUCCESS,
  CREATE_ONE_TIME_EMAIL_SUCCESS,
  CREATE_ONE_TIME_EMAIL_FAILURE,
  GET_ONE_TIME_EMAIL_SUCCESS,
  GET_ONE_TIME_EMAIL_FAILURE,
  GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_SUCCESS,
  GET_OLD_FREE_BUSINESS_LIST_SUCCESS,
  GET_OLD_FREE_BUSINESS_LIST_FAILURE,
  REGENERATE_ALL_SEALS_SUCCESS,
  REGENERATE_ALL_SEALS_FAILURE,
  GET_SMS_HISTORIES_SUCCESS,
  GET_SMS_HISTORY_DETAIL_SUCCESS,
  GET_ALL_SSL_RENEWAL_DOMAINS_SUCCESS,
  UPDATE_SSL_RENEWAL_DOMAINS_SUCCESS,
  UPDATE_ACCOUNT_4X_DETAILS_SUCCESS,
  UPDATE_ACCOUNT_4X_DETAILS_FAILURE,
  UPDATE_ONBOARDING_POPUP_DATA_SUCCESS,
  SEND_TEST_EMAIL_SUCCESS,
  SEND_TEST_EMAIL_FAILURE,
  SAVE_SMS_TIMING_SUCCESS,
  DELETE_TRUSTSEALS_SUCCESS,
  DELETE_TRUSTSEALS_FAILURE,
} from "../types/index";

import _ from "lodash";

const defaultState = {
  businesses: [],
  businessAccount: [],
  userAccount: [],
  messageType: "",
  message: "",
  currentBusiness: "",
  loggedInUser: "",
  domains: [],
  notes: [],
  scanPendingBusinesses: [],
  emails: [],
  domainList: [],
  checkScanReports: [],
  businessUserList: [],
  trustSeals: [],
  businessDetails: [],
  emailHistories: [],
  emailHistoryDetails: [],
  businesData: null,
  checkScanData: [],
  industryData: "",
  insuranceData: "",
  doUpdateSidebarMenu: "No",
  insuranceHistories: [],
  sealScripts: [],
  oneTimeEmails: [],
  otherMessagesStats: {},
  freeBusinsses: [],
  allSealsRegenerated: false,
  renewSslDomains: [],
  details4X: false,
  onboardingPopupUpdateStatus: false,
  decision: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET_MESSAGES:
      return {
        ...state,
        messageType: "",
        message: "",
      };
    case CREATE_DOMAIN_SUCCESS:
      return {
        ...state,
        domains: [...state.domains, action.payload],
      };
    case GET_DOMAINS_SUCCESS:
      return {
        ...state,
        domains: action.payload,
      };
    case CREATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        userAccount: [...state.userAccount, action.payload],
        messageType: "create-success",
        message: action.message,
      };
    case UPDATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        userAccount: [...state.userAccount, action.payload],
        messageType: "create-success",
        message: action.message,
      };
    case CREATE_USER_ACCOUNT_FAILED:
      return {
        ...state,
        messageType: "create_failed",
        message: action.message,
      };
    case CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        messageType: "create_failed",
        message: action.message,
      };
    case GET_ALL_BUSINESS_SUCCESS:
      return {
        ...state,
        businessAccount: action.payload.data,
      };
    case CREATE_BUSINESS_ACCOUNT_SUCCESS:
      return {
        ...state,
        businessAccount: [...state.businessAccount, action.payload],
      };
    case GET_ALL_BUSINESS_FAILED:
      return {
        ...state,
      };
    case USER_CREATE_FAILED:
      return {
        ...state,
        userAccount: [...state.userAccount, action.payload],
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        userAccount: action.payload,
      };
    case GET_ALL_USERS_FAILED:
      return {
        ...state,
        loggedInUser: "",
      };
    case GET_ONE_BUSINESS_SUCCESS:
      return {
        ...state,
        currentBusiness: action.payload,
      };
    case GET_ONE_BUSINESS_FAILED:
      return {
        ...state,
        messageType: "create_failed",
        message: action.message,
        code: action.code,
      };
    case GET_ONE_USER_SUCCESS:
      return {
        ...state,
        loggedInUser: action.payload,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loggedInUser: action.payload,
        messageType: "create-success",
        message: action.message,
      };
    case UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        messageType: "create_failed",
        message: action.message,
      };
    case USER_ROLES_UPDATE_SUCCESS:
      return {
        ...state,
        userAccount: [...state.userAccount, action.payload],
        messageType: "create-success",
        message: action.message,
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loggedInUser: action.payload,
        messageType: "create-success",
        message: action.message,
      };
    case SEARCH_BY_ITEM_SUCCESS:
      return {
        ...state,
        domains: action.payload,
      };
    case SEARCH_BY_ITEM_FAILED:
      return {
        ...state,
        messageType: "create_failed",
        message: action.message,
      };
    case UPDATE_BUSINESS_SUCCESS:
      const updateBusiness = action.payload;
      return {
        ...state,
        businessAccount: state.businessAccount.map(
          (business) =>
            business._id === updateBusiness._id ? updateBusiness : business
        ),
        currentBusiness: updateBusiness,
        messageType: "update_success",
      };
    case UPDATE_DOMAIN_SUCCESS:
      const updateDomain = action.payload;
      return {
        ...state,
        domains: state.domains.map(
          (domain) => (domain._id === updateDomain._id ? updateDomain : domain)
        ),
        messageType: "update-success",
      };
    case DELETE_SUBDOMAIN_SUCCESS:
      const deleteSubDomain = action.payload;
      return {
        ...state,
        domains: state.domains.filter(
          (item) => item._id !== deleteSubDomain._id
        ),
        messageType: "subDomain-delete-success",
      };
    case UPDATE_UNSEEN_NOTIFICATION_TO_SEEN:
      return {
        ...state,
        loggedInUser: action.payload,
      };
    case UPDATE_UNSEEN_NOTIFICATION_TO_SEEN_FAILED:
      return {
        ...state,
      };
    case GET_BUSINESSES_SUCCESS:
      let businessCleanData = [];
      const businessUncleanData = [...action.payload];
      /*eslint-disable*/
      for (let k = 0; k < businessUncleanData.length > 0; k++) {
        /*eslint-enable*/
        businessCleanData.push(
          _.omit(businessUncleanData[k], [
            "scanVendor",
            "clone",
            "notes",
            "certificates",
            "supportEmail",
            "billingEmail",
            "address",
            "address2",
          ])
        );
      }
      return {
        ...state,
        businesses: businessCleanData,
      };
    case GET_BUSINESSES_FAILED:
      return {
        ...state,
      };
    case NOTE_CREATED_SUCCESS:
      const noteBusiness = action.payload;
      return {
        ...state,
        businessAccount: state.businessAccount.map(
          (business) =>
            business._id === noteBusiness._id ? noteBusiness : business
        ),
        messageType: "do-success",
        message: "Note created successfully",
      };
    case NOTE_CREATED_FAILED:
      return {
        ...state,
        messageType: "create-failed",
        message: action.message,
      };
    case USER_NOTIFICATION_CREATE_SUCCESS:
      return {
        ...state,
        loggedInUser: action.payload,
      };
    case GET_PENDING_SCAN_BUSINESS_SUCCESS:
      return {
        ...state,
        scanPendingBusinesses: action.payload.data,
      };
    case EMAIL_CREATED_SUCCESS:
      return {
        ...state,
        emails: [...state.emails, action.payload],
        messageType: "create-email-success",
      };
    case EMAIL_CREATED_FAILED:
      return {
        ...state,
      };
    case GET_ALL_EMAILS_SUCCESS:
      return {
        ...state,
        emails: action.payload.data,
      };
    case GET_ALL_EMAILS_FAILED:
      return {
        ...state,
      };
    case UPDATE_EMAIL_SUCCESS:
      const updateEmail = action.payload;
      return {
        ...state,
        emails: state.emails.map(
          (email) => (email._id === updateEmail._id ? updateEmail : email)
        ),
      };
    case UPDATE_EMAIL_FAILED:
      return {
        ...state,
      };
    case INACTIVE_EMAIL_SUCCESS:
      const inactiveEmail = action.payload;
      return {
        ...state,
        emails: state.emails.filter((email) => email._id !== inactiveEmail._id),
      };
    case GET_DOMAINS_USING_STATUS_SUCCESS:
      return {
        ...state,
        domainList: action.payload,
      };
    case GET_DOMAINS_USING_STATUS_FALIURE:
      return {
        ...state,
      };
    case GET_CHECK_SCAN_REPORTS_SUCCESS:
      return {
        ...state,
        checkScanReports: action.payload.data,
      };
    case GET_CHECK_SCAN_REPORTS_FALIURE:
      return {
        ...state,
      };
    case GET_USER_LIST_USING_BUSINESS_ID_SUCCESS:
      return {
        ...state,
        businessUserList: action.payload,
      };
    case GET_USER_LIST_USING_BUSINESS_ID_FALIURE:
      return {
        ...state,
      };
    case CREATE_TRUSTSEAL_SUCCESS:
      return {
        ...state,
        trustSeals: action.payload,
        messageType: "",
        message: "",
      };
    case CREATE_TRUSTSEAL_FALIURE:
      return {
        ...state,
      };

    case UPDATE_TRUSTSEAL_SUCCESS:
      return {
        ...state,
        trustSeals: action.payload,
        messageType: "success",
        message: action.message,
      };
    case UPDATE_TRUSTSEAL_FALIURE:
      return {
        ...state,
      };
    case GET_TRUSTSEAL_SUCCESS:
      return {
        ...state,
        trustSeals: action.payload,
      };
    case GET_TRUSTSEAL_FALIURE:
      return {
        ...state,
      };

    case UPDATE_BUSINESS_CERTIFICATE_SUCCESS:
      return {
        ...state,
        businesData: action.payload,
        messageType: "success",
        message: "Certificate has been updated.",
      };

    case UPDATE_BUSINESS_CERTIFICATE_CONTACT_SUCCESS:
      return {
        ...state,
        currentBusiness: action.payload,
        messageType: "success",
        message: "Certificate has been updated.",
      };
    case UPDATE_WELCOME_POPUP_SUCCESS:
      return {
        ...state,
        messageType: "success",
        message: "",
      };

    case SEALS_DATA_UPDATED_SUCCESS:
      return {
        ...state,
        messageType: "success",
        message: "Seals refreshed.",
      };
    case SEALS_DATA_UPDATED_FAILURE:
      return {
        ...state,
        messageType: "error",
        message: "Trust seal not found.",
      };
    case GET_BUSINESS_USING_PRIMARY_DOMAIN_SUCCESS:
      return {
        ...state,
        businessDetails: action.payload,
      };
    case GET_EMAIL_HISTORIES_SUCCESS:
      return {
        ...state,
        emailHistories: action.payload,
      };
    case GET_SMS_HISTORIES_SUCCESS:
      return {
        ...state,
        smsHistories: action.payload,
      };
    case GET_INSURANCE_HISTORIES_SUCCESS:
      return {
        ...state,
        insuranceHistories: action.payload,
      };

    case GET_EMAIL_HISTORY_DETAIL_SUCCESS:
      return {
        ...state,
        emailHistoryDetails: action.payload,
      };
    case GET_SMS_HISTORY_DETAIL_SUCCESS:
      return {
        ...state,
        smsHistoryDetails: action.payload,
      };

    case UPDATE_SKIPPED_MEMBER_SUCCESS:
      return {
        ...state,
        businesData: action.payload,
        messageType: "success",
        message: "Request has been canceled.",
      };
    case RESOLVED_NOTE_SAVE_SUCCESS:
      return {
        ...state,
        checkScanData: action.payload,
        messageType: "success",
        message: "This issue is been resolved succesfully.",
      };
    case RESOLVED_NOTE_SAVE_FAILURE:
      return {
        ...state,
        messageType: "error",
        message: "Resolved data has not been saved successfully.",
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        userAccount: action.payload,
        messageType: "userDeleteSuccess",
        message: "User deleted successfully.",
      };
    case BUSINESS_USERS_SUCCESS:
      return {
        ...state,
        userAccount: action.payload,
      };
    case UPDATE_BUSINESS_DATA_SUCCESS:
      return {
        ...state,
        messageType: "success",
        message: "Business account type changed successfully",
        businesses: action.payload,
      };
    case GET_INSURANCE_INDUSTRY_LIST_SUCCESS:
      return {
        ...state,
        industryData: action.payload,
      };
    case POST_INSURANCE_DATA_SUCCESS:
      return {
        ...state,
        insuranceData: action.payload,
        messageType: "create-success",
      };
    case POST_INSURANCE_DATA_FAILURE:
      return {
        ...state,
        insuranceData: action.payload,
        messageType: "create-failed",
      };
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_APP_SIDEBAR_MENUS:
      return {
        ...state,
        doUpdateSidebarMenu: "Yes",
      };
    case CREATE_CSS_HASH_SUCCESS:
      return {
        ...state,
        sealScripts: action.payload.data,
        messageType: "success",
        message: "Seal scripts CSS hash created successfully.",
      };
    case CREATE_CSS_HASH_FAILURE:
      return {
        ...state,
        sealScripts: action.payload.data,
        messageType: "error",
        message:
          action.purpose === "mustBeBiggerThanLatestVersion"
            ? "Please upgrade script version."
            : action.purpose === "oldVersionCanNotBeUpdated"
              ? "Old seal script not allowed to upload."
              : "",
      };
    case CREATE_JS_HASH_SUCCESS:
      return {
        ...state,
        sealScripts: action.payload.data,
        messageType: "success",
        message: "Seal script JS hash created successfully.",
      };
    case CREATE_ONE_TIME_EMAIL_SUCCESS:
      return {
        ...state,
        messageType: "success",
        message: "One time email created successfully.",
      };
    case CREATE_ONE_TIME_EMAIL_FAILURE:
      return {
        ...state,
        messageType: "error",
        message: "One time email not created",
      };
    case GET_ONE_TIME_EMAIL_SUCCESS:
      return {
        ...state,
        oneTimeEmails: action.payload.data,
      };
    case GET_ONE_TIME_EMAIL_FAILURE:
      return {
        ...state,
        messageType: "error",
        message: "One time email list error.",
      };
    case CREATE_JS_HASH_FAILURE:
      return {
        ...state,
        sealScripts: action.payload.data,
        messageType: "error",
        message:
          action.purpose === "mustBeBiggerThanLatestVersion"
            ? "Please upgrade script version."
            : action.purpose === "oldVersionCanNotBeUpdated"
              ? "Old seal script not allowed to upload."
              : "",
      };
    case SEND_REPORT_EMAIL_SUCCESS:
      return {
        ...state,
        messageType: "email-sent-success",
      };
    case SEND_REPORT_EMAIL_FAILURE:
      return {
        ...state,
        messageType: "email-sent-failure",
      };
    case GET_ALL_CSS_JS_HASH_SUCCESS:
      return {
        ...state,
        sealScripts: action.payload,
      };
    case GET_OLD_FREE_BUSINESS_LIST_SUCCESS:
      return {
        ...state,
        freeBusinsses: action.payload.data,
      };

    case GET_OLD_FREE_BUSINESS_LIST_FAILURE:
      return {
        ...state,
        messageType: "free-business-list-failure",
      };
    case GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_SUCCESS:
      return {
        ...state,
        otherMessagesStats: action.payload,
      };

    case REGENERATE_ALL_SEALS_SUCCESS:
      return {
        ...state,
        allSealsRegenerated: action.payload,
      };

    case REGENERATE_ALL_SEALS_FAILURE:
      return {
        ...state,
        allSealsRegenerated: false,
      };

    case GET_ALL_SSL_RENEWAL_DOMAINS_SUCCESS:
      return {
        ...state,
        renewSslDomains: action.payload,
      };

    case UPDATE_SSL_RENEWAL_DOMAINS_SUCCESS:
      return {
        ...state,
        messageType: "status-completed",
      };

    case UPDATE_ACCOUNT_4X_DETAILS_SUCCESS:
      return {
        ...state,
        details4X: action.payload,
        messageType: "4x-account-success",
      };
    case UPDATE_ACCOUNT_4X_DETAILS_FAILURE:
      return {
        ...state,
        messageType: "4x-account-failure",
      };
    case UPDATE_ONBOARDING_POPUP_DATA_SUCCESS:
      return {
        ...state,
        onboardingPopupUpdateStatus: action.payload,
      };

    case SEND_TEST_EMAIL_SUCCESS:
      return {
        ...state,
        messageType: "test-email-send-successfully",
      };

    case SEND_TEST_EMAIL_FAILURE:
      return {
        ...state,
        messageType: "test-email-process-was-unsccessfull",
      };

    case SAVE_SMS_TIMING_SUCCESS:
      return {
        ...state,
        messageType: "sms-timing-updated",
        message: "SMS timing updated successfully.",
      };

    case DELETE_TRUSTSEALS_SUCCESS:
      return {
        ...state,
        decision: action.payload,
      };

    default:
      return state;
  }
};
