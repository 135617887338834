import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class DeleteConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    const { onDelete } = this.props;

    return (
      <div className="modal-dialog " role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title f800">Delete Confirmation</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-lg-12 col-md-12 text-left">
              <h5>Are You Sure Want To Delete?</h5>
            </div>
          </div>
          <div className="modal-footer text-right">
            <div className="col-md-12">
              <button
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                style={{ marginRight: "7px" }}
              >
                No
              </button>
              <button
                type="button"
                className="btn-success btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => onDelete()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteConfirmation);
