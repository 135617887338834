import React from "react";
import TableHead from "./tableHead";

const table = (props) => {
  const headerColumns = props.headers.map((row, i) => (
    <th key={row + i} className={props.thClassName}>
      {row.name}
    </th>
  ));

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        {props.tablehead ? (
          <TableHead
            title={props.tablehead}
            emptyHeaderColumnsCount={props.emptyHeaderColumnsCount}
          />
        ) : null}
        <thead>
          <tr className={props.trClass}>{headerColumns}</tr>
        </thead>
        {props.children}
      </table>
    </div>
  );
};

export default table;
