import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext from "../../components/context/appContext";
import { updateBusinessData } from "../../modules/actions/index";
import {
  callApiGetBusinessData,
  callApiUpdateCheckScanReportsData,
  callApiGetCheckScanReportsDataUsingBusinessId,
} from "../../services/index";

class DelayFunctionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      reReview: "",
      customDate: "",
    };
  }

  componentDidMount() {
    this.setState({
      note: "",
      reReview: "",
      customDate: null,
    });
  }

  closeModal() {
    this.setState({
      note: "",
      reReview: "",
      customDate: null,
    });
  }

  updateCheckScanReportsChekDate = async (businessId) => {
    let finalDate;
    const tempCheckScanReportsData = await callApiGetCheckScanReportsDataUsingBusinessId(
      businessId
    );

    if (
      tempCheckScanReportsData &&
      tempCheckScanReportsData.data &&
      tempCheckScanReportsData.data.length > 0
    ) {
      switch (this.state.reReview) {
        case "1Week":
          finalDate = new Date().setDate(new Date().getDate() + 7);
          break;
        case "2Week":
          finalDate = new Date().setDate(new Date().getDate() + 14);
          break;
        case "3Week":
          finalDate = new Date().setDate(new Date().getDate() + 21);
          break;
        case "1Month":
          finalDate = new Date().setDate(new Date().getDate() + 30);
          break;
        case "custom":
          finalDate = this.state.customDate;
          break;

        default:
          break;
      }

      await callApiUpdateCheckScanReportsData(
        tempCheckScanReportsData.data[0]._id,
        {
          checkDate: finalDate,
        }
      );
    }
  };

  onClickAddNotes = async () => {
    let businessId = this.context.currentBusinessId;
    if (businessId) {
      if (this.state.reReview === "") {
        toast.error("Please select delay", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        // For not to display the business into the verification list.
        await this.updateCheckScanReportsChekDate(businessId);

        const tempBusinessData = await callApiGetBusinessData(businessId);
        tempBusinessData.notes.push({
          userId: this.context.userData._id,
          note: this.state.note,
          noteType: "delayed",
        });

        this.props.updateBusinessData(businessId, {
          purpose: "notes",
          notes: tempBusinessData.notes,
        });

        this.closeModal();
        document.getElementById("btnCancelDelayFunctionModal").click();

        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    }
  };

  render() {
    const { note, reReview, customDate } = this.state;
    const { userData } = this.context;

    return (
      <div
        id="delayfunctionInAdvanced"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Delay Functions</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-6 col-sm-6 col-xs-12 text-left">
                <label style={{ marginBottom: "15px" }}>
                  Re-review again in:
                </label>
                <select
                  className="form-control"
                  value={reReview}
                  onChange={(evt) => {
                    this.setState({ reReview: evt.target.value }, () => {});
                  }}
                >
                  <option>Please select</option>
                  <option value="1Week">1 Week</option>
                  <option value="2Week">2 Week</option>
                  <option value="3Week">3 Week</option>
                  <option value="1Month">1 Month</option>
                  <option value="never">Never</option>
                  <option value="custom">Custom</option>
                </select>
              </div>
              {reReview === "custom" ? (
                <div className="col-md-6 col-sm-6 col-xs-12 text-left">
                  <label style={{ marginBottom: "15px" }}>
                    or Choose a Date:
                  </label>
                  <div className="input-group">
                    <input
                      type="date"
                      className="form-control date-range-input"
                      name="singledate"
                      value={customDate}
                      style={{ width: "205px" }}
                      onChange={(evt) => {
                        this.setState({ customDate: evt.target.value });
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-lg-12 text-left">
                <hr />
              </div>
              <div className="col-lg-12 text-left">
                <label style={{ marginBottom: "15px" }}>Notes : </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Note"
                  value={note}
                  onChange={(e) => {
                    this.setState({ note: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                id="btnCancelDelayFunctionModal"
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-tgdark2 btn-140-width btn-md"
                onClick={() => {
                  this.onClickAddNotes();
                }}
                style={{ marginRight: "15px" }}
              >
                Delay
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DelayFunctionModal.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateBusinessData }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DelayFunctionModal);
