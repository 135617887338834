import React from "react";
import Switch from "react-switch";

const switchButton = (props) => {
  return (
    <div>
      <label htmlFor="normal-switch">
        <Switch
          onChange={props.changeStatus}
          checked={props.status}
          id="normal-switch"
          width={40}
          height={20}
          onColor="#28a745"
        />
      </label>
    </div>
  );
};

export default switchButton;
