import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { images } from "../../helpers/images";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Aux from "../../components/hoc/containerAux";
import { resetStatus } from "../../modules/actions/index";
import { onClickLogin } from "../../modules/actions/index";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      email: "",
      password: "",
      disableLogin: false,
    };

    this.onChangeHandelUserInput = this.onChangeHandelUserInput.bind(this);
    this.onTextKeyPress = this.onTextKeyPress.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.res.data && newProps.res.data.isAdmin) {
      this.props.history.push("/control-panel");
    } else {
      this.props.history.push("/");
    }
  }

  onChangeHandelUserInput(evt) {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState({
      [name]: value,
    });
  }

  onClickLogin = () => {
    this.setState({ disableLogin: true });

    this.props.onClickLogin({
      email: this.state.email,
      password: this.state.password,
    });

    setTimeout(() => this.setState({ disableLogin: false }), 2500);
  };

  onTextKeyPress(evt) {
    if (evt.key === "Enter") {
      this.onClickLogin(evt);
    }
  }

  render() {
    const { email, password, disableLogin } = this.state;

    return (
      <Aux>
        <div
          id="login-bg"
          style={{
            backgroundImage: "url(" + images.loginBGFinal + ")",
          }}
        />

        <div id="login-content">
          <div id="login-panel-wrapper">
            <div id="login-panel" className="col-md-12 text-center">
              <ToastContainer />

              <img
                alt="tgRegularDart"
                id="login-logo"
                src={images.tgRegularDark}
              />
              <br />
              <input
                type="text"
                placeholder="Email"
                className="form-control"
                name="email"
                value={email}
                onChange={this.onChangeHandelUserInput}
                onKeyPress={this.onTextKeyPress}
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="form-control"
                value={password}
                onChange={this.onChangeHandelUserInput}
                onKeyPress={this.onTextKeyPress}
                required
              />

              <div
                id="googleLoginMsg"
                className="alert alert-danger"
                style={{ display: "none", marginTop: "25px" }}
              />

              <button
                className="btn"
                id="login-btn"
                onClick={() => this.onClickLogin()}
                disabled={disableLogin}
              >
                Login
              </button>

              <div className="col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad text-left">
                <p
                  className="login-links"
                  onClick={() => {
                    window.open("https://trustguard.com", "_blank");
                  }}
                >
                  Don't have an account?
                </p>
              </div>

              <div className="col-md-6 col-sm-6 col-xs-6  no-pad text-right">
                <p
                  onClick={() => {
                    this.props.history.push("/reset-password");
                  }}
                  className="login-links"
                >
                  Forgot Password?
                </p>
              </div>

              <div
                id="login-copy"
                className="col-md-12 col-sm-12 col-xs-12 no-pad text-center"
              >
                <p>
                  By logging in you agree to the{" "}
                  <span
                    className="login-links text-success"
                    onClick={() => {
                      window.open(
                        "https://trustguard.com/terms28195423",
                        "_blank"
                      );
                    }}
                  >
                    Terms of Use
                  </span>{" "}
                  and{" "}
                  <span
                    className="login-links text-success"
                    onClick={() => {
                      window.open(
                        "https://trustguard.com/privacy-policy",
                        "_blank"
                      );
                    }}
                  >
                    Privacy Policy<br />{" "}
                  </span>
                  &copy; {new Date().getFullYear()} Trust-Guard. All Rights
                  Reserved
                </p>
              </div>
            </div>

            <div id="oldSiteContainer" className="col-md-12 text-center">
              <a
                href="https://members.trust-guard.com/login.php"
                id="btn-OldSite"
                className="btn btn-outline-secondary mt-5"
                rel="noopener noreferrer"
              >
                Access Old Site Login
              </a>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    res: state.authStore.res,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onClickLogin,
      resetStatus,
      goToControlPanel: () => push("/control-panel"),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
