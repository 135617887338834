import { api } from "./api";
import { callApi } from "../helpers/index";

export const callApiBuyerProtectionAccount = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api
        .service("api/buyer-protection")
        .create({ businessId: businessId });
    } else {
      return null;
    }
  });
};
