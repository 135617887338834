import { api } from "./api";
import { callApi } from "../helpers/index";

function callFindMalwareVulnerability(domainID) {
  const queryDomainID = {
    query: {
      domainId: domainID,
    },
  };
  return callApi().then((token) => {
    return api.service("malware-vulnerability").find(queryDomainID);
  });
}

function callFindMalwareVulnerabilityByReportUrl(
  reportUrls,
  targetId,
  businessId
) {
  const queryReportUrl = {
    query: {
      purpose: "vulnerabilitesbyurl",
      reportUrls: reportUrls,
      malwareHistoryId: targetId,
      businessId: businessId,
    },
  };

  return api.service("api/malware-vulnerability").find(queryReportUrl);
}

function callCreateMalwareVulnerability(malwareVulnerabilityData) {
  return callApi().then((token) => {
    return api
      .service("malware-vulnerability")
      .create(malwareVulnerabilityData);
  });
}

function callUpdateMalwareVulnerability(_id, data) {
  return callApi().then((token) => {
    return api.service("malware-vulnerability").patch(_id, data);
  });
}

function callUpdateMalwareVulnerabilityStatus(id, data) {
  return callApi().then((token) => {
    return api.service("api/malware-vulnerability").patch(id, data);
  });
}

function callUpdateDomainsDumpMD5(_id, data) {
  return callApi().then((token) => {
    return api.service("domains").patch(_id, data);
  });
}

function callUpdatereviewMalwareVulnerability(_id, data) {
  return callApi().then((token) => {
    return api.service("malware-vulnerability").patch(_id, data);
  });
}

function pushMalwareVulnerabilityHistory(_id, data) {
  return callApi().then((token) => {
    return api.service("malware-vulnerability").patch(_id, data);
  });
}

function callGetDomainHost(domainID) {
  return callApi().then((token) => {
    return api.service("domains").get(domainID);
  });
}

export {
  callUpdateMalwareVulnerability,
  callUpdateMalwareVulnerabilityStatus,
  callCreateMalwareVulnerability,
  callFindMalwareVulnerability,
  callUpdatereviewMalwareVulnerability,
  callGetDomainHost,
  callFindMalwareVulnerabilityByReportUrl,
  callUpdateDomainsDumpMD5,
  pushMalwareVulnerabilityHistory,
};
