import React from "react";
import Sidebar from "../sidebar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppContext from "../../components/context/appContext";
import RouteContext from "../../components/context/routeContext";
import {
  getUserDecryptedData,
  getBusinessDecryptedData,
} from "../../helpers/general";
import {
  callGetBusiness,
  callApiGetUserData,
  callApiGetUserBusinessData,
  callApiGetBusinessData,
  callApiGetBusinessUsersList,
} from "../../services/";

class PostLogin extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      isAdmin: false,
      isMounted: false,
      userLsData: {},
      userData: {},
      userBusinessData: [],
      notificationsCount: 0,
      updateUserData: this.updateUserData,
      updateUserBusinessData: this.updateUserBusinessData,
      updateNotificationCount: this.updateNotificationCount,
      currentBusinessId: null,
      updateCurrentBusinessId: this.updateCurrentBusinessId,
      /* pendingDomains: [],
      updatePendingDomains: this.updatePendingDomains, */
    };
  }

  async componentDidMount() {
    this.setState({
      userLsData: getUserDecryptedData(),
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.userLsData !== this.state.userLsData) {
      await this.updateUserData();
      await this.updateUserBusinessData();
      await this.updateNotificationCount();
    }
  }

  updateUserBusinessData = async () => {
    let userLsdata = this.state.user;
    if (userLsdata === null || userLsdata === undefined) {
      userLsdata = getUserDecryptedData();
    }

    if (!userLsdata.isAdmin) {
      const businessData = await callApiGetUserBusinessData(userLsdata);
      if (businessData) {
        this.setState({
          userBusinessData: businessData.data.filter(
            (business) => business.status !== "canceled"
          ),
          isMounted: true,
        });
      }
    } else {
      let businessLsId = getBusinessDecryptedData();
      if (businessLsId) {
        const businessId = businessLsId;
        const tempBusinessData = [];
        tempBusinessData.push(await callApiGetBusinessData(businessId));

        this.setState({
          userBusinessData: tempBusinessData,
          isMounted: true,
        });
      }
    }
  };

  updateUserData = async () => {
    let userLsdata = this.state.user;
    if (userLsdata === null || userLsdata === undefined) {
      userLsdata = getUserDecryptedData();
    }

    const userData = await callApiGetUserData(userLsdata);
    if (userData) {
      this.setState({
        isAdmin: userLsdata.isAdmin,
        userData: userData,
        isMounted: true,
      });
    }
  };

  updateNotificationCount = async () => {
    const userNotifications =
      this.state.userData &&
      this.state.userData.hasOwnProperty("notifications") &&
      this.state.userData.notifications.length > 0
        ? this.state.userData.notifications
        : null;

    const unseenNotifications = userNotifications
      ? userNotifications.filter((v, i) => {
          return v.status === "unseen" ? v : null;
        })
      : null;

    this.setState({
      notificationsCount: unseenNotifications ? unseenNotifications.length : 0,
    });
  };

  updateCurrentBusinessId = async (businessId) => {
    if (businessId) {
      this.setState({
        currentBusinessId: businessId,
      });
    }
  };

  /* updatePendingDomains = async () => {
    const pendingDomains = await callApiGetCheckScanReportsPendingDomainData();

    if (
      pendingDomains &&
      pendingDomains.data &&
      pendingDomains.data.length > 0
    ) {
      this.setState({
        pendingDomains: pendingDomains.data,
      });
    }
  }; */

  render() {
    const {
      userData,
      userBusinessData,
      currentBusinessId,
      notificationsCount,
      businessUsersList,
    } = this.state;

    const contextValue = {
      userData,
      userBusinessData: userBusinessData,
      notificationsCount,
      showBackDrop: this.context.showBackDrop,
      updateUserData: this.updateUserData,
      updateUserBusinessData: this.updateUserBusinessData,
      updateNotificationCount: this.updateNotificationCount,
      currentBusinessId,
      updateCurrentBusinessId: this.updateCurrentBusinessId,
    };

    return (
      <div>
        <AppContext.Provider value={contextValue}>
          <div id="main-container" className="col-lg-8">
            {this.props.pageContent}
          </div>
          <Sidebar />
        </AppContext.Provider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
PostLogin.contextType = RouteContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostLogin);
