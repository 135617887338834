import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../../views/charts/line";

import AppContext from "../../components/context/appContext";

class BusinessSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userBusinessData: [],
      businessId: "",
      isCompleted: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userBusinessData } = this.context;

    if (prevState.userBusinessData !== userBusinessData) {
      this.setState(
        {
          userBusinessData: userBusinessData,
          businessId:
            userBusinessData && userBusinessData.length > 0
              ? userBusinessData[0]._id
              : "",
        },
        () => {
          this.context.updateCurrentBusinessId(this.state.businessId);
        }
      );
    }
  }

  setBusiness = async (businessId) => {
    this.setState(
      {
        businessId: businessId,
      },
      () => {
        const currentBusinessData = this.state.userBusinessData.filter(
          (v, i) => {
            return v._id === businessId ? v : null;
          }
        )[0];

        if (
          currentBusinessData &&
          currentBusinessData.hasOwnProperty("details4X") &&
          currentBusinessData.details4X.hasOwnProperty("integrationStatus") &&
          currentBusinessData.details4X.integrationStatus
        ) {
          this.setState({
            isCompleted: true,
          });
        } else {
          this.setState({
            isCompleted: false,
          });
        }
      }
    );
  };

  render() {
    const { userBusinessData, businessId, isCompleted } = this.state;

    return (
      <>
        {0 && userBusinessData && userBusinessData.length > 1 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "12px 45px 12px 45px",
            }}
          >
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
              <label>Select Business:</label>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <select
                className="form-control"
                onChange={(evt) => {
                  this.setBusiness(evt.target.value);
                }}
                value={businessId}
              >
                <option>Select business</option>
                {userBusinessData.map((v, i) => {
                  return (
                    <option key={i} value={v._id}>
                      {v.primaryDomain}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        ) : (
          ""
        )}
        {userBusinessData && userBusinessData.length > 1 ? (
          <div id="mcontent-wrapper" style={{ padding: "40px 40px 0px 40px" }}>
            <table id="dash-overview-table" className="table table-striped">
              <tbody className="scan-status-list">
                <tr>
                  <td width="60%" />
                  <td width="40%" colSpan="2">
                    <select
                      id="dateRange"
                      className="form-control"
                      onChange={(evt) =>
                        this.setState(
                          {
                            businessId: evt.target.value,
                          },
                          () => {
                            // this.setCurrentBusinessData();
                          }
                        )
                      }
                      value={businessId}
                    >
                      {userBusinessData.map((v, i) => {
                        return (
                          <option value={v._id} key={i}>
                            {v.primaryDomain}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

BusinessSelector.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessSelector);
