import React from "react";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Carousel } from "react-bootstrap";
import { isArray } from "util";
import "./panels.css";
import SpinnerRect from "../../ui/spinner/spinnerRectScan";
import ProgressScanAnimation from "../ProgressScanAnimation";
import { getUserDecryptedData } from "../../../helpers/general";
import { convertTimeTo } from "../../../helpers/timeZone";
import { images } from "../../../helpers/images";
import { callMalwareSearchByBusinessIdAndLatestDate } from "../../../services/index";
import SlideContext from "../../context/slidePanelContexts";
import PciScanResult from "./pciScanResult";
import MalwareScanResult from "./malwareScanResult";
import SslScanResult from "./sslScanResult";
import InsuranceResult from "./insuranceResult";
import SealsResult from "./sealsResult";

import {
  callApiGetBusinessData,
  callApiGetBusinessSubdomainData,
} from "../../../services";
import sealsResult from "./sealsResult";

class ScanResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business: {},
      subdomains: [],
      subdomainLinks: "",
    };
  }

  async componentDidMount() {
    const businessData = await callApiGetBusinessData(this.props.businessId);
    this.setState({
      business: businessData,
    });

    this.getBusinesSubDomains();
  }

  async getBusinesSubDomains() {
    const subdomainData = await callApiGetBusinessSubdomainData(
      this.props.businessId
    );

    let subdomainLinks = subdomainData.data.map((domain, domainIndex) => {
      if (domainIndex < 4) {
        return (
          <li
            key={domainIndex}
            style={{
              fontSize: "0.7em",
              color: "#7f7f7f",
              padding: "0px 0 0px 20px",
            }}
          >
            {domain.host}{" "}
          </li>
        );
      }
    });

    this.setState({
      subdomains: subdomainData.data,
      subdomainLinks: subdomainLinks,
    });
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    const { businessId, index, value } = this.props;
    const { business, subdomains, subdomainLinks } = this.state;

    let scanSetOptions = (
      <>
        <SlideContext.drawerContext.Provider value={value}>
          <SlideContext.drawerContext.Consumer>
            {(context) => (
              <div className="col-1 text-center">
                <br />
                <a
                  href="/"
                  id="dash-acct-toggle"
                  className="btn-setting-link settings-toggle"
                  onClick={(e) => {
                    e.preventDefault();
                    context.scanOptions[index].openScanOptionsPanel(
                      businessId,
                      index
                    );
                  }}
                  data-testid="dashAcctToggle"
                >
                  <span id="acct-setup" className="fa fa-ellipsis-v" />
                  <i
                    style={{
                      display:
                        context.scanOptions[index] !== undefined
                          ? context.scanOptions[index].isOptionActive
                            ? "block"
                            : "none"
                          : null,
                    }}
                    className="set-arrow-ident fas fa-sort-up"
                  />
                </a>
              </div>
            )}
          </SlideContext.drawerContext.Consumer>
        </SlideContext.drawerContext.Provider>
      </>
    );

    return (
      <div className="row" style={{ marginBottom: "-5px" }}>
        <div className="col-11">
          <div className="row">
            <div className="col-2">
              <ul
                style={{ fontSize: "1em", listStyle: "none", padding: "0px" }}
              >
                <li>
                  <i className="fas fa-globe-americas" />&nbsp;{" "}
                  {business.primaryDomain}
                </li>
                {subdomainLinks}
                {subdomains.length >= 4 ? (
                  <li>
                    <button
                      className="tg-dashboard-link"
                      style={{
                        fontSize: "0.8em",
                        textAlign: "left",
                        padding: "5px 0 0px 25px",
                        display: "block",
                        cursor: "pointer",
                      }}
                    >
                      View more +
                    </button>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <div className="col-2 text-center">
              <SlideContext.drawerContext.Provider value={value}>
                <PciScanResult businessId={businessId} index={index} />
              </SlideContext.drawerContext.Provider>
            </div>
            <div className="col-2 text-center" id="divToggleSlideMalwareButton">
              <SlideContext.drawerContext.Provider value={value}>
                <MalwareScanResult businessId={businessId} index={index} />
              </SlideContext.drawerContext.Provider>
            </div>
            <div className="col-2 text-center" id="divToggleSlideSSLButton">
              <SlideContext.drawerContext.Provider value={value}>
                <SslScanResult businessId={businessId} index={index} />
              </SlideContext.drawerContext.Provider>
            </div>
            <div className="col-2 text-center">
              <InsuranceResult businessId={businessId} index={index} />
            </div>
            <div className="col-2 text-center">
              <SealsResult businessId={businessId} index={index} />
            </div>
          </div>
        </div>
        {scanSetOptions}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanResults);
