import React from "react";
import Aux from "../../components/hoc/containerAux";

class MalwareVulnerabilityOption1 extends React.Component {
  render() {
    return (
      <Aux>
        <option className="bg-white" defaultValue>
          Action
        </option>
        <option value="Ignored">Ignore</option>
      </Aux>
    );
  }
}

export default MalwareVulnerabilityOption1;
