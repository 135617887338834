import React from "react";
import {
  callOnSetFalseActiveSecurityOfficer,
  callOnUpdateSecurityOfficer,
} from "../../services/index";
class SecurityOfficer extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  setUserAsSecuriyOfficer = async (userId) => {
    await callOnSetFalseActiveSecurityOfficer(userId);
    await callOnUpdateSecurityOfficer({
      userId: userId,
      param: { users: this.props.userLists },
      setSecurityOfficer: true,
    });

    this.props.updateSecurityStatus();
  };

  render() {
    return (
      <div className="modal fade" id="security-officer" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" style={{ paddingLeft: "20px" }}>
                Assign as Security Officer
              </h4>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <strong>
                    Are you going to set this user as the official security
                    user/officer?
                  </strong>
                </div>
              </div>
            </div>

            <div
              className="modal-footer text-right pt-4"
              style={{ margin: "0 15px" }}>
              <button
                type="button"
                className="btn-tglight2 btn-md btn-140-width mr-2"
                data-dismiss="modal">
                Cancel
              </button>

              <button
                type="button"
                className="btn-tgdark2 btn-md modal-footer-button"
                data-dismiss="modal"
                onClick={() =>
                  this.setUserAsSecuriyOfficer(this.props.securityOfficeUser)
                }>
                Set as Official PCI User
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SecurityOfficer;
