import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";

import Profile from "./profile";
import NotificationSettings from "./notificationSettings";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    const screenHeight = window.innerHeight;

    return (
      <>
        <Header titlePage="Profile" bgColor="#F5F6F8" />

        <div id="mcontent-wrapper" style={!0 ? { height: screenHeight } : {}}>
          <div id="control-panel" className="">
            <ToastContainer />

            <Profile />
            <NotificationSettings />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
