import React from "react";
import "./ringBig.css";

const spinner = () => (
  <div className="lds-ring-big">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default spinner;
