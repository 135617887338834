import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { callOnGetMissingScanResults } from "../../services";
import AppContext from "../../components/context/appContext";

class RetriveMissingScansModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      businessId: "",
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      this.setState({
        businessId: currentBusinessId,
      });
    }
  }

  retrieveMissingScans = async (data) => {
    toast.info(
      "We are retrieving missing scans in the background. Please check with 1 -2 minutes.",
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      }
    );

    await callOnGetMissingScanResults({
      businessId: this.state.businessId,
      domainId: null,
    });

    toast.success("Missing scans successfully retrieved.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500,
    });

    //this.props.goToDashboard();
    //window.open("/", "_blank");
  };

  render() {
    const { businessId } = this.state;

    return (
      <div
        id="retriveMissingScansModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title f800">Retrive Missing Scans</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                <strong>
                  Are you sure to retrive missing scans of this business?
                </strong>
              </p>
            </div>
            <div className="modal-footer text-right">
              <button
                type="button"
                className="btn btn-danger btn-140-width btn-md"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-success btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.retrieveMissingScans();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RetriveMissingScansModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToDashboard: () => push("/"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetriveMissingScansModal);
