import React from "react";
import Pagination from "react-js-pagination";
import "./panels.css";

const tableMalwareContents = (props) => {
  return (
    <table
      className="table table-borderless"
      style={{
        border: "0px solid #efefef",
        borderCollapse: " collapse",
        borderRadius: "5px",
        overflow: " hidden",
      }}
    >
      <thead className="thead-dark">
        <tr>
          <th width="3%" style={{ padding: "20px" }}>
            &nbsp;
          </th>

          <th width="15%" style={{ padding: "20px 15px" }}>
            Last Scanned
          </th>
          <th width="15%" style={{ padding: "20px 15px" }}>
            Frequency
          </th>
          <th width="15%" style={{ padding: "20px 15px" }}>
            Detection
          </th>
          <th width="15%" style={{ padding: "20px 15px" }}>
            Status
          </th>
          <th
            width="15%"
            className="text-center"
            style={{ padding: "20px 15px" }}
          >
            Domains
          </th>
          <th className="text-center" style={{ padding: "20px 15px" }}>
            Actions
          </th>
          <th
            width="5%"
            height="3px"
            className="text-center"
            style={{ padding: "20px" }}
          />
        </tr>
      </thead>
      <tbody style={{ border: "6px solid #efefef" }}>{props.children}</tbody>
      <thead className="thead-dark">
        <tr>
          <th colSpan="3" id="custom-pagination">
            {/* <Pagination
              activePage={props.activePage}
              itemsCountPerPage={props.pageLimit}
              totalItemsCount={props.totalItems}
              pageRangeDisplayed={4}
              onChange={props.pageChange}
            /> */}
          </th>
          <th />
          <th />
          <th />
          <th />
          <th />
        </tr>
      </thead>
    </table>
  );
};

export default tableMalwareContents;
