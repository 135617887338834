import React, { Component } from "react";
import Modal from "../../components/ui/modal/modal";

class NonExistentScanReport extends Component {
  render() {
    const {
      scans,
      labelText,
      addLabelHandler,
      markDeleteHandler,
      labelHandler,
      confirmAddLabelHandler,
    } = this.props;

    return (
      <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
        <div className=" tab-content">
          {scans !== null ? (
            scans.length > 0 ? (
              <div class="alert alert-warning" role="alert">
                {" "}
                These scans do not exists on this server. Either one of these
                results exists on Developement server or in Local server.
              </div>
            ) : null
          ) : null}

          <table className="tg table table-bordered table-hover">
            <thead>
              <tr>
                <th />
                <th>Company Name</th>
                <th>Scan ID</th>
                <th>Label</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {scans !== null
                ? scans.length > 0
                  ? scans.map((scan, i) => {
                      return (
                        <tr key={scan.scanId}>
                          <td>{i + 1}</td>
                          <td>{scan.businessName}</td>
                          <td>{scan.scanId}</td>
                          <td>{scan.label}</td>
                          <td>
                            <button
                              className="button btn-success btn-sm"
                              onClick={() =>
                                addLabelHandler(i, scan.scanId, scan._id)
                              }
                              data-toggle="modal"
                              data-target="#addLabel">
                              Add Label
                            </button>{" "}
                            &nbsp;{" "}
                            <button
                              className="button btn-danger btn-sm"
                              onClick={() => markDeleteHandler(scan._id, i)}>
                              Mark as Deleted
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : null
                : null}
            </tbody>
          </table>
        </div>

        <Modal targetID="addLabel" title="Add Label">
          <label className="text-left">Comment</label>
          <input
            type="text"
            className="form-control"
            name="comment"
            value={labelText}
            onChange={(event) => labelHandler(event)}
          />

          <br />
          <div set="modal-footer" className="text-right modal-footer px-0">
            <button
              type="button"
              className="btn-tglight2 h5 btn-md btn-140-width mr-2"
              data-dismiss="modal"
              style={{ marginRight: "7px" }}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success btn-md btn-140-width"
              data-dismiss="modal"
              onClick={() => confirmAddLabelHandler()}>
              Add
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default NonExistentScanReport;
