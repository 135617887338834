import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "../../components/ui/table/table";
import { removeStorageItem } from "../../helpers/storage";
import { convertTimeTo } from "../../helpers/timeZone";
import {
  camelize,
  viewUserBusinessAccountDashboard,
} from "../../helpers/general";
import config from "../../helpers/config";
import EmailTemplateReport from "./emailTemplateReport";
import {
  getDomainsUsingStatus,
  sendEmailForReport,
} from "../../modules/actions/index";

class SslScanReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      domains: [],
      timezone: null,
      runningScans: "none",
      invalidScans: "none",
      pendingScans: "none",
      activeSSLsExpiresInComing30Days: "none",
      canceledSitesThatHaveBeenScannedWithinTheLast30Days: "none",
      selectedReportBusinessId: null,
      sslReportTypes: ["scanning", "pending", "failed", "active", "cancel"],
      sslReportSubTitle: {
        scanning: "Running Scans",
        pending: "Pending Scans",
        failed: "Invalid Scans",
        active: "Active SSLs Expires in Coming 30 Days",
        cancel: "Canceled sites that have been scanned within the last 30 days",
      },
    };
  }

  componentDidMount() {
    let domainQuery = {
      action: "get-domains-using-status-for-ssl-scan-report",
    };

    this.props.getDomainsUsingStatus(domainQuery);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.domainList) {
      this.setState({
        domains: newProps.domainList,
      });
    }

    if (newProps.loggedInUser) {
      this.setState({
        timezone: newProps.loggedInUser.timezone,
      });
    }
  }

  displaySslScanReport(type) {
    let thisType = camelize(type);

    this.setState({
      [thisType]: this.state[thisType] === "none" ? "block" : "none",
    });
  }

  setSelectedBusinessId = (businessId) => {
    this.setState({
      selectedReportBusinessId: businessId,
    });
  };

  viewUserDashboard = (e, businessId, accountType) => {
    viewUserBusinessAccountDashboard(businessId, accountType);
    window.open(config.urls.dashboardUrl, "_blank");
  };

  populateSslScanReports(type, thisData) {
    const sslScanTableHeaderColumns = [
      { name: "Domain/Subdomains/IPs" },
      { name: "Last SSL Validation" },
      { name: "Frequency" },
      { name: "SSL Provider" },
      { name: "Expiration" },
      { name: "Status" },
      { name: "Email Client" },
    ];

    return (
      <div key={Math.random()}>
        <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
          <div className=" tab-content">
            <div className="tab-pane fade in active reportsTab">
              <div className="">
                <button
                  className="btn-success"
                  onClick={() => {
                    this.displaySslScanReport(type);
                  }}
                >
                  {type}
                </button>
                <div className="clearfix" />
              </div>
              <p style={{ marginTop: "10px" }}>{type}</p>
              <hr />

              <div
                key={type}
                style={{
                  marginBottom: 25,
                  display: this.state[camelize(type)],
                }}
              >
                <h4> {type}</h4>
                <Table
                  headers={sslScanTableHeaderColumns}
                  emptyHeaderColumnsCount="7"
                  trclassName="bg-torq-light"
                >
                  <tbody className="scan-status-list">
                    {thisData.length ? (
                      thisData.map((value, index) => {
                        if (value.businessId) {
                          return (
                            <tr key={index}>
                              <td>
                                {value.host !== "" ? (
                                  <button
                                    className="tg-link"
                                    onClick={(e) => {
                                      this.viewUserDashboard(
                                        e,
                                        value.businessId
                                      );
                                    }}
                                  >
                                    {value.host}
                                  </button>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>
                                {value.sslInfo.hasOwnProperty("lastCheckDate")
                                  ? value.sslInfo.lastCheckDate !== ""
                                    ? convertTimeTo(
                                        value.sslInfo.lastCheckDate,
                                        this.state.timezone,
                                        "MMM Do YYYY"
                                      )
                                    : "N/A"
                                  : "N/A"}
                              </td>
                              <td className="text-left">
                                {value.sslInfo.hasOwnProperty("checkFrequency")
                                  ? value.sslInfo.checkFrequency !== ""
                                    ? value.sslInfo.checkFrequency
                                    : "N/A"
                                  : "N/A"}
                              </td>
                              <td className="text-left">
                                {value.sslInfo.hasOwnProperty("sslProvider")
                                  ? value.sslInfo.sslProvider !== ""
                                    ? value.sslInfo.sslProvider
                                    : "N/A"
                                  : "N/A"}
                              </td>
                              <td className="text-left">
                                {value.sslInfo.hasOwnProperty("expireDate")
                                  ? value.sslInfo.expireDate !== ""
                                    ? value.sslInfo.expireDate
                                    : "N/A"
                                  : "N/A"}
                              </td>
                              <td className="text-left">
                                {value.sslInfo.hasOwnProperty("status")
                                  ? value.sslInfo.status !== ""
                                    ? value.sslInfo.status
                                    : "N/A"
                                  : "N/A"}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <button
                                  className="btn-success btn-sm"
                                  data-toggle="modal"
                                  href={"#" + camelize(type) + "SslScan"}
                                  onClick={() => {
                                    this.setSelectedBusinessId(
                                      value.businessId
                                    );
                                  }}
                                >
                                  <i className="fa fa-envelope" />{" "}
                                </button>
                              </td>
                            </tr>
                          );
                        }
                        return true;
                      })
                    ) : (
                      <tr>
                        <td colSpan="7">
                          <div className="alert alert-info">No results</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        <div
          id="runningScansSslScan"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <EmailTemplateReport sendSslReport={this.sendSslReport} />
        </div>

        <div
          id="invalidScansSslScan"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <EmailTemplateReport sendSslReport={this.sendSslReport} />
        </div>

        <div
          id="pendingScansSslScan"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <EmailTemplateReport sendSslReport={this.sendSslReport} />
        </div>

        <div
          id="activeSSLsExpiresInComing30DaysSslScan"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <EmailTemplateReport sendSslReport={this.sendSslReport} />
        </div>

        <div
          id="canceledSitesThatHaveBeenScannedWithinTheLast30DaysSslScan"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <EmailTemplateReport sendSslReport={this.sendSslReport} />
        </div>
      </div>
    );
  }

  sendSslReport = (data) => {
    data.businessId = this.state.selectedReportBusinessId;
    this.props.sendEmailForReport(data);
  };

  render() {
    const { domains } = this.state;

    removeStorageItem("businessID");

    return (
      <Aux>
        {domains.map((value, index) => {
          if (value.hasOwnProperty("scanning")) {
            return this.populateSslScanReports("Running Scans", value.scanning);
          }

          if (value.hasOwnProperty("pending")) {
            return this.populateSslScanReports("Pending Scans", value.pending);
          }

          if (value.hasOwnProperty("failed")) {
            return this.populateSslScanReports("Invalid Scans", value.failed);
          }

          if (value.hasOwnProperty("active")) {
            return this.populateSslScanReports(
              "Active SSLs Expires in Coming 30 Days",
              value.active
            );
          }

          if (value.hasOwnProperty("cancel")) {
            return this.populateSslScanReports(
              "Canceled sites that have been scanned within the last 30 days",
              value.cancel
            );
          }

          return "";
        })}
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.accountStore.loggedInUser,
    domainList: state.accountStore.domainList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDomainsUsingStatus,
      sendEmailForReport,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SslScanReport);
