import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import config from "../../helpers/config";
import AppContext from "../../components/context/appContext";
import UserChangePassword from "../modals/userChangePassword";
import { updateUserProfileData } from "../../modules/actions/index";

// TODO: Need to implement isLoading contentsvg

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userData: {},
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      timezone: "America/New_York",
      isDisabled: false,
    };

    this.onChangeHandelUserInput = this.onChangeHandelUserInput.bind(this);
    this.onTextKeyPress = this.onTextKeyPress.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.context;

    if (prevState.userData !== userData) {
      this.setState({
        userData: userData,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        timezone:
          userData.timezone !== null ? userData.timezone : "America/New_York",
      });
    }
  }

  onChangeHandelUserInput(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    this.setState({
      [name]: value,
    });
  }

  onTextKeyPress(evt) {
    if (evt.key === "Enter") {
      this.updateUserProfileData();
    }
  }

  updateUserProfileData() {
    this.setState({
      isDisabled: true,
    });
    this.props.updateUserProfileData(this.state.userData._id, {
      purpose: "profile",
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      timezone: this.state.timezone,
    });

    setTimeout(() => {
      this.setState({
        isDisabled: false,
      });
    }, 2500);
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      timezone,
      isDisabled,
    } = this.state;

    return (
      <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  no-pad">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label>First Name:</label>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <input
              autoFocus
              type="text"
              className="form-control"
              name="firstName"
              value={firstName}
              onChange={this.onChangeHandelUserInput}
              onKeyPress={this.onTextKeyPress}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label>Last Name:</label>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <input
              type="text"
              className="form-control"
              name="lastName"
              value={lastName}
              onChange={this.onChangeHandelUserInput}
              onKeyPress={this.onTextKeyPress}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label>Email:</label>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <input
              type="text"
              className="form-control"
              name="email"
              value={email}
              onChange={this.onChangeHandelUserInput}
              onKeyPress={this.onTextKeyPress}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label>Phone:</label>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <input
              type="text"
              className="form-control"
              name="phone"
              value={phone}
              onChange={this.onChangeHandelUserInput}
              onKeyPress={this.onTextKeyPress}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label>Timezone:</label>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <select
              className="form-control"
              onChange={(evt) => {
                this.setState({
                  timezone: evt.target.value,
                });
              }}
              value={timezone}
            >
              {config.timezones.map((v, i) => {
                return (
                  <option key={i} value={v.timezone}>
                    {v.timezone}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" />
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-3">
            <BrowserView>
              <div className="text-right">
                <button
                  data-toggle="modal"
                  data-target="#changepass"
                  className="btn-md btn-tglight2 mr-2 btn"
                  data-testid="btnUserProfileChangePasswordBrowser"
                >
                  <i className="fas fa-key" />&nbsp; Change Password
                </button>
                <button
                  data-toggle="modal"
                  data-target="#email-ver"
                  className="btn-success btn-md btn"
                  onClick={() => {
                    this.updateUserProfileData();
                  }}
                  data-testid="btnUserProfileUpdateBrowser"
                  disabled={isDisabled}
                >
                  <i className="fas fa-edit" />&nbsp; Update
                </button>
              </div>
            </BrowserView>

            <MobileView>
              <button
                data-toggle="modal"
                data-target="#changepass"
                className="btn-tglight2 btn"
                data-testid="btnUserProfileChangePasswordMobile"
              >
                <i className="fas fa-key" />&nbsp; Change Password
              </button>
              &nbsp; &nbsp;
              <button
                data-toggle="modal"
                data-target="#email-ver"
                className="btn-success btn"
                onClick={() => {
                  this.updateUserProfileData();
                }}
                data-testid="btnUserProfileUpdateMobile"
                disabled={isDisabled}
              >
                <i className="fas fa-edit" />&nbsp; Update
              </button>
            </MobileView>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" />
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <UserChangePassword />
          </div>
        </div>
      </div>
    );
  }
}

Profile.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfileData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
