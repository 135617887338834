import {
  GET_BUSINESS_SUBDOMAIN_DATA_SUCCESS,
  GET_BUSINESS_SUBDOMAIN_DATA_FAILURE,
} from "../types/index";

const defaultState = {
  subDomains: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_BUSINESS_SUBDOMAIN_DATA_SUCCESS:
      return {
        ...state,
        subDomains: action.payload.data,
      };
    case GET_BUSINESS_SUBDOMAIN_DATA_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
