import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import Table from "../../components/ui/table/table";
import ControlButtons from "./controlButtons";
import config from "../../helpers/config";
import { getStorageItem, removeStorageItem } from "../../helpers/storage";
import { viewUserBusinessAccountDashboard } from "../../helpers/general";
import {
  getUserById,
  getDomains,
  cancelOrActiveDomain,
  getBusiness,
  findUsersInBusiness,
  unlockUserAccount,
  createNote,
  resetStatus,
  getFailedScanDomains,
  onClickRemoveTrustSealsSettingsDate,
  onClickSaveResolvedNote,
  get30DaysOldFreeBusinesses,
} from "../../modules/actions/index";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class FreeBusinessList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      domains: [],
      userId: "",
      firstName: "",
      businessAccount: [],
      taskOnDomain: "",
      usersInBusiness: [],
      selectedBusinessId: "",
      notifyType: "",
      notifyMessage: "",
      note: "",
      notesInBusiness: [],
      customerNotification: false,
      noteType: "",
      reReview: "",
      resolvedNote: "",
      businessId: "",
      reason: "",
      reportId: "",
      doCall: false,
      checkScanReportId: null,
      freeBusinsses: [],
      displayFreeBusinessList: false,
    };
  }

  componentDidMount() {
    removeStorageItem("businessID");
    let user = {};
    let userData = getStorageItem("userData");
    if (userData) {
      let userString = cryptr.decrypt(userData);
      user = JSON.parse(userString);
      this.props.getUserById(user._id);
      this.setState({
        userId: user._id,
      });
      this.props.get30DaysOldFreeBusinesses();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.freeBusinsses) {
      this.setState({
        freeBusinsses: newProps.freeBusinsses,
      });
    }

    if (newProps.usersInBusiness) {
      this.setState({
        usersInBusiness: newProps.usersInBusiness,
      });
    }
  }

  onClickSetBusinessId = (businessId, taskOnDomain) => {
    this.setState({
      selectedBusinessId: businessId,
      taskOnDomain: taskOnDomain,
    });
  };

  onClickCancelOrActiveDomain = () => {
    if (this.state.selectedBusinessId) {
      this.props.cancelOrActiveDomain(
        this.state.selectedBusinessId,
        this.state.userId,
        this.state.taskOnDomain
      );
    }
  };

  onClickSetBusinessInfo = (businessId, type) => {
    this.setState({
      noteType: type,
      selectedBusinessId: businessId,
    });
  };

  onClickClearTrustSealsSettingsDate = (businessId) => {
    if (businessId) {
      let query = {
        action: "refresh-seals",
        businessId: businessId,
      };
      this.props.onClickRemoveTrustSealsSettingsDate(query);
    }
  };

  saveResolvedNote = async () => {
    let notes = {
      roles: {
        admin: false,
        manager: false,
        staff: false,
      },
      userId: this.state.userId,
      note: this.state.resolvedNote,
      noteType: "resolved",
    };
    await this.props.onClickSaveResolvedNote(
      this.state.businessId,
      notes,
      this.state.reason,
      this.state.reportId,
      this.state.checkScanReportId
    );

    const query = {
      query: {
        status: "pending",
        checkDate: { $lt: new Date() },
        details: { $elemMatch: { resolvedBy: null } },
      },
    };
    await this.props.getFailedScanDomains(query);
    document.getElementById("resolvedIssueModalCloseButton").click();
  };

  viewUserDashboard = (businessId, accountType) => {
    viewUserBusinessAccountDashboard(businessId, accountType);
    window.open(config.urls.dashboardUrl, "_blank");
  };

  render() {
    const {
      taskOnDomain,
      displayFreeBusinessList,
      freeBusinsses,
      userId,
      resolvedNote,
    } = this.state;

    const { populateDelayModalData, populateBusinessUsersData } = this.props;

    removeStorageItem("businessID");

    const FailedScansHeaderColumns = [
      { name: "Business" },
      { name: "PCI" },
      { name: "Malware" },
      { name: "SSL" },
      { name: "Insurance" },
      { name: "Actions" },
    ];

    return (
      <Aux>
        <div className="modal fade" id="cancelDomain" role="dialog">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {taskOnDomain === "active"
                    ? "Active Domain"
                    : "Cancel Domain"}
                </h4>
              </div>
              <div className="modal-body">
                {taskOnDomain === "active" ? (
                  <p>Are you sure want to active this domain.</p>
                ) : (
                  <p>Are you sure want to cancel this domain.</p>
                )}
              </div>
              <div className="modal-footer text-right">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-tglight2 mr-2"
                    data-dismiss="modal"
                    style={{ marginRight: "7px" }}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn  btn-tglight2"
                    data-dismiss="modal"
                    onClick={() => {
                      this.onClickCancelOrActiveDomain();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="reportTab">
          <button
            className="btn-success"
            onClick={() => {
              this.setState({
                displayFreeBusinessList: !displayFreeBusinessList,
              });
            }}
          >
            Active Free Accounts Older than 30 Days
          </button>
          <div className="clearfix" />
        </div>
        <p style={{ marginTop: "10px", color: "#757574" }}>
          Active Free Accounts Older than 30 Days
        </p>
        <hr />

        <div
          id="dash-overview-table"
          className="table-responsive"
          style={{
            display: displayFreeBusinessList ? "block" : "none",
          }}
        >
          <Table
            headers={FailedScansHeaderColumns}
            emptyHeaderColumnsCount="11"
            trclassName="bg-torq-light"
          >
            <tbody className="scan-status-list">
              {freeBusinsses && freeBusinsses.length > 0 ? (
                freeBusinsses.map((business, index) => {
                  if (business) {
                    return (
                      <tr key={index}>
                        <td>
                          {business ? (
                            <a
                              href="/"
                              className="text-link"
                              onClick={(e) => {
                                e.preventDefault();
                                this.viewUserDashboard(
                                  business._id,
                                  business.accountType
                                );
                              }}
                            >
                              {business.primaryDomain}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>

                        <td>
                          <button
                            className={
                              business &&
                              business.hasOwnProperty("lastPciStatus")
                                ? business.lastPciStatus === "passed" ||
                                  business.lastPciStatus === "pass"
                                  ? "btn status-box btn-success"
                                  : business.lastPciStatus === "fail"
                                    ? "btn status-box btn-success status-box-red"
                                    : "btn status-box btn-success status-box-grey"
                                : "btn status-box btn-success status-box-grey"
                            }
                          >
                            <i
                              className={
                                business &&
                                business.hasOwnProperty("lastPciStatus")
                                  ? business.lastPciStatus === "passed" ||
                                    business.lastPciStatus === "pass"
                                    ? "fa fa-check"
                                    : business.lastPciStatus === "fail"
                                      ? "fa fa-times"
                                      : "fa fa-question"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={
                              business &&
                              business.hasOwnProperty("lastMalwareScanResult")
                                ? business.lastMalwareScanResult === "clean"
                                  ? "btn status-box btn-success"
                                  : business.lastMalwareScanResult ===
                                      "malicious" ||
                                    business.lastMalwareScanResult ===
                                      "suspicious"
                                    ? "btn status-box btn-success status-box-red"
                                    : "btn status-box btn-success status-box-grey"
                                : "btn status-box btn-success status-box-grey"
                            }
                          >
                            <i
                              className={
                                business &&
                                business.hasOwnProperty("lastMalwareScanResult")
                                  ? business.lastMalwareScanResult === "clean"
                                    ? "fa fa-check"
                                    : business.lastMalwareScanResult ===
                                        "malicious" ||
                                      business.lastMalwareScanResult ===
                                        "suspicious"
                                      ? "fa fa-times"
                                      : "fa fa-question"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={
                              business &&
                              business.hasOwnProperty("domains") &&
                              business.domains.hasOwnProperty("sslInfo")
                                ? business.domains.sslInfo.status === "active"
                                  ? "btn status-box btn-success"
                                  : business.domains.sslInfo.status === "failed"
                                    ? "btn status-box btn-success status-box-red"
                                    : "btn status-box btn-success status-box-grey"
                                : "btn status-box btn-success status-box-grey"
                            }
                          >
                            <i
                              className={
                                business &&
                                business.hasOwnProperty("domains") &&
                                business.domains.hasOwnProperty("sslInfo")
                                  ? business.domains.sslInfo.status === "active"
                                    ? "fa fa-check"
                                    : business.domains.sslInfo.status ===
                                      "failed"
                                      ? "fa fa-times"
                                      : "fa fa-question"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={
                              business &&
                              business.hasOwnProperty("insuranceData") &&
                              business.insuranceData.insuranceSatus ===
                                "approved"
                                ? "btn status-box btn-success"
                                : "btn status-box status-box-grey btn-success"
                            }
                          >
                            <i
                              className={
                                business &&
                                business.hasOwnProperty("insuranceData") &&
                                business.insuranceData.insuranceSatus ===
                                  "approved"
                                  ? "fa fa-check"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <ControlButtons
                            business={business}
                            userId={userId}
                            populateDelayModalData={populateDelayModalData}
                            populateBusinessUsersData={
                              populateBusinessUsersData
                            }
                          />
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <tr>
                  <td colSpan="7">
                    <div className="alert alert-info">No records.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div
            id="resolvedIssueModal"
            className="modal fade"
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog " role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title f800">
                    Is this issue is resolved?
                  </h4>
                  <button
                    id="resolvedIssueModalCloseButton"
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-lg-12 text-left">
                    <label>Notes :</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Add Note"
                      value={resolvedNote}
                      cols={40}
                      rows={10}
                      onChange={(evt) => {
                        this.setState({
                          resolvedNote: evt.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer text-right">
                  <button
                    id="testhbhasdfas"
                    type="button"
                    className="btn-tglight2 btn-140-width btn-md"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn-tgdark2 btn-140-width btn-md"
                    style={{ marginRight: "15px" }}
                    onClick={() => {
                      this.saveResolvedNote();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.accountStore.domains,
    loggedInUser: state.accountStore.loggedInUser,
    userAccount: state.accountStore.userAccount,
    businessAccount: state.accountStore.businessAccount,
    usersInBusiness: state.loginStore.usersInBusiness,
    messageType: state.accountStore.messageType,
    message: state.accountStore.message,
    code: state.accountStore.code,
    scanPendingBusinesses: state.accountStore.scanPendingBusinesses,
    freeBusinsses: state.accountStore.freeBusinsses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDomains,
      getUserById,
      cancelOrActiveDomain,
      getBusiness,
      findUsersInBusiness,
      unlockUserAccount,
      createNote,
      getFailedScanDomains,
      resetStatus,
      onClickRemoveTrustSealsSettingsDate,
      onClickSaveResolvedNote,
      get30DaysOldFreeBusinesses,
      goToDashboard: () =>
        push("/", {
          accountAdminView: true,
        }),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FreeBusinessList);
