import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { Button, Modal } from "react-bootstrap";
import AppContext from "../../../components/context/appContext";
import config from "../../../helpers/config";
import _ from "lodash";

class StartScanButton extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      beyondSecurityScanInfo: {
        scanTime: _.random(6, 23),
        scanTimeZone: "America/Chicago",
      },
    };
  }

  abortController = new AbortController();

  handleClose() {
    this.setState({ show: false });
  }

  async handleStartScan() {
    const data = {
      _id: this.props.businessId,
      scanTimeDetails: {
        scanTime: this.state.beyondSecurityScanInfo.scanTime,
        scanTimeZone: this.state.beyondSecurityScanInfo.scanTimeZone,
      },
    };
    this.props.startScanDomainHandler(this.props.toggledIndex, data);
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleScanTime = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };
    beyondSecurityScanInfo["scanTime"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });
  };

  handleScanTimeZone = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };
    beyondSecurityScanInfo["scanTimeZone"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });

    if (input.target.value === 0) {
      this.setState({ invalidTimeZone: true });
    } else {
      this.setState({ invalidTimeZone: false });
    }
  };

  render() {
    const { show, beyondSecurityScanInfo, invalidTimeZone } = this.state;

    let id = "start-scan-button";
    if (isMobile) {
      id = "start-scan-button-mobile";
    }
    return (
      <>
        <div className="row" id={id}>
          <button
            data-testid="startNewPCIScan"
            onClick={this.handleShow}
            className="btn-success btn-lg"
            style={{
              margin: "0px auto",
              display: "block",
              cursor: "pointer",
            }}
            disabled={this.props.submitted ? true : false}>
            {isMobile
              ? this.props.submitted
                ? "Creating a Scan..."
                : "Start PCI Scan"
              : "Start PCI Scan"}
          </button>

          <Modal style={{ top: "0px" }} show={show} onHide={this.handleClose}>
            <Modal.Header style={{ padding: "10px" }}>
              <Modal.Title>
                <h4 style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                  START NEW SCAN
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm">
                    <p style={{ fontSize: "16px" }}>
                      Once you press the Start button below, we will begin
                      scanning your website/IP every day for the latest
                      vulnerabilities. <br />
                      <br />
                      If you want to change your scanning frequency, you can do
                      so after you start your first scan by clicking on the
                      circle icon under PCI Scan.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label htmlFor="timeZoneSelect">Time Zone</label>
                      <select
                        data-testid="selPciTimeZone"
                        className="form-control"
                        id="timeZoneSelect"
                        name="timeZoneSelect"
                        onChange={this.handleScanTimeZone}
                        value={beyondSecurityScanInfo.scanTimeZone}>
                        <option value="0">Please Select Timezone</option>
                        {config.timezones.map((v, i) => {
                          return (
                            <option key={i} value={v.timezone}>
                              {v.timezone}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm" style={{ marginLeft: "6.5rem" }}>
                    <div className="form-group">
                      <label htmlFor="timeZoneSelect">Scan Time</label>
                      <select
                        data-testid="selPciScanTime"
                        className="form-control col-sm-12"
                        id="timeSelect"
                        name="timeSelect"
                        onChange={this.handleScanTime}
                        value={beyondSecurityScanInfo.scanTime}>
                        <option value="24">12:00 AM</option>
                        <option value="1">01:00 AM</option>
                        <option value="2">02:00 AM</option>
                        <option value="3">03:00 AM</option>
                        <option value="4">04:00 AM</option>
                        <option value="5">05:00 AM</option>
                        <option value="6">06:00 AM</option>
                        <option value="7">07:00 AM</option>
                        <option value="8">08:00 AM</option>
                        <option value="9">09:00 AM</option>
                        <option value="10">10:00 AM</option>
                        <option value="11">11:00 AM</option>
                        <option value="12">12:00 PM</option>
                        <option value="13">01:00 PM</option>
                        <option value="14">02:00 PM</option>
                        <option value="15">03:00 PM</option>
                        <option value="16">04:00 PM</option>
                        <option value="17">05:00 PM</option>
                        <option value="18">06:00 PM</option>
                        <option value="19">07:00 PM</option>
                        <option value="20">08:00 PM</option>
                        <option value="21">08:00 PM</option>
                        <option value="22">10:00 PM</option>
                        <option value="23">11:00 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col1">
                    <div
                      className="alert alert-dark"
                      role="alert"
                      style={{
                        background: "#818181",
                        color: "#fff",
                        fontSize: "1.2em",
                      }}>
                      <i
                        className="fa fa-info-circle"
                        style={{
                          float: "left",
                          marginRight: "10px",
                          marginTop: "4px",
                          fontSize: "2.5em",
                        }}
                      />
                      Your first scan will begin right now but future scans will
                      start at approximately the time you select.
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                data-testid="clickStartScan"
                className="btn btn-success pull-right"
                onClick={() => this.handleStartScan()}
                disabled={invalidTimeZone}>
                Start PCI Scan
              </Button>
              <Button
                className="btn btn-default pull-right"
                onClick={this.handleClose}
                style={{ marginRight: "10px" }}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div id="panelhack" />
      </>
    );
  }
}
StartScanButton.contextType = AppContext;
export default StartScanButton;
