import React from "react";
import Aux from "../../hoc/containerAux";
import ModalButton from "./button/button";

const modal = (props) => {
  let footerModal = null;
  for (let child in props.children) {
    if (props.children[child]) {
      if (props.children[child].type !== undefined) {
        if (props.children[child].type.name === "modalFooter") {
          footerModal = props.children[child].props.children;
        }
      }
    }
  }

  const bigModal = props.largeModal
    ? {
        width: "800px",
        marginLeft: "-27.8%",
      }
    : null;

  return (
    <Aux>
      {props.showbutton ? (
        <ModalButton
          target={props.targetID}
          nofloat={props.buttonfloatdisable}
          label={props.buttonLabel}
          large={props.buttonlarge}
          small={props.buttonsmall}
        />
      ) : null}
      <div
        id={props.targetID}
        className="modal text-left"
        tabIndex="-1"
        role="dialog">
        <div
          className={props.wide ? "modal-dialog modal-lg" : "modal-dialog "}
          role="document">
          <div className="modal-content col-md-12" style={bigModal}>
            <div className="modal-header">
              <h4 className="modal-title f800">{props.title}</h4>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className={
                props.wide
                  ? "modal-body col-md-12 col-sm-12 col-xs-12 py-5"
                  : "modal-body pt-4 pb-2"
              }>
              {props.children}
            </div>

            <div>{footerModal}</div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default modal;
