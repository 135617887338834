import * as Client from "../../services/index";
import * as ActionType from "../types/index";

export const getBusiness = (id) => {
  return async (dispatch) => {
    return Client.callOnGetBusinessInfo(id).then((business) => {
      dispatch({
        type: ActionType.GET_BUSINESS_ID,
        payload: business,
      });
    });
  };
};

export const updateBusiness = (data) => {
  return async (dispatch) => {
    return Client.callOnUpdateBusinessFrequency(data).then((business) => {
      dispatch({
        type: ActionType.UPDATE_BUSINESS_FREQUENCY,
        payload: business,
      });
    });
  };
};

export const updateBusinessReportOption = (data) => {
  return async (dispatch) => {
    return Client.callOnUpdateBusinessReportOptions(data).then((business) => {
      dispatch({
        type: ActionType.UPDATE_BUSINESS_SCAN_REPORT,
        payload: business,
      });
    });
  };
};
