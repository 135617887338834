import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppContext from "../../components/context/appContext";

import { updateUserProfileData } from "../../modules/actions";

class UserChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isDisable: false,
    };

    this.onChangeHandelUserInput = this.onChangeHandelUserInput.bind(this);
    this.onTextKeyPress = this.onTextKeyPress.bind(this);
  }

  onChangeHandelUserInput(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    this.setState({
      [name]: value,
    });
  }

  onTextKeyPress(evt) {
    if (evt.key === "Enter") {
      this.updateUserPassword();
    }
  }

  updateUserPassword() {
    this.setState({
      isDisable: true,
    });

    this.props.updateUserProfileData(this.context.userData._id, {
      purpose: "password",
      email: this.context.userData.email,
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
    });

    setTimeout(() => {
      this.setState({
        isDisable: false,
      });
      this.context.updateUserData();
    }, 2500);

    if (
      this.state.oldPassword &&
      this.state.newPassword &&
      this.state.confirmPassword
    ) {
      this.setState(
        {
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        },
        () => {
          document.getElementById("hideModalOnSuccess").click();
        }
      );
    }
  }

  render() {
    const { oldPassword, newPassword, confirmPassword, isDisable } = this.state;

    return (
      <div id="changepass" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog " role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title">Change Password</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{ margin: "15px 0" }}
                  >
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-left">
                      <label>Current password:</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <input
                        type="password"
                        autoFocus
                        name="oldPassword"
                        autoComplete="off"
                        value={oldPassword}
                        onChange={this.onChangeHandelUserInput}
                        onKeyPress={this.onTextKeyPress}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{ marginBottom: "15px" }}
                  >
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-left">
                      <label>New password:</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <input
                        type="password"
                        name="newPassword"
                        autoComplete="off"
                        value={newPassword}
                        onChange={this.onChangeHandelUserInput}
                        onKeyPress={this.onTextKeyPress}
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{ marginBottom: "15px" }}
                  >
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-left">
                      <label>Confirm password:</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <input
                        type="password"
                        name="confirmPassword"
                        autoComplete="off"
                        value={confirmPassword}
                        onChange={this.onChangeHandelUserInput}
                        onKeyPress={this.onTextKeyPress}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer text-right pt-4">
              <button
                id="hideModalOnSuccess"
                type="button"
                className="btn-tglight2 btn btn-md mr-2"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-success btn btn-md"
                onClick={() => this.updateUserPassword()}
                disabled={isDisable}
              >
                <i className="fas fa-key" />&nbsp; Change password
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserChangePassword.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfileData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserChangePassword);
