import { callGetUserById, callUpdateUserProfile } from "../../services/index";

import {
  UPDATE_UNSEEN_NOTIFICATION_TO_SEEN,
  UPDATE_UNSEEN_NOTIFICATION_TO_SEEN_FAILED,
} from "../types/index";

export const notificationSetSeen = (userId) => {
  return async (dispatch) => {
    return callGetUserById(userId)
      .then((user) => {
        let seenNotifications = user.notifications.filter((notification) => {
          return notification.status === "seen";
        });
        let unseenNotifications = user.notifications.filter((notification) => {
          return notification.status === "unseen";
        });
        if (unseenNotifications.length > 0) {
          for (var i = 0; i < unseenNotifications.length; i++) {
            unseenNotifications[i].status = "seen";
            seenNotifications.push({
              businessId: unseenNotifications[i].businessId,
              title: unseenNotifications[i].title,
              details: unseenNotifications[i].details,
              regarding: unseenNotifications[i].regarding,
              status: unseenNotifications[i].status,
              date: unseenNotifications[i].date,
            });
          }
          const updatedDataSet = {
            notifications: seenNotifications,
          };
          return callUpdateUserProfile(userId, updatedDataSet)
            .then((unseenNotificationPayload) => {
              dispatch({
                type: UPDATE_UNSEEN_NOTIFICATION_TO_SEEN,
                payload: unseenNotificationPayload,
              });
            })
            .catch((err) => {
              dispatch({
                type: UPDATE_UNSEEN_NOTIFICATION_TO_SEEN_FAILED,
              });
            });
        } else {
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_UNSEEN_NOTIFICATION_TO_SEEN_FAILED,
        });
      });
  };
};
