import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MalwareNotCleanFiles from "./malwareNotCleanFiles";
import {
  callFindMalwareVulnerabilityByReportUrl,
  callOnMalwareCreateEmailHistory,
  callUpdatereviewMalwareVulnerability,
} from "../../services";
import ToastMessage from "../../components/toast/toastMessage";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class MalwareScanResultInModal extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      malwareInfoData: [],
      reportUrl: this.props.reportUrl,
      domainID: null,
      submitted: false,
      actionValue: "",
      displayButtonForReview: "none",
    };
  }
  async componentDidMount() {
    this._isMounted = true;
    let response = await callFindMalwareVulnerabilityByReportUrl(
      this.props.reportUrl
    );

    if (response.total > 0) {
      let malwareData = response.data[0].malwareVulnerabilities;
      let malwareInfoData = [];
      for (let j = 0; j < malwareData.length; j++) {
        let malInfo = { _id: malwareData._id };
        const domainInfo = malwareData[j];

        if (domainInfo.action !== "Ignored") {
          malInfo["fileName"] = domainInfo.fileName;
          malInfo["fileTypeName"] = domainInfo.fileType;
          malInfo["reason"] = domainInfo.reason;
          malInfo["details"] = domainInfo.details;
          malInfo["threat"] = domainInfo.threat;
          malInfo["scanStatus"] = domainInfo.threat;
          malInfo["scanFinish"] = domainInfo.date;
          malInfo["dump"] = domainInfo.dump;
          malInfo["actionStatus"] = domainInfo.action;
          malInfo["reportUrl"] = this.props.reportUrl;
          malInfo["_id"] = domainInfo._id;

          malwareInfoData.push(malInfo);
        }
      }
      if (this._isMounted) {
        this.setState({
          malwareInfoData,
        });
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  hideSendForMalware = () => {
    this.setState({
      displayButtonForReview: "none",
      submitted: false,
      actionValue: "",
    });
  };

  displaySendForMalware = () => {
    this.setState({
      displayButtonForReview: "block",
      submitted: false,
      actionValue: "",
    });
  };

  async onChangeSetMalwareVulnerability(actionVul) {
    let actionVulToast = actionVul;

    let vulReport = await callFindMalwareVulnerabilityByReportUrl(
      this.state.reportUrl
    );

    if (vulReport.data.length > 0) {
      let actionVulnerability = false;

      if (actionVul === "Sent for review") {
        actionVulnerability = true;
      } else {
        actionVulnerability = false;
      }

      const malwareVulnerabilityData = {
        sentForReview: actionVulnerability,
        dateSentForReview: Date.now,
      };

      callUpdatereviewMalwareVulnerability(
        vulReport.data[0]._id,
        malwareVulnerabilityData
      );
    }

    let userId = null;
    let userData = window.localStorage.getItem("userData");
    let userString = cryptr.decrypt(userData);
    let user = JSON.parse(userString);
    if (userData) {
      userId = user._id;
    }

    const awsAddress =
      "https://tg3malware.s3.amazonaws.com/" + this.state.reportUrl;
    let emailHistoryData = {
      action: "create_email_history_from_malware",
      businessId: this.props.businessID,
      userId: userId,
      customMessage: awsAddress,
    };

    await callOnMalwareCreateEmailHistory(emailHistoryData).then(
      (response) => {}
    );
    // }

    if (actionVulToast === "Sent for review") {
      this.setState({
        submitted: true,
        actionValue: "Support " + actionVulToast,
      });
    } else {
      this.setState({
        submitted: false,
        actionValue: "",
      });
    }
  }
  statusColor(status) {
    let detectionColor = "";
    let sendForReviewButton = "";
    let sendForReviewButtonConfirmation = "";

    if (this.props.sendForReview === true || this.state.submitted) {
      sendForReviewButton = (
        <p>
          <button
            className="btn btn-sm btn-outline-danger btn-140-width disabled"
            style={{
              borderRadius: "0.2rem",
              padding: "0.25rem 0.25rem",
            }}
          >
            Sent for Review
          </button>
        </p>
      );
      sendForReviewButtonConfirmation = "";
    } else {
      sendForReviewButton = (
        <p>
          <button
            className="btn btn-sm btn-outline-danger btn-140-width"
            style={{
              borderRadius: "0.2rem",
              padding: "0.25rem 0.25rem",
            }}
            onClick={this.displaySendForMalware}
          >
            Send for Review
          </button>
        </p>
      );
      sendForReviewButtonConfirmation = (
        <p
          style={{
            display: this.state.displayButtonForReview,
            color: "#000",
          }}
        >
          Submit this malware for scan for review? Submitting this malware scan<br />{" "}
          for review will send your results to Trust Guard so we can help you
          resolve<br /> this issue. We may require additional access like FTP
          <br />
          <br />
          <button
            type="button"
            onClick={() =>
              this.onChangeSetMalwareVulnerability("Sent for review")
            }
            className="btn-success btn-140-width btn-md mr5"
            style={{ marginRight: "10px" }}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn-tglight2 btn-140-width"
            onClick={this.hideSendForMalware}
          >
            No
          </button>
        </p>
      );
    }

    if (status === "malicious") {
      detectionColor = (
        <td className="text-danger">
          <p>
            <i className="fas fa-exclamation-circle" /> Threat: Malicious
          </p>
          {sendForReviewButton}
          {sendForReviewButtonConfirmation}
        </td>
      );
    } else if (status === "potentially suspicious") {
      detectionColor = (
        <td style={{ color: "orange" }}>
          <p>
            <i className="fas fa-exclamation-circle" /> Threat: Potentially
            Suspicious
          </p>
          {sendForReviewButton}
          {sendForReviewButtonConfirmation}
        </td>
      );
    } else if (status === "suspicious") {
      detectionColor = (
        <td style={{ color: "orange" }}>
          <p>
            <i className="fas fa-exclamation-circle" /> Threat: Suspicious
          </p>
          {sendForReviewButton}
          {sendForReviewButtonConfirmation}
        </td>
      );
    } else if (status === "clean") {
      detectionColor = (
        <td style={{ color: "#33981f" }}>
          <p>
            <i className="text-success fas fa-exclamation-circle" /> No
            Vulnerabilities Found
          </p>
          <p>&nbsp;</p>
        </td>
      );
    } else {
      detectionColor = (
        <td style={{ color: "#000" }}>
          <p>
            <i className="fas fa-exclamation-circle" /> Threat: Pending
          </p>
          <p>&nbsp;</p>
        </td>
      );
    }
    return detectionColor;
  }
  render() {
    let alert = null;
    if (this.state.submitted) {
      alert = (
        <ToastMessage type="success" notifyMessage={this.state.actionValue} />
      );
    }
    return (
      <tbody style={{ border: "1px solid #cccccc" }}>
        <tr>
          {this.statusColor(this.props.scanStatus)}
          <td>
            {alert}
            <p>
              <a
                href="/"
                className="pointer pull-right"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/malware-scanned-files", {
                    reportUrl: this.props.reportUrl,
                    domainID: this.props.domainID,
                    malwareHistoryID: this.props.malwareHistoryID,
                    isMalwareScannedFilesPage: true,
                  });
                }}
              >
                View All Scanned Files
              </a>
            </p>
            <p>&nbsp;</p>
          </td>
        </tr>
        {this.props.scanStatus !== "clean"
          ? this.state.malwareInfoData.map((i) => (
              <MalwareNotCleanFiles
                key={i._id}
                vulnerabilityId={i._id}
                history={this.props.history}
                fileName={i.fileName}
                reason={i.reason}
                details={i.details}
                actionStatus={i.actionStatus}
                fileTypeName={i.fileTypeName}
                threat={i.threat}
                scanFinish={i.scanFinish}
                dump={i.dump}
                reportUrl={this.props.reportUrl}
                malwareHistoryID={this.props.malwareHistoryID}
                scanStatus={this.props.scanStatus}
                changeClassNames={() => this.props.changeClassNames()}
              />
            ))
          : null}
      </tbody>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareScanResultInModal);
