import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import { images } from "../../helpers/images";

import WelcomePopup from "../../views/home/welcomePopup";

import BusinessHeader from "./partials/businessHeader";
import ScanResult from "./partials/scanResults";
import SlidePanels from "./partials/slidePanels";
import AppContext from "../context/appContext";
import BackDrop from "./partials/backDropVulnerabilityDetails";
import {
  callCreateBeyondScan,
  callApiGetBusinessSubdomainData,
} from "../../services/index";
import { callOnGetPciScanData } from "../../services/scanHistoryService";

class ManageYourDomains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAdmin: null,
      businesses: [],
      showCancelledAccountBanner: false,
      showBackDrop: false,
      vulnerabilityError: false,
      slidePanelsOption: {
        businessCount: 0,
        scanOptions: [],
        pci: [],
        malware: [],
        ssl: [],
      },
    };
  }

  async componentDidMount() {
    const { userBusinessData } = this.context;

    if (userBusinessData && userBusinessData.length > 0) {
      this.setState({
        isLoading: false,
        businesses: userBusinessData,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { userData, userBusinessData } = this.context;

    if (prevState.showBackDrop !== this.context.showBackDrop) {
      this.setState({ showBackDrop: this.context.showBackDrop });

      if (this.state.showBackDrop) {
        setTimeout(() => {
          this.setState({ vulnerabilityError: true });
        }, 70000);
      }
    }

    if (Object.keys(userData).length !== 0 && userData.constructor === Object) {
      if (prevState.isAdmin !== userData.userDetails.isAdmin) {
        this.setState({
          isAdmin: userData.userDetails.isAdmin,
        });
      }
    }

    if (userBusinessData.length > 0) {
      if (
        userBusinessData.length ===
        userBusinessData.filter((v) => {
          return v.status === "canceled";
        }).length
      ) {
        if (prevState.businesses !== userBusinessData) {
          this.setState({
            showCancelledAccountBanner: true,
          });
        }
      }

      const slidePanelsOption = { ...this.state.slidePanelsOption };

      if (prevState.businesses !== userBusinessData) {
        this.setState({
          isLoading: false,
          slidePanelsOption,
          businesses: userBusinessData,
        });
      }
    }

    if (prevState.businesses !== this.state.businesses) {
      if (this.state.businesses.length >= 1) {
        for (let x = 0; x < this.state.businesses.length; x++) {
          if (
            this.state.businesses.length !==
            this.state.slidePanelsOption.pci.length
          ) {
            this.pushSlidePanelObject(
              this.state.businesses.length === 1 ? true : false
            );

            continue;
          }
        }
      }

      if (this.state.businesses.length === 1) {
        const scanHistories = await callOnGetPciScanData(
          this.state.businesses[0]._id
        );
        const slidePanelsOption = { ...this.state.slidePanelsOption };
        slidePanelsOption.pci[0].scanCount = scanHistories.data.length;
        this.setState({ slidePanelsOption });
      }
    }
  }

  closeVulnerabilityModal = () => {
    this.setState({ vulnerabilityError: false, showBackDrop: false });
  };

  openPciPanelHandler = async (businessId, index) => {
    const scanHistories = await callOnGetPciScanData(businessId);
    const slidePanelsOption = { ...this.state.slidePanelsOption };
    slidePanelsOption.pci[index].scanCount = scanHistories.data.length;
    slidePanelsOption.pci[
      index
    ].goToVulnerabiltyDetails = this.goToVulnerabiltyDetails;
    this.setState({ slidePanelsOption });
    this.updateSlidePanel("pci", businessId, index);
  };

  openMalwarePanelHandler = (businessId, index) => {
    this.updateSlidePanel("malware", businessId, index);
  };

  openSslPanelHandler = (businessId, index) => {
    this.updateSlidePanel("ssl", businessId, index);
  };

  openScanOptionsPanelHandler = (businessId, index) => {
    this.updateSlidePanel("scanOptions", businessId, index);
    this.getDomains(businessId, index);
  };

  updateSlidePanel(slidePanel, businessId, index) {
    const slidePanelsOption = { ...this.state.slidePanelsOption };
    switch (slidePanel) {
      case "pci":
        slidePanelsOption.pci[index].isPciActive = !this.state.slidePanelsOption
          .pci[index].isPciActive;
        slidePanelsOption.ssl[index].isSslActive = false;
        slidePanelsOption.malware[index].isMalwareActive = false;
        slidePanelsOption.scanOptions[index].isOptionActive = false;
        slidePanelsOption.pci[index].businessId = businessId;
        break;
      case "malware":
        slidePanelsOption.malware[index].isMalwareActive = !this.state
          .slidePanelsOption.malware[index].isMalwareActive;
        slidePanelsOption.pci[index].isPciActive = false;
        slidePanelsOption.ssl[index].isSslActive = false;
        slidePanelsOption.scanOptions[index].isOptionActive = false;
        slidePanelsOption.malware[index].businessId = businessId;
        break;
      case "ssl":
        slidePanelsOption.ssl[index].isSslActive = !this.state.slidePanelsOption
          .ssl[index].isSslActive;
        slidePanelsOption.pci[index].isPciActive = false;
        slidePanelsOption.malware[index].isMalwareActive = false;
        slidePanelsOption.scanOptions[index].isOptionActive = false;
        slidePanelsOption.ssl[index].businessId = businessId;
        break;
      case "scanOptions":
        slidePanelsOption.scanOptions[index].isOptionActive = !this.state
          .slidePanelsOption.scanOptions[index].isOptionActive;
        slidePanelsOption.ssl[index].isSslActive = false;
        slidePanelsOption.pci[index].isPciActive = false;
        slidePanelsOption.malware[index].isMalwareActive = false;
        slidePanelsOption.ssl[index].businessId = businessId;
        break;
      default:
        slidePanelsOption.pci[index].isPciActive = !this.state.slidePanelsOption
          .pci[index].isPciActive;
        slidePanelsOption.ssl[index].isSslActive = false;
        slidePanelsOption.malware[index].isMalwareActive = false;
        slidePanelsOption.scanOptions[index].isOptionActive = false;
        slidePanelsOption.pci[index].businessId = businessId;
        break;
    }

    this.setState({ slidePanelsOption });
  }

  async getDomains(businessId, index) {
    const slidePanelsOption = { ...this.state.slidePanelsOption };
    const domains = await callApiGetBusinessSubdomainData(businessId);

    slidePanelsOption.scanOptions[index].domains = domains.data;
    this.setState({ slidePanelsOption });
  }

  pushSlidePanelObject(isSingleBusiness = true) {
    const slidePanelsOption = { ...this.state.slidePanelsOption };

    slidePanelsOption.pci.push({
      isPciActive: isSingleBusiness ? true : false,
      isPciScanning: false,
      domains: [],
      businessId: null,
      startPCIscan: this.startPCIscan,
      openPciPanel: this.openPciPanelHandler,
    });

    slidePanelsOption.scanOptions.push({
      isOptionActive: false,
      domains: [],
      openScanOptionsPanel: this.openScanOptionsPanelHandler,
    });

    slidePanelsOption.malware.push({
      isMalwareActive: false,
      businessId: null,
      openMalwarePanel: this.openMalwarePanelHandler,
    });

    slidePanelsOption.ssl.push({
      isSslActive: false,
      businessId: null,
      openSslPanel: this.openSslPanelHandler,
    });
  }

  startPCIscan = async (index, scanType = "first") => {
    const flag = scanType;
    const user = this.context.userData;
    const business = this.context.userBusinessData[index];
    const slidePanelsOption = { ...this.state.slidePanelsOption };
    if (user) {
      let userEmail = user.email;
      let userId = user._id;
      if (user.userDetails.isAdmin) {
        userEmail = user.userDetails.email;
        userId = user.userDetails._id;
      }

      slidePanelsOption.pci[index].isPciScanning = true;

      this.setState({
        slidePanelsOption,
        scanning: true,
        rescansubmitted: true,
        showPCIscanToast: true,
      });

      const beyondsecurity = await callCreateBeyondScan({
        _id: business._id,
        userId: userId,
        loginEmail: userEmail,
        supportEmail: business.supportEmail,
        businessName: business.businessName,
        primaryDomain: business.primaryDomain,
        scanFrequency: business.scanFrequency,
        supportPhone: business.supportPhone,
        state: business.state,
        zip: business.zip,
        address: business.address,
        address2: business.address2,
        city: business.state,
        country: business.country,
        flag: flag,
        purpose: "create-scan",
      });
      if (beyondsecurity.success) {
        window.location.reload();
      }
    }
  };

  showContentLoader() {
    return (
      <ContentLoader
        height={260}
        width={989}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#e1dfdf">
        <circle cx="70" cy="93" r="36" />
        <circle cx="891" cy="96" r="36" />
        <circle cx="696" cy="88" r="36" />
        <circle cx="480" cy="91" r="36" />
        <circle cx="273" cy="91" r="36" />
        <rect x="21" y="22" rx="0" ry="0" width="90" height="15" />
        <rect x="146" y="30" rx="0" ry="0" width="0" height="0" />
        <rect x="228" y="18" rx="0" ry="0" width="90" height="15" />
        <rect x="431" y="19" rx="0" ry="0" width="90" height="15" />
        <rect x="647" y="20" rx="0" ry="0" width="90" height="15" />
        <rect x="841" y="20" rx="0" ry="0" width="90" height="15" />
        <rect x="30" y="160" rx="0" ry="0" width="900" height="11" />
        <rect x="29" y="218" rx="0" ry="0" width="900" height="11" />
        <rect x="30" y="181" rx="0" ry="0" width="900" height="11" />
        <rect x="29" y="200" rx="0" ry="0" width="900" height="11" />
        <circle cx="958" cy="73" r="8" />
        <circle cx="958" cy="93" r="8" />
        <circle cx="958" cy="113" r="8" />
      </ContentLoader>
    );
  }

  showCanceledBanner() {
    return (
      <div className="text-center">
        <img src={images.customPopupAvatar} alt="Contact Support" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>
          <strong style={{ color: "gray" }}>
            This account has been cancelled.
            <br />
            <span
              className="pointer"
              onClick={() =>
                window.open("https://trustguard.com/contact-us/", "_blank")
              }
              style={{ color: "#268226" }}>
              Please contact support to reactivate it.
            </span>
          </strong>
        </h1>
      </div>
    );
  }

  showAdminBanner() {
    return (
      <div className="text-center">
        <i
          className="far fa-cogs"
          style={{
            fontSize: "20.5em",
            color: "gray",
            marginBottom: "50px",
            marginTop: "50px",
          }}
        />
        <h1>
          <strong style={{ color: "gray" }}>
            <span
              className="pointer"
              onClick={() => this.props.goToPageControlPanel()}
              style={{ color: "#268226" }}>
              View
            </span>{" "}
            Your Admin
            <br /> Control Panel
          </strong>
        </h1>
      </div>
    );
  }

  showScanContent(businesses, slidePanelsOption) {
    return businesses && businesses.length > 0
      ? businesses.map((business, businessIndex) => {
          return business.status != "canceled" ? (
            <div className="business-domain-results" key={business._id}>
              {/* FALSE POSITIVE */}
              {this.state.showBackDrop ? (
                <BackDrop
                  vulnerabilityError={this.state.vulnerabilityError}
                  closeModal={this.closeVulnerabilityModal}
                />
              ) : null}
              <BusinessHeader businessId={business._id} />
              <ScanResult
                index={businessIndex}
                businessId={business._id}
                value={slidePanelsOption}
              />
              <SlidePanels
                businessId={business._id}
                index={businessIndex}
                value={slidePanelsOption}
              />
            </div>
          ) : (
            ""
          );
        })
      : "";
  }

  render() {
    const {
      isLoading,
      isAdmin,
      businesses,
      showCancelledAccountBanner,
      slidePanelsOption,
    } = this.state;

    return (
      <div id="manage-your-domains">
        {isLoading === true && isAdmin !== true ? (
          this.showContentLoader()
        ) : isLoading === true && isAdmin === true ? (
          this.showAdminBanner()
        ) : showCancelledAccountBanner === true ? (
          this.showCanceledBanner()
        ) : (
          <div id="overview" className="tab-pane fade in active">
            <h4>MANAGE YOUR SCANS</h4>
            {this.showScanContent(businesses, slidePanelsOption)}

            <WelcomePopup />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPageControlPanel: () => push("/control-panel"),
    },
    dispatch
  );

ManageYourDomains.contextType = AppContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageYourDomains);
