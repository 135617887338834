import React from "react";
import "./reportButton.css";

const scanStatusButton = (props) => {
  let label = "";
  /*eslint-disable*/
  let btnStyle = "btn-outline-success";
  let disabledButton = false;
  let faStyle = "bg-success";
  let faIcon = "fa-file-times";
  let bgColor = null;
  let btnStatus = "btnPass";
  switch (props.status) {
    case "fail":
      label = "Failed Scan Report";
      btnStyle = "btn-outline-danger";
      faStyle = "bg-danger";
      btnStatus = "btnFail";
      break;
    case "pass":
      label = "Passed Scan Report";
      faIcon = "fa-file-check";
      btnStatus = "btnPass";
      break;
    case "running":
      label = "Running";
      btnStatus = "btnRunning";
      disabledButton = true;
      faIcon = "fa-sync-alt";
      break;
    case "finalizing":
      label = "Finalizing";
      disabledButton = true;
      faIcon = "fa-sync-alt";
      break;
    case "invalid":
      label = "Invalid URL";
      disabledButton = true;
      btnStyle = "";
      faStyle = "";
      bgColor = "rgb(127, 127, 127)";
      faIcon = "fa-clock";
      break;
    default:
      label = "Pending";
      disabledButton = true;
      btnStyle = "";
      btnStatus = "btnPending";
      faStyle = "";
      bgColor = "rgb(127, 127, 127)";
      faIcon = "fa-clock";

      break;
  }

  const spanIcon = {
    backgroundColor: "#28a745",
    padding: "0.3rem",
    color: "#ffffff",
    marginRight: "-1px",
    marginTop: "-1px",
  };

  let hideDashIcon = false;

  if (props.status === "invalid") {
    hideDashIcon = true;
  }
  /*eslint-enable*/

  return (
    <>
      <button
        className={btnStatus}
        data-toggle="modal"
        disabled={disabledButton}
        onClick={() => props.selectedReport(props.index)}>
        {label}
      </button>
    </>
  );
};

export default scanStatusButton;
