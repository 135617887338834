import React from "react";
import Aux from "../../../hoc/containerAux";

const modalButton = (props) => {
  let classes = ["btn-tglight2a"];
  if (props.large) {
    classes.push("btn", "btn-success", "btn-lg");
    classes.shift("btn-tglight2a");
  }
  if (props.small) {
    classes.push("btn", "btn-success", "btn-sm");
    classes.shift("btn-tglight2a");
  }
  return (
    <Aux>
      <button
        data-toggle="modal"
        data-target={"#" + props.target}
        className={classes.join(" ")}
      >
        {props.label}
      </button>
    </Aux>
  );
};

export default modalButton;
