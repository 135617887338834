import * as Client from "../../services/index";
import * as ActionType from "../types/index";

export const addNewDomain = (domain) => {
  return async (dispatch) => {
    dispatch({
      type: ActionType.ADD_NEW_DOMAIN,
      payload: domain,
    });
    return Client.api
      .service("domains")
      .create(domain)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return e;
      });
  };
};

export const deleteSubDomain = (domainID, info) => {
  return async (dispatch) => {
    dispatch({
      type: ActionType.REMOVE_NEW_DOMAIN,
      payload: domainID,
    });

    return Client.api.service("domains").patch(
      domainID,
      {
        deleted: true,
        deletedAt: Date.now(),
      },
      {
        query: {
          deleted: false,
          _id: info.id,
          businessId: info.businessId,
          isSubDomain: true,
        },
      }
    );
  };
};

export const getSubDomainsByID = (id) => {
  return async (dispatch) => {
    return Client.callOnGetSubDomains(id).then((domains) => {
      dispatch({
        type: ActionType.GET_SUBDOMAINS,
        payload: domains,
      });
    });
  };
};

export const getDomainsByBusinessId = (id) => {
  return async (dispatch) => {
    return Client.callOnGetSubDomains(id).then((domains) => {
      dispatch({
        type: ActionType.GET_SUBDOMAINS,
        payload: domains.data,
      });
    });
  };
};

export const rescanDomainSsl = (id) => {
  return async (dispatch) => {
    return Client.callOnUpdateDomainUsingId(id).then((domain) => {
      dispatch({
        type: ActionType.UPDATE_DOMAIN_SSL_INFO_NEXT_CHECK_DATE,
        payload: domain,
      });
    });
  };
};

export const updateDomainPingDetails = (id, data) => {
  return async (dispatch) => {
    return Client.callOnUpdateDomainPingDetails(id, data).then((domain) => {
      dispatch({
        type: ActionType.UPDATE_DOMAIN_SSL_INFO_NEXT_CHECK_DATE,
        payload: domain,
      });
    });
  };
};

export const removeNewDomain = (id) => ({
  type: ActionType.DELETE_NEW_DOMAIN,
  payload: id,
});
