// export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_SUCCESS_REDIRECT_TO_CONTROL_PANEL =
  "auth/LOGIN_SUCCESS_REDIRECT_TO_CONTROL_PANEL";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const RESET_MESSAGES = "reset_messages/RESET_MESSAGES";
export const GET_FORGET_PASSWORD_CODE_SUCCESS =
  "forgetPassword/GET_FORGET_PASSWORD_CODE_SUCCESS";
export const GET_FORGET_PASSWORD_CODE_FAILED =
  "forgetPassword/GET_FORGET_PASSWORD_CODE_FAILED";
export const CREATE_USER_ACCOUNT_SUCCESS =
  "create_user_account/CREATE_USER_ACCOUNT_SUCCESS";
export const UPDATE_USER_ACCOUNT_SUCCESS =
  "create_user_account/UPDATE_USER_ACCOUNT_SUCCESS";
export const CREATE_BUSINESS_ACCOUNT_SUCCESS =
  "create_business_success/CREATE_BUSINESS_ACCOUNT_SUCCESS";
export const NOTE_CREATED_SUCCESS =
  "create_business_note_success/NOTE_CREATED_SUCCESS";
export const NOTE_CREATED_FAILED =
  "create_business_note_success/NOTE_CREATED_FAILED";
export const CREATE_USER_ACCOUNT_FAILED =
  "create_User_account/CREATE_USER_ACCOUNT_FAILED";
export const CREATE_ACCOUNT_FAILED =
  "create_User_account/CREATE_ACCOUNT_FAILED";
export const GET_ALL_BUSINESS_SUCCESS = "get_business/GET_ALL_BUSINESS_SUCCESS";
export const GET_ALL_BUSINESS_FAILED = "get_business/GET_ALL_BUSINESS_FAILED";
export const UPDATE_BUSINESS_SUCCESS =
  "update_business/UPDATE_BUSINESS_SUCCESS";
export const UPDATE_BUSINESS_FAILED = "update_business/UPDATE_BUSINESS_FAILED";
export const UPDATE_BUSINESS_FREQUENCY = "UPDATE_BUSINESS_FREQUENCY";
export const UPDATE_BUSINESS_SCAN_REPORT = "UPDATE_BUSINESS_SCAN_REPORT";
export const UPDATE_DOMAIN_SUCCESS = "update_domain/UPDATE_DOMAIN_SUCCESS";
export const GET_ALL_USERS_SUCCESS = "get_all_users/GET_ALL_USERS_SUCCESS";
export const GET_ONE_USER_SUCCESS = "get_all_users/GET_ONE_USER_SUCCESS";
export const GET_ALL_USERS_FAILED = "get_all_users/GET_ALL_USERS_FAILED";
export const USER_CREATE_SUCCESS = "create_users/USER_CREATE_SUCCESS";
export const USER_CREATE_FAILED = "create_users/USER_CREATE_FAILED";
export const GET_ONE_BUSINESS_SUCCESS =
  "get_one_business/GET_ONE_BUSINESS_SUCCESS";
export const GET_ONE_BUSINESS_FAILED =
  "get_one_business/GET_ONE_BUSINESS_FAILED";
export const USER_ROLES_UPDATE_SUCCESS =
  "update_user_roles/USER_ROLES_UPDATE_SUCCESS";
export const UPDATE_USER_PROFILE_SUCCESS =
  "update_user_profile/UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILED =
  "update_user_profile/UPDATE_USER_PROFILE_FAILED";
export const UPDATE_USER_PASSWORD_SUCCESS =
  "update_user_password/UPDATE_USER_PASSWORD_SUCCESS";
// export const UPDATE_PASSWORD_SUCCESS = "update_user_password/UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILED =
  "update_user_password/UPDATE_PASSWORD_FAILED";
export const FIND_LOCKED_USERS_SUCCESS =
  "find_locked_user/FIND_LOCKED_USERS_SUCCESS";
export const FIND_LOCKED_USERS_FAILED =
  "find_locked_user/FIND_LOCKED_USERS_FAILED";
export const USER_UNLOCKED_SUCCESS = "unlocked_user/USER_UNLOCKED_SUCCESS";
export const USER_UNLOCKED_FAILED = "unlocked_user/USER_UNLOCKED_FAILED";
export const USER_ACCOUNT_LOCKED_SUCCESS =
  "locked_user/USER_ACCOUNT_LOCKED_SUCCESS";
export const SEARCH_BY_ITEM_SUCCESS = "search_by/SEARCH_BY_ITEM_SUCCESS";
export const SEARCH_BY_ITEM_FAILED = "search_by/SEARCH_BY_ITEM_FAILED";
export const CREATE_DOMAIN_SUCCESS = "create_domain/CREATE_DOMAIN_SUCCESS";
export const GET_DOMAINS_SUCCESS = "get_domain/GET_DOMAINS_SUCCESS";
export const DELETE_SUBDOMAIN_SUCCESS = "domain/DELETE_SUBDOMAIN_SUCCESS";
export const GET_BUSINESSES_SUCCESS =
  "control-panel/advanced-search/GET_BUSINESSES_SUCCESS";
export const GET_BUSINESSES_FAILED =
  "control-panel/advanced-search/GET_BUSINESSES_FAILED";
export const INSURANCE_CREATE_SUCCESS =
  "create_insurance/INSURANCE_CREATE_SUCCESS";
export const INSURANCE_CREATE_FAILED =
  "create_insurance/INSURANCE_CREATE_FAILED";
export const ADD_NEW_DOMAIN = "domain/ADD_NEW_DOMAIN";
export const REMOVE_NEW_DOMAIN = "domain/REMOVE_NEW_DOMAIN";
export const DELETE_NEW_DOMAIN = "domain/DELETE_NEW_DOMAIN";
export const GET_SUBDOMAINS = "domain/GET_SUBDOMAINS";
export const ADD_NEW_SCAN = "scan/ADD_NEW_SCAN";
export const GET_BUSINESS_ID = "business/GET_BUSINESS_ID";
export const GET_PENDING_SCAN_BUSINESS_SUCCESS =
  "business/GET_PENDING_SCAN_BUSINESS_SUCCESS";
export const UPDATE_UNSEEN_NOTIFICATION_TO_SEEN =
  "notification/UPDATE_UNSEEN_NOTIFICATION_TO_SEEN";
export const UPDATE_UNSEEN_NOTIFICATION_TO_SEEN_FAILED =
  "notification/UPDATE_UNSEEN_NOTIFICATION_TO_SEEN_FAILED";
export const USER_NOTIFICATION_CREATE_SUCCESS =
  "notification/USER_NOTIFICATION_CREATE_SUCCESS";
export const UPDATE_BUSINESS_CERTIFICATE_SUCCESS =
  "trust-seals/UPDATE_BUSINESS_CERTIFICATE_SUCCESS";
export const UPDATE_BUSINESS_CERTIFICATE_FAILED =
  "trust-seals/UPDATE_BUSINESS_CERTIFICATE_FAILED";
export const EMAIL_CREATED_SUCCESS = "emails/EMAIL_CREATED_SUCCESS";
export const EMAIL_CREATED_FAILED = "emails/EMAIL_CREATED_FAILED";
export const GET_ALL_EMAILS_SUCCESS = "emails/GET_ALL_EMAILS_SUCCESS";
export const GET_ALL_EMAILS_FAILED = "emails/GET_ALL_EMAILS_FAILED";
export const UPDATE_EMAIL_SUCCESS = "emails/UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILED = "emails/UPDATE_EMAIL_FAILED";
export const INACTIVE_EMAIL_SUCCESS = "emails/INACTIVE_EMAIL_SUCCESS";
export const UPDATE_DOMAIN_SSL_INFO_NEXT_CHECK_DATE =
  "domain/UPDATE_DOMAIN_SSL_INFO_NEXT_CHECK_DATE";
export const GET_DOMAINS_USING_STATUS_SUCCESS =
  "domains/GET_DOMAINS_USING_STATUS_SUCCESS";
export const GET_DOMAINS_USING_STATUS_FALIURE =
  "domains/GET_DOMAINS_USING_STATUS_FALIURE";
export const GET_CHECK_SCAN_REPORTS_SUCCESS =
  "verification/GET_CHECK_SCAN_REPORTS_SUCCESS";
export const GET_CHECK_SCAN_REPORTS_FALIURE =
  "verification/GET_CHECK_SCAN_REPORTS_FALIURE";
export const GET_USER_LIST_USING_BUSINESS_ID_AND_ROLES_SUCCESS =
  "users/GET_USER_LIST_USING_BUSINESS_ID_AND_ROLES_SUCCESS";
export const GET_USER_LIST_USING_BUSINESS_ID_AND_ROLES_FALIURE =
  "users/GET_USER_LIST_USING_BUSINESS_ID_AND_ROLES_FALIURE";
export const GET_USER_LIST_USING_BUSINESS_ID_SUCCESS =
  "users/GET_USER_LIST_USING_BUSINESS_ID_SUCCESS";
export const GET_USER_LIST_USING_BUSINESS_ID_FALIURE =
  "users/GET_USER_LIST_USING_BUSINESS_ID_FALIURE";
export const UPDATE_SKIPPED_MEMBER_SUCCESS =
  "skipped-member/UPDATE_SKIPPED_MEMBER_SUCCESS";
export const UPDATE_SKIPPED_MEMBER_FALIURE =
  "skipped-member/UPDATE_SKIPPED_MEMBER_FALIURE";
export const GET_TRUSTSEAL_SUCCESS = "trustseals/GET_TRUSTSEAL_SUCCESS";
export const GET_TRUSTSEAL_FALIURE = "trustseals/GET_TRUSTSEAL_FALIURE";
export const CREATE_TRUSTSEAL_SUCCESS = "trustseals/CREATE_TRUSTSEAL_SUCCESS";
export const CREATE_TRUSTSEAL_FALIURE = "trustseals/CREATE_TRUSTSEAL_FALIURE";
export const UPDATE_TRUSTSEAL_SUCCESS = "trustseals/UPDATE_TRUSTSEAL_SUCCESS";
export const UPDATE_TRUSTSEAL_FALIURE = "trustseals/UPDATE_TRUSTSEAL_FALIURE";
export const GET_INSURANCE_SUCCESS = "insurance/GET_INSURANCE_SUCCESS";
export const GET_INSURANCE_FAILURE = "insurance/GET_INSURANCE_FAILURE";
export const CREATE_AUTO_RENEWING_SSL_SUCCESS =
  "autoRenewingSSL/CREATE_AUTO_RENEWING_SSL_SUCCESS";
export const CREATE_AUTO_RENEWING_SSL_FAILED =
  "autoRenewingSSL/CREATE_AUTO_RENEWING_SSL_FAILED";
export const GET_AUTO_RENEWING_SSL_SUCCESS =
  "autoRenewingSSL/GET_AUTO_RENEWING_SSL_SUCCESS";
export const GET_AUTO_RENEWING_SSL_FAILED =
  "autoRenewingSSL/GET_AUTO_RENEWING_SSL_FAILED";
export const SEALS_DATA_UPDATED_SUCCESS = "seals/SEALS_DATA_UPDATED_SUCCESS";
export const SEALS_DATA_UPDATED_FAILURE = "seals/SEALS_DATA_UPDATED_FAILURE";
export const GET_BUSINESS_USING_PRIMARY_DOMAIN_SUCCESS =
  "certificate/GET_BUSINESS_USING_PRIMARY_DOMAIN_SUCCESS";
export const GET_BUSINESS_USING_PRIMARY_DOMAIN_FAILED =
  "certificate/GET_BUSINESS_USING_PRIMARY_DOMAIN_FAILED";
export const UPDATE_BUSINESS_CERTIFICATE_CONTACT_SUCCESS =
  "certificate/UPDATE_BUSINESS_CERTIFICATE_CONTACT_SUCCESS";
export const UPDATE_BUSINESS_CERTIFICATE_CONTACT_FAILED =
  "certificate/UPDATE_BUSINESS_CERTIFICATE_CONTACT_FAILED";
export const GET_EMAIL_HISTORIES_SUCCESS =
  "emailStatistics/GET_EMAIL_HISTORIES_SUCCESS";
export const GET_EMAIL_HISTORIES_FALIURE =
  "emailStatistics/GET_EMAIL_HISTORIES_FALIURE";
export const UPDATE_WELCOME_POPUP_SUCCESS =
  "welcomePopup/UPDATE_WELCOME_POPUP_SUCCESS";
export const UPDATE_WELCOME_POPUP_FAILURE =
  "welcomePopup/UPDATE_WELCOME_POPUP_FAILURE";
export const GET_EMAIL_HISTORY_DETAIL_SUCCESS =
  "emailStatModal/GET_EMAIL_HISTORY_DETAIL_SUCCESS";
export const GET_EMAIL_HISTORY_DETAIL_FAILURE =
  "emailStatModal/GET_EMAIL_HISTORY_DETAIL_FAILURE";
export const DELETE_USER_SUCCESS = "deleteUser/DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "deleteUser/DELETE_USER_FAILURE";
export const RESOLVED_NOTE_SAVE_SUCCESS =
  "resolvedNote/RESOLVED_NOTE_SAVE_SUCCESS";
export const RESOLVED_NOTE_SAVE_FAILURE =
  "resolvedNote/RESOLVED_NOTE_SAVE_FAILURE";
export const BUSINESS_USERS_SUCCESS = "businessUsers/BUSINESS_USERS_SUCCESS";
export const BUSINESS_USERS_FAILURE = "businessUsers/BUSINESS_USERS_FAILURE";
export const UPDATE_USER_DATA_SUCCESS =
  "updateUserData/UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAILURE =
  "updateUserData/UPDATE_USER_DATA_FAILURE";
// export const UPDATE_BUSINESS_DATA_SUCCESS =
//   "updateBusinessData/UPDATE_BUSINESS_DATA_SUCCESS";
// export const UPDATE_BUSINESS_DATA_FAILURE =
//   "updateBusinessData/UPDATE_BUSINESS_DATA_FAILURE";
export const GET_INSURANCE_INDUSTRY_LIST_SUCCESS =
  "insuranceData/GET_INSURANCE_INDUSTRY_LIST_SUCCESS";
export const GET_INSURANCE_INDUSTRY_LIST_FAILURE =
  "insuranceData/GET_INSURANCE_INDUSTRY_LIST_FAILURE";
export const POST_INSURANCE_DATA_SUCCESS =
  "insuranceData/POST_INSURANCE_DATA_SUCCESS";
export const POST_INSURANCE_DATA_FAILURE =
  "insuranceData/POST_INSURANCE_DATA_FAILURE";
export const UPDATE_APP_SIDEBAR_MENUS =
  "updateSidebar/UPDATE_APP_SIDEBAR_MENUS";
export const GET_INSURANCE_HISTORIES_SUCCESS =
  "insuranceReport/GET_INSURANCE_HISTORIES_SUCCESS";
export const GET_INSURANCE_HISTORIES_FALIURE =
  "insuranceReport/GET_INSURANCE_HISTORIES_FALIURE";
export const CREATE_CSS_HASH_SUCCESS = "sealScripts/CREATE_CSS_HASH_SUCCESS";
export const CREATE_CSS_HASH_FAILURE = "sealScripts/CREATE_CSS_HASH_FAILURE";
export const CREATE_JS_HASH_SUCCESS = "sealScripts/CREATE_JS_HASH_SUCCESS";
export const CREATE_JS_HASH_FAILURE = "sealScripts/CREATE_JS_HASH_FAILURE";
export const SEND_REPORT_EMAIL_SUCCESS =
  "sendReportEmail/SEND_REPORT_EMAIL_SUCCESS";
export const SEND_REPORT_EMAIL_FAILURE =
  "sendReportEmail/SEND_REPORT_EMAIL_FAILURE";
export const GET_ALL_CSS_JS_HASH_SUCCESS =
  "cssJsHashList/GET_ALL_CSS_JS_HASH_SUCCESS";
export const GET_ALL_CSS_JS_HASH_FAILURE =
  "cssJsHashList/GET_ALL_CSS_JS_HASH_FAILURE";
export const CREATE_ONE_TIME_EMAIL_SUCCESS =
  "oneTimeEMail/CREATE_ONE_TIME_EMAIL_SUCCESS";
export const CREATE_ONE_TIME_EMAIL_FAILURE =
  "oneTimeEMail/CREATE_ONE_TIME_EMAIL_FAILURE";
export const GET_ONE_TIME_EMAIL_SUCCESS =
  "oneTimeEmailList/GET_ONE_TIME_EMAIL_SUCCESS";
export const GET_ONE_TIME_EMAIL_FAILURE =
  "oneTimeEmailList/GET_ONE_TIME_EMAIL_FAILURE";
export const GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_SUCCESS =
  "otherMessages/GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_SUCCESS";
export const GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_FAILURE =
  "otherMessages/GET_ALL_BUSINESS_USERS_FOR_OTHER_MESSAGES_FAILURE";
export const GET_OLD_FREE_BUSINESS_LIST_SUCCESS =
  "freeBusinessList/GET_OLD_FREE_BUSINESS_LIST_SUCCESS";
export const GET_OLD_FREE_BUSINESS_LIST_FAILURE =
  "freeBusinessList/GET_OLD_FREE_BUSINESS_LIST_FAILURE";
export const REGENERATE_ALL_SEALS_SUCCESS =
  "seals/REGENERATE_ALL_SEALS_SUCCESS";
export const REGENERATE_ALL_SEALS_FAILURE =
  "seals/REGENERATE_ALL_SEALS_FAILURE";
export const GET_SMS_HISTORIES_SUCCESS = "smsStat/GET_SMS_HISTORIES_SUCCESS";
export const GET_SMS_HISTORIES_FAILURE = "smsStat/GET_SMS_HISTORIES_FAILURE";
export const GET_SMS_HISTORY_DETAIL_SUCCESS =
  "smsDetailsStat/GET_SMS_HISTORY_DETAIL_SUCCESS";
export const GET_SMS_HISTORY_DETAIL_FAILURE =
  "smsDetailsStat/GET_SMS_HISTORY_DETAIL_FAILURE";
export const GET_ALL_SSL_RENEWAL_DOMAINS_SUCCESS =
  "renewSsl/GET_ALL_SSL_RENEWAL_DOMAINS_SUCCESS";
export const GET_ALL_SSL_RENEWAL_DOMAINS_FAILURE =
  "renewSsl/GET_ALL_SSL_RENEWAL_DOMAINS_FAILURE";
export const UPDATE_SSL_RENEWAL_DOMAINS_SUCCESS =
  "renewSsl/UPDATE_SSL_RENEWAL_DOMAINS_SUCCESS";
export const UPDATE_SSL_RENEWAL_DOMAINS_FAILURE =
  "renewSsl/UPDATE_SSL_RENEWAL_DOMAINS_FAILURE";
export const UPDATE_ONBOARDING_POPUP_DATA_SUCCESS =
  "onBoardingPopupData/UPDATE_ONBOARDING_POPUP_DATA_SUCCESS";
export const UPDATE_ONBOARDING_POPUP_DATA_FAILURE =
  "onBoardingPopupData/UPDATE_ONBOARDING_POPUP_DATA_FAILURE";
export const SEND_TEST_EMAIL_SUCCESS = "sendTestEmail/SEND_TEST_EMAIL_SUCCESS";
export const SEND_TEST_EMAIL_FAILURE = "sendTestEmail/SEND_TEST_EMAIL_FAILURE";
export const UPDATE_ACCOUNT_4X_DETAILS_SUCCESS =
  "4xAccount/UPDATE_ACCOUNT_4X_DETAILS_SUCCESS";
export const UPDATE_ACCOUNT_4X_DETAILS_FAILURE =
  "4xAccount/UPDATE_ACCOUNT_4X_DETAILS_FAILURE";
export const SAVE_SMS_TIMING_SUCCESS = "smsTiming/SAVE_SMS_TIMING_SUCCESS";
export const SAVE_SMS_TIMING_FAILURE = "smsTiming/SAVE_SMS_TIMING_FAILURE";

export const DELETE_TRUSTSEALS_SUCCESS = "trustseals/DELETE_TRUSTSEALS_SUCCESS";
export const DELETE_TRUSTSEALS_FAILURE = "trustseals/DELETE_TRUSTSEALS_FAILURE";

/* Refactored */

/* Auth */
export const LOGIN_ACCOUNT_LOCKED = "auth/LOGIN_ACCOUNT_LOCKED";
export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "auth/LOGIN_FAILURE";

export const RESET_PASSWORD_SUCCESS = "auth/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "auth/RESET_PASSWORD_FAILURE";

export const UPDATE_PASSWORD_SUCCESS = "auth/UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "auth/UPDATE_PASSWORD_FAILURE";
/* Auth */

/* User data*/
export const GET_LOGGED_IN_USER_DATA_SUCCESS =
  "user/GET_LOGGED_IN_USER_DATA_SUCCESS";
export const GET_LOGGED_IN_USER_DATA_FAILURE =
  "user/GET_LOGGED_IN_USER_DATA_FAILURE";

export const GET_USER_DATA_SUCCESS = "user/GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "user/GET_USER_DATA_FAILURE";

export const UPDATE_USER_NOTIFICATION_DATA_SUCCESS =
  "user/UPDATE_USER_NOTIFICATION_DATA_SUCCESS";
export const UPDATE_USER_NOTIFICATION_DATA_FAILURE =
  "user/UPDATE_USER_NOTIFICATION_DATA_FAILURE";

export const UPDATE_USER_PROFILE_DATA_SUCCESS =
  "user/UPDATE_USER_PROFILE_DATA_SUCCESS";
export const UPDATE_USER_PROFILE_DATA_FAILURE =
  "user/UPDATE_USER_PROFILE_DATA_FAILURE";

/* User data*/

/* Business data*/
export const GET_USER_BUSINESS_DATA_SUCCESS =
  "userBusinessData/GET_USER_BUSINESS_DATA_SUCCESS";
export const GET_USER_BUSINESS_DATA_FAILURE =
  "userBusinessData/GET_USER_BUSINESS_DATA_FAILURE";

export const GET_BUSINESS_DATA_SUCCESS =
  "businessData/GET_BUSINESS_DATA_SUCCESS";
export const GET_BUSINESS_DATA_FAILURE =
  "businessData/GET_BUSINESS_DATA_FAILURE";

export const UPDATE_BUSINESS_DATA_SUCCESS =
  "businessData/UPDATE_BUSINESS_DATA_SUCCESS";
export const UPDATE_BUSINESS_DATA_FAILURE =
  "businessData/UPDATE_BUSINESS_DATA_FAILURE";

export const CREATE_BUSINESS_DATA_SUCCESS =
  "businessData/CREATE_BUSINESS_DATA_SUCCESS";
export const CREATE_BUSINESS_DATA_FAILURE =
  "businessData/CREATE_BUSINESS_DATA_FAILURE";

export const CREATE_BUSINESS_USER_DATA_SUCCESS =
  "businessUser/CREATE_BUSINESS_USER_DATA_SUCCESS";
export const CREATE_BUSINESS_USER_DATA_FAILURE =
  "businessUser/CREATE_BUSINESS_USER_DATA_FAILURE";

export const DELETE_BUSINESS_USER_DATA_SUCCESS =
  "businessUser/DELETE_BUSINESS_USER_DATA_SUCCESS";
export const DELETE_BUSINESS_USER_DATA_FAILURE =
  "businessUser/DELETE_BUSINESS_USER_DATA_FAILURE";

export const CREATE_BUSINESS_NOTES_SUCCESS =
  "businessUser/CREATE_BUSINESS_NOTES_SUCCESS";
export const CREATE_BUSINESS_NOTES_FAILURE =
  "businessUser/CREATE_BUSINESS_NOTES_FAILURE";
/* Business data*/

/* Domain data */
export const GET_BUSINESS_SUBDOMAIN_DATA_SUCCESS =
  "businessSubdomainData/GET_BUSINESS_SUBDOMAIN_DATA_SUCCESS";
export const GET_BUSINESS_SUBDOMAIN_DATA_FAILURE =
  "businessSubdomainData/GET_BUSINESS_SUBDOMAIN_DATA_FAILURE";
/* Domain data */

/* Trustseals data */
export const GET_BUSINESS_TRUSTSEALS_DATA_SUCCESS =
  "business/GET_BUSINESS_TRUSTSEALS_DATA_SUCCESS";
export const GET_BUSINESS_TRUSTSEALS_DATA_FAILURE =
  "business/GET_BUSINESS_TRUSTSEALS_DATA_FAILURE";

export const CREATE_BUSINESS_TRUSTSEALS_DATA_SUCCESS =
  "business/CREATE_BUSINESS_TRUSTSEALS_DATA_SUCCESS";
export const CREATE_BUSINESS_TRUSTSEALS_DATA_FAILURE =
  "business/CREATE_BUSINESS_TRUSTSEALS_DATA_FAILURE";

export const UPDATE_BUSINESS_TRUSTSEALS_DATA_SUCCESS =
  "business/UPDATE_BUSINESS_TRUSTSEALS_DATA_SUCCESS";
export const UPDATE_BUSINESS_TRUSTSEALS_DATA_FAILURE =
  "business/UPDATE_BUSINESS_TRUSTSEALS_DATA_FAILURE";

export const REMOVE_BUSINESS_TRUSTSEALS_DATA_SUCCESS =
  "business/REMOVE_BUSINESS_TRUSTSEALS_DATA_SUCCESS";
export const REMOVE_BUSINESS_TRUSTSEALS_DATA_FAILURE =
  "business/REMOVE_BUSINESS_TRUSTSEALS_DATA_FAILURE";

export const DELETE_CANCELED_TRUSTSEALS_SUCCESS =
  "business/DELETE_CANCELED_TRUSTSEALS_SUCCESS";

export const DELETE_CANCELED_TRUSTSEALS_FAILURE =
  "business/DELETE_CANCELED_TRUSTSEALS_FAILURE";
/* Trustseals data */

/* Buyer protection */
export const CREATE_BUYER_PROTECTION_ACCOUNT_SUCCESS =
  "buyerProtection/CREATE_BUYER_PROTECTION_ACCOUNT_SUCCESS";
export const CREATE_BUYER_PROTECTION_ACCOUNT_FAILURE =
  "buyerProtection/CREATE_BUYER_PROTECTION_ACCOUNT_FAILURE";
/* Buyer protection */

/* Control panel */

export const GET_CHECK_SCAN_REPORTS_PENDING_DOMAINS_DATA_SUCCESS =
  "controlPanel/GET_CHECK_SCAN_REPORTS_PENDING_DOMAINS_DATA_SUCCESS";
export const GET_CHECK_SCAN_REPORTS_PENDING_DOMAINS_DATA_FAILURE =
  "controlPanel/GET_CHECK_SCAN_REPORTS_PENDING_DOMAINS_DATA_FAILURE";

/* Emails */
export const GET_EMAIL_DATA_SUCCESS = "controlPanel/GET_EMAIL_DATA_SUCCESS";
export const GET_EMAIL_DATA_FAILURE = "controlPanel/GET_EMAIL_DATA_FAILURE";
export const CREATE_EMAIL_DATA_SUCCESS =
  "controlPanel/CREATE_EMAIL_DATA_SUCCESS";
export const CREATE_EMAIL_DATA_FAILURE =
  "controlPanel/CREATE_EMAIL_DATA_FAILURE";
export const UPDATE_EMAIL_DATA_SUCCESS =
  "controlPanel/UPDATE_EMAIL_DATA_SUCCESS";
export const UPDATE_EMAIL_DATA_FAILURE =
  "controlPanel/UPDATE_EMAIL_DATA_FAILURE";
/* Emails */

/* Control panel */
