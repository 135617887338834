import {
  RESET_MESSAGES,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_REDIRECT_TO_CONTROL_PANEL,
  LOGIN_FAILED,
  GET_FORGET_PASSWORD_CODE_SUCCESS,
  GET_FORGET_PASSWORD_CODE_FAILED,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  FIND_LOCKED_USERS_SUCCESS,
  FIND_LOCKED_USERS_FAILED,
  USER_UNLOCKED_SUCCESS,
  USER_UNLOCKED_FAILED,
  USER_ACCOUNT_LOCKED_SUCCESS,
} from "../types/index";

const defaultState = {
  mailCode: "",
  messageType: "",
  message: "",
  userData: "",
  usersInBusiness: [],
  notificationData: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET_MESSAGES:
      return {
        ...state,
        messageType: "",
        message: "",
        mailCode: "",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.data,
        messageType: "login_success",
      };
    case LOGIN_SUCCESS_REDIRECT_TO_CONTROL_PANEL:
      return {
        ...state,
        userData: action.data,
        messageType: "login_success_redirect_to_control_panel",
      };

    case LOGIN_FAILED:
      return {
        ...state,
        messageType: "login_failed",
        message: action.message,
      };
    case GET_FORGET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        mailCode: action.payload,
      };
    case GET_FORGET_PASSWORD_CODE_FAILED:
      return {
        ...state,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        messageType: "update_password_success",
      };
    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        messageType: "update_failed",
        message: action.message,
      };
    case FIND_LOCKED_USERS_SUCCESS:
      return {
        ...state,
        usersInBusiness: action.payload,
        notificationData: "control_panel_user_edited_success",
      };
    case FIND_LOCKED_USERS_FAILED:
      return {
        ...state,
      };
    case USER_UNLOCKED_SUCCESS:
      let unlockUser = action.payload;
      return {
        ...state,
        usersInBusiness: state.usersInBusiness.map(
          (user) => (user._id === unlockUser._id ? unlockUser : user)
        ),
      };
    case USER_UNLOCKED_FAILED:
      return {
        ...state,
      };

    case USER_ACCOUNT_LOCKED_SUCCESS:
      let lockedUser = action.payload;
      return {
        ...state,
        usersInBusiness: state.usersInBusiness.map(
          (user) => (user._id === lockedUser._id ? lockedUser : user)
        ),
      };
    default:
      return state;
  }
};
