import React from "react";
import Aux from "../../components/hoc/containerAux";
import { convertTimeTo } from "../../helpers/timeZone";
import { getUserDecryptedData } from "../../helpers/general";
import { malwareStatus } from "../../helpers/malwareStatus";

class DomainMalwareTable extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }
  statusBox(status, targetID) {
    let boxColor = "";

    if (
      status === "suspicious" ||
      status === "potentially suspicious" ||
      status === "malicious"
    ) {
      boxColor = (
        <div>
          <button
            className="btn btn-sm btn-outline-danger"
            style={{
              borderRadius: "0.2rem 0px 0px 0.2rem",
              padding: "0.25rem 0.25rem",
              fontSize: "0.75rem",
            }}
            data-toggle="modal"
            data-target={targetID}
          >
            Failed scan report
          </button>

          <span
            className="bg-danger dash-status-ico"
            style={{ paddingBottom: "5px" }}
          >
            <i className="fas fa-file-times" />
          </span>
        </div>
      );
    } else if (status === "clean") {
      boxColor = (
        <div>
          <button
            className="btn btn-sm btn-outline-success"
            style={{
              borderRadius: "0.2rem 0px 0px 0.2rem",
              padding: "0.25rem 0.40rem",
              fontSize: "12px",
            }}
            data-toggle="modal"
            data-target={targetID}
          >
            Passed scan report
          </button>
          <span className="bg-success dash-status-ico">
            <i className="fas fa-file-check" />
          </span>
        </div>
      );
    } else if (status === "running") {
      boxColor = (
        <div>
          <button
            className="btn btn-sm btn-outline-pending"
            style={{
              borderRadius: "0.2rem 0px 0px 0.2rem",
              padding: "0.25rem 0.25rem",
              fontSize: "0.75rem",
            }}
            data-toggle="modal"
            data-target={targetID}
          >
            Pending scan Report
          </button>

          <span
            className="bg-pending dash-status-ico"
            style={{ paddingBottom: "5px" }}
          >
            <i className="fas fa-file-times" />
          </span>
        </div>
      );
    }

    return boxColor;
  }
  render() {
    const {
      scanFinish,
      detection,
      scanStatus,
      targetID,
      domainName,
    } = this.props;

    const userData = getUserDecryptedData();
    let timeZone = "America/Denver";

    if (userData !== undefined) {
      timeZone = userData.hasOwnProperty("timezone")
        ? userData.timezone
        : timeZone;
    }

    return (
      <Aux>
        <td>
          {convertTimeTo(scanFinish, timeZone, "MM/DD/YYYY hh:mm a", false)}
        </td>
        <td>{domainName}</td>
        <td>{malwareStatus(detection)}</td>
        <td>
          <div className="input-group">
            {this.statusBox(scanStatus, targetID)}
          </div>
        </td>
        <td />
        <td />
        <td className="text-center">&nbsp;</td>
      </Aux>
    );
  }
}

export default DomainMalwareTable;
