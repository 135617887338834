import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { createBusinessUserData } from "../../modules/actions/index";
import AppContext from "../../components/context/appContext";

class AddUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isDisable: false,
      businessId: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      groupType: "",
    };

    this.onClickAddUser = this.onClickAddUser.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      this.setState({
        businessId: currentBusinessId,
      });
    }
  }

  closeModal() {
    this.setState({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      groupType: "",
    });
  }

  onClickAddUser() {
    this.setState({
      isDisable: true,
    });

    let userPayload = {
      purpose: "addUser",
      groupType: this.state.groupType,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      userStatus: "pending",
      userDetails: {
        groupType: "customer",
      },
      isNewAssignedUser: true,
    };

    this.props.createBusinessUserData(this.state.businessId, userPayload);

    setTimeout(() => {
      this.setState(
        {
          isDisable: false,
        },
        () => {}
      );
    }, 2500);

    if (this.state.firstName && this.state.email && this.state.groupType) {
      this.setState(
        {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          groupType: "",
        },
        () => {
          document.getElementById("closeModal").click();
        }
      );
    }
  }

  render() {
    const {
      firstName,
      lastName,
      phone,
      email,
      groupType,
      isDisable,
    } = this.state;

    return (
      <div
        id="accountSettingsAddUserModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Add User</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 text-left p-0">
                <div className="col-lg-6 col-md-6 col-sm-12 pl-0 pr-0">
                  <h5>First Name</h5>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="form-control p-sm"
                    name="firstName "
                    value={firstName}
                    onChange={(evt) => {
                      this.setState({
                        firstName: evt.target.value,
                      });
                    }}
                    dataTestid="inpAddUserFirstName"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 pr-0">
                  <h5>Last Name</h5>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="form-control p-sm"
                    name="lastName "
                    value={lastName}
                    onChange={(evt) => {
                      this.setState({
                        lastName: evt.target.value,
                      });
                    }}
                    dataTestid="inpAddUserLastName"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 pl-0 pr-0">
                  <h5>Phone</h5>
                  <input
                    type="tel"
                    placeholder="Phone"
                    className="form-control p-sm"
                    nameText="phone "
                    value={phone}
                    onChange={(evt) => {
                      this.setState({
                        phone: evt.target.value,
                      });
                    }}
                    dataTestid="inpAddUserPhone"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 pr-0">
                  <h5>Email</h5>
                  <input
                    type="email"
                    placeholder="Email"
                    className="form-control p-sm"
                    name="email"
                    value={email}
                    onChange={(evt) => {
                      this.setState({
                        email: evt.target.value,
                      });
                    }}
                    dataTestid="inpAddUserEmail"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 text-left p-0">
                <h5>Security Access Level</h5>
                <select
                  dataTestid="selUserRole"
                  name="groupType"
                  className="form-control"
                  onChange={(evt) => {
                    this.setState({
                      groupType: evt.target.value,
                    });
                  }}
                  defaultValue={groupType}
                >
                  <option>Select a Role</option>
                  <option value="Account Admin">Account Admin</option>
                  <option value="Account Manager">Account Manager</option>
                  <option value="Scan Report Access">Scan Report Access</option>
                </select>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                id="btnCancelDelayFunctionModal"
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-success btn-md  btn-140-width"
                onClick={this.onClickAddUser}
                disabled={isDisable}
                data-testid="btnSaveBusinessUser"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddUserModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createBusinessUserData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserModal);
