import React, { useState } from "react";
import Slide from "../../../../components/animation/slide";
import SlideContext from "../../../../components/context/slidePanelContexts";
function SslSlidePanel({ index, businessId, children }) {
  return (
    <SlideContext.drawerContext.Consumer>
      {(value) => {
        let willOpen = false;
        if (value.ssl[index] !== undefined) {
          if (value.ssl.length === 1) {
            willOpen = value.ssl[index].isSslActive;
          } else {
            willOpen = value.ssl[index].isSslActive
              ? businessId === value.ssl[index].businessId
                ? true
                : false
              : false;
          }
        }
        return (
          <Slide open={willOpen}>
            <div
              style={{
                display: "block",
                position: "relative",
                background: "#ffffff",
              }}
            >
              {children}
            </div>
          </Slide>
        );
      }}
    </SlideContext.drawerContext.Consumer>
  );
}

export default SslSlidePanel;
