import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { images } from "../../helpers/images";
import { Carousel } from "react-bootstrap";
import AppContext from "../../components/context/appContext";

import { callApiUpdateUserWelcomePopup } from "../../services";

class WelcomePopupModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null,
      userId: null,
      userFirstName: "",
      welcomePopup: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.context;

    if (
      prevState.userId !== userData._id &&
      prevState.welcomePopup !== userData.welcomePopup
    ) {
      this.setState({
        userId: userData._id,
        welcomePopup: userData.welcomePopup,
        userFirstName: userData.firstName,
      });
    }
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  goToNextSlide() {
    this.setState({
      index: 1,
      direction: "next",
    });
  }

  async updateWelcomePopup() {
    await callApiUpdateUserWelcomePopup(this.state.userId, {
      welcomePopup: false,
    });
  }

  render() {
    const { index, direction, userId, userFirstName } = this.state;

    return (
      <div
        className="modal fade"
        id="welcomePopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered welcomePopup"
          role="document"
        >
          <div className="modal-content walkthrough-content">
            <Carousel
              className="welcomePopup"
              activeIndex={index}
              direction={direction}
              onSelect={this.handleSelect}
              interval={null}
              wrap={false}
              prevIcon={<i className="fas fa-chevron-circle-left fa-2x" />}
              nextIcon={<i className="fas fa-chevron-circle-right fa-2x" />}
            >
              <Carousel.Item>
                <img
                  className="d-block w-100 img-top"
                  src={images.tgWalkthrough1}
                  alt="First slide"
                />
                <div className="walkbottom-container d-block w-100 img-bottom">
                  <h5 className="mb-5">
                    Hi {userFirstName}, Welcome to Trust Guard Total Website
                    Protection!
                  </h5>
                  <button
                    data-slide="next"
                    onClick={() => this.goToNextSlide()}
                    className="tg-link"
                  >
                    <img
                      src={images.tgWalkthroughMainBtn}
                      alt="Trust Guard Tour Start"
                    />
                  </button>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100 img-top"
                  src={images.tgWalkthrough2}
                  alt="Second slide"
                />
                <div className="walkbottom-container d-block w-100 img-bottom">
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-md-2 text-right"
                        style={{ marginTop: "20px" }}
                      >
                        <img
                          src={images.tgWalkthrough2Icon}
                          alt="Trust Guard Scan Passed Icon"
                        />
                      </div>
                      <div className="col-md-6 text-left">
                        <h5 className="welcome-popup-h5">
                          We've made protecting your website and your business
                          as easy as pie. If all the lights are green, you are
                          protected.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-top"
                  src={images.tgWalkthrough3}
                  alt="Third slide"
                />
                <div className="walkbottom-container d-block w-100 img-bottom">
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-md-2 text-right"
                        style={{ marginTop: "20px" }}
                      >
                        <img
                          src={images.tgWalkthrough3Icon}
                          alt="Trust Guard Incomplete Icon"
                        />
                      </div>
                      <div className="col-md-6 text-left">
                        <h5 className="welcome-popup-h5">
                          If any of the lights are gray, that item needs to be
                          activated.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-top"
                  src={images.tgWalkthrough4}
                  alt="Fourth slide"
                />
                <div className="walkbottom-container d-block w-100 img-bottom">
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-md-2 text-right"
                        style={{ marginTop: "20px" }}
                      >
                        <img
                          src={images.tgWalkthrough4Icon}
                          alt="Trust Guard Failed Icon"
                        />
                      </div>
                      <div className="col-md-6 text-left">
                        <h5 className="welcome-popup-h5">
                          If any of the lights are red, there is a problem that
                          needs to be fixed.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-top"
                  src={images.tgWalkthrough5}
                  alt="Fifth slide"
                />
                <div className="walkbottom-container d-block w-100 img-bottom">
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-md-2 text-right"
                        style={{ marginTop: "20px" }}
                      >
                        <img
                          src={images.tgWalkthrough5Icon}
                          alt="Trust Guard More Details Icon"
                        />
                      </div>
                      <div className="col-md-6 text-left">
                        <h5 className="welcome-popup-h5">
                          To see scan results, just click on the corresponding
                          circle, and the details will appear below.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-top"
                  src={images.tgWalkthrough6}
                  alt="Sixth slide"
                />
                <div className="walkbottom-container d-block w-100 img-bottom">
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-md-2 text-right"
                        style={{ marginTop: "20px" }}
                      >
                        <img
                          src={images.tgWalkthrough6Icon}
                          alt="Trust Guard Option Icon"
                        />
                      </div>
                      <div className="col-md-6 text-left">
                        <h5 className="welcome-popup-h5">
                          To change scanning options, simply click on the three
                          dots, and details will appear below.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-top"
                  src={images.tgWalkthrough7}
                  alt="Seventh slide"
                />
                <div className="walkbottom-container d-block w-100 img-bottom">
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-md-2 text-right"
                        style={{ marginTop: "20px" }}
                      >
                        <img
                          src={images.tgWalkthrough7Icon}
                          alt="Trust Guard Cyber Insurance Icon"
                        />
                      </div>
                      <div className="col-md-6 text-left">
                        <h5 className="welcome-popup-h5">
                          Please Note: Before you can activate your Cyber
                          Insurance policy, your PCI, Malware, and SSL scans all
                          need to be green.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-top"
                  src={images.tgWalkthrough8}
                  alt="Seventh slide"
                />
                <div className="walkbottom-container d-block w-100 img-bottom">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-2 text-right mt-5" style={{}}>
                        <img
                          src={images.tgWalkthrough8Icon}
                          alt="Trust Guard Cyber Insurance Icon"
                        />
                      </div>
                      <div className="col-md-6 text-left">
                        <h5 className="welcome-popup-h5">
                          Once your PCI, Malware, SSL icons are all green, the
                          Trust Seal icon will turn green. Adding the 'Trust
                          Guard SECURED' Seal to your website adds trust and
                          credibility to your website, and will help you
                          increase your sales!
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div>
                  <div
                    style={{ height: "300px", width: "100%" }}
                    className="container"
                  >
                    <div className="row">
                      <div
                        className="col-md-12 text-center"
                        style={{ marginTop: "30px" }}
                      >
                        <h1 className="f800 pt-5 walkthrough-h1">
                          It's that easy!
                        </h1>
                        <h3 className="pt-4">
                          Not only does Trustguard help you protect your
                          business and your customers from hackers, but adding
                          the Trust Guard SECURED Seal has been proven to
                          increase your conversion rate and average ordr value.
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="walkbottom-container d-block w-100">
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="tg-link"
                      data-testid="btnWelcomePopupDismiss"
                      onClick={() => {
                        this.updateWelcomePopup();
                      }}
                    >
                      <img
                        src={images.tgWalkthroughClosewindow}
                        className="mt-5"
                        alt="Trust Guard Tour Close"
                      />
                    </button>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

WelcomePopupModal.contextType = AppContext;

const mapStateToProps = (state) => ({
  loggedInUser: state.accountStore.loggedInUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomePopupModal);
