const storage = window.localStorage;

function setStorageItem(key, value) {
  storage.setItem(key, value);
}

function getStorageItem(key) {
  try {
    return storage.getItem(key);
  } catch (error) {
    removeAllStorage();
  }
}

function removeStorageItem(key) {
  storage.removeItem(key);
}

function removeAllStorage() {
  storage.clear();
}

export { setStorageItem, getStorageItem, removeStorageItem, removeAllStorage };
