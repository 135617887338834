import React from "react";
import ReportsTabHeader from "./reportsTabHeader";
import ReportsTabBody from "./reportsTabBody";

class ReportsTab extends React.Component {
  state = {};
  render() {
    return (
      <div id="reports" className="tab-pane fade in active">
        <ReportsTabHeader />
        <ReportsTabBody />
      </div>
    );
  }
}

export default ReportsTab;
