import { api } from "../services/index";
import { getStorageItem, removeAllStorage } from "./storage";

var jwtDecode = require("jwt-decode");

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

export function callApi(rApp) {
  const token = getStorageItem("accessToken");
  if (token) {
    var tokenExp = jwtDecode(token);
    var dateNow = new Date();
    if (tokenExp.exp < dateNow.getTime() / 1000) {
      removeAllStorage();
      window.location = "/login";
    } else {
      return api
        .authenticate({
          strategy: "jwt",
          accessToken: token,
        })
        .then((tokenData) => {
          return tokenData;
        })
        .catch((err) => {
          removeAllStorage();
        });
    }
  }
}

export function checkUserRole(user, business, screen) {
  let screens = [
    {
      billing: ["admin", "Account Manager"],
    },
    {
      accountSettings: ["admin"],
    },
  ];
  let getScreen = screens.find((findScreen) => {
    return findScreen[screen];
  });
  var getUserRole = user.businessDetails.find((findBusinessID) => {
    return findBusinessID.businessId === business._id;
  });
  if (getUserRole) {
    if (user.userDetails.isAdmin) {
      return true;
    } else if (!user.userDetails.isAdmin) {
      let findRole = getScreen[screen].find((screenRole) => {
        return screenRole === getUserRole.groupType;
      });
      if (findRole) {
        return true;
      } else {
        return false;
      }
    }
  }
}

export const checkAuth = () => {
  let isDev = process.env.REACT_APP_IS_DEV;
  let user = {};

  const token = getStorageItem("accessToken");
  const defaultToken = getStorageItem("feathers-jwt");
  const userData = getStorageItem("userData");

  if (userData) {
    let userString = cryptr.decrypt(userData);
    user = JSON.parse(userString);
  }

  if (!token || !defaultToken) {
    return false;
  }

  return {
    isDev: isDev,
    isAdmin: user.isAdmin,
    groupType: user.groupType,
    roles: user.roles,
  };
};
