import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { callApiRefreshTrustSealsData } from "../../services";

class RefreshSealModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  hardRefreshSeal = async () => {
    await callApiRefreshTrustSealsData(this.props.businessId);
  };

  render() {
    return (
      <div id="refreshSealModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Refresh Seals</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 text-left p-0">
                <p>
                  <strong>
                    Your seals will be refreshed and if there are any changes
                    you should see them within 1 - 2 minutes.
                  </strong>
                </p>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                type="button"
                className="btn-success btn-md pull-right btn-140-width"
                data-dismiss="modal"
                onClick={() => {
                  this.hardRefreshSeal();
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefreshSealModal);
