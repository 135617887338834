import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../../views/charts/line";

import AppContext from "../../components/context/appContext";
import BpStatistics from "./bpStatistics";
import BpImplementation from "./bpImplementation";

class BuyerProtectionStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userBusinessData: [],
      businessId: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userBusinessData } = this.context;

    if (prevState.userBusinessData !== userBusinessData) {
      this.setState(
        {
          userBusinessData: userBusinessData,
          businessId:
            userBusinessData && userBusinessData.length > 0
              ? userBusinessData[0]._id
              : "",
        },
        () => {
          this.context.updateCurrentBusinessId(this.state.businessId);
        }
      );
    }
  }

  render() {
    return (
      <div id="mcontent-wrapper" style={{ padding: "40px 40px 0px" }}>
        <div id="mcontent-top" className="pull-left">
          <ul id="dashTab" className="nav menu-tab" role="tablist">
            <li role="presentation" className="active">
              <a
                data-toggle="tab"
                href="#statistics"
                aria-controls="statistics"
                role="tab"
              >
                Statistics
              </a>
            </li>
            <li role="presentation">
              <a
                data-toggle="tab"
                href="#implementation"
                aria-controls="implementation"
                role="tab"
              >
                Implementation
              </a>
            </li>
            <li role="presentation">
              <a
                data-toggle="tab"
                href="#sealCode"
                aria-controls="sealCode"
                role="tab"
              >
                Seal Code
              </a>
            </li>
          </ul>
        </div>

        <div id="mcontent-wrapper">
          <div className="tab-content">
            <div
              role="tabpanel"
              className="tab-pane active"
              id="statistics"
              style={{ margin: "0 15px" }}
            >
              <BpStatistics />
            </div>

            <div
              role="tabpanel"
              className="tab-pane fade"
              id="implementation"
              style={{ margin: "0 15px" }}
            >
              <BpImplementation />
            </div>

            <div
              role="tabpanel"
              className="tab-pane fade"
              id="sealCode"
              style={{ margin: "0 15px" }}
            >
              sealCode
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BuyerProtectionStats.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyerProtectionStats);
