import { api } from "./api";
import { callApi } from "../helpers/index";

function callGetCertificates(_id) {
  return callApi().then((token) => {
    return api.service("business").get(_id);
  });
}

function updateCertificates(_id, certificateName, displayIcon) {
  return callApi().then((token) => {
    return api.service("business").patch(_id, {
      certificate: certificateName,
      displayIcons: displayIcon,
    });
  });
}

export { callGetCertificates, updateCertificates };
