import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Switch from "react-switch";
import ContentLoader from "react-content-loader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";

import config from "../../helpers/config";
import { images } from "../../helpers/images";
import { getStorageItem } from "../../helpers/storage";
import Aux from "../../components/hoc/containerAux";
import Spinner from "../../components/ui/spinner/spinnerRectScan";
import AppContext from "../../components/context/appContext";
import TrustSealsInfo from "../modals/trustSealsInfo";
import TrustSealsCreatePreset from "../modals/trustSealsCreatePreset";

import {
  callApiGetBusinessData,
  callApiGetTrustSealsData,
  callApiCreateTrustSealsData,
  callApiUpdateTrustSealsData,
  callApiPatchTrustSealsData,
  callApiCreateMissingTrustSeals,
} from "../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

const sliderStyle = {
  position: "relative",
  width: "95%",
  height: 80,
  border: "none",
};

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 8,
  marginTop: 35,
  borderRadius: 2,
  backgroundColor: "#fff",
};

export function Handle({ handle: { id, value, percent }, getHandleProps }) {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: 0,
        marginTop: 30,
        zIndex: 2,
        width: 18,
        height: 18,
        border: 0,
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "50%",
        backgroundColor: "#28a745",
        color: "#333",
      }}
      {...getHandleProps(id)}
    >
      <div style={{ fontFamily: "Roboto", fontSize: 11, marginTop: -35 }}>
        {value + "px"}
      </div>
    </div>
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 10,
        zIndex: 1,
        marginTop: 35,
        backgroundColor: "#28a745",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

class SealComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      businessId: null,
      codeGenerated: false,
      businessData: "",
      certificateURL: "CERTIFICATE_PATH/certificate/PRIMARY_DOMAIN",
      sealCode: {
        /*eslint-disable*/
        fixed:
          '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART <div class="_TG_SEALCODE_CLASS _ONE"_POSITION>_STANDALONE_ANCHOR_START<img alt="Trust Guard Security Scanned" _IMG_ID class="tgfloat-inner" src="_THREE" style="border: 0; _SIZE " oncontextmenu="var d = new Date(); alert(\'Copying Prohibited by Law - This image and all included logos are copyrighted by Trust Guard \'+d.getFullYear()+\'.\'); return false;" />_STANDALONE_ANCHOR_END</div> _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART',

        custom:
          '<a name="trustlink" href="_CERTIFICATE_URL" rel="nofollow" target="_blank" onclick="var nonwin=navigator.appName!=\'Microsoft Internet Explorer\'?\'yes\':\'no\'; window.open(this.href,\'welcome\',\'location=\'+nonwin+\',scrollbars=yes,width=517,height=\'+screen.availHeight+\',menubar=no,toolbar=no\'); return false;" oncontextmenu="var d = new Date(); alert(\'Copying Prohibited by Law - This image and all included logos are copyrighted by trust-guard \\251 \'+d.getFullYear()+\'.\'); return false;" > <img name="trustseal" alt="Security Seals" style="border: 0;" src="_BUSINESS_SEAL" /></a>',
        /*eslint-enable*/
        textboxCode: "",
      },
      trustSealsData: null,
      presetType: null,
      presetName: "",
      label: "",
      color: "green",
      sealType: "outer",
      floatSide: "left",
      width: "225",
      distanceFromEdge: "0",
      colorSettings: {
        green: {
          placeholderShieldOuter: "#333333",
          placeholderShieldInner: "#FFFFFF",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#33981f",
          placeholderBodyRight: "#43b12a",
          placeholderStatusColor: "#ffffff",
          placeholderDateColor: "#ffffff",
          placeholderLabelColor: "#ffffff",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#ffffff",
          placeholderSecuredText: "#ffffff",
          placeholderShieldBg: "#33981F",
          placeholderShieldTop: "#33981F",
          placeholderPrimaryLayer: "#ffffff",
          placeholderSecondaryLayer: "#ffffff",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#ffffff",
          placeholderSealBorder: "#33981F",
        },
        black: {
          placeholderShieldOuter: "#ffffff",
          placeholderShieldInner: "#333333",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#333333",
          placeholderBodyRight: "#434343",
          placeholderStatusColor: "#ffffff",
          placeholderDateColor: "#ffffff",
          placeholderLabelColor: "#ffffff",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#ffffff",
          placeholderSecuredText: "#ffffff",
          placeholderShieldBg: "#333333",
          placeholderShieldTop: "#333333",
          placeholderPrimaryLayer: "#ffffff",
          placeholderSecondaryLayer: "#ffffff",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#333333",
          placeholderSealBorder: "#333333",
        },
        white: {
          placeholderShieldOuter: "#333333",
          placeholderShieldInner: "#FFFFFF",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#f2f2f2",
          placeholderBodyRight: "#ffffff",
          placeholderStatusColor: "#333333",
          placeholderDateColor: "#333333",
          placeholderLabelColor: "#333333",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#333333",
          placeholderSecuredText: "#33981f",
          placeholderShieldBg: "#FFFFFF",
          placeholderShieldTop: "#FFFFFF",
          placeholderPrimaryLayer: "#333333",
          placeholderSecondaryLayer: "#333333",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#ffffff",
          placeholderSealBorder: "#f5f5f5",
        },
      },
      selectedPresetId: null,
      newPresetName: "",
      isSealSaved: false,
      trustSealPath: null,
      demoTrustSealPath: null,
      trustSealPathForWebsiteCode: null,
      isTextElementChanged: false,
      isCopied: false,
      trustsealHost: config.seals.sealsHost,
      displayBlock: "none",
      changingSealParameter: "",
      conditionalNotificationParameters: [
        "sealType",
        "floatSide",
        "distanceFromEdge",
      ],
      sealScriptsCss: "",
      sealScriptsJs: "",
      disableFloatSide: false,
      displayOnChangeSealModal: true,
      sealMaxWidth: 175,
      sealMaxWidthOnMobile: 145,
      showMobileSeal: false,
      userInfoText: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  async componentDidMount() {
    await this.getTrustsealsData();
  }

  getTrustsealsData = async () => {
    let businessId = null;
    const { currentBusinessId } = this.context;

    if (currentBusinessId) {
      businessId = currentBusinessId;
    } else {
      businessId = cryptr.decrypt(getStorageItem("businessId"));
    }

    const businessData = await callApiGetBusinessData(businessId);

    this.setState({
      businessId: businessId,
      businessData: businessData,
    });

    let trustSealsData = await callApiGetTrustSealsData(businessId);

    if (trustSealsData.data === null) {
      const sealData = this.populateSealsData();
      trustSealsData = await callApiCreateTrustSealsData({
        businessId,
        sealData,
      });

      toast.success("Preset created successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    }

    this.setTrustSealsUiData(trustSealsData);
  };

  setTrustSealsUiData = (trustSealsData) => {
    let certificatePathUrl = config.seals.certificateHost;
    let targetIndex = null;
    let selectedPresetId = null;

    targetIndex = trustSealsData
      ? trustSealsData.data.sealSettings.length - 1
      : 0;
    selectedPresetId = trustSealsData
      ? trustSealsData.data.sealSettings[targetIndex]._id
      : null;

    if (this.state.selectedPresetId) {
      selectedPresetId = this.state.selectedPresetId;

      if (trustSealsData && trustSealsData.data.sealSettings.length) {
        for (let i = 0; i < trustSealsData.data.sealSettings.length; i++) {
          if (
            trustSealsData.data.sealSettings[i]._id ===
            this.state.selectedPresetId
          ) {
            targetIndex = i;
          }
        }
      }
    }

    this.setState({
      trustSealsData: trustSealsData.data,
      selectedPresetId: selectedPresetId,
      trustSealPath: null,
      demoTrustSealPath: null,
      presetType: trustSealsData.data.sealSettings[targetIndex].presetType,
      presetName: trustSealsData.data.sealSettings[targetIndex].presetName,
      label: trustSealsData.data.sealSettings[targetIndex].label,
      color: trustSealsData.data.sealSettings[targetIndex].color.title,
      sealType: trustSealsData.data.sealSettings[targetIndex].sealType,
      floatSide: trustSealsData.data.sealSettings[targetIndex].floatSide,
      width: trustSealsData.data.sealSettings[targetIndex].width,
      distanceFromEdge:
        trustSealsData.data.sealSettings[targetIndex].distanceFromEdge,
      isSealSaved: false,
      certificateURL: this.state.certificateURL.replace(
        "PRIMARY_DOMAIN",
        this.state.businessData.primaryDomain
      ),
      sealMaxWidth:
        trustSealsData.data.sealWidth &&
        trustSealsData.data.sealWidth.desktopWidth
          ? trustSealsData.data.sealWidth.desktopWidth
          : 175,
      sealMaxWidthOnMobile:
        trustSealsData.data.sealWidth &&
        trustSealsData.data.sealWidth.mobileWidth
          ? trustSealsData.data.sealWidth.mobileWidth
          : 145,
    });

    setTimeout(() => {
      let tempPresetSealImage =
        trustSealsData.data.sealSettings[targetIndex]._id +
        ".svg?v=" +
        new Date().getTime();

      let tempPresetSealImageForWebsiteCode =
        trustSealsData.data.sealSettings[targetIndex]._id + ".svg";

      this.setState(
        {
          trustSealPath:
            this.state.trustsealHost +
            "/sites/" +
            this.state.businessData.primaryDomain +
            "/" +
            tempPresetSealImage,
          trustSealPathForWebsiteCode:
            this.state.trustsealHost +
            "/sites/" +
            this.state.businessData.primaryDomain +
            "/" +
            tempPresetSealImageForWebsiteCode,
          demoTrustSealPath:
            this.state.trustsealHost +
            "/sites/" +
            this.state.businessData.primaryDomain +
            "/" +
            "demo-" +
            tempPresetSealImage,
          certificateURL: this.state.certificateURL.replace(
            "CERTIFICATE_PATH",
            certificatePathUrl
          ),
        },
        () => {
          this.generateSealWebsiteCode();
        }
      );
    }, 2500);
  };

  handleSwitchChange(event) {
    this.setState({ showMobileSeal: !this.state.showMobileSeal });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let thisUserInfoText = "";

    if (name === "sealType") {
      this.setState({
        sealMaxWidth: 175,
      });
    }

    if (name === "sealType" && value === "checkout") {
      this.setState({
        disableFloatSide: true,
        floatSide: "none",
        sealMaxWidth: 225,
      });
    } else {
      this.setState({
        disableFloatSide: false,
      });
    }

    if (this.state.conditionalNotificationParameters.indexOf(name) !== -1) {
      thisUserInfoText =
        "Your seal has been updated. Please note you must update the code on your website for the seal to display properly.";
    }

    this.setState(
      {
        [name]: value,
        userInfoText: thisUserInfoText,
        isTextElementChanged: true,
        changingSealParameter: name,
      },
      () => {
        switch (name) {
          case "color":
          case "sealType":
          case "floatSide":
            this.saveSeal();
            break;
          default:
            break;
        }

        if (name === "sealType" || name === "floatSide") {
          document.getElementById("btnDisplayModalSealInfo").click();
        }
      }
    );
  }

  showActivePresetContent = (sealSettings) => {
    var ul = document.getElementById("sealPresetUl");
    var items = ul.getElementsByTagName("li");

    for (var i = 0; i < items.length; ++i) {
      items[i].classList.remove("active");
    }

    this.setState(
      {
        presetType: sealSettings.presetType,
        presetName: sealSettings.presetName,
        trustSealPath:
          this.state.trustsealHost +
          "/sites/" +
          this.state.businessData.primaryDomain +
          "/" +
          sealSettings._id +
          ".svg?v=" +
          new Date().getTime(),
        trustSealPathForWebsiteCode:
          this.state.trustsealHost +
          "/sites/" +
          this.state.businessPrimaryDomain +
          "/" +
          sealSettings._id +
          ".svg",
        demoTrustSealPath:
          this.state.trustsealHost +
          "/sites/" +
          this.state.businessData.primaryDomain +
          "/" +
          "demo-" +
          sealSettings._id +
          ".svg",
        selectedPresetId: sealSettings._id,
        label: sealSettings.label,
        color: sealSettings.color.title,
        sealType: sealSettings.sealType,
        floatSide: sealSettings.floatSide,
        width: sealSettings.width,
        distanceFromEdge: sealSettings.distanceFromEdge,
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );

    document.getElementById(sealSettings._id).classList.add("active");
  };

  generateSealWebsiteCode = () => {
    let websiteSealCode = null;

    switch ("fixed") {
      case "fixed":
        websiteSealCode = this.state.sealCode.fixed;
        let sealOffset = "";
        let tgScript = "tgscript-v" + this.state.sealScriptsJs.version;
        let tgScriptHash = "sha384-" + this.state.sealScriptsJs.hash;

        let tgCss = config.seals.seal_css_version.replace(
          "_STYLE",
          "cert-style-v" + this.state.sealScriptsCss.version + ".css"
        );
        let tgCssHash = "sha384-" + this.state.sealScriptsCss.hash;

        let sealPlacement = "";

        if (
          (this.state.floatSide === "left" ||
            this.state.floatSide === "right") &&
          this.state.floatSide !== "standAlone"
        ) {
          if (this.state.sealType !== "checkout") {
            /*eslint-disable*/
            websiteSealCode = websiteSealCode.replace(
              "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART",
              '<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js" integrity="sha384-tsQFqpEReu7ZLhBV2VZlAu7zcOV+rXbYlF2cqB8txI/8aZajjp4Bqd+V6D5IgvKT" crossorigin="anonymous"></script><script>tg_jq = jQuery.noConflict( true );</script>'
            );
          }
          websiteSealCode = websiteSealCode.replace(
            "_EXTERNAL_STYLE_AND_SCRIPT_REAR_PART",
            '<div id="tgSlideContainer"></div><script src="https://tgscript.s3.amazonaws.com/_TG_SCRIPT" integrity="_TG_SCRIPT_HASH" crossorigin="anonymous"></script><script>document.write(_TG_SCRIPT_FOR_DOCUMENT_WRITE);tg_load_animations("_BUSINESS_ID", "_PLACEMENT_TYPE");</script><link rel="stylesheet" rel="preload" href="_TG_CSS" integrity="_TG_CSS_HASH" crossorigin="anonymous"><style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx !important; }}</style>'
          );
          /*eslint-enable*/

          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT",
            tgScript + ".js"
          );
          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_HASH",
            tgScriptHash
          );
          websiteSealCode = websiteSealCode.replace(
            "_BUSINESS_ID",
            this.state.businessId
          );

          websiteSealCode = websiteSealCode.replace("_TG_CSS", tgCss);
          websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);
          websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);
          websiteSealCode = websiteSealCode.replace("_CERTIFICATE_URL", "");

          let floatSideType = this.state.floatSide;
          let documentScript = "";
          let placementType = "";

          switch (this.state.sealType) {
            case "outer":
              if (this.state.floatSide === "left") {
                floatSideType = "bleft";
                sealPlacement = "tgBleft";
                documentScript = "tg_script_bottom_left";
                placementType = "bottom_left";
              } else if (this.state.floatSide === "right") {
                floatSideType = "bright";
                sealPlacement = "tgBright";
                documentScript = "tg_script_bottom_right";
                placementType = "bottom_right";
              }

              break;
            case "inner":
              if (this.state.floatSide === "left") {
                floatSideType = "bleft";
                sealPlacement = "tgBleft";
                documentScript = "tg_script_bottom_left";
                placementType = "bottom_left";
              } else if (this.state.floatSide === "right") {
                floatSideType = "bright";
                sealPlacement = "tgBright";
                documentScript = "tg_script_bottom_right";
                placementType = "bottom_right";
              }

              break;

            case "side":
              if (this.state.floatSide === "left") {
                floatSideType = "left";
                sealPlacement = "tgLeft";
                documentScript = "tg_script_side_left";
                placementType = "side_left";
              } else if (this.state.floatSide === "right") {
                floatSideType = "right";
                sealPlacement = "tgRight";
                documentScript = "tg_script_side_right";
                placementType = "side_right";
              }

              break;

            default:
              break;
          }

          websiteSealCode = websiteSealCode.replace(
            "_PLACEMENT_TYPE",
            placementType
          );
          /*eslint-disable*/

          websiteSealCode = websiteSealCode.replace(
            "_IMG_ID",
            'id="tg_1-' + floatSideType + '"'
          );
          /*eslint-enable*/

          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_FOR_DOCUMENT_WRITE",
            documentScript
          );
        } else {
          if (this.state.sealType !== "checkout") {
            /*eslint-disable*/
            websiteSealCode = websiteSealCode.replace(
              "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ",
              '<style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx; }}</style>'
            );
            /*eslint-enable*/
          } else {
            websiteSealCode = websiteSealCode.replace(
              "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ",
              ""
            );
          }

          websiteSealCode = websiteSealCode.replace(
            " _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART",
            ""
          );

          websiteSealCode = websiteSealCode.replace(
            "_CERTIFICATE_URL",
            this.state.certificateURL
          );

          websiteSealCode = websiteSealCode.replace(" _IMG_ID", "");
        }

        if (this.state.sealType !== "checkout") {
          websiteSealCode = websiteSealCode.replace(
            "_SEAL_WIDTH_FOR_MOBILE",
            this.state.sealMaxWidthOnMobile
          );
        }

        if (this.state.sealType !== "side") {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, "width");
        } else {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, "height");
        }

        websiteSealCode = websiteSealCode.replace("_ONE", sealPlacement);

        if (this.state.floatSide === "left") {
          websiteSealCode = websiteSealCode.replace("_TWO", "left");
          websiteSealCode = websiteSealCode.replace(
            "_THREE",
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace("_FOUR", "left:");
          websiteSealCode = websiteSealCode.replace(
            "_FIVE",
            this.state.distanceFromEdge + "px;"
          );
          websiteSealCode = websiteSealCode.replace(
            "_SIX",
            this.state.width + "px;"
          );

          sealOffset = "left:" + this.state.distanceFromEdge + "px;";
        } else {
          websiteSealCode = websiteSealCode.replace("_TWO", "right");
          websiteSealCode = websiteSealCode.replace(
            "_THREE",
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace("_FOUR", "right:");
          websiteSealCode = websiteSealCode.replace(
            "_FIVE",
            this.state.distanceFromEdge + "px;"
          );
          websiteSealCode = websiteSealCode.replace(
            "_SIX",
            this.state.width + "px;"
          );

          sealOffset = "right:" + this.state.distanceFromEdge + "px;";
        }

        if (
          (this.state.sealType === "outer" ||
            this.state.sealType === "inner") &&
          this.state.floatSide === "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "width: " + this.state.sealMaxWidth + "px;"
          );
        }

        if (
          (this.state.sealType === "outer" ||
            this.state.sealType === "inner") &&
          this.state.floatSide !== "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            ""
          );
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            ""
          );
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "width: " + this.state.sealMaxWidth + "px;"
          );
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_POSITION",
            ' style="position:fixed;bottom:0px;z-index: 999999;text-align:left;' +
              sealOffset +
              '"'
          );
          /*eslint-enable*/
        }

        if (
          this.state.sealType === "side" &&
          this.state.floatSide !== "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "height: " + this.state.sealMaxWidth + "px"
          );
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_POSITION",
            ' style="position:fixed;top:150px;z-index:999999;text-align:left;' +
              sealOffset +
              '"'
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            ""
          );
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            ""
          );
        }

        if (
          this.state.sealType === "side" &&
          this.state.floatSide === "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "height: " + this.state.sealMaxWidth + "px"
          );
        }

        if (this.state.floatSide === "standAlone") {
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );

          websiteSealCode = websiteSealCode.replace(
            "_TG_SEALCODE_CLASS",
            "tgbanner"
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            "</a>"
          );
          websiteSealCode = websiteSealCode.replace("_POSITION", "");
          websiteSealCode = websiteSealCode.replace("_SIZE ", "");
        } else {
          websiteSealCode = websiteSealCode.replace(
            "_TG_SEALCODE_CLASS",
            "tgfloat"
          );
        }

        if (this.state.sealType === "checkout") {
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            "</a>"
          );
          websiteSealCode = websiteSealCode.replace("_POSITION", "");
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "width: " + this.state.sealMaxWidth + "px;"
          );
        }

        this.setState({
          sealCode: { ...this.state.sealCode, textboxCode: websiteSealCode },
        });

        break;
      case "custom":
        break;

      default:
        break;
    }
  };

  generateSealCode = async () => {
    await callApiPatchTrustSealsData(this.state.trustSealsData._id, {
      lastGeneratedDate: Date.now(),
      businessId: this.state.businessId,
    });

    this.setState({
      codeGenerated: true,
    });

    toast.success("TrustSeal code generated successfully.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500,
    });
  };

  createSealPreset = async (newPresetName) => {
    if (newPresetName && typeof newPresetName !== "undefined") {
      const newSealsData = this.populateSealsData(newPresetName, "create");
      document.getElementById("hideModalOnSuccess").click();
      this.setState({
        selectedPresetId: "",
      });

      const updatednewSealsData = await callApiUpdateTrustSealsData(
        this.state.trustSealsData._id,
        {
          businessId: this.state.businessId,
          sealData: newSealsData,
        }
      );

      toast.success("Preset created successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });

      this.setTrustSealsUiData(updatednewSealsData);
    } else {
      toast.error("Please enter preset name.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    }
  };

  saveSeal = async () => {
    this.setState({
      isSealSaved: true,
      trustSealPath: null,
    });

    const updatableSealData = this.populateSealsData(
      this.state.presetName,
      "update",
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(
      this.state.trustSealsData._id,
      {
        businessId: this.state.businessId,
        sealData: updatableSealData,
      }
    );

    toast.success("Preset updated successfully.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500,
    });

    this.setTrustSealsUiData(updatedSealsData);
  };

  removeSeal = async () => {
    let confirm = window.confirm("Are you sure to remove this preset?");

    if (confirm) {
      const removableSealData = this.populateSealsData(
        this.state.presetName,
        "remove",
        this.state.selectedPresetId
      );

      const currentSealData = await callApiUpdateTrustSealsData(
        this.state.trustSealsData._id,
        {
          businessId: this.state.businessId,
          sealData: removableSealData,
          removableSealSettingsId: this.state.selectedPresetId,
        }
      );

      toast.success("Preset removed successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });

      this.setTrustSealsUiData(currentSealData);
    }
  };

  populateSealsData(presetName = null, purpose = null, sealSettingsId = null) {
    let trustSealId = null;
    let sealData = null;
    let presetSettings = {
      presetType: null,
      presetName: "Default",
      label: this.state.businessData
        ? this.state.businessData.primaryDomain.toUpperCase()
        : "",
      sealType: "outer",
      floatSide: "left",
      width: "225",
      distanceFromEdge: 0,
      color: {
        title: "green",
        placeholderShieldOuter: "#333333",
        placeholderShieldInner: "#FFFFFF",
        placeholderShieldLeft: "#66CC3E",
        placeholderShieldRight: "#33981F",
        placeholderBodyLeft: "#33981f",
        placeholderBodyRight: "#43b12a",
        placeholderStatusColor: "#ffffff",
        placeholderDateColor: "#ffffff",
        placeholderLabelColor: "#ffffff",
        placeholderKeyHole: "#ffffff",
        placeholderTrustGuardText: "#ffffff",
        placeholderSecuredText: "#ffffff",
        placeholderShieldBg: "#33981F",
        placeholderShieldTop: "#33981F",
        placeholderPrimaryLayer: "#ffffff",
        placeholderSecondaryLayer: "#ffffff",
        placeholderCheckoutSeparator: "#e6e6e5",
        placeholderShieldInnerBorder: "#ffffff",
        placeholderSealBorder: "#33981F",
      },
      sealWidth: {
        desktopWidth: this.state.sealMaxWidth,
        mobileWidth: this.state.sealMaxWidthOnMobile,
      },
      updateDate: new Date().toISOString,
    };

    let sealSettingsData = null;

    if (this.state.sealType === "checkout") {
      this.setState({
        floatSide: "none",
      });
    }

    switch (purpose) {
      case "create":
        trustSealId = this.state.trustSealsData._id;
        presetSettings.presetName = presetName;
        sealSettingsData = [
          ...this.state.trustSealsData.sealSettings,
          presetSettings,
        ];
        break;

      case "update":
        let sealLabel = this.state.label;
        if (sealLabel === "") {
          sealLabel = this.state.businessData.primaryDomain;
        }

        let tgSealOuterShieldColor = null;
        if (
          this.state.floatSide === "right" &&
          this.state.sealType === "side" &&
          (this.state.color === "black" || this.state.color === "whiteAlt")
        ) {
          tgSealOuterShieldColor = "#dddddd";
        } else {
          tgSealOuterShieldColor = this.state.colorSettings[this.state.color]
            .placeholderShieldOuter;
        }

        trustSealId = this.state.trustSealsData
          ? this.state.trustSealsData._id
          : null;
        sealSettingsData =
          this.state.trustSealsData && this.state.trustSealsData.sealSettings
            ? this.state.trustSealsData.sealSettings.map((v, i) => {
                if (v._id === sealSettingsId) {
                  v.presetName = this.state.presetName;
                  v.label = sealLabel.toUpperCase();
                  v.sealType = this.state.sealType;
                  v.floatSide =
                    this.state.sealType !== "checkout"
                      ? this.state.floatSide !== "none"
                        ? this.state.floatSide
                        : "left"
                      : "none";
                  v.width = "225";
                  v.distanceFromEdge = this.state.distanceFromEdge;
                  v.color = {
                    title: this.state.color,
                    placeholderShieldOuter: tgSealOuterShieldColor,
                    placeholderShieldInner: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldInner,
                    placeholderShieldLeft: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldLeft,
                    placeholderShieldRight: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldRight,
                    placeholderBodyLeft: this.state.colorSettings[
                      this.state.color
                    ].placeholderBodyLeft,
                    placeholderBodyRight: this.state.colorSettings[
                      this.state.color
                    ].placeholderBodyRight,
                    placeholderStatusColor: this.state.colorSettings[
                      this.state.color
                    ].placeholderStatusColor,
                    placeholderDateColor: this.state.colorSettings[
                      this.state.color
                    ].placeholderDateColor,
                    placeholderLabelColor: this.state.colorSettings[
                      this.state.color
                    ].placeholderLabelColor,
                    placeholderKeyHole: this.state.colorSettings[
                      this.state.color
                    ].placeholderKeyHole,
                    placeholderTrustGuardText: this.state.colorSettings[
                      this.state.color
                    ].placeholderTrustGuardText,
                    placeholderSecuredText: this.state.colorSettings[
                      this.state.color
                    ].placeholderSecuredText,

                    placeholderShieldBg: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldBg,

                    placeholderShieldTop: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldTop,

                    placeholderPrimaryLayer: this.state.colorSettings[
                      this.state.color
                    ].placeholderPrimaryLayer,

                    placeholderSecondaryLayer: this.state.colorSettings[
                      this.state.color
                    ].placeholderSecondaryLayer,
                    placeholderCheckoutSeparator: this.state.colorSettings[
                      this.state.color
                    ].placeholderCheckoutSeparator,
                    placeholderShieldInnerBorder: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldInnerBorder,
                    placeholderSealBorder: this.state.colorSettings[
                      this.state.color
                    ].placeholderSealBorder,
                  };
                  v.sealWidth = {
                    desktopWidth: this.state.sealMaxWidth,
                    mobileWidth: this.state.sealMaxWidthOnMobile,
                  };
                  v.updateDate = new Date().toISOString();
                }

                return v;
              })
            : null;
        break;

      case "remove":
        trustSealId = this.state.trustSealsData
          ? this.state.trustSealsData._id
          : null;
        sealSettingsData = this.state.trustSealsData
          ? this.state.trustSealsData.sealSettings.filter((eachData) => {
              return eachData._id !== sealSettingsId;
            })
          : null;
        break;

      default:
        presetSettings.presetType = "Default";
        sealSettingsData = presetSettings;
        break;
    }

    sealData = {
      businessId: this.state.businessId,
      lastGeneratedDate: null,
      nextGenerateDate: null,
      status: "active",
      sealSettings: sealSettingsData,
    };

    return sealData;
  }

  onUpdate = (values) => {
    this.setState(
      {
        sealMaxWidth: values[0],
      },
      () => {
        this.generateSealWebsiteCode();
        this.onSealSizeUpdate();
      }
    );
  };

  onUpdateMobile = (values) => {
    this.setState(
      {
        sealMaxWidthOnMobile: values[0],
      },
      () => {
        this.generateSealWebsiteCode();
        this.onSealSizeUpdate();
      }
    );
  };

  onSealSizeUpdate = async (prevState) => {
    const onUpdateSealData = this.populateSealsData(
      this.state.presetName,
      "update",
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(
      this.state.trustSealsData ? this.state.trustSealsData._id : null,
      {
        businessId: this.state.businessId,
        sealData: onUpdateSealData,
      }
    );
  };

  render() {
    const {
      businessId,
      codeGenerated,
      businessData,
      certificateURL,
      sealCode,
      trustSealsData,
      presetType,
      presetName,
      label,
      color,
      sealType,
      floatSide,
      width,
      distanceFromEdge,
      colorSettings,
      selectedPresetId,
      newPresetName,
      isSealSaved,
      trustSealPath,
      demoTrustSealPath,
      trustSealPathForWebsiteCode,
      isTextElementChanged,
      isCopied,
      trustsealHost,
      displayBlock,
      changingSealParameter,
      conditionalNotificationParameters,
      sealScriptsCss,
      sealScriptsJs,
      disableFloatSide,
      displayOnChangeSealModal,
      sealMaxWidth,
      sealMaxWidthOnMobile,
      showMobileSeal,
      userInfoText,
    } = this.state;

    return (
      <Aux>
        <div className="seal-content-wrapper">
          <h4 style={{ textTransform: "uppercase" }}>
            SEAL OPTIONS
            <b>
              {this.state.businessData
                ? " - " + this.state.businessData.primaryDomain
                : ""}
            </b>
          </h4>
          <p style={{ marginBottom: "25px" }}>
            Below you will find the options to control the position of your
            Trust Guard trust seal. Use the controls to modify the position and
            copy the code when you are done and simply paste it into the desired
            webpage or in the universal footer to show on all pages.
          </p>
          <p>
            Note: You can use presets to display different seals on different
            pages.
          </p>
        </div>

        <div id="dash-malware-table">
          <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
            <div className="tab-content">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 tg-seals-well">
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-xxs-12 left">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-center header">
                    <button
                      className="btn-tgdark2 btn-md"
                      data-toggle="modal"
                      data-target="#modalCreatePreset"
                    >
                      <b className="fas fa-plus" /> Create New Preset
                    </button>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 menu-list">
                    {trustSealsData !== null ? (
                      <ul id="sealPresetUl" className="list-group">
                        {trustSealsData.hasOwnProperty("sealSettings")
                          ? trustSealsData.sealSettings
                              .map((v, i) => {
                                return (
                                  <li
                                    id={v._id}
                                    key={i}
                                    onClick={() => {
                                      this.showActivePresetContent(v);
                                    }}
                                    className={
                                      v._id === selectedPresetId
                                        ? "list-group-item active"
                                        : "list-group-item"
                                    }
                                  >
                                    {v.presetName}
                                  </li>
                                );
                              })
                              .reverse()
                          : ""}
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-xxs-12 right">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 header">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12 title">
                      <h4>{presetName}</h4>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12 text-right save-button">
                      {presetType === null ? (
                        <button
                          className="btn-danger btn-md"
                          onClick={() => {
                            this.removeSeal();
                          }}
                          style={{}}
                        >
                          <b className="fas fa-trash" />&nbsp; Remove Preset
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 body">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-xxs-12 body-left">
                      <div className="title">Seals</div>
                      <div
                        className="well well-lg"
                        style={{
                          background: "rgb(255, 255, 255)",
                          fontWeight: "normal",
                          fontSize: "13px",
                          color: "#dc3545",
                          borderBottomLeftRadius: "0px",
                          borderTopLeftRadius: "0px",
                          borderLeft: "6px solid #dc3545",
                          borderColor: "#dc3545",
                          padding: "12px 24px",
                          display: displayBlock,
                        }}
                      >
                        Your seals are currently inactive and will not be
                        displayed on your website until you've passed your PCI,
                        Malware and SSL scans. <br />While your seals are
                        inactive we have created a placeholder seal for you to
                        preview. <br />Note that your active seal will display
                        the website name you enter below instead of "Demo".
                      </div>
                      <div
                        className="well well-lg"
                        style={{ background: "#dddddd" }}
                      >
                        <div className="row">
                          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 mt-2 pr-0">
                            <p>
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                Color
                              </label>
                              <input
                                name="color"
                                className="greenRadio sealRadio"
                                type="radio"
                                style={{ marginRight: "12px" }}
                                value="green"
                                checked={color === "green" ? true : ""}
                                onChange={this.handleInputChange}
                              />
                              <input
                                name="color"
                                className="blackRadio sealRadio"
                                type="radio"
                                style={{ marginRight: "12px" }}
                                value="black"
                                checked={color === "black" ? true : ""}
                                onChange={this.handleInputChange}
                              />
                              <input
                                name="color"
                                className="whiteRadio sealRadio"
                                type="radio"
                                style={{ marginRight: "12px" }}
                                value="white"
                                checked={color === "white" ? true : ""}
                                onChange={this.handleInputChange}
                              />
                            </p>

                            <p>
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                Type
                              </label>
                              <select
                                name="sealType"
                                value={sealType}
                                onChange={this.handleInputChange}
                              >
                                <option value="outer">'Bump Out' Shield</option>
                                <option value="inner">Standard Shield</option>
                                {businessData.scanFrequency === "daily" &&
                                businessData.malwareFrequency === "daily" ? (
                                  <option value="checkout">
                                    Banner 'Checkout' Shield
                                  </option>
                                ) : (
                                  ""
                                )}
                                <option value="side">Side Shield</option>
                              </select>
                            </p>

                            <p
                              style={{
                                display:
                                  sealType === "checkout" ? "none" : "block",
                              }}
                            >
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                Placement
                              </label>
                              <select
                                id="selFloatSide"
                                name="floatSide"
                                value={floatSide}
                                onChange={this.handleInputChange}
                                disabled={disableFloatSide ? true : false}
                              >
                                <option value="left">Left</option>
                                <option value="right">Right</option>
                                <option value="standAlone">Stand Alone</option>
                              </select>
                            </p>

                            <div
                              style={{
                                display:
                                  sealType === "checkout" ? "none" : "block",
                                margin: "0 0 10px",
                              }}
                            >
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                {showMobileSeal
                                  ? "Mobile Version"
                                  : "Dektop Version"}
                              </label>
                              <Switch
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onChange={this.handleSwitchChange}
                                checked={showMobileSeal}
                                height={20}
                                width={60}
                              />
                            </div>

                            <div
                              style={{
                                margin: "0 0 10px",
                              }}
                            >
                              <label
                                className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left"
                                style={{
                                  display:
                                    showMobileSeal && sealType !== "checkout"
                                      ? "none"
                                      : "block",
                                }}
                              >
                                {sealType === "side"
                                  ? "Max Height"
                                  : "Max Width"}
                              </label>
                            </div>

                            <div
                              className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-pad text-left"
                              style={{
                                display:
                                  showMobileSeal && sealType !== "checkout"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <Slider
                                rootStyle={sliderStyle}
                                domain={
                                  sealType === "checkout"
                                    ? [225, 400]
                                    : [160, 250]
                                }
                                step={1}
                                mode={2}
                                values={[175]}
                                onUpdate={this.onUpdate}
                              >
                                <Rail>
                                  {({ getRailProps }) => (
                                    <div
                                      style={railStyle}
                                      {...getRailProps()}
                                    />
                                  )}
                                </Rail>
                                <Handles>
                                  {({ handles, getHandleProps }) => (
                                    <div className="slider-handles">
                                      {handles.map((handle) => (
                                        <Handle
                                          key={handle.id}
                                          handle={handle}
                                          getHandleProps={getHandleProps}
                                        />
                                      ))}
                                    </div>
                                  )}
                                </Handles>
                                <Tracks right={false}>
                                  {({ tracks, getTrackProps }) => (
                                    <div className="slider-tracks">
                                      {tracks.map(({ id, source, target }) => (
                                        <Track
                                          key={id}
                                          source={source}
                                          target={target}
                                          getTrackProps={getTrackProps}
                                        />
                                      ))}
                                    </div>
                                  )}
                                </Tracks>
                              </Slider>
                            </div>

                            {sealType !== "checkout" ? (
                              <div
                                style={{
                                  display: showMobileSeal ? "block" : "none",
                                  margin: "0 0 10px",
                                }}
                              >
                                <label className="col-lg-6 col-md-6 col-sm-6 col-xs-6 no-pad text-left">
                                  {sealType === "side"
                                    ? "Max Height on Mobile"
                                    : "Max Width on Mobile"}
                                </label>
                              </div>
                            ) : (
                              ""
                            )}

                            {sealType !== "checkout" ? (
                              <div
                                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-pad text-left"
                                style={{
                                  display: showMobileSeal ? "block" : "none",
                                  margin: "0 0 10px",
                                }}
                              >
                                <Slider
                                  rootStyle={sliderStyle}
                                  domain={[145, 175]}
                                  step={1}
                                  mode={2}
                                  values={[30]}
                                  onUpdate={this.onUpdateMobile}
                                >
                                  <Rail>
                                    {({ getRailProps }) => (
                                      <div
                                        style={railStyle}
                                        {...getRailProps()}
                                      />
                                    )}
                                  </Rail>
                                  <Handles>
                                    {({ handles, getHandleProps }) => (
                                      <div className="slider-handles">
                                        {handles.map((handle) => (
                                          <Handle
                                            key={handle.id}
                                            handle={handle}
                                            getHandleProps={getHandleProps}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </Handles>
                                  <Tracks right={false}>
                                    {({ tracks, getTrackProps }) => (
                                      <div className="slider-tracks">
                                        {tracks.map(
                                          ({ id, source, target }) => (
                                            <Track
                                              key={id}
                                              source={source}
                                              target={target}
                                              getTrackProps={getTrackProps}
                                            />
                                          )
                                        )}
                                      </div>
                                    )}
                                  </Tracks>
                                </Slider>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div
                            className="col-lg-5 col-md-12 col-sm-12 col-xs-12 text-center my-2"
                            style={{
                              display:
                                sealType === "checkout" ? "none" : "flex",
                              alignItems: "center",
                            }}
                          >
                            <p className="previewLabel">Preview</p>

                            {trustSealPath ? (
                              <img
                                className="
                                            my-2
                                          "
                                src={
                                  businessData.lastPciStatus !== "pass"
                                    ? demoTrustSealPath
                                    : trustSealPath
                                }
                                alt="Seal"
                                style={
                                  sealType === "side"
                                    ? {
                                        maxHeight: "250px",
                                        minHeight: sealMaxWidth + "px",
                                        cursor: "pointer",
                                        display: showMobileSeal
                                          ? "none"
                                          : "block",
                                      }
                                    : sealType === "checkout"
                                      ? {
                                          display: "none",
                                        }
                                      : {
                                          maxWidth: sealMaxWidth + "px",
                                          minHeight: "175px",
                                          cursor: "pointer",
                                          display: showMobileSeal
                                            ? "none"
                                            : "block",
                                        }
                                }
                                onClick={() =>
                                  window.open(certificateURL, "_blank")
                                }
                              />
                            ) : (
                              <div
                                style={{
                                  display: showMobileSeal ? "none" : "block",
                                }}
                              >
                                <Spinner />
                              </div>
                            )}

                            {trustSealPath ? (
                              <img
                                className="
                                            my-2
                                          "
                                src={
                                  businessData.lastPciStatus !== "pass"
                                    ? demoTrustSealPath
                                    : trustSealPath
                                }
                                alt="Seal"
                                onError={() =>
                                  this.setState({
                                    trustSealPath: images.tgSealDefault,
                                  })
                                }
                                style={
                                  sealType === "side"
                                    ? {
                                        maxHeight: "250px",
                                        minHeight: sealMaxWidthOnMobile + "px",
                                        cursor: "pointer",
                                        display: showMobileSeal
                                          ? "block"
                                          : "none",
                                      }
                                    : sealType === "checkout"
                                      ? {
                                          display: "none",
                                        }
                                      : {
                                          maxWidth: sealMaxWidthOnMobile + "px",
                                          minHeight: "175px",
                                          cursor: "pointer",
                                          display: showMobileSeal
                                            ? "block"
                                            : "none",
                                        }
                                }
                                onClick={() =>
                                  window.open(certificateURL, "_blank")
                                }
                              />
                            ) : (
                              <div
                                style={{
                                  display: showMobileSeal ? "block" : "none",
                                }}
                              >
                                <Spinner />
                              </div>
                            )}
                          </div>

                          <div
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center my-2"
                            style={{
                              display:
                                sealType === "checkout" ? "block" : "none",
                            }}
                          >
                            {trustSealPath ? (
                              <img
                                className="my-2"
                                src={
                                  businessData.lastPciStatus !== "pass"
                                    ? demoTrustSealPath
                                    : trustSealPath
                                }
                                alt="Seal"
                                onError={() =>
                                  this.setState({
                                    trustSealPath: images.tgSealDefault,
                                  })
                                }
                                onClick={() =>
                                  window.open(certificateURL, "_blank")
                                }
                                style={{
                                  maxWidth: sealMaxWidth + parseInt(100) + "px",
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <div>
                                <Spinner />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <p>
                        <label> Preset Name</label>
                        <input
                          type="text"
                          name="presetName"
                          value={presetName}
                          onChange={this.handleInputChange}
                          onBlur={(evt) => {
                            if (isTextElementChanged) {
                              this.saveSeal();
                              this.setState({
                                isTextElementChanged: false,
                              });
                            }
                          }}
                        />
                      </p>
                      <p>
                        <label> Website Name on Trust Seal</label>
                        <input
                          type="text"
                          name="label"
                          value={label}
                          onChange={this.handleInputChange}
                          onBlur={(evt) => {
                            if (isTextElementChanged) {
                              this.saveSeal();
                              this.setState({
                                isTextElementChanged: false,
                              });
                            }
                          }}
                          style={{ textTransform: "uppercase" }}
                        />
                      </p>
                      <p
                        style={
                          floatSide === "standAlone"
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <label> Offset Placement ( px )</label>
                        <input
                          type="text"
                          name="distanceFromEdge"
                          value={distanceFromEdge}
                          onChange={this.handleInputChange}
                          onBlur={() => {
                            if (isTextElementChanged) {
                              this.saveSeal();
                              this.setState({
                                isTextElementChanged: false,
                              });

                              document
                                .getElementById("btnDisplayModalSealInfo")
                                .click();
                            }
                          }}
                        />
                      </p>
                      {userInfoText ? (
                        <div
                          className="well well-lg info"
                          style={{
                            background: "rgb(255, 255, 255)",
                            fontWeight: "normal",
                            fontSize: "13px",
                            color: "#2e6da4",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px",
                            borderLeft: "6px solid #2e6da4",
                            borderColor: "#337ab7",
                            padding: "12px 24px",
                          }}
                        >
                          {userInfoText}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-xxs-12 body-right">
                      <div className="title">Code</div>
                      {!codeGenerated ? (
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            this.generateSealCode();
                          }}
                        >
                          <i className="fa fa-code" /> Generate Seal Code
                        </button>
                      ) : (
                        <>
                          <div
                            className="well well-lg"
                            style={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <textarea
                              value={sealCode.textboxCode}
                              readOnly={true}
                              style={{
                                height: "290px",
                                border: "none",
                              }}
                            />
                          </div>
                          <div className="text-right mb-3">
                            <CopyToClipboard
                              text={sealCode.textboxCode}
                              onCopy={() =>
                                this.setState(
                                  {
                                    isCopied: true,
                                    // notificationType: "success",
                                    // notificationMessage:
                                    //   "Code copied to clipboard.",
                                  },
                                  () => {
                                    this.setState({
                                      isCopied: false,
                                    });
                                  }
                                )
                              }
                            >
                              <button className="btn-tglight2 btn-md">
                                <b className="fas fa-copy" />&nbsp; Copy to
                                Clipboard
                              </button>
                            </CopyToClipboard>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TrustSealsInfo />

        <TrustSealsCreatePreset createSealPreset={this.createSealPreset} />

        <button
          id="btnDisplayModalSealInfo"
          data-toggle="modal"
          data-target="#modalSealInfo"
          style={{ display: "none" }}
        >
          Seal Info
        </button>
      </Aux>
    );
  }
}

SealComponent.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealComponent);
