import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { callOnGetPciScanData } from "../../../../services/scanHistoryService";
import ScanStatusButton from "../scanStatusButton";
import ModalDownloadOption from "../modals/modalDownloadOption";
import ModalRescan from "../modals/modalRescan";
import { Modal } from "react-bootstrap";
import { capitalize } from "../../../../helpers/capitalize";
import AppContext from "../../../../components/context/appContext";
import SlidePanelContext from "../../../../components/context/slidePanelContexts";
import RouteContext from "../../../../components/context/routeContext";
import Spinner from "../../../../components/ui/spinner/spinnerRectScan";
import fileDownload from "js-file-download";
import {
  callOnGetBusinessById,
  callDownloadPCIreport,
} from "../../../../services/index";
import moment from "moment";
class PciSlidePanelData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scanData: [],
      scanNumber: [],
      reportType: "pci",
      reportFormat: "pdf",
      isDownloading: false,
      scanning: false,
      rescansubmitted: false,
      showPCIscanToast: false,
      scanTimeDetails: {
        scanTime: null,
        scanTimeZone: null,
      },
    };
  }

  async getScanHistory() {
    const scanHistory = await callOnGetPciScanData(this.props.businessId);
    if (this.state.scanData.length > 0) {
    }
    this.setState({
      scanData: scanHistory.data,
    });
  }

  componentDidMount() {
    this.getScanHistory();
  }

  getDownloadReport = async (index) => {
    const toggledBusinessIndex = this.props.index;

    const businessData = this.context.userBusinessData[toggledBusinessIndex];

    const toggledBusinessId = businessData._id;

    this.setState({ isDownloading: true });
    const userBusiness = await callOnGetBusinessById(toggledBusinessId);
    const scanVendor = userBusiness.scanVendor;
    const networkID = scanVendor.owner;
    const api = scanVendor.apiKey;
    const businessName = businessData.primaryDomain;

    const ReportResponse = await callDownloadPCIreport({
      networkID: networkID,
      api: api,
      businessName: businessName,
      format: this.state.reportFormat,
      type: this.state.reportType,
      scanNumber: this.state.scanNumber,
      scanDate: this.state.scanDate,
    });
    await fileDownload(ReportResponse.data, ReportResponse.filename);

    this.setState({ isDownloading: false });
  };

  reportTypeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  selectedReport = (index, scanNumber, scanDate) => {
    this.setState({ scanDate: scanDate, scanNumber: scanNumber });
  };

  getVulnerabilityDetail = async (owner, id, scandate, severity) => {};

  scanHistoryContent() {
    const { scanData, reportFormat, reportType } = this.state;
    const { index } = this.props;
    const business = this.context.userBusinessData[index];
    let highCountStyle = {
      display: "block",
      width: "34px",
      background: "rgba(255, 255, 255, 0.1)",
      color: "black",
      border: "1px solid #d8d4d4",
    };

    let mediumCountStyle = {
      display: "block",
      width: "34px",
      background: "rgba(255, 255, 255, 0.1)",
      color: "black",
      border: "1px solid #d8d4d4",
    };

    let lowCountStyle = {
      display: "block",
      width: "34px",
      background: "rgba(255, 255, 255, 0.1)",
      color: "black",
      border: "1px solid #d8d4d4",
    };

    return scanData.map((scan, i) => {
      const high = +scan.high;
      const medium = +scan.medium;
      const low = +scan.low;
      const status = scan.status;
      const scanNumber = scan.scanNumber;
      const scanDate =
        scan.scanDate !== null
          ? moment(scan.scanDate)
              .utc()
              .format("MM/DD/YYYY h:m:s a")
          : "N/A";
      const owner = scan.owner;
      const scanId = scan._id;
      const frequency =
        scan.flag === "manual" ? "Rescanned" : scan.scanFrequency;
      let rescanButton = null;
      let statusButton = null;

      const riskFactors = [
        high > 0 ? 8 : 0,
        medium > 0 ? 4 : 0,
        low > 0 ? 1 : 0,
      ];

      const modalShowDownloadReportOpt = (
        <ModalDownloadOption
          reports={[]}
          targetID={"dash-download-modal-" + i}
          defaultValueFormat={reportFormat}
          defaultValueType={reportType}
          reportTypeHandler={this.reportTypeHandler}
          downloadReport={() => this.getDownloadReport(i)}
          scanNumber={scanNumber}
          showRemediation={status === "fail"}
        />
      );

      statusButton = (
        <ScanStatusButton
          status={scan.status}
          selectedReport={() =>
            this.selectedReport(index, scanNumber, scanDate)
          }
          index={i}
        />
      );

      if (
        status !== "pending" &&
        status !== "running" &&
        status !== "finalizing"
      ) {
        let falsePositiveButton = false;
        if (high >= 1 || medium >= 1) {
          falsePositiveButton = true;
        }
        if (business.accountType === "free") {
          if (i === 1) {
            rescanButton = (
              <a
                rel="noopener noreferrer"
                href="https://trustguard.com/home"
                target="_blank"
                className="btn btn-success btn-sm">
                <i className="fas fa-search" />&nbsp; Rescan
              </a>
            );
          }
        } else {
          if (i === 0) {
            rescanButton = (
              <>
                <RouteContext.Consumer>
                  {(value) => {
                    return (
                      <ModalRescan
                        index={i + 1}
                        toggledIndex={index}
                        scanData={{ _id: business._id }}
                        rescan={() => {}}
                        goToPageVulnerabilities={() =>
                          value.goToPageVulnerabilities(
                            scan._id,
                            owner,
                            riskFactors,
                            scanDate,
                            business
                          )
                        }
                        owner={owner}
                        resultId={scanId}
                        scandate={scanDate}
                        high={high}
                        medium={medium}
                        low={low}
                      />
                    );
                  }}
                </RouteContext.Consumer>
              </>
            );
          }
        }
      } else {
        if (i === 1) {
          if (
            status === "running" ||
            status === "pending" ||
            status === "finalizing"
          )
            rescanButton = (
              <button
                type="button"
                className="btn btn-success btn-sm"
                disabled={true}>
                <i className="fas fas-sync" />&nbsp; Rescan
              </button>
            );
        }
      }

      let downloadReportButton = (
        <span style={{ textTransform: "capitalize" }}>{statusButton}</span>
      );

      if (
        status !== "pending" &&
        status !== "running" &&
        status !== "finalizing"
      ) {
        downloadReportButton = (
          <button
            className="tg-dashboard-link"
            data-toggle="modal"
            data-target={"#dash-download-modal-" + i}
            style={{ cursor: "pointer", textTransform: "capitalize" }}>
            {statusButton}
          </button>
        );
      }

      const total = high + medium + low;
      if (high > 0) {
        highCountStyle = {
          display: "block",
          width: "34px",
          cursor: "pointer",
        };
      }

      if (medium > 0) {
        mediumCountStyle = {
          display: "block",
          width: "34px",
          cursor: "pointer",
        };
      }

      if (low > 0) {
        lowCountStyle = {
          display: "block",
          width: "34px",
          cursor: "pointer",
        };
      }

      return (
        <RouteContext.Consumer>
          {(value) => (
            <tr className="pciTable">
              <td />

              <td>{scanDate}</td>
              <td style={{ textTransform: "capitalize" }}>
                {frequency !== null
                  ? status !== "invalid"
                    ? frequency
                    : "Invalid URL"
                  : ""}
              </td>
              <td className="text-center px-0">
                <span
                  style={{
                    textAlign: "center",
                    width: "42px",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}>
                  {high > 0 ? (
                    <span
                      className="bg bg-sm bg-serious"
                      style={highCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }>
                      {" "}
                      {high}{" "}
                    </span>
                  ) : (
                    <span
                      className="bg bg-sm bg-serious"
                      style={highCountStyle}>
                      {" "}
                      {high}{" "}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: "center",
                    width: "43px",
                    display: "inline-block",
                    paddingLeft: "6px",
                  }}>
                  {medium > 0 ? (
                    <span
                      className="bg bg-sm bg-medium"
                      style={mediumCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }>
                      {" "}
                      {medium}{" "}
                    </span>
                  ) : (
                    <span
                      className="bg bg-sm bg-medium"
                      style={mediumCountStyle}>
                      {" "}
                      {medium}{" "}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: "center",
                    width: "43px",
                    display: "inline-block",
                    paddingLeft: "6px",
                  }}>
                  {low > 0 ? (
                    <span
                      className="bg bg-sm bg-green"
                      style={lowCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }>
                      {" "}
                      {low}{" "}
                    </span>
                  ) : (
                    <span className="bg bg-sm bg-green" style={lowCountStyle}>
                      {" "}
                      {low}{" "}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: "center",
                    width: "43px",
                    display: "inline-block",
                    paddingLeft: "6px",
                  }}>
                  {total > 0 ? (
                    <span
                      className="bg bg-sm bg-green"
                      style={lowCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }>
                      {" "}
                      {total}{" "}
                    </span>
                  ) : (
                    <span className="bg bg-sm bg-green" style={lowCountStyle}>
                      {" "}
                      {total}{" "}
                    </span>
                  )}
                </span>
              </td>
              <td className="pr-0">
                {downloadReportButton}
                {modalShowDownloadReportOpt}
              </td>
              <td className="text-success scan-btn">{rescanButton}</td>
            </tr>
          )}
        </RouteContext.Consumer>
      );
    });
  }

  render() {
    const { isDownloading } = this.state;
    return (
      <>
        <Modal
          show={isDownloading}
          backdropClassName="modalBackdrop"
          size="lg"
          dialogClassName="modalBoxDialog">
          <Modal.Body>
            <p className="textNotify">
              One moment, we are generating your report.
            </p>
            <Spinner />
          </Modal.Body>
        </Modal>
        {this.scanHistoryContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
PciSlidePanelData.contextType = AppContext;
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PciSlidePanelData);
