import React from "react";
import Aux from "../hoc/containerAux";
import ModalMalware from "../../components/ui/modal/modalMalware";
import MalwareScanResultInModal from "./malwareScanResultInModal";

class MalwareModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      domainInfoID,
      scanFinish,
      scanStatus,
      domainID,
      sendForReview,
      history,
      reportUrl,
      malwareHistoryID,
      changeClassNames,
    } = this.props;

    return (
      <Aux>
        <ModalMalware
          targetID={this.props.targetID}
          title="Vulnerabilities"
          wide
        >
          <table className="table bordered">
            <MalwareScanResultInModal
              key={domainInfoID}
              scanFinish={scanFinish}
              scanStatus={scanStatus}
              domainInfoID={domainInfoID}
              domainID={domainID}
              sendForReview={sendForReview}
              history={history}
              reportUrl={reportUrl}
              malwareHistoryID={malwareHistoryID}
              changeClassNames={() => changeClassNames()}
            />
          </table>
        </ModalMalware>
      </Aux>
    );
  }
}

export default MalwareModal;
