import { urlWithWww } from "./general";

function checkValidDomain(event, state) {
  const info = { ...state.info };
  let validateDomain = false;
  if (state.subdomains.length > 0 && event.target.name === "host") {
    info[event.target.name] = event.target.value
      .toLowerCase()
      .concat("", event.target.placeholder);
    const primaryDomain = state.subdomains[0].host;
    const inputDomain = event.target.value.toLowerCase();
    let validDomain = false;
    let validIP = false;
    const regexRuleIP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm;
    const regexRulewww = /(www)./gm;
    const siteWithWww = regexRulewww.test(primaryDomain);
    const isIP = regexRuleIP.test(inputDomain);

    if (!isIP) {
      validIP = false;
      let rootDomain = "";
      let checkDomain = "";
      if (siteWithWww) {
        rootDomain = primaryDomain.split(".");
        checkDomain = rootDomain[1] + "." + rootDomain[2];
        checkDomain = checkDomain.split(".");
      } else {
        checkDomain = primaryDomain.split(".");
      }

      const inputUrl = inputDomain.split(".");
      const ext1 = checkDomain[1];
      const ext2 = checkDomain[2] ? "[.]" + checkDomain[2] : "";
      const regexRuleSubDomain =
        "([a-z0-9]+[.])*" + checkDomain[0] + "[.]" + ext1 + ext2;

      const isValidDomain = new RegExp(`${regexRuleSubDomain}`, "g");
      if (urlWithWww(inputDomain)) {
        validDomain = false;
      } else {
        if (checkDomain[2] !== undefined) {
          if (checkDomain[2] === inputUrl[3]) {
            if (isValidDomain.test(inputDomain)) {
              validDomain = true;
            }
          }
        } else {
          if (ext1 === inputUrl[2]) {
            if (isValidDomain.test(inputDomain)) {
              validDomain = true;
            }
          }
        }
      }
    } else {
      validIP = true;
    }

    if ((validIP === false && validDomain === true) || validIP) {
      validateDomain = true;
    } else {
      validateDomain = false;
    }
  }

  return validateDomain;
}

export { checkValidDomain };
