import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import Header from "../header";
import { getStorageItem } from "../../helpers/storage";
import ToastMessage from "../../components/toast/toastMessage";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../../views/charts/line";

import AppContext from "../../components/context/appContext";

import { updateBusinessData } from "../../modules/actions";

class BuyerProtectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      userBusinessData: [],
      websiteType: "other",
      /* eslint-disable */
      codeBox:
        '<script type="text/javascript">' +
        'var email = "__EMAIL__";' +
        'var name = "__NAME__";' +
        'var order_number = "";' +
        'var order_amount = "";' +
        'var address1 = "";' +
        'var address2 = "";' +
        'var city = "";' +
        'var state = "";' +
        'var zip = "";' +
        'var country = "";' +
        'var phone = "__PHONE__";' +
        'var key = "__CUSTOMER_KEY__";' +
        'var domain = "https://member.4xbuyerprotection.com/gener/capture.php?email="+email+"&name="+name+"&order_number="+order_number+"&order_amount="+order_amount+"&address1="+address1+"&address2="+address2+"&city="+city+"&state="+state+"&zip="+zip+"&country="+country+"&phone="+phone+"&key="+key;' +
        'document.write(\'<img src=""' +
        " +domain +" +
        '" height="1" width="1" />\');' +
        "</script>",
      /* eslint-enable */
      currentBusinessData: {},
      isDisabled: false,
      integrationStatus: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData, userBusinessData, currentBusinessId } = this.context;

    if (
      prevState.userData !== userData &&
      prevState.userBusinessData !== userBusinessData
    ) {
      if (currentBusinessId) {
        const tempBusiness = userBusinessData.filter((v, i) => {
          return v._id === currentBusinessId ? v : null;
        });

        this.setState({
          userData: userData,
          userBusinessData: userBusinessData,
          currentBusinessData: tempBusiness[0],
          integrationStatus:
            tempBusiness &&
            tempBusiness.length &&
            tempBusiness[0].hasOwnProperty("details4X") &&
            tempBusiness[0].details4X &&
            tempBusiness[0].details4X.hasOwnProperty("integrationStatus")
              ? tempBusiness[0].details4X.integrationStatus
              : false,
        });
      }
    }
  }

  clickWebsiteTypeRadio = (thisValue) => {
    document.getElementById(thisValue).click();
  };

  updateBusinessData = () => {
    this.setState({
      isDisabled: true,
    });

    let details4xData =
      this.state.currentBusinessData && this.state.currentBusinessData.details4X
        ? this.state.currentBusinessData.details4X
        : null;
    if (details4xData) {
      Object.assign(details4xData, {
        integrationStatus: true,
        websiteType: this.state.websiteType,
      });

      this.props.updateBusinessData(this.context.currentBusinessId, {
        purpose: "buyerProtection",
        details4X: details4xData,
      });

      setTimeout(() => {
        this.setState({
          isDisabled: false,
        });
      }, 2500);
    }
  };

  render() {
    const {
      websiteType,
      codeBox,
      currentBusinessData,
      isDisabled,
      integrationStatus,
    } = this.state;

    const apiKey =
      currentBusinessData &&
      currentBusinessData.hasOwnProperty("details4X") &&
      currentBusinessData.details4X &&
      currentBusinessData.details4X.hasOwnProperty("merchant") &&
      currentBusinessData.details4X.merchant &&
      currentBusinessData.details4X.merchant.api_key
        ? currentBusinessData.details4X.merchant.api_key
        : "";

    return (
      <>
        <p style={{ marginBottom: "25px" }}>
          Welcome to Buyer Protection, where we insure your products. To get
          started please follow the instructions below.
        </p>
        <p>
          Implementing Buyer Protection is usually pretty easy but we need to
          add some code into your thank you page to get started.
        </p>
        <p>
          If you have any questions feel free to reach out to us at
          <b> support@trust-guard.com</b>
        </p>
        <p style={{ marginTop: "25px" }}>
          <b>Please select how your website is created:</b>
        </p>
        <p>
          {/* <input
                        id="rdoWebsiteTypeShopify"
                        type="radio"
                        name="websiteType"
                        value="shopify"
                        checked={websiteType === "shopify" ? true : false}
                        onChange={(evt) => {
                          this.setwebsiteType(evt);
                        }}
                        style={{ cursor: "pointer" }}
                      />{" "}
                      <label
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                        onClick={() =>
                          this.clickWebsiteTypeRadio("rdoWebsiteTypeShopify")
                        }
                      >
                        Shopify
                      </label>
                      <br />
                      <br />
                      <input
                        id="rdoWebsiteTypeWooCommerce"
                        type="radio"
                        name="websiteType"
                        value="WooCommerce"
                        checked={websiteType === "WooCommerce" ? true : false}
                        onChange={(evt) => {
                          this.setwebsiteType(evt);
                        }}
                        style={{ cursor: "pointer" }}
                      />{" "}
                      <label
                        onClick={() =>
                          this.clickWebsiteTypeRadio(
                            "rdoWebsiteTypeWooCommerce"
                          )
                        }
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                      >
                        WooCommerce
                      </label>
                      <br />
                      <br /> */}
          <input
            id="rdoWebsiteTypeOther"
            type="radio"
            name="websiteType"
            value="other"
            checked={websiteType === "other" ? true : false}
            onChange={(evt) => {
              this.setState({
                websiteType: evt.target.value,
              });
            }}
            style={{ cursor: "pointer" }}
          />
          <label
            onClick={() => this.clickWebsiteTypeRadio("rdoWebsiteTypeOther")}
            style={{ cursor: "pointer", marginLeft: "15px" }}
          >
            Custom Website
          </label>
        </p>
        {websiteType === "other" ? (
          <div>
            <p style={{ marginTop: "25px" }}>
              <b>
                Please paste the following code into your websites thank you
                page:
              </b>
            </p>
            <div
              className="well well-lg"
              style={{
                width: "750px",
                backgroundColor: "rgb(255, 255, 255)",
              }}
            >
              <textarea
                style={{
                  width: "100%",
                  height: "350px",
                }}
                readOnly="readonly"
                value={codeBox
                  .replace("__EMAIL__", "")
                  .replace("__NAME__", "")
                  .replace("__CUSTOMER_KEY__", apiKey)
                  .replace("__PHONE__", "")}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <p>
          <button
            className="btn-success btn-md btn"
            onClick={() => {
              this.updateBusinessData();
            }}
            disabled={isDisabled}
            style={{
              display: integrationStatus === true ? "none" : "block",
            }}
          >
            Completed
          </button>
        </p>
      </>
    );
  }
}

BuyerProtectionForm.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateBusinessData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyerProtectionForm);
