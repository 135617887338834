import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteConfirmation from "../../components/confrimation/deleteConfirmation";
import { getStorageItem, removeStorageItem } from "../../helpers/storage";
import { callGetAllUsers } from "../../services";
import {
  onCreateEmail,
  getAllEmails,
  resetStatus,
  onUpdateEmail,
  onDeleteEmail,
  sendTestEmail,
} from "../../modules/actions/index";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class CreateEmail extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      emailTag: "",
      emailName: "",
      from: "support@trust-guard.com",
      subject: "",
      body: "",
      type: "",
      emails: [],
      emailId: "",
      edit: false,
      userId: "",
      sendSms: false,
      smsBody: "",
      usersList: [],
      selectedUserId: "",
      selectedUsersBusinesses: [],
      selectedUsersBusinessesId: "",
      sendUserNotification: false,
      userNotificationDetails: "",
      userNotificationRegarding: "",
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.messageType === "create-email-success") {
      toast.success("A new email is created successfuly", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.resetAll();
    }

    if (newProps.messageType === "test-email-send-successfully") {
      toast.success("Test email sent successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.resetAll();
    }

    if (newProps.messageType === "test-email-process-was-unsccessfull") {
      toast.danger("Test email sent was unsuccessfull.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.resetAll();
    }

    if (newProps.emails) {
      this.setState({ emails: newProps.emails });
    }
  }

  componentWillMount() {
    removeStorageItem("businessID");
    let userData = getStorageItem("userData");
    if (userData) {
      let userString = cryptr.decrypt(userData);
      let user = JSON.parse(userString);
      this.setState({ userId: user._id });
    }
    let query = {
      query: {
        status: "active",
      },
    };
    this.props.getAllEmails(query);
  }

  resetAll = () => {
    this.setState({
      emailTag: "",
      emailName: "",
      subject: "",
      body: "",
      emailId: "",
      smsBody: "",
      type: "",
      edit: false,
    });
    this.props.resetStatus();
  };

  onChangeHandelEmailInput(evt) {
    let name = evt.target.name;
    let value = evt.target.value;

    this.setState({
      [name]: value,
    });
  }

  onClickSaveEmail = () => {
    if (this.state.emailId) {
      const updateData = {
        emailTag: this.state.emailTag,
        name: this.state.emailName,
        from: this.state.from,
        subject: this.state.subject,
        body: this.state.body,
        sendSms: this.state.sendSms,
        smsBody: this.state.smsBody,
        sendUserNotification: this.state.sendUserNotification,
        userNotificationDetails: this.state.userNotificationDetails,
        userNotificationRegarding: this.state.userNotificationRegarding,
        type: this.state.type,
      };
      if (updateData) {
        this.props.onUpdateEmail(this.state.emailId, updateData);
      }
      this.resetAll();
    } else {
      if (
        this.state.emailTag.length <= 0 ||
        this.state.emailName.length <= 0 ||
        this.state.subject.length <= 0 ||
        this.state.body.length <= 0
      ) {
        toast.info("You can not leave blank any field", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const emailData = {
          emailTag: this.state.emailTag,
          name: this.state.emailName,
          from: this.state.from,
          subject: this.state.subject,
          body: this.state.body,
          sendSms: this.state.sendSms,
          smsBody: this.state.smsBody,
          type: this.state.type,
          status: "active",
        };

        this.props.onCreateEmail(emailData);
      }
    }
  };

  onClickEditEmail = async (email) => {
    if (email) {
      const allUsersData = await callGetAllUsers({
        query: {
          "userDetails.isAdmin": true,
        },
      });

      this.setState({
        emailTag: email.emailTag,
        emailName: email.name,
        subject: email.subject,
        body: email.body,
        emailId: email._id,
        type: email.type ? email.type : "",
        edit: true,
        sendSms: email.sendSms,
        smsBody: email.smsBody,
        sendUserNotification: email.sendUserNotification,
        userNotificationDetails: email.userNotificationDetails,
        userNotificationRegarding: email.userNotificationRegarding,
        usersList:
          allUsersData.total && allUsersData.total > 0 ? allUsersData.data : [],
      });
    }
  };

  onClickSetEmailId = (emailId) => {
    if (emailId) {
      this.setState({
        emailId: emailId,
      });
    }
  };

  onClickDeleteEmail = () => {
    if (this.state.emailId) {
      this.props.onDeleteEmail(this.state.emailId, this.state.userId);
    }
  };

  getSelectedUsersBusiness = (evt) => {
    const selectedUsersBusiness = [];

    const selectedUserData = this.state.usersList.filter((v, i) => {
      return v._id === evt.target.value;
    });

    if (Array.isArray(selectedUserData[0].businessDetails)) {
      for (let c = 0; c < selectedUserData[0].businessDetails.length; c++) {
        if (
          selectedUserData[0].businessDetails[c] &&
          selectedUserData[0].businessDetails[c].businessData
        ) {
          if (
            Array.isArray(selectedUserData[0].businessDetails[c].businessData)
          ) {
            for (
              let i = 0;
              i < selectedUserData[0].businessDetails.businessData;
              i++
            ) {
              selectedUsersBusiness.push({
                _id: selectedUserData[0].businessDetails[c].businessData[i]._id,
                primaryDomain:
                  selectedUserData[0].businessDetails[c].businessData[i]
                    .primaryDomain,
              });
            }
          } else {
            selectedUsersBusiness.push({
              _id: selectedUserData[0].businessDetails[c].businessData._id,
              primaryDomain:
                selectedUserData[0].businessDetails[c].businessData
                  .primaryDomain,
            });
          }
        }
      }
    }

    this.setState({
      selectedUsersBusiness: selectedUsersBusiness,
      selectedUserId: selectedUserData[0]._id,
    });
  };

  setSelectedUsersBusiness = (evt) => {
    this.setState({
      selectedUsersBusinessesId: evt.target.value,
    });
  };

  sendTestEmail = () => {
    const emailData = {
      emailId: this.state.emailId,
      userId: this.state.selectedUserId,
      businessId: this.state.selectedUsersBusinessesId,
      status: "pending",
      customMessage: "",
      sentDate: "",
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    this.props.sendTestEmail(emailData);

    this.setState({
      selectedUserId: "",
      selectedUsersBusinesses: [],
      selectedUsersBusinessesId: "",
    });

    document.getElementById("divCloseSendTestEmailModal").click();
  };

  render() {
    const {
      emailTag,
      emailName,
      subject,
      type,
      body,
      sendSms,
      sendUserNotification,
      edit,
      emails,
      usersList,
      smsBody,
      userNotificationDetails,
      userNotificationRegarding,
      selectedUsersBusiness,
    } = this.state;

    removeStorageItem("businessID");
    return (
      <div>
        <h4 className="mb-1">Create Email</h4>
        <h5 className="">Email Info</h5>
        <br />

        <div>
          <div className="Rtable Rtable--6cols Rtable--collapse">
            <div className="Rtable-cell p-sm pl-0">
              <span className="Rparent-label-mobile">Email Tag</span>
              <br />
              <input
                type="text"
                className="form-control p-sm"
                placeholder="Enter email tag"
                name="emailTag"
                value={emailTag}
                onChange={this.onChangeHandelEmailInput.bind(this)}
              />
            </div>
            <div className="Rtable-cell p-sm">
              <span className="Rparent-label-mobile">Email Name</span>
              <br />
              <input
                type="text"
                className="form-control"
                placeholder="Enter an email name"
                name="emailName"
                value={emailName}
                onChange={this.onChangeHandelEmailInput.bind(this)}
              />
            </div>

            <div className="Rtable-cell p-sm">
              <span className="Rparent-label-mobile">Email Subject</span>
              <br />
              <input
                type="text"
                className="form-control"
                placeholder="Subject for email"
                value={subject}
                name="subject"
                onChange={this.onChangeHandelEmailInput.bind(this)}
              />
            </div>

            <div className="Rtable-cell p-sm">
              <span className="Rparent-label-mobile">Email Type</span>
              <br />
              <select
                className="form-control"
                value={type}
                name="type"
                onChange={this.onChangeHandelEmailInput.bind(this)}
              >
                <option select="false">Select email type</option>
                <option value="pci-scan">PCI</option>
                <option value="malware-scan">Malware</option>
                <option value="ssl-scan">SSL</option>
                <option value="tg-messages">System</option>
                <option value="other-messages">Others</option>
              </select>
            </div>
          </div>
          <div>
            <span>Email Body</span>
            <br />
            <textarea
              name="body"
              rows="12"
              placeholder="Enter email body"
              value={body}
              onChange={this.onChangeHandelEmailInput.bind(this)}
              style={{ width: "100%" }}
            />
          </div>

          <div
            style={{
              marginTop: "15px",
            }}
          >
            <input
              type="checkbox"
              value={sendSms}
              checked={sendSms}
              onChange={() => {
                this.setState({
                  sendSms: !sendSms,
                });
              }}
              style={{ cursor: "pointer" }}
            />{" "}
            <label
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  sendSms: !sendSms,
                });
              }}
            >
              Send SMS
            </label>
          </div>

          <div
            style={{
              marginTop: "15px",
              display: sendSms ? "block" : "none",
            }}
          >
            <span>SMS Body</span>
            <br />
            <textarea
              name="smsBody"
              rows="12"
              placeholder="Enter SMS body"
              value={smsBody}
              onChange={this.onChangeHandelEmailInput.bind(this)}
              style={{ width: "100%" }}
            />
          </div>

          <div
            style={{
              marginTop: "15px",
            }}
          >
            <input
              type="checkbox"
              value={sendUserNotification}
              checked={sendUserNotification}
              onChange={() => {
                this.setState({
                  sendUserNotification: !sendUserNotification,
                });
              }}
              style={{ cursor: "pointer" }}
            />{" "}
            <label
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  sendUserNotification: !sendUserNotification,
                });
              }}
            >
              Send User Notification
            </label>
          </div>

          <div
            style={{
              marginTop: "15px",
              display: sendUserNotification ? "block" : "none",
            }}
          >
            <span>User Notification Details</span>
            <br />
            <input
              name="userNotificationDetails"
              value={userNotificationDetails}
              onChange={this.onChangeHandelEmailInput.bind(this)}
              style={{ width: "100%" }}
            />
            <br />
            <br />
            <span>User Notification Regarding</span>
            <br />
            <textarea
              name="userNotificationRegarding"
              rows="12"
              placeholder="Enter User Notification regarding"
              value={userNotificationRegarding}
              onChange={this.onChangeHandelEmailInput.bind(this)}
              style={{ width: "100%" }}
            />
          </div>

          <div style={{ display: "block", width: "100%", marginTop: "25px" }}>
            {edit ? (
              <button
                className="btn-danger btn-md btn-140-width"
                data-target="#divSendTestEmailModal"
                data-toggle="modal"
              >
                Send Test Email
              </button>
            ) : (
              ""
            )}

            <button
              className="btn-success btn-md btn-140-width pull-right"
              onClick={() => this.onClickSaveEmail()}
            >
              {edit ? "Update" : "Create Email"}
            </button>
          </div>
        </div>

        <br />
        <br />
        <br />

        {emails.length > 0 ? (
          <div
            className="table-wrapper-scroll-y"
            style={{ display: "block", clear: "both", marginTop: "25px" }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Email Tag</th>
                  <th scope="col">Email name</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Body</th>
                  <th scope="col" style={{ width: "15%" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {emails.map((email) => {
                  return (
                    <tr key={email._id}>
                      <td>{email.emailTag}</td>
                      <td>{email.name}</td>
                      <td>{email.subject}</td>
                      <td>{email.body}</td>
                      <td>
                        <button
                          className="text-left btn btn-secondary btn-md mr-2"
                          onClick={() => {
                            this.onClickEditEmail(email);
                          }}
                          style={{}}
                        >
                          <i className="fas fa-edit" />&nbsp; Edit
                        </button>

                        <button
                          data-toggle="modal"
                          data-target="#deleteConfirmation"
                          className="text-left btn btn-danger btn-md"
                          onClick={() => {
                            this.onClickSetEmailId(email._id);
                          }}
                        >
                          <i className="fas fa-times" />&nbsp; Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
        <br />
        <br />

        <div
          id="deleteConfirmation"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <DeleteConfirmation onDelete={this.onClickDeleteEmail} />
        </div>

        <div
          id="divSendTestEmailModal"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content col-md-12">
              <div className="modal-header">
                <h4 className="modal-title f800">Send Test Email</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <label>User:</label>
                  <br />
                  <br />
                  <select
                    className="form-control"
                    onChange={(evt) => {
                      this.getSelectedUsersBusiness(evt);
                    }}
                  >
                    <option>Please select</option>
                    {usersList &&
                      usersList.length > 0 &&
                      usersList.map((v, i) => {
                        return (
                          <option value={v._id}>
                            {v.firstName +
                              " " +
                              v.lastName +
                              "  <" +
                              v.email +
                              ">"}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="col-md-6 col-sm-4 col-xs-12">
                  <label>Business:</label>
                  <br />
                  <br />
                  <select
                    className="form-control"
                    onChange={(evt) => {
                      this.setSelectedUsersBusiness(evt);
                    }}
                  >
                    <option>Please select</option>
                    {selectedUsersBusiness && selectedUsersBusiness.length > 0
                      ? selectedUsersBusiness.map((v, i) => {
                          return (
                            <option key={i} value={v._id}>
                              {v.primaryDomain}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </div>
              <div className="modal-footer text-right">
                <button
                  id="divCloseSendTestEmailModal"
                  className="btn-danger btn-md btn-140-width"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  className="btn-success btn-md btn-140-width"
                  onClick={() => {
                    this.sendTestEmail();
                  }}
                >
                  Send Test Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    emails: state.accountStore.emails,
    messageType: state.accountStore.messageType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onCreateEmail,
      getAllEmails,
      resetStatus,
      onUpdateEmail,
      onDeleteEmail,
      sendTestEmail,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEmail);
