import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import AppContext from "../../components/context/appContext";
import SecurityOfficer from "../modals/securityofficer";
import AddUserModal from "../modals/addUserModal";
import { callApiGetBusinessUsersList } from "../../services/";
import DeleteUserConfirmation from "../modals/deleteUserConfirmation";

class AccountUserData extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      businessId: null,
      userList: [],
      toolTipText: {
        admin: "You are a business Admin",
        "Account Admin": "Account Admin",
        "Account Manager":
          "Can update billing, change frequency of scans, can download and view scan reports, can manage Scan Report Access. Cannot access admin or account info.",
        "Scan Report Access":
          "Can view, download and submit false positives in regards to all scan functions, will be notified of failed scans. Cannot access scan frequency, billing, admin or account info.",
      },
      securityOfficeUserIndex: "",
      securityOfficeUser: "",
      showAlert: false,
      deletableUserId: null,
    };

    this.populateBusinessUsersList = this.populateBusinessUsersList.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId, businessUsersList } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      const businessUserList = await callApiGetBusinessUsersList({
        businessId: currentBusinessId,
      });
      this.setState({
        businessId: currentBusinessId,
        userList: businessUserList.data,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.businessUsersList &&
      newProps.businessUsersList.hasOwnProperty("data") &&
      newProps.businessUsersList.data.length > 0
    ) {
      this.setState({
        showAlert: true,
        userList: newProps.businessUsersList.data,
      });
    }
  }

  populateBusinessUsersList = async (businessId) => {
    setTimeout(async () => {
      const businessUserList = await callApiGetBusinessUsersList({
        businessId: businessId,
      });

      this.setState({
        userList: businessUserList.data,
      });
    }, 1000);
  };

  updateUIsecurityOfficerStatus = () => {
    const index = this.state.securityOfficeUserIndex;
    const userList = [...this.state.userList];
    userList[index].userDetails.isSecurityOfficer = true;
    this.setState({ userList });
  };

  checkSecurityOfficerStatus = (index, user) => {
    let buttonMarkup = (
      <button
        disabled
        className="pull-right btn btn-success btn-sm btn-140-width"
        style={{ width: "145px" }}
      >
        {" "}
        Pending
      </button>
    );

    if (user.userDetails.apiKey) {
      buttonMarkup = (
        <button
          data-toggle="modal"
          data-target="#security-officer"
          className="pull-right btn btn-secondary btn-sm"
          onClick={() =>
            this.setState({
              securityOfficeUserIndex: index,
              securityOfficeUser: user._id,
            })
          }
        >
          Set as Official PCI User
        </button>
      );
    }

    return buttonMarkup;
  };

  render() {
    const { userList, toolTipText, showAlert } = this.state;

    return (
      <>
        {showAlert === true ? (
          <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
            {userList && userList.length > 0 ? (
              _.some(userList, { userStatus: "pending" }) ? (
                <div className="alert alert-info">
                  Please allow about 10-15 minutes for pending users to be fully
                  created before setting them as the pci user.
                </div>
              ) : null
            ) : null}
          </div>
        ) : (
          ""
        )}

        <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
          <div className="inner-ptab tab-content">
            <div id="seal" className="tab-pane fade in active">
              <div className="Rtable Rtable--6cols Rtable--collapse">
                <table id="dash-overview-table" className="table table-striped">
                  <tbody className="scan-status-list">
                    <tr>
                      <td
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          margin: "5px 0",
                        }}
                      >
                        USER PERMISSIONS
                      </td>
                      <td width="40%" colSpan="5">
                        <span className="pull-right">
                          <button
                            data-toggle="modal"
                            data-target="#accountSettingsAddUserModal"
                            className="btn-success btn-md btn pull-right"
                            data-testid="btnAddBusinessUser"
                          >
                            <i className="fas fa-user-plus" />
                            Add Users
                          </button>
                        </span>

                        <AddUserModal />
                      </td>
                    </tr>
                    <tr>
                      <th width="20%">Name</th>
                      <th width="20%">Phone</th>
                      <th width="20%">Email</th>
                      <th width="20%">Security Access Level</th>
                      <th width="15%" />
                      <th width="5%" />
                    </tr>
                    {userList.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td width="20%">{v.firstName}</td>
                          <td width="20%">{v.phone}</td>
                          <td width="20%">{v.email}</td>
                          <td width="20%">
                            <span className="accadmin-hover-1">
                              <span className="account-title1">
                                <span className="roleTooltip">
                                  {v.userBusinessRole}
                                  <span className="roleTooltipText">
                                    <div>
                                      {v.userBusinessRole}
                                      <small
                                        style={{
                                          display: "block",
                                          clear: "both",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        [CURRENT ROLE]
                                      </small>
                                      {toolTipText[v.userBusinessRole]}
                                    </div>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </td>
                          <td width="15%" style={{ textAlign: "right" }}>
                            {v.userDetails.isSecurityOfficer ? (
                              <button
                                className="btn btn-sm pull-right"
                                disabled={true}
                                style={{ width: "145px" }}
                              >
                                <i className="fas fa-user" />
                                Official PCI Officer
                              </button>
                            ) : (
                              this.checkSecurityOfficerStatus(i, v)
                            )}
                          </td>
                          <td width="5%" style={{ textAlign: "right" }}>
                            {v.primaryUser === false ? (
                              <span
                                data-toggle="modal"
                                data-target="#deleteUserConfirmationModal"
                                onClick={() => {
                                  this.setState({
                                    deletableUserId: v._id,
                                    businessId: this.context.currentBusinessId,
                                  });
                                }}
                              >
                                <i className="fa fa-times" />
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <SecurityOfficer
          securityOfficeUserIndex={this.state.securityOfficeUserIndex}
          securityOfficeUser={this.state.securityOfficeUser}
          userLists={this.state.userList}
          updateSecurityStatus={this.updateUIsecurityOfficerStatus}
        />

        <AddUserModal
          populateBusinessUsersList={this.populateBusinessUsersList}
        />

        <DeleteUserConfirmation
          userId={this.state.deletableUserId}
          businessId={this.state.businessId}
          populateBusinessUsersList={this.populateBusinessUsersList}
        />
      </>
    );
  }
}

AccountUserData.contextType = AppContext;

const mapStateToProps = (state) => ({
  businessUsersList: state.businessStore.businessUsersList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountUserData);
