import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllEmails } from "../../modules/actions/index";

class EmailTemplateReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      emails: null,
      emailTemplateId: "",
      roles: {
        admin: false,
        manager: false,
        staff: false,
      },
    };
  }

  componentDidMount() {
    this.props.getAllEmails();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.emails) {
      this.setState({ emails: newProps.emails });
    }

    if (newProps.messageType === "email-sent-success") {
    }
  }

  setRoles(type, status) {
    let userRoles = this.state.roles;
    userRoles[type] = status;

    userRoles = this.state.roles;

    this.setState({
      roles: userRoles,
    });
  }

  sendEmailForReport = () => {
    let thisData = {
      emailId: this.state.emailTemplateId,
      roles: this.state.roles,
      fromAdmin: "yes",
    };

    this.props.sendSslReport(thisData);
  };

  render() {
    const { emails, emailTemplateId, roles } = this.state;

    return (
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title f800">Send Email</h4>
            </div>
            <div className="modal-body">
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <br />
                <br />
                <label>Email template:</label>
                {emails && emails.length > 0 ? (
                  <select
                    className="form-control"
                    name="emailTemplateId"
                    defaultValue={emailTemplateId}
                    onChange={(evt) => {
                      this.setState({
                        emailTemplateId: evt.target.value,
                      });
                    }}
                  >
                    <option>Please select</option>
                    {emails.map((v, i) => {
                      return (
                        <option key={i} value={v._id}>
                          {v.name}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  ""
                )}
              </div>
              {emailTemplateId ? (
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="well well-lg">
                    {
                      emails.filter((v, i) => {
                        return v._id === emailTemplateId;
                      })[0].body
                    }
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                <label>Send Email</label>
                <br />
                <span style={{ cursor: "pointer", marginRight: "50px" }}>
                  <input
                    type="checkbox"
                    id="chkAdmin"
                    checked={roles.admin}
                    onChange={() => {
                      this.setRoles("admin", !roles.admin);
                    }}
                  />{" "}
                  <label
                    style={{ marginLeft: "15px" }}
                    onClick={() => {
                      this.setRoles("admin", !roles.admin);
                    }}
                  >
                    Admin
                  </label>
                </span>
                <span style={{ cursor: "pointer", marginRight: "50px" }}>
                  <input
                    type="checkbox"
                    id="chkManager"
                    checked={roles.manager}
                    onChange={() => {
                      this.setRoles("manager", !roles.manager);
                    }}
                  />{" "}
                  <label
                    style={{ marginLeft: "15px" }}
                    onClick={() => {
                      this.setRoles("manager", !roles.manager);
                    }}
                  >
                    Managers
                  </label>
                </span>
                <span style={{ cursor: "pointer" }}>
                  <input
                    type="checkbox"
                    id="chkStaff"
                    checked={roles.staff}
                    onChange={() => {
                      this.setRoles("staff", !roles.staff);
                    }}
                  />{" "}
                  <label
                    onClick={() => {
                      this.setRoles("staff", !roles.staff);
                    }}
                    style={{ marginLeft: "15px" }}
                  >
                    Staff
                  </label>
                </span>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                id="btnCancelModal"
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-tgdark2 btn-140-width btn-md"
                style={{ marginRight: "15px" }}
                onClick={() => {
                  this.sendEmailForReport();
                }}
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.accountStore.loggedInUser,
    emails: state.accountStore.emails,
    messageType: state.accountStore.messageType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getAllEmails }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplateReport);
