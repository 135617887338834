import React from "react";

export default function pciVulnerability({ name, id, count }) {
  return (
    <tr>
      <td>{count}</td>
      <td>{name}</td>
      <td>{id}</td>
    </tr>
  );
}
