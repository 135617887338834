import React from "react";
import Modal from "../../../ui/modal/modal";
const modalAdditionalScanInfo = ({ additionalScanInfo }) => {
  return (
    <Modal
      targetID="additionalScanInfo"
      title="Additional Scan Information"
      largeModal
    >
      <div className="modal-body">
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header" id="headingOne">
              <h2 className="mb-0">
                <button
                  className="btn btn-block btn-primary"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Host Information
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              className="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                {additionalScanInfo && additionalScanInfo.hostData
                  ? JSON.stringify(additionalScanInfo.hostData)
                  : ""}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTwo">
              <h2 className="mb-0">
                <button
                  className="btn btn-block btn-primary collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Domain Information
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                {additionalScanInfo && additionalScanInfo
                  ? JSON.stringify(additionalScanInfo)
                  : ""}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingThree">
              <h2 className="mb-0">
                <button
                  className="btn btn-block btn-primary collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Scan Information
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                {additionalScanInfo && additionalScanInfo.scanData
                  ? JSON.stringify(additionalScanInfo.scanData)
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer pt-4 px-0" set="modal-footer">
        <button
          type="button"
          className="btn-success btn-md pull-right btn-140-width"
          data-dismiss="modal"
        >
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default modalAdditionalScanInfo;
