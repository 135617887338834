import React from "react";
import Aux from "../hoc/containerAux";
const layoutHeaderNavigation = (props) => {
  let dataTabLink = null;

  const tabs = props.tabs.map((tab, i) => {
    dataTabLink = (
      <li key={tab.target + "-" + i} className={i === 0 ? "active" : null}>
        {tab.name === "Create Account" ? (
          <a
            data-toggle="tab"
            href={"#" + tab.target}
            data-testid="ancCtlPnlCrtAcc"
          >
            {tab.name}
          </a>
        ) : tab.name === "Advanced Search" ? (
          <a
            data-toggle="tab"
            href={"#" + tab.target}
            data-testid="ancCtlAdvSrch"
          >
            {tab.name}
          </a>
        ) : (
          <a data-toggle="tab" href={"#" + tab.target}>
            {tab.name}
          </a>
        )}
      </li>
    );
    return dataTabLink;
  });
  return (
    <Aux>
      <div id="mcontent-top" className="pull-left">
        <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
          <ul id="dashTab" className="nav menu-tab">
            {tabs}
          </ul>
        </div>
      </div>
    </Aux>
  );
};

export default layoutHeaderNavigation;
