import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../../views/charts/line";

import AppContext from "../../components/context/appContext";
import BuyerProtectionForm from "./buyerProtectionForm";
import BuyerProtectionStats from "./buyerProtectionStats";
import BusinessSelector from "./businessSelector";

class BuyerProtection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userBusinessData: [],
      businessId: "",
      integrationStatus: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userBusinessData, currentBusinessId } = this.context;

    if (prevState.userBusinessData !== userBusinessData) {
      if (currentBusinessId) {
        const tempBusiness = userBusinessData.filter((v, i) => {
          return v._id === currentBusinessId ? v : null;
        });

        this.setState(
          {
            userBusinessData: userBusinessData,
            businessId:
              userBusinessData && userBusinessData.length > 0
                ? userBusinessData[0]._id
                : "",
            currentBusinessData: tempBusiness[0],
            integrationStatus:
              tempBusiness &&
              tempBusiness.length &&
              tempBusiness[0].hasOwnProperty("details4X") &&
              tempBusiness[0].details4X &&
              tempBusiness[0].details4X.hasOwnProperty("integrationStatus")
                ? tempBusiness[0].details4X.integrationStatus
                : false,
          },
          () => {
            this.context.updateCurrentBusinessId(this.state.businessId);
          }
        );
      }
    }
  }

  render() {
    const { userBusinessData, businessId, integrationStatus } = this.state;

    return (
      <>
        <Header titlePage="Trust Guard Buyer Protection" bgColor="#F5F6F8" />
        <ToastContainer />
        <BusinessSelector />
        {!integrationStatus ? (
          <>
            <div id="mcontent-wrapper">
              <div id="mcontent-top" className="pull-left">
                <ul id="dashTab" className="nav menu-tab" role="tablist">
                  <li key="1" className="active">
                    <a data-toggle="tab" href="#gettingStartedtab">
                      Buyer Protection
                    </a>
                  </li>
                </ul>
              </div>
              <div id="mcontent-wrapper">
                <div className="tab-content">
                  <div
                    id="gettingStartedtab"
                    className="seal-content-wrapper tab-pane fade in active"
                    style={{ margin: "20px 15px" }}
                  >
                    <BuyerProtectionForm />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <BuyerProtectionStats />
        )}
      </>
    );
  }
}

BuyerProtection.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyerProtection);
