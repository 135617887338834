import React from "react";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Aux from "../../../components/hoc/containerAux";

class BusinessHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(newProps) {}

  render() {
    return (
      <Aux>
        <div
          className="row"
          style={{
            marginBottom: "20px",
            borderBottom: "1px solid #cfd2d2",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <div className="col-11">
            <div className="row">
              <div className="col-2">
                <strong>Business</strong>
              </div>
              <div className="col-2 text-center">
                <strong>PCI Scan</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Malware Scan</strong>
              </div>
              <div className="col-2 text-center">
                <strong>SSL Certificate</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Cyber Insurance</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Trust Seal</strong>
              </div>
            </div>
          </div>
          <div className="col-1 text-center" />
        </div>
      </Aux>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessHeader);
