import React from "react";
import SpinnerRingBig from "./spinner/spinnerRingBig";
const backDrop = props => {
  const backDropStyle = {
    background: "rgba(0, 0, 0, 0.3)",
    zIndex: 1000,
    position: "absolute",
    width: "100%",
    height: "100%"
  };

  const showContent = props.show ? (
    <div id="backDrop" style={backDropStyle}>
      <center>
        <SpinnerRingBig />
      </center>
    </div>
  ) : null;
  return showContent;
};

export default backDrop;
