import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Aux from "../../components/hoc/containerAux";
import { createNote } from "../../modules/actions/index";
import {
  updateCheckDateInCheckScanReport,
  callGetCheckScanReportByBusinessId,
} from "../../services";

class DelayModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      reReview: null,
      customerNotification: null,
      notifyType: null,
      notifyMessage: null,
      customDate: null,
    };
  }

  async updateCheckDate() {
    let checkScanReport = await callGetCheckScanReportByBusinessId(
      this.props.selectedBusinessId
    );

    let checkScanReportId = null;
    if (checkScanReport.total > 0) {
      checkScanReportId = checkScanReport.data[0]._id;
      await updateCheckDateInCheckScanReport(
        checkScanReportId,
        this.state.reReview,
        this.state.customDate
      );
    }
    window.location.reload();
  }

  onClickAddNotes = () => {
    let businessId = this.props.selectedBusinessId;
    if (businessId) {
      if (this.state.reReview === null) {
        toast.error("Please select delay", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        this.updateCheckDate();
        const noteData = {
          userId: this.props.userId,
          note: this.state.note,
          noteType: "delayed",
        };
        this.props.createNote(businessId, noteData);
        document.getElementById("closeModal").click();
      }
    } else {
      alert("no businessId");
    }
    this.setState({
      note: "",
    });
  };

  render() {
    const { customDate, note } = this.state;

    return (
      <Aux>
        <div className="modal-content col-md-12">
          <div className="modal-header">
            <h4 className="modal-title f800">Delay Functions</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-md-6 col-sm-6 col-xs-12 text-left">
              <br />
              <br />
              <label>Re-review again in:</label>
              <br />
              <br />
              <select
                className="form-control"
                onChange={(evt) => {
                  this.setState({ reReview: evt.target.value });
                }}
              >
                <option>Please select</option>
                <option value="1Week">1 Week</option>
                <option value="2Week">2 Week</option>
                <option value="3Week">3 Week</option>
                <option value="1Month">1 Month</option>
                <option value="never">Never</option>
                <option value="custom">Custom</option>
              </select>
            </div>
            {this.state.reReview === "custom" ? (
              <div className="col-md-6 col-sm-6 col-xs-12 text-left">
                <br />
                <br />
                <label>or Choose a Date:</label>
                <br />
                <br />
                <div className="input-group">
                  <input
                    type="date"
                    className="form-control date-range-input"
                    name="singledate"
                    value={customDate}
                    onChange={(evt) => {
                      this.setState({ customDate: evt.target.value });
                    }}
                  />
                  <span className="input-group-addon date-range-ico">
                    <i className="fa fa-calendar" />
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-lg-12 text-left">
              <hr />
              <label>Notes : </label>
              <input
                type="text"
                className="form-control"
                placeholder="Add Note"
                value={note}
                onChange={(e) => {
                  this.setState({ note: e.target.value });
                }}
              />
            </div>
            <div className="col-md-12">
              <br />
              <br />
            </div>
          </div>
          <div className="modal-footer text-right">
            <button
              type="button"
              className="btn-tglight2 btn-140-width btn-md"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-tgdark2 btn-140-width btn-md"
              onClick={() => {
                this.onClickAddNotes();
              }}
              style={{ marginRight: "15px" }}
            >
              Delay
            </button>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAccount: state.accountStore.userAccount,
    businessAccount: state.accountStore.businessAccount,
    messageType: state.accountStore.messageType,
    message: state.accountStore.message,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createNote,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DelayModal);
