import io from "socket.io-client";

import feathers from "@feathersjs/client/dist/feathers.min";
import socketio from "@feathersjs/client/dist/socketio.min";
import authentication from "@feathersjs/client/dist/authentication.min";
import customMethods from "feathers-custom-methods/client";
import config from "../helpers/config";

let timeout = 50000;

const api = feathers()
  .configure(
    socketio(
      io(config.urls.apiUrl, { transports: ["websocket"], timeout: timeout }),
      { timeout: timeout }
    )
  )
  .configure(
    customMethods({
      methods: {
        beyondsecurity: [
          "downloadreport",
          "updateContactInfo",
          "sendmail",
          "updateSecurityOfficer",
        ],
      },
    })
  )
  .configure(authentication({ storage: window.localStorage }));

export { api };
