import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ControlPanelTabHeader from "./controlPanelTabHeader";
import ControlPanelTabBody from "./controlPanelTabBody";
import ControlPanelModals from "./controlPanelModals";

class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Header titlePage="Control Panel" bgColor="#F5F6F8" />
        <ToastContainer />
        <ControlPanelTabHeader />
        <ControlPanelTabBody />

        <ControlPanelModals />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlPanel);
