import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { BrowserView } from "react-device-detect";
import Logout from "../components/logout/logout";
import { getStorageItem } from "../helpers/storage";
import { getUserDecryptedData } from "../helpers/general";

import { getLoggedInUserData, getUserBusinessData } from "../modules/actions";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isAdmin: "",
      groupType: "",
    };
  }

  componentDidMount() {
    let user = getUserDecryptedData();
    if (user) {
      this.setState({
        isAdmin: user.isAdmin,
        groupType: user.groupType,
      });

      this.props.getLoggedInUserData(user);
      this.props.getUserBusinessData(user);
    }
  }

  deSelectSidebarMenu = () => {
    let currentPage = window.location.href.split("/");
    if (currentPage[currentPage.length - 1] === "control-panel") {
      let targetElement = document.getElementsByClassName("side-menu-selected");
      if (targetElement.length > 0) {
        targetElement[0].classList.remove("side-menu-selected");
      }
    }
  };

  render() {
    const { isAdmin, groupType } = this.state;

    return (
      <div>
        <div id="logout" className="modal" tabIndex="-1" role="dialog">
          <Logout />
        </div>
        <BrowserView>
          <div id="mtop-container" style={{ background: this.props.bgColor }}>
            <span id="page-title" className="h3 hidden-xs">
              {this.props.titlePage}
            </span>
          </div>
        </BrowserView>
        {isAdmin || groupType === "staff" ? (
          <span
            onClick={() => {
              this.props.goToControlPanel();
              this.deSelectSidebarMenu();
            }}
            id="sideWidget"
            className="btn btn-default btn-lg pull-right a-pointer"
          >
            <i className="fa fa-cogs" />
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLoggedInUserData,
      getUserBusinessData,
      goToControlPanel: () => push("/control-panel"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
