import React from "react";
import { push } from "connected-react-router";
import Header from "../header";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isArray } from "util";
import Spinner from "../../components/ui/spinner/spinnerRectScan";
import Aux from "../../components/hoc/containerAux";

class Insurance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pciStatus: "",
      malwareStatus: "",
      sslStatus: "",
      insuranceStatus: "",
      primaryDomain: "",
    };
  }

  componentDidMount() {
    console.log("this.props.location.state", this.props.location.state);
    if (this.props.location.state) {
      const {
        pciScanStatus,
        malwareScanStatus,
        sslScanStatus,
        insuranceStatus,
        primaryDomain,
      } = this.props.location.state;

      this.setState(
        {
          pciStatus: pciScanStatus,
          malwareStatus: malwareScanStatus,
          sslStatus: sslScanStatus === "passed" ? "active" : sslScanStatus,
          insuranceStatus: insuranceStatus,
          primaryDomain: primaryDomain,
        },
        () => {
          console.log(this.state);
        }
      );
    } else {
      this.props.goToPageDashboard();
    }
  }

  render() {
    const {
      pciStatus,
      malwareStatus,
      sslStatus,
      insuranceStatus,
      primaryDomain,
    } = this.state;
    const locationStateValues = this.props.location.state;

    let sslCertificateTextStatus = "",
      scanSslIconClass = "",
      scanSslStyle = {},
      malwareScanTextStatus = "",
      malwareScanIconClass = "",
      malwareScanStyle = {},
      pciScanStatus = "Never Scanned",
      pciScanProgressPercentage = null,
      //eslint-disable-next-line
      pciScanIconClass = "",
      pciScanStyle = {},
      isPciScanRunning = false,
      insuranceTextStatus = "",
      insuranceIconClass = "",
      insuranceStyle = {},
      doSubmitInsuranceApplication = false;

    switch (pciStatus) {
      case "fail":
        pciScanStatus = "Failed";
        pciScanIconClass = "fa fa-exclamation-circle fa-6x";
        pciScanStyle = { color: "#C01818", margin: "20px 0" };
        break;
      case "pending":
        pciScanStatus = "Pending";
        pciScanIconClass = "fa fa-clock fa-6x";
        pciScanStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
      case "Never Scanned":
        pciScanIconClass = "fa fa-clock fa-6x";
        pciScanStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
      case "running":
      case "finalizing":
        isPciScanRunning = true;
        pciScanStatus = pciScanProgressPercentage + " %";
        pciScanStyle = {};
        break;
      case "pass":
        pciScanStatus = "Passed";
        pciScanIconClass = "fa fa-check-circle fa-6x";
        pciScanStyle = { color: "#33981f", margin: "20px 0" };
        break;
      default:
        pciScanStatus = "Pending";
        pciScanIconClass = "fa fa-clock fa-6x";
        pciScanStyle = { color: "#7f7f7f", margin: "20px 0" };
    }

    switch (malwareStatus) {
      case "clean":
        malwareScanTextStatus = "Passed";
        malwareScanIconClass = "fa fa-check-circle fa-6x";
        malwareScanStyle = { color: "#33981f", margin: "20px 0" };
        break;
      case "":
        malwareScanTextStatus = "Failed";
        malwareScanIconClass = "fa fa-exclamation-circle fa-6x";
        malwareScanStyle = { color: "#C01818", margin: "20px 0" };
        break;
      default:
        malwareScanTextStatus = "Pending";
        malwareScanIconClass = "fa fa-clock fa-6x";
        malwareScanStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
    }

    switch (sslStatus) {
      case "active":
        sslCertificateTextStatus = "Verified";
        scanSslIconClass = "fa fa-check-circle fa-6x";
        scanSslStyle = { color: "#33981f", margin: "20px 0" };
        break;
      case "failed":
        sslCertificateTextStatus = "Failed";
        scanSslIconClass = "fa fa-exclamation-circle fa-6x";
        scanSslStyle = { color: "#C01818", margin: "20px 0" };
        break;
      case "pending":
        sslCertificateTextStatus = "Scan Pending";
        scanSslIconClass = "fa fa-clock fa-6x";
        scanSslStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
      default:
        break;
    }

    switch (insuranceStatus) {
      case "completed":
        insuranceTextStatus = "Completed";
        insuranceIconClass = "fa fa-check-circle fa-6x";
        insuranceStyle = { color: "#33981f", margin: "20px 0" };
        break;
      case "submitted":
        insuranceTextStatus = "Submitted";
        insuranceIconClass = "fa fa-clock fa-6x";
        insuranceStyle = { color: "orange", margin: "20px 0" };
        break;
      case "pending":
        insuranceTextStatus = "Pending";
        insuranceIconClass = "fa fa-clock fa-6x";
        insuranceStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
      default:
        break;
    }

    if (
      sslStatus === "active" &&
      malwareStatus === "clean" &&
      (pciStatus === "pass" || pciStatus === "passed")
    ) {
      doSubmitInsuranceApplication = true;
    }

    return (
      <div>
        <Header titlePage="Insurance" bgColor="#F5F6F8" />

        <div id="mcontent-wrapper">
          <div id="PCI-scan" className="">
            <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
              <div className="inner-ptab tab-content">
                <div id="seal" className="tab-pane fade in active">
                  <h4>
                    Breach Protection Insurance &nbsp;&nbsp;
                    <span style={{ color: "#28a745" }}>{primaryDomain}</span>
                  </h4>
                  <p>
                    Please make sure that your PCI scan, Malware scan and Active
                    SSL Certificate are in place prior to completing the
                    insurance application. We will automatically update your
                    progress as you scan and pass your PCI and Malware scans and
                    verify your SSL Certificate.
                  </p>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <br />
                    <br />
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                      <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                        PCI SCAN
                      </span>
                      <div style={pciScanStyle}>
                        {isPciScanRunning ? (
                          <Aux>
                            <Spinner />
                          </Aux>
                        ) : (
                          <i className={pciScanIconClass} />
                        )}
                      </div>
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {pciScanStatus}
                      </span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                      <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                        MALWARE SCAN
                      </span>
                      <div style={malwareScanStyle}>
                        <i className={malwareScanIconClass} />
                      </div>
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {malwareScanTextStatus}
                      </span>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                      <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                        SSL CERTIFICATE
                      </span>
                      <div style={scanSslStyle}>
                        <i className={scanSslIconClass} />
                      </div>
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {sslCertificateTextStatus}
                      </span>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                      <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                        INSURANCE APPLICATION
                      </span>

                      <div>
                        <div style={insuranceStyle}>
                          <i className={insuranceIconClass} />
                        </div>
                        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {insuranceTextStatus}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-12"
                    style={{ margin: "15px 0px", padding: "0" }}
                  >
                    <hr />
                  </div>

                  <div className=" text-left">
                    <h4>INSURANCE APPLICATION </h4>
                    {insuranceStatus !== "completed" ||
                    insuranceStatus !== "submitted" ? (
                      <p>
                        Once the pre-requisite scans and SSL are in place, you
                        will be able to start the application for breach
                        insurance by clicking the button below.
                      </p>
                    ) : (
                      <p>
                        Your insurance application is under review. You will be
                        notified via email from Coalition to finalize your
                        application with 1 business day.
                      </p>
                    )}

                    {insuranceStatus !== "completed" ||
                    insuranceStatus !== "submitted" ? (
                      <div>
                        <br />
                        <button
                          onClick={() =>
                            doSubmitInsuranceApplication
                              ? this.props.history.push(
                                  "/insurance-application",
                                  locationStateValues
                                    ? locationStateValues
                                    : null
                                )
                              : ""
                          }
                          className={
                            doSubmitInsuranceApplication
                              ? "btn btn-success btn-md"
                              : "btn btn-success btn-md disabled"
                          }
                        >
                          Start Insurance Application
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPageDashboard: () => push("/"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Insurance);
