import React from "react";
import { images } from "../../helpers/images";

require("velocity-animate");
require("velocity-animate/velocity.ui");

class TrustGuardAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smallArrowUp: true,
      smallAnimation: true,
    };
  }

  toggleState() {
    this.setState({});
  }
  onMouseOverRenderSmallAnimation = () => {
    if (!this.state.smallArrowUp) {
      this.setState({
        smallAnimation: true,
      });
    }
  };
  onMouseOutRenderSmallAnimation = () => {
    if (!this.state.smallArrowUp) {
      this.setState({
        smallAnimation: false,
      });
    }
  };

  render() {
    const { smallArrowUp, smallAnimation } = this.state;
    const { element } = this.props;

    return (
      <li
        id="cert-sec-scan"
        className="cert-content ui-state-default"
        onClick={this.toggleState.bind(this)}
      >
        <div className="cert3 text-center">
          {element.image ? (
            <img
              style={{ margin: " 0 2px" }}
              src={images[element.image]}
              alt={element.title}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className={
            smallAnimation
              ? "cert-content2 cert7 text-left active"
              : "cert-content2 cert7 text-left"
          }
        >
          <div className="cert-g1">
            <p className="cert-gdprlabel">{element.title}</p>
            <p className="cert-gdprlabel-sub">{element.subTitle}</p>
          </div>

          {smallArrowUp ? (
            <div className="smallArrowDiv">
              <p className="cert-slide-down-text">Scan Frequency : Daily</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </li>
    );
  }
}

export default TrustGuardAnimation;
