import React, { Component } from "react";
import Modal from "../../../ui/modal/modal";
import SwitchButton from "../../../../components/ui/switch/SwitchButton";
import { Table } from "react-bootstrap";
import { urlWithWww } from "../../../../helpers/general";
import Config from "../../../../helpers/config";
import AppContext from "../../../../components/context/appContext";
import {
  callApiCreateBusinessSubdomain,
  callApiGetBusinessSubdomainData,
} from "../../../../services/domainService";
class ModalAddDomainScanOpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: {
        valid: false,
        error: "",
      },
      info: {
        host: "",
        alias: "",
      },
      scanOptions: {
        scanActive: {
          pci: true,
          malware: true,
          ssl: true,
        },
      },
      subdomains: [],
      submitted: false,
      errorAddDomain: null,
    };
  }

  async componentWillReceiveProps() {
    const businessData = this.getUserBusinessData();
    const business = businessData;
    const subdomains = await callApiGetBusinessSubdomainData(business._id);
    this.setState({ subdomains: subdomains.data });
  }

  getUserData() {
    const userData = this.context.userData;
    return userData;
  }

  getUserBusinessData() {
    const { index } = this.props;
    const userBusinessData = this.context.userBusinessData[index];
    return userBusinessData;
  }

  onLostFocus = (event) => {
    const info = { ...this.state.info };
    if (event.target.name === "host") {
      info[event.target.name] = event.target.value
        .toLowerCase()
        .concat("", event.target.placeholder);
      const business = this.getUserBusinessData();
      const primaryDomain = business.primaryDomain;
      const inputDomain = event.target.value.toLowerCase();
      let validDomain = false;
      let validIP = false;
      const regexRuleIP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm;
      const regexRulewww = /(www)./gm;
      const siteWithWww = regexRulewww.test(primaryDomain);
      const isIP = regexRuleIP.test(inputDomain);

      if (!isIP) {
        validIP = false;
        let rootDomain = "";
        let checkDomain = "";
        if (siteWithWww) {
          rootDomain = primaryDomain.split(".");
          checkDomain = rootDomain[1] + "." + rootDomain[2];
          checkDomain = checkDomain.split(".");
        } else {
          checkDomain = primaryDomain.split(".");
        }

        const inputUrl = inputDomain.split(".");
        const ext1 = checkDomain[1];
        const ext2 = checkDomain[2] ? "[.]" + checkDomain[2] : "";
        const regexRuleSubDomain =
          "([a-z0-9]+[.])*" + checkDomain[0] + "[.]" + ext1 + ext2;

        const isValidDomain = new RegExp(`${regexRuleSubDomain}`, "g");
        if (urlWithWww(inputDomain)) {
          validDomain = false;
        } else {
          if (checkDomain[2] !== undefined) {
            if (checkDomain[2] === inputUrl[3]) {
              if (isValidDomain.test(inputDomain)) {
                validDomain = true;
              }
            }

            if (ext2 !== "") {
              const regexIsInputAsubDomain = "([a-z0-9]+[.])*" + ext1 + ext2;
              const isAsubDomain = new RegExp(`${regexIsInputAsubDomain}`, "g");
              const validSubDomain = isAsubDomain.test(inputDomain);
              if (validSubDomain) {
                validDomain = true;
              }
            }
          } else {
            if (ext1 === inputUrl[2]) {
              if (isValidDomain.test(inputDomain)) {
                validDomain = true;
              }
            }
          }
        }
      } else {
        validIP = true;
      }

      if ((validIP === false && validDomain === true) || validIP) {
        this.setState({
          validate: { valid: true, error: "" },
        });
      } else {
        this.setState({
          validate: {
            valid: false,
            error: Config.messages.inValidURL,
          },
        });
      }
      this.setState({ submitted: false });
    }
  };

  onChangeInput = (event) => {
    const info = { ...this.state.info };
    info[event.target.name] = event.target.value;
    this.setState({ info });
    this.setState({ submitted: false });
  };

  errorChecker = () => {
    let errorMessage = null;

    if (this.state.validate.valid === false) {
      errorMessage = (
        <div className="alert alert-warning" role="alert">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={(event) => this.setState({ errorMessage: null })}
          >
            <span>&times;</span>
          </button>
          {this.state.validate.error}
        </div>
      );
    } else {
      errorMessage = null;
    }

    this.setState({ errorMessage: errorMessage });
  };

  onClick = () => {
    this.addSubDomain();
  };

  addSubDomain = async () => {
    this.errorChecker();
    let info = { ...this.state.info };

    const userData = this.getUserData();
    const business = this.getUserBusinessData();
    if (userData) {
      const businessID = business._id;
      info["businessId"] = businessID;
      info["isSubDomain"] = true;
      info["enableSecurityScan"] = this.state.scanOptions.scanActive.pci;
      info["enableMalware"] = this.state.scanOptions.scanActive.malware;
      info["enableSSL"] = this.state.scanOptions.scanActive.ssl;

      if (this.state.validate.valid) {
        this.setState({ submitted: true });

        const addedDomain = await callApiCreateBusinessSubdomain(info);
        if (addedDomain.type && addedDomain.type === "FeathersError") {
          this.setState({ errorAddDomain: Config.messages.domainExists });
        }

        this.context.updateUserBusinessData();
        this.setState({ submitted: true, viewSubDomains: true });
        this.setState({ info: { host: "", alias: "" } });
        window.location.reload();
      }
    }
  };

  render() {
    const { validate, subdomains, info, submitted, scanOptions } = this.state;

    return (
      <Modal targetID="dash-addmodal" title="Add/Edit SubDomain or IP Address">
        <div className="scrollbar" id="style-1">
          <div className="force-overflow">
            <label className="text-left">Subdomain/IP Address</label>
            {validate.error ? (
              <p style={{ color: "red", fontWeight: "bold" }}>
                {validate.error}
              </p>
            ) : submitted ? (
              validate.valid ? (
                "Domain successfully added"
              ) : null
            ) : null}

            <input
              type="text"
              className="form-control"
              placeholder={
                subdomains.length > 0
                  ? "." + subdomains[0].host
                  : ".yourdomain.com"
              }
              id="businessSubdomain"
              name="host"
              onBlur={(event) => this.onLostFocus(event)}
              onChange={(event) => this.onChangeInput(event)}
              value={info.host}
              data-testid="inpBusinessSubdomain"
            />
            <small style={{ fontSize: "10px" }}>
              You can add unlilmited subdomains and IPs related to your main
              website (<b>
                {subdomains.length > 0 ? subdomains[0].host : null}
              </b>). Enter your subdomain / IP Address exactly as you want it to
              show up on your scan.
            </small>
            <br />
            <label>Alias</label>
            <input
              type="text"
              className="form-control"
              placeholder="My Server 2"
              name="alias"
              onChange={(event) => this.onChangeInput(event)}
              value={info.alias}
            />
            <br />
            <label>Subdomain/IP Options</label>
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "10px" }}>Status</th>
                  <th style={{ width: "90%" }}>Services</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i
                      className={
                        scanOptions.scanActive.pci
                          ? "text-success fas fa-circle mr-2"
                          : "text-muted fas fa-circle mr-2"
                      }
                      style={{
                        textAlign: "center",
                        display: "block",
                      }}
                    />
                  </td>
                  <td>PCI Scan</td>
                  <td>
                    <SwitchButton
                      changeStatus={() =>
                        console.log('this.switchToggleHandler("pci")')
                      }
                      status={scanOptions.scanActive.pci}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <i
                      className={
                        scanOptions.scanActive.malware
                          ? "text-success fas fa-circle mr-2"
                          : "text-muted fas fa-circle mr-2"
                      }
                      style={{
                        textAlign: "center",
                        display: "block",
                      }}
                    />
                  </td>
                  <td>Malware Scan</td>
                  <td>
                    <SwitchButton
                      changeStatus={() =>
                        console.log("this.switchToggleHandler('malware')")
                      }
                      status={scanOptions.scanActive.malware}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <i
                      className={
                        scanOptions.scanActive.ssl
                          ? "text-success fas fa-circle mr-2"
                          : "text-muted fas fa-circle mr-2"
                      }
                      style={{
                        textAlign: "center",
                        display: "block",
                      }}
                    />
                  </td>
                  <td>SSL Scan</td>
                  <td>
                    <SwitchButton
                      changeStatus={() => this.switchToggleHandler("ssl")}
                      status={scanOptions.scanActive.ssl}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <br />
            <div set="modal-footer" className="text-right modal-footer px-0">
              {submitted
                ? validate.valid
                  ? "Domain added successfully"
                  : null
                : null}
              <button
                type="button"
                className="btn-tglight2 h5 btn-md btn-140-width mr-2"
                data-dismiss="modal"
                style={{ marginRight: "7px" }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success btn-md btn-140-width"
                onClick={this.onClick}
                data-dismiss="modal"
                disabled={submitted || validate.valid === false}
                data-testid="btnAddSubdomainSubmit"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
ModalAddDomainScanOpt.contextType = AppContext;
export default ModalAddDomainScanOpt;
