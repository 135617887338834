import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import { isArray } from "util";
import { Modal } from "react-bootstrap";
import "./modals/modalBackdrop.css";
import Aux from "../../hoc/containerAux";
import ModalDownloadOption from "./modals/modalDownloadOption";
import ModalRescan from "./modals/modalRescan";
import ScanResultData from "./scanResultData";
import Spinner from "../../../components/ui/spinner/spinnerRectScan";
import fileDownload from "js-file-download";
import ScanStatusButton from "./scanStatusButton";
import { getUserDecryptedData } from "../../../helpers/general";

import {
  callDownloadPCIreport,
  callOnGetBusinessById,
} from "../../../services";
import { getBusiness } from "../../../modules/actions/businessActionCopy";

class ScanResultRow extends Component {
  constructor() {
    super();
    this.state = {
      scanNumber: [],
      scanDate: null,
      reportType: "pci",
      reportFormat: "pdf",
      isDownloading: false,
      toggledBusinessId: null,
      scanVendor: {},
      toggledIndex: 0,
      accountType: "",
    };
  }

  reportTypeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setBusinessDetail = async () => {
    const businessInfo = getUserDecryptedData();
    if (businessInfo !== undefined) {
      if (businessInfo !== null) {
        if (businessInfo.hasOwnProperty("isAdmin")) {
          if (!businessInfo.isAdmin) {
            const toggledBusinessIndex =
              this.props.toggledIndex === null ||
              this.props.toggledIndex === undefined
                ? 0
                : this.props.toggledIndex;

            let business = null;
            if (businessInfo.isAdmin) {
              business = _.find(this.props.businessData, {
                _id: this.props.businessId,
              });
            }
            let accountType =
              business !== null
                ? business.accountType
                : businessInfo.businessDetails[toggledBusinessIndex]
                    .businessData.accountType;

            this.setState({
              toggledBusinessId: this.props.businessId,
              accountType: accountType,
            });
          } else {
            const toggledBusinessDetail = _.isArray(businessInfo)
              ? businessInfo.businessDetails[0]
              : businessInfo;
            if (toggledBusinessDetail.length > 0) {
              this.setState({
                toggledBusinessId: toggledBusinessDetail.businessId,
                accountType: toggledBusinessDetail.businessAccountType,
              });
            }
          }
        }
      }
    }
  };

  componentWillReceiveProps(newprops) {
    if (this.props.businessId !== null) {
      this.setBusinessDetail();
    }
  }

  componentDidMount(prevState) {
    this.setBusinessDetail();
  }

  componentDidUpdate(nextProps, prevState) {
    if (
      prevState.scanNumber !== this.state.scanNumber &&
      prevState.toggledBusinessId !== this.state.toggledBusinessId
    ) {
      const toggledBusinessIndex =
        this.props.toggledIndex === null ? 0 : this.props.toggledIndex;

      const businessData = this.props.businessData[toggledBusinessIndex];
      const businessId = this.props.businessIdRescan
        ? this.props.businessIdRescan
        : businessData._id;
      this.props.getBusiness(businessId);
    }
  }

  getDownloadReport = async (index) => {
    const toggledBusinessIndex =
      this.props.toggledIndex === null ? 0 : this.props.toggledIndex;

    const businessData = this.props.businessData[toggledBusinessIndex];
    const businessId = this.props.businessIdRescan
      ? this.props.businessIdRescan
      : businessData._id;
    const toggledBusinessId =
      this.props.businessId === null ? businessData._id : this.props.businessId;

    if (businessId === toggledBusinessId || this.props.businessIdRescan) {
      this.setState({ isDownloading: true });
      const userBusiness = await callOnGetBusinessById(toggledBusinessId);
      const scanVendor = userBusiness.scanVendor;
      const networkID = scanVendor.owner;
      const api = scanVendor.apiKey;
      const businessName = businessData.primaryDomain;

      const ReportResponse = await callDownloadPCIreport({
        networkID: networkID,
        api: api,
        businessName: businessName,
        format: this.state.reportFormat,
        type: this.state.reportType,
        scanNumber: this.state.scanNumber,
        scanDate: this.state.scanDate,
      });
      await fileDownload(ReportResponse.data, ReportResponse.filename);
    }
    this.setState({ isDownloading: false });
  };

  selectedReport = (index) => {
    let scanNumber = 0;
    let scanDate = null;

    const toggledBusinessIndex =
      this.props.toggledIndex === null ? 0 : this.props.toggledIndex;
    const businessData = this.props.businessIdRescan
      ? this.props.businessData
      : this.props.businessData[toggledBusinessIndex];
    let selectedHistory = null;
    const offsetIndex = index - 1;

    if (isArray(businessData.domains)) {
      if (this.state.toggledBusinessId === null) {
        selectedHistory = _.filter(
          businessData.domains,
          (h) => h.businessId === businessData._id && !h.isSubDomain
        );
      } else {
        selectedHistory = _.filter(
          businessData.domains,
          (h) => h.businessId === this.state.toggledBusinessId && !h.isSubDomain
        );
      }
      scanNumber = selectedHistory[0].scanhistory[offsetIndex].scanNumber;
      scanDate = selectedHistory[0].scanhistory[offsetIndex].scanDate;
    } else {
      if (Array.isArray(businessData.domains.scanhistory)) {
        scanNumber = businessData.domains.scanhistory[offsetIndex].scanNumber;
        scanDate = businessData.domains.scanhistory[offsetIndex].scanDate;
      } else {
        scanNumber = businessData.domains.scanhistory.scanNumber;
        scanDate = businessData.domains.scanhistory.scanDate;
      }
    }

    const defaultValueSelected =
      businessData.pciStatus === "fail" ? "remediation" : "pci";

    this.setState({
      scanNumber: scanNumber,
      scanDate: moment(scanDate).format("YYYY-MM-DD"),
      reportType: defaultValueSelected,
    });
  };

  render() {
    const {
      accountType,
      reportFormat,
      reportType,
      scanNumber,
      isDownloading,
    } = this.state;

    const {
      businessData,
      business,
      index,
      high,
      medium,
      submitFalsePostive,
      rescan,
      goToPageVulnerabilities,
      owner,
      resultId,
      scandate,
      low,
      activePage,
      skipValidation,
      frequency,
      log,
      total,
      status,
      isSearching,
    } = this.props;

    let statusButton = null;
    let spinner = null;
    let reports = null;
    let rescanButton = null;

    const toggledIndex =
      this.props.toggledIndex !== null && this.props.toggledIndex !== undefined
        ? this.props.toggledIndex
        : 0;

    let businessID = null;
    if (!isArray(businessData)) {
      businessID = businessData._id;
    } else {
      if (toggledIndex !== null && toggledIndex !== undefined) {
        businessID = businessData[toggledIndex]._id;
      }
    }

    const scanReportOptions = { ...business };
    if (scanReportOptions.availableReports) {
      reports = Object.keys(scanReportOptions.availableReports).map(
        (key, _) => {
          if (scanReportOptions.availableReports[key]) {
            return (
              <option key={key} value={key}>
                {key.toUpperCase()}
              </option>
            );
          }

          return "";
        }
      );
    }

    statusButton = (
      <ScanStatusButton
        status={status}
        selectedReport={this.selectedReport}
        index={index}
      />
    );

    if (
      status !== "pending" &&
      status !== "running" &&
      status !== "finalizing"
    ) {
      let falsePositiveButton = false;
      if (high >= 1 || medium >= 1) {
        falsePositiveButton = submitFalsePostive;
      }
      if (accountType === "free") {
        if (index === 1) {
          rescanButton = (
            <a
              rel="noopener noreferrer"
              href="https://trustguard.com/home"
              target="_blank"
              className="btn btn-success btn-sm"
            >
              <i className="fas fa-search" />&nbsp; Rescan
            </a>
          );
        }
      } else {
        if (!isSearching) {
          rescanButton = (
            <ModalRescan
              index={index}
              toggledIndex={toggledIndex}
              scanData={{ _id: businessID }}
              rescan={rescan}
              submitFalsePostive={falsePositiveButton}
              goToPageVulnerabilities={goToPageVulnerabilities}
              owner={owner}
              resultId={resultId}
              scandate={scandate}
              high={high}
              medium={medium}
              low={low}
            />
          );
        }
      }
    } else {
      if (
        status === "running" ||
        status === "pending" ||
        status === "finalizing"
      )
        rescanButton = (
          <button
            type="button"
            className="btn btn-success btn-sm"
            disabled={true}
          >
            <i className="fas fas-sync" />&nbsp; Rescan
          </button>
        );
    }

    if (activePage >= 2) {
      rescanButton = null;
    }

    if (status === "invalid") {
      if (index === 1) {
        rescanButton = (
          <button
            className="btn btn-warning btn-sm"
            onClick={(e) => {
              e.preventDefault();
              skipValidation(resultId, businessID);
            }}
          >
            <i className="fas fa-search" />&nbsp; This is a correct domain
          </button>
        );
      }
    }

    let downloadReportButton = (
      <span style={{ textTransform: "capitalize" }}>{statusButton}</span>
    );

    if (
      status !== "pending" &&
      status !== "running" &&
      status !== "finalizing"
    ) {
      downloadReportButton = (
        <button
          className="tg-dashboard-link"
          data-toggle="modal"
          data-target={"#dash-download-modal-" + index}
          style={{ cursor: "pointer", textTransform: "capitalize" }}
        >
          {statusButton}
        </button>
      );
    }

    if (status === "invalid") {
      downloadReportButton = (
        <button
          className="btn btn-sm"
          style={{
            borderRadius: "0.2rem 0px 0px 0.2rem",
            padding: "0.25rem 0.40rem",
            fontSize: "12px",
            borderColor: "rgb(127, 127, 127)",
          }}
          disabled={true}
        >
          Invalid URL
        </button>
      );
    }

    const modalShowDownloadReportOpt = (
      <ModalDownloadOption
        reports={reports}
        targetID={"dash-download-modal-" + index}
        defaultValueFormat={reportFormat}
        defaultValueType={reportType}
        reportTypeHandler={this.reportTypeHandler}
        downloadReport={() => this.getDownloadReport(index)}
        scanNumber={scanNumber}
        showRemediation={status === "fail"}
      />
    );

    return (
      <Aux>
        <Modal
          show={isDownloading}
          backdropClassName="modalBackdrop"
          size="lg"
          dialogClassName="modalBoxDialog"
        >
          <Modal.Body>
            <p className="textNotify">
              One moment, we are generating your report.
            </p>
            <Spinner />
          </Modal.Body>
        </Modal>

        <ScanResultData
          index={index}
          status={status}
          resultId={resultId}
          spinner={spinner}
          owner={owner}
          scandate={scandate}
          frequency={frequency}
          high={high}
          medium={medium}
          low={low}
          log={log}
          total={total}
          downloadReportButton={downloadReportButton}
          modalShowDownloadReportOpt={modalShowDownloadReportOpt}
          rescanButton={rescanButton}
          goToPageVulnerabilities={goToPageVulnerabilities}
        />
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.businessStore.businessInfo,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBusiness,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanResultRow);
