import React from "react";
import VerificationReports from "./verificationReports";
import SealReports from "./sealReports";
import SslScanReports from "./sslScanReports";
import OthersReport from "./othersReport";
import EmailStatisticsReport from "./emailStatisticsReport";
import SmsStatisticsReport from "./smsStatisticsReport";
import InsuranceStatisticsReport from "./insuranceStatisticsReport";

class ReportsTabBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="tab-content" id="reportTabContent">
        <div
          className="tab-pane fade in active"
          id="verification-report"
          role="tabpanel"
          aria-labelledby="verification-report-tab"
        >
          <VerificationReports />
        </div>
        <div
          className="tab-pane fade"
          id="pci-scan-report"
          role="tabpanel"
          aria-labelledby="pci-scan-report-tab"
        >
          PCI Reports
        </div>
        <div
          className="tab-pane fade"
          id="external-malware-scan"
          role="tabpanel"
          aria-labelledby="external-malware-scan-tab"
        >
          Malware Reports
        </div>

        <div
          className="tab-pane fade"
          id="seals-report"
          role="tabpanel"
          aria-labelledby="seals-report-tab"
        >
          <SealReports />
        </div>
        <div
          className="tab-pane fade"
          id="ssl-scan-report"
          role="tabpanel"
          aria-labelledby="ssl-scan-report-tab"
        >
          <SslScanReports />
        </div>

        <div
          className="tab-pane fade"
          id="active-old-free-businesses"
          role="tabpanel"
          aria-labelledby="active-old-free-businesses-tab"
        >
          <OthersReport />
        </div>

        <div
          className="tab-pane fade"
          id="email-statistics"
          role="tabpanel"
          aria-labelledby="email-statistics"
        >
          <EmailStatisticsReport />
        </div>

        <div
          className="tab-pane fade"
          id="sms-statistics"
          role="tabpanel"
          aria-labelledby="sms-statistics"
        >
          <SmsStatisticsReport />
        </div>

        <div
          className="tab-pane fade"
          id="insurance-statistics"
          role="tabpanel"
          aria-labelledby="insurance-statistics"
        >
          <InsuranceStatisticsReport />
        </div>
      </div>
    );
  }
}

export default ReportsTabBody;
