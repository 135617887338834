import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "../header";
import { getStorageItem } from "../../helpers/storage";
import MalwareScanResults from "../../components/malware/malwareScanResults";
import MalwareAllModal from "../../components/malware/malwareAllModal";
import TableMalwareContents from "../../components/dashboard/partials/tableMalwareContents"; //Goto line: 120:144
import {
  callMalwareSearchByBusinessId,
  callBusinessInfo,
} from "../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class MalwareScanning extends React.Component {
  _isMounted = false;

  constructor(props) {
    let ID = getStorageItem("businessID");
    let businessId = "";

    if (ID) {
      businessId = cryptr.decrypt(ID);
    }

    super(props);
    this.state = {
      malwareInfoData: [],
      businessID: businessId,
      malwareFrequency: "",
      filterStatus: "all",
      countScanResults: 0,
      malwareScanHistoryResults: null,
      activePage: 1,
      pageLimit: 5,
      malwareActivePage: 1,
      malwarePageLimit: 10,
      malwareInfoLength: 0,
    };
  }

  componentDidMount() {
    this.getMalwareSearchByBusinessID();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChangeSetFilter(e) {
    let actionVul = e.target.value;
    this.setState({
      filterStatus: actionVul,
    });
    this.getMalwareSearchByBusinessID();
  }

  getCurrentMalwareScanHistories = (pageNumber = 1) => {
    const pageResults = this.state.malwareInfoData;
    const pageLimit = this.state.malwarePageLimit;
    const currentPage = pageNumber;
    const offset = (currentPage - 1) * pageLimit;
    let scanHistories = this.state.malwareInfoData;

    if (pageResults.length > 0) {
      scanHistories = pageResults.slice(offset, offset + pageLimit);
    } else {
      scanHistories = pageResults;
    }

    this.setState({
      malwareActivePage: pageNumber,
      malwareScanHistoryResults: scanHistories,
    });
  };

  async getMalwareSearchByBusinessID() {
    this._isMounted = true;
    const businessInfoData = await callBusinessInfo(
      this.props.history.location.state.businessId
    );
    if (businessInfoData !== undefined) {
      this.setState({
        malwareFrequency: businessInfoData.malwareFrequency,
      });
    }

    callMalwareSearchByBusinessId(
      this.props.history.location.state.businessId
    ).then((malwareAPIfetch) => {
      let malwareData = malwareAPIfetch.data.data;

      let malwareInfoData = [];

      let malwareData2 = malwareData
        .sort((a, b) => {
          return (
            new Date(a.scanStart).getTime() - new Date(b.scanStart).getTime()
          );
        })
        .reverse()
        .filter((a) => {
          if (this.state.filterStatus === "all") {
            return a.scanStatus !== "x";
          } else {
            return a.scanStatus === this.state.filterStatus;
          }
        });
      let countMal = 0;
      let count = 0;
      if (malwareData2.length > 0) {
        for (let i = 0; i < malwareData2.length; i++) {
          countMal = i;
          const malwareInfo = malwareData2[i];
          let domainNumber = i + 1;
          let malInfo = { _id: malwareInfo._id };
          malInfo["scanStart"] = malwareInfo.scanStart;
          malInfo["malwareHistoryID"] = malwareInfo._id;
          malInfo[
            "scanFrequency"
          ] = this.props.history.location.state.malwareFrequency;
          malInfo["status"] = malwareInfo.scanStatus;
          malInfo["detection"] = malwareInfo.scanStatus;
          malInfo["count"] = malwareInfo.domains.length;
          malInfo["domainNumber"] = domainNumber;
          if (malwareInfo.scanStatus === "running") {
            malInfo["showViewMoreText"] = "none";
          } else if (malwareInfo.domains.length <= 1) {
            malInfo["showViewMoreText"] = "none";
          } else {
            malInfo["showViewMoreText"] = "block";
          }
          if (malwareInfo.scanStatus !== "running") {
            malwareInfoData.push(malInfo);
            count++;
          }
        }
        if (this._isMounted) {
          this.setState({
            malwareInfoData,
            isMalwareScanResults: true,
            countScanResults: count,
            malwareInfoLength: countMal,
          });
        }
      } else {
        if (this._isMounted) {
          this.setState({
            isMalwareScanResults: false,
            countScanResults: count,
          });
        }
      }
      this.getCurrentMalwareScanHistories();
    });
  }

  handleMalwarePageChange = (pageNumber) => {
    this.getCurrentMalwareScanHistories(pageNumber);
  };

  scanMalwareContent() {
    let scanMalwareContent = "";
    const malwareScanResults = this.state.malwareScanHistoryResults;
    if (
      Array.isArray(malwareScanResults) &&
      this.state.countScanResults !== 0
    ) {
      scanMalwareContent = (
        <TableMalwareContents
          activePage={this.state.malwareActivePage}
          pageLimit={this.state.malwarePageLimit}
          totalItems={this.state.malwareInfoLength}
          pageChange={this.handleMalwarePageChange}
        >
          {malwareScanResults.map((i) => {
            return (
              <MalwareScanResults
                key={i.malwareHistoryID}
                index={i + 1}
                indexOf={i}
                number={i.domainNumber}
                scanFinish={i.scanFinish}
                scanStart={i.scanStart}
                frequency={this.props.history.location.state.malwareFrequency}
                detection={i.detection}
                scanStatus={i.status}
                businessID={this.state.businessID}
                numberOfDomains={i.count}
                malwareHistoryID={i.malwareHistoryID}
                history={this.props.history}
                showViewMoreText={i.showViewMoreText}
                changeClassNames={() => this.props.changeClassNames()}
                showRescan={false}
              />
            );
          })}
        </TableMalwareContents>
      );
    }
    return scanMalwareContent;
  }

  render() {
    const { malwareInfoData, businessID, countScanResults } = this.state;
    const { changeClassNames } = this.props;

    return (
      <div>
        {malwareInfoData.map((i) => (
          <MalwareAllModal
            key={i.malwareHistoryID}
            history={this.props.history}
            targetID={i.malwareHistoryID}
            malwareHistoryID={i.malwareHistoryID}
            scanStatus={i.status}
            businessID={businessID}
            changeClassNames={() => changeClassNames()}
          />
        ))}
        <Header titlePage="Malware Scanning" bgColor="#F5F6F8" />

        <div id="mcontent-wrapper">
          <div id="PCI-scan" className="">
            <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
              <div className="inner-ptab tab-content">
                <div id="scan" className="tab-pane fade in active">
                  <h4>Scan Reports</h4>
                  <p>
                    To maintain the integrity of your servers it’s imperative
                    that you scan and monitor to protect from the onslaught of
                    malware. Review your monitors and scans below, manage
                    monitors, view reports and get the help you need in our help
                    center.
                  </p>
                  <br />
                  <br />

                  <div className="col-md-8 col-sm-8 col-xs-12 text-left no-pad">
                    <select onChange={(e) => this.onChangeSetFilter(e)}>
                      <option value="all">All</option>
                      <option value="clean">Clean</option>
                      <option value="potentially suspicious">
                        Potentially Suspicious
                      </option>
                      <option value="suspicious">Suspicious</option>
                      <option value="malicious">Malicious</option>
                    </select>
                    <br />
                    <br />
                    <strong>{countScanResults} found </strong>
                    <br />
                    <br />
                  </div>

                  {this.scanMalwareContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareScanning);
