import React, { Component } from "react";
import LayoutContainer from "../../components/layout/layoutContainer";
import LayoutBody from "../../components/layout/layoutBody";
import { callOnGetUserSessionKey } from "../../services/cloneSecurityService";

class createSAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionKey: "",
      error: false,
    };
  }

  componentDidMount() {
    const { businessToggledId } = this.props.location.state;

    try {
      this._asyncRequest = callOnGetUserSessionKey(businessToggledId)
        .then((result) => {
          this.setState({ sessionKey: result.userSessionKey, error: false });
        })
        .catch((e) => {
          this.setState({ error: true });
        });
    } catch (e) {}
  }

  render() {
    const { sessionKey, error } = this.state;
    return (
      <React.Fragment>
        <LayoutContainer titlePage="Create SAQ" tabs={[]}>
          <LayoutBody>
            <div id="overview" className="tab-pane fade in active">
              {!error && sessionKey !== "" ? (
                <iframe
                  title="tgapi-clone"
                  src={`https://tgapi.clone-systems.com/saq_login/?token=${sessionKey}`}
                  style={{ border: "none", width: "100%", height: "700px" }}
                />
              ) : null}
            </div>
          </LayoutBody>
        </LayoutContainer>
      </React.Fragment>
    );
  }
}

export default createSAQ;
