import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import moment from "moment";
import CustomerNoteModal from "../customerNoteModal/customerNoteModal";
import DelayModal from "../../components/delayModal/delayFunction";
import InsuranceDocsModal from "../../components/control-panel/insuranceDocsModal";
import Table from "../../components/ui/table/table";
import LockUnlockUser from "../../components/lockUnlockUser/lockUnlockUser";
import PrimaryDomainUpdate from "./primaryDomainUpdate";
import BusinessPrimaryUserModal from "./businessPrimaryUserModal";
import ToastMessage from "../../components/toast/toastMessage";
import ScanDetails from "./scanDetails/scanDetails";

import {
  getBusiness,
  getUserById,
  getDomains,
  getBusinesses,
  createNote,
  cancelOrActiveDomain,
  skippedActionByMember,
  getUserListUsingBusinessId,
  getBusinessDetailsById,
  onClickRemoveTrustSealsSettingsDate,
  lockAndUnlockUserAccount,
  create4xAccount,
  actionAgainstCancelledTrustSeals,
} from "../../modules/actions/index";

class ControlButtonModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      userId: null,
      firstName: null,
      userLocked: false,
      selectedBusinessData: null,
    };
  }

  saveUserNameAndId = (userId, fName, userStatus) => {
    this.setState({
      userId: userId,
      firstName: fName,
      userLocked: userStatus === "locked",
    });
  };

  onClikStoreLockUser = (userId, fName) => {
    this.setState({
      userId: userId,
      firstName: fName,
      userLocked: true,
    });
  };

  onClickChangeUserAccountLockedStatus(lockedStatus) {
    let status = null;
    if (lockedStatus) {
      status = "active";
    } else {
      status = "locked";
    }

    this.props.lockAndUnlockUserAccount(this.state.userId, status);
  }

  async onClickCreate4xAccount(businessId) {
    this.props.create4xAccount(businessId);
  }

  async onClickDeleteTrustSeals(sealId, primaryDomain) {
    this.props.actionAgainstCancelledTrustSeals(
      sealId,
      primaryDomain,
      "delete"
    );
  }

  async onClickHideTrustSeals(sealId, primaryDomain) {
    this.props.actionAgainstCancelledTrustSeals(sealId, primaryDomain, "hide");
  }

  render() {
    const { firstName, userLocked } = this.state;
    const {
      usersInBusiness,
      userId,
      selectedBusinessId,
      populateBusinessUsersData,
      controlPanelUserUpdated,
      getTotalUsersInBusinessUsingBusinessId,
      messageType,
      message,
      selectedBusinessData,
      cancelOrActiveDomain,
    } = this.props;

    const BusinessStatusLogHeaderColumns = [
      { name: "Current Status" },
      { name: "New Status" },
      { name: "Notes" },
      { name: "User" },
      { name: "Action Date" },
      { name: "Skipped Member ID" },
      { name: "Action" },
    ];

    return (
      <div>
        <ToastMessage type={messageType} notifyMessage={message} />

        <div
          id="delayfunctionInAdvanced"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog " role="document">
            <DelayModal
              selectedBusinessId={selectedBusinessId}
              userId={userId}
            />
          </div>
        </div>

        <div id={"customerInfo"} className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-dialog modal-lg" role="document">
              <CustomerNoteModal
                selectedBusinessData={selectedBusinessData}
                selectedBusinessId={selectedBusinessId}
                userId={userId}
                usersInBusiness={usersInBusiness}
              />
            </div>
          </div>
        </div>

        <div
          id={"scanDetailsUpdateModal"}
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-dialog modal-lg" role="document">
              <ScanDetails
                selectedBusinessId={selectedBusinessId}
                userId={userId}
              />
            </div>
          </div>
        </div>

        <div id={"primarydomain"} className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" style={{ paddingLeft: "20px" }}>
                    Update Primary Domain
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <PrimaryDomainUpdate businessId={selectedBusinessId} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id={"cancelDomainInAdv"} role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" style={{ paddingLeft: "20px" }}>
                  {selectedBusinessData
                    ? selectedBusinessData.status === "active"
                      ? "Cancel"
                      : "Activate"
                    : ""}
                  {" Account "}
                </h4>
              </div>

              <div className="modal-body">
                {selectedBusinessData &&
                selectedBusinessData.statusLog.length ? (
                  <div style={{ margin: "35px 0 60px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                      History Data
                    </div>

                    <Table
                      headers={BusinessStatusLogHeaderColumns}
                      emptyHeaderColumnsCount="6"
                      className="bg-torq-light"
                    >
                      {selectedBusinessData
                        ? selectedBusinessData.statusLog.length > 0
                          ? selectedBusinessData.statusLog.map((v, i) => {
                              return (
                                <tr
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <td>
                                    {v.currentStatus.charAt(0).toUpperCase() +
                                      v.currentStatus.slice(1)}
                                  </td>
                                  <td>
                                    {v.newStatus.charAt(0).toUpperCase() +
                                      v.newStatus.slice(1)}
                                  </td>
                                  <td>{v.notes}</td>
                                  <td>{v.user}</td>
                                  <td>{moment(v.actionDate).format("LL")}</td>
                                  <td>
                                    {v.skippedMemberId === null
                                      ? "N/A"
                                      : v.skippedMemberId}
                                  </td>
                                  <td>
                                    {v.currentStatus !== "completed" ? (
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() =>
                                          skippedActionByMember(
                                            selectedBusinessId,
                                            userId
                                          )
                                        }
                                        data-dismiss="modal"
                                        disabled={
                                          v.currentStatus === "pending"
                                            ? true
                                            : false
                                        }
                                      >
                                        Cancel
                                      </button>
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          : ""
                        : ""}
                    </Table>
                  </div>
                ) : (
                  ""
                )}

                <div>
                  Are you sure want to{" "}
                  {selectedBusinessData
                    ? selectedBusinessData.status === "active"
                      ? "cancel"
                      : "activate"
                    : ""}{" "}
                  <b>
                    {selectedBusinessData
                      ? selectedBusinessData.businessName
                      : ""}
                  </b>
                </div>
              </div>
              <div className="modal-footer text-right">
                <button
                  id="cancelDomainInAdvDismissModal"
                  type="button"
                  className="btn btn-danger btn-140-width btn-md"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-success btn-140-width btn-md"
                  data-dismiss="modal"
                  onClick={() => {
                    cancelOrActiveDomain(
                      selectedBusinessId,
                      userId,
                      selectedBusinessData.status
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="myModal" className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <LockUnlockUser
              key={selectedBusinessId}
              usersInBusiness={usersInBusiness}
              userId={userId}
              saveUserNameAndId={this.saveUserNameAndId}
              onClikStoreLockUser={this.onClikStoreLockUser}
              businessId={selectedBusinessId}
              populateBusinessUsersData={populateBusinessUsersData}
              controlPanelUserUpdated={controlPanelUserUpdated}
              getTotalUsersInBusinessUsingBusinessId={
                getTotalUsersInBusinessUsingBusinessId
              }
            />
          </div>
        </div>

        <div
          id="primaryUsersModal"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <BusinessPrimaryUserModal
              key={selectedBusinessId}
              usersInBusiness={usersInBusiness}
              userId={userId}
              saveUserNameAndId={this.saveUserNameAndId}
              onClikStoreLockUser={this.onClikStoreLockUser}
              businessId={selectedBusinessId}
              populateBusinessUsersData={populateBusinessUsersData}
              controlPanelUserUpdated={controlPanelUserUpdated}
              getTotalUsersInBusinessUsingBusinessId={
                getTotalUsersInBusinessUsingBusinessId
              }
            />
          </div>
        </div>

        <div className="modal fade" id="confirmModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Confirmation</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                {!userLocked ? (
                  <h4>Do you want to lock user {firstName}?</h4>
                ) : (
                  <h4>Do you want to unlock user {firstName}?</h4>
                )}
              </div>

              <div className="modal-footer text-right">
                <button
                  type="button"
                  className="btn btn-danger btn-140-width btn-md"
                  data-dismiss="modal"
                >
                  No
                </button>

                <button
                  type="button"
                  className="btn btn-info btn-140-width btn-md"
                  data-dismiss="modal"
                  onClick={() => {
                    this.onClickChangeUserAccountLockedStatus(userLocked);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id="insuranceDocsModal"
          className="modal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <InsuranceDocsModal
              selectedBusinessId={selectedBusinessId}
              userId={userId}
            />
          </div>
        </div>

        <div id="enable4xAccount" className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content col-md-12">
              <div className="modal-header">
                <h4 className="modal-title f800">
                  Buyer Protection - 4X Account
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Are you sure to enable 4X account for this user?
              </div>
              <div className="modal-footer text-right">
                <button
                  type="button"
                  className="btn btn-danger btn-140-width btn-md"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-info btn-140-width btn-md"
                  data-dismiss="modal"
                  onClick={() => {
                    this.onClickCreate4xAccount(selectedBusinessId);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="deleteSeals" className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content col-md-12">
              <div className="modal-header">
                <h4 className="modal-title f800">Hide / Delete Trust Seals</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Please confirm your action for this business trustseals.
              </div>
              <div className="modal-footer text-right">
                <button
                  type="button"
                  className="btn btn-danger btn-140-width btn-md"
                  data-dismiss="modal"
                >
                  No
                </button>

                <button
                  type="button"
                  className="btn btn-warning btn-140-width btn-md"
                  data-dismiss="modal"
                  onClick={() => {
                    this.onClickHideTrustSeals(
                      selectedBusinessData.trustseals._id,
                      selectedBusinessData.primaryDomain
                    );
                  }}
                >
                  Hide
                </button>
                <button
                  type="button"
                  className="btn btn-success btn-140-width btn-md"
                  data-dismiss="modal"
                  onClick={() => {
                    this.onClickDeleteTrustSeals(
                      selectedBusinessData.trustseals._id,
                      selectedBusinessData.primaryDomain
                    );
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.accountStore.domains,
    businesses: state.accountStore.businesses,
    businessAccount: state.accountStore.businessAccount,
    userAccount: state.accountStore.userAccount,
    businessUserList: state.accountStore.businessUserList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBusiness,
      getUserById,
      getDomains,
      getBusinesses,
      createNote,
      cancelOrActiveDomain,
      skippedActionByMember,
      getUserListUsingBusinessId,
      getBusinessDetailsById,
      onClickRemoveTrustSealsSettingsDate,
      lockAndUnlockUserAccount,
      goToDashboard: () =>
        push("/", {
          accountAdminView: true,
        }),
      create4xAccount,
      actionAgainstCancelledTrustSeals,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlButtonModal);
