import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { callApiSendFeedback } from "../../services/index";
import AppContext from "../../components/context/appContext";

class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isDisable: false,
      feedback: "",
      email: "",
      businessId: "",
    };

    this.onClickSendFeedback = this.onClickSendFeedback.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.context;
    if (Object.keys(userData).length !== 0 && userData.constructor === Object) {
      if (prevState.email !== userData.email) {
        this.setState({
          email: userData.email,
          businessId: userData.businessDetails[0].businessId,
        });
      }
    }
  }

  closeModal() {
    this.setState({
      feedback: "",
      businessId: "",
    });
  }

  async onClickSendFeedback() {
    this.setState({
      isDisable: true,
    });

    let userPayload = {
      purpose: "feedback",
      feedback: this.state.feedback,
      email: this.state.email,
      businessId: this.state.businessId,
    };

    const feedbackResult = await callApiSendFeedback(userPayload);

    setTimeout(() => {
      this.setState(
        {
          isDisable: false,
        },
        () => {}
      );
    }, 2500);

    if (this.state.feedback) {
      this.setState(
        {
          feedback: "",
          emailId: "",
          businessId: "",
        },
        () => {
          document.getElementById("closeModal").click();
          document.getElementById("feedbackAlert").click();
        }
      );
    }
  }

  render() {
    const { feedback, isDisable } = this.state;

    return (
      <div id="userFeedbackModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Feedback</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 text-left p-0">
                <p>
                  Please feel free to leave us any feedback you have in regards
                  to the system.
                </p>
              </div>
              <div className="col-lg-12 col-md-12 text-left p-0">
                <p>This may include questions or comments you have as well.</p>
              </div>

              <div>
                <textarea
                  id="feedbackTextArea"
                  rows="5"
                  className="textarea-100"
                  name="feedback"
                  value={feedback}
                  onChange={(evt) => {
                    this.setState({
                      feedback: evt.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                id="btnCancelDelayFunctionModal"
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-success btn-md  btn-140-width"
                onClick={this.onClickSendFeedback}
                disabled={isDisable}
                data-testid="btnSaveBusinessUser"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FeedbackModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackModal);
