import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../components/ui/table/table";
import { removeStorageItem } from "../../helpers/storage";
import {
  getSmsHistories,
  getSmsHistoryDetail,
} from "../../modules/actions/index";

const groupBy = (key) => (array) =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
    }),
    {}
  );

class SmsStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      startDate: moment().subtract(1, "days"),
      endDate: moment(),
      smsHistories: [],
      smsHistoryDetails: [],
      statData: null,
      selectedEmailTag: null,
      displayStatDetails: false,
    };

    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date,
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
    });
  }

  componentDidMount() {
    this.props.getSmsHistories();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.smsHistories) {
      this.setState({
        smsHistories: newProps.smsHistories,
      });
    }

    if (newProps.smsHistoryDetails) {
      this.setState(
        {
          smsHistoryDetails: newProps.smsHistoryDetails,
        },
        () => {
          if (
            this.state.smsHistoryDetails &&
            this.state.smsHistoryDetails.length > 0
          ) {
            let tempStat = [];
            for (var i = 0; i < this.state.smsHistoryDetails.length; i++) {
              if (tempStat.indexOf()) {
              }
              tempStat.push({
                name:
                  this.state.smsHistoryDetails[i].userData.firstName +
                  " " +
                  this.state.smsHistoryDetails[i].userData.lastName,
                email: this.state.smsHistoryDetails[i].userData.email,
              });
            }

            const groupByBrand = groupBy("email");

            this.setState({
              statData: groupByBrand(tempStat),
            });
          }
        }
      );
    }
  }

  getSmsStatistics() {
    this.props.getSmsHistories(this.state.startDate, this.state.endDate);
  }

  async getSmsStatisticsInDetails(emailTag) {
    this.setState({
      displayStatDetails: false,
      selectedEmailTag: emailTag,
      statData: null,
    });

    await this.props.getSmsHistoryDetail(
      emailTag,
      this.state.startDate,
      this.state.endDate
    );

    setTimeout(() => {
      this.setState({ displayStatDetails: true });
    }, 3500);
  }

  render() {
    const {
      startDate,
      endDate,
      smsHistories,
      statData,
      displayStatDetails,
      selectedEmailTag,
    } = this.state;

    removeStorageItem("businessID");

    return (
      <Aux>
        <div>
          <h4> SMS Statistics</h4>
          <div className="row">
            <div
              style={{ display: "block" }}
              className="col-lg-4 col-md-3 col-sm-12"
            >
              <label style={{ display: "block" }}>From - To:</label>
              <div className="input-group">
                <DatePicker
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={this.handleChangeStart}
                  className="form-control"
                />
                &nbsp;&nbsp;&nbsp;
                <DatePicker
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  onChange={this.handleChangeEnd}
                  className="form-control"
                />
              </div>
            </div>
            <div
              style={{ display: "block", marginTop: "25px" }}
              className="col-lg-4 col-md-3 col-sm-12"
            >
              <button
                className="col-lg-2 col-md-2 col-sm-12 btn-140-width btn-success btn-md"
                onClick={() => {
                  this.getSmsStatistics();
                }}
              >
                Go
              </button>
            </div>
          </div>
          <Table
            headers={[{ name: "Type" }, { name: "Count" }]}
            emptyHeaderColumnsCount="5"
            trclassName="bg-torq-light"
          >
            <tbody className="scan-status-list">
              {smsHistories.length > 0 ? (
                smsHistories.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <button
                          className="btn-info"
                          data-toggle="modal"
                          data-target="#smsStatisticsModal"
                          onClick={() => {
                            this.getSmsStatisticsInDetails(value._id);
                          }}
                        >
                          {value._id}
                        </button>
                      </td>
                      <td>{value.count}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">
                    <div className="alert alert-info">No results</div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="modal fade" id="smsStatisticsModal" role="dialog">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" style={{ paddingLeft: "20px" }}>
                    Sms Statistics Details for {selectedEmailTag}
                  </h4>
                </div>

                <div
                  className="modal-body"
                  style={{ maxHeight: "450px", overflowY: "scroll" }}
                >
                  {displayStatDetails ? (
                    <Table
                      headers={[
                        { name: "Name" },
                        { name: "Email" },
                        { name: "Count" },
                      ]}
                      emptyHeaderColumnsCount="5"
                      trclassName="bg-torq-light"
                    >
                      <tbody className="scan-status-list">
                        {statData ? (
                          Object.values(statData).map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>{v[0].name}</td>
                                <td>{v[0].email}</td>
                                <td>{v.length}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="6">
                              <div className="alert alert-info">No results</div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  ) : (
                    <div style={{ fontSize: "16px", textAlign: "center" }}>
                      Loading ...
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    smsHistories: state.accountStore.smsHistories,
    smsHistoryDetails: state.accountStore.smsHistoryDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSmsHistories,
      getSmsHistoryDetail,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmsStatistics);
