import {
  GET_LOGGED_IN_USER_DATA_SUCCESS,
  GET_LOGGED_IN_USER_DATA_FAILURE,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  UPDATE_USER_PROFILE_DATA_SUCCESS,
  UPDATE_USER_PROFILE_DATA_FAILURE,
} from "../types/index";

const defaultState = {
  loggedInUserData: {},
  userData: {},
  doUpdateSidebarMenu: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_LOGGED_IN_USER_DATA_SUCCESS:
      return {
        ...state,
        loggedInUserData: action.payload,
      };
    case GET_LOGGED_IN_USER_DATA_FAILURE:
      return {
        ...state,
      };
    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case GET_USER_DATA_FAILURE:
      return {
        ...state,
      };
    case UPDATE_USER_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case UPDATE_USER_PROFILE_DATA_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
