import React, { Component } from "react";
import { Route } from "react-router-dom";
import moment from "moment";
import { callOnIgnoreVulnerabilityContains } from "../../../../../services/index";
class MalwareVulnerabilitiesData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vulnerabilities: [],
      clickedIndex: null,
      showDump: false,
      actionStatus: null,
      containFieldValue: "",
      showContainPatternField: false,
    };
  }

  componentDidMount() {
    this.setState({ vulnerabilities: this.props.malwareInfoData });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.actionStatus !== this.state.actionStatus) {
      if (this.state.actionStatus === "IgnoredIfFoundPattern") {
        this.setState({ showContainPatternField: true });
      }
    }
  }

  goToScannedFiles = () => {};

  onSubmitContainPatternHandler = async (
    vulnerabilityId,
    containFieldValue,
    malwareHistoryId,
    businessId,
    domainId,
    dumpMD5,
    fileName
  ) => {
    await callOnIgnoreVulnerabilityContains(
      vulnerabilityId,
      containFieldValue,
      malwareHistoryId,
      businessId,
      domainId,
      dumpMD5,
      fileName
    );

    window.location.reload();
  };

  render() {
    const {
      businessId,
      primaryDomain,
      domainId,
      malwareHistoryId,
      reportUrl,
      status,
      index,
      malwareInfoData,
      updateMalwareStatus,
      totalIgnoredFiles,
      malwareRules,
    } = this.props;
    const {
      clickedIndex,
      actionStatus,
      vulnerabilities,
      showDump,
      showContainPatternField,
      containFieldValue,
    } = this.state;

    const Button = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            onClick={() => {
              history.push("/new-location");
            }}
          >
            Click Me!
          </button>
        )}
      />
    );
    return (
      <>
        <div index={index} className="vulnerabilityInfoData">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <strong>{primaryDomain}</strong>
                <br />
                <br />
                <br />
                <button
                  class="btn btn-sm btn-outline-danger btn-140-width"
                  style={{ borderRadius: "0.2rem", padding: "0.25rem" }}
                >
                  Send for Review
                </button>
                <br />
                <br />
                {totalIgnoredFiles >= 1 ? (
                  <p>
                    <a href="#">
                      {totalIgnoredFiles} File{totalIgnoredFiles >= 2
                        ? "s"
                        : null}{" "}
                      Ignored
                    </a>
                  </p>
                ) : null}
              </div>
              <div className="col-sm-6">
                <Route
                  render={({ history }) => (
                    <a
                      href="#"
                      style={{
                        textAlign: "right",
                        display: "block",
                        marginTop: "1.3em",
                      }}
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        history.push({
                          pathname: "/malware-scanned-files",
                          state: {
                            vulnerabilities: vulnerabilities,
                            domainHost: primaryDomain,
                            businessId: businessId,
                            reportUrl: reportUrl,
                            malwareHistoryId: malwareHistoryId,
                            malwareRules: malwareRules,
                          },
                        })
                      }
                    >
                      View All Scanned Files
                    </a>
                  )}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                {vulnerabilities.length > 0
                  ? vulnerabilities.map((malware, i) => {
                      return malware.vulnerabilityInfo.malwareVulnerabilities.map(
                        (info, i) => (
                          <>
                            <div class="row">
                              <div class="col-xs-10 col-md-10">
                                <ul class="vulnerabilityList" index={i}>
                                  <li>
                                    <strong>Scan Completed:</strong>{" "}
                                    {moment(malware.scanFinish).format(
                                      "MM/DD/YYYY h:m a"
                                    )}
                                  </li>
                                  <li>
                                    <strong>Filename:</strong> {info.fileName}
                                  </li>
                                  <li>
                                    <strong>File Type:</strong> {info.fileType}
                                  </li>
                                  <li>
                                    <strong>Details:</strong> {info.details}
                                  </li>
                                  <li>
                                    <strong>Reason:</strong> {info.reason}
                                  </li>
                                  <li>
                                    <strong>Threat Detected:</strong>{" "}
                                    {info.threat}
                                  </li>
                                  <li>
                                    <strong>Status:</strong> {info.action}
                                  </li>
                                </ul>
                              </div>
                              <div class="col-xs-2 col-md-2">
                                <select
                                  className="btn-default btn-md form-control"
                                  onChange={(e) => {
                                    if (e.target.value === "Ignored") {
                                      updateMalwareStatus(
                                        e,
                                        reportUrl,
                                        info.threat,
                                        info.fileName,
                                        status,
                                        malwareHistoryId,
                                        domainId,
                                        info.dumpMD5,
                                        businessId
                                      );
                                    }

                                    this.setState({
                                      actionStatus: e.target.value,
                                      clickedIndex: i,
                                    });
                                  }}
                                  value={
                                    i === clickedIndex
                                      ? actionStatus
                                      : info.action
                                  }
                                >
                                  <option
                                    className="bg-white"
                                    defaultValue
                                    value="Action needed"
                                  >
                                    Action
                                  </option>
                                  {info.action === "Ignored" ? (
                                    <option value="Ignored">Ignored</option>
                                  ) : (
                                    <option value="Ignored">Ignore</option>
                                  )}

                                  <option value="IgnoredIfFoundPattern">
                                    Ignore if contains
                                  </option>
                                </select>
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <a
                                href="#"
                                class="code-dump"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    showDump: !showDump,
                                    clickedIndex: i,
                                  });
                                }}
                              >
                                Code Dump
                              </a>
                              <br />
                              <br />
                              <div
                                id="malware-dump"
                                style={{
                                  display:
                                    showDump && i == clickedIndex
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {info.dump}
                              </div>
                            </div>
                            <div class="show-ignore-pattern">
                              {i === clickedIndex && showContainPatternField ? (
                                <>
                                  <br />
                                  <br />
                                  <div className="well">
                                    <div className="form-inline">
                                      <div className="form-group">
                                        <label>
                                          Text that Contains: &nbsp;&nbsp;
                                        </label>
                                        <input
                                          onChange={(e) =>
                                            this.setState({
                                              containFieldValue: e.target.value,
                                            })
                                          }
                                          type="text"
                                          className="form-control"
                                          id="regexcontains"
                                          placeholder="Put text that match a pattern eg: &#60code&#62"
                                        />
                                      </div>
                                      <button
                                        style={{ marginTop: "-10px" }}
                                        className="btn btn-success"
                                        onClick={() =>
                                          this.onSubmitContainPatternHandler(
                                            malware.vulnerabilityInfo._id,
                                            containFieldValue,
                                            malwareHistoryId,
                                            businessId,
                                            domainId,
                                            info.dumpMD5,
                                            info.fileName
                                          )
                                        }
                                      >
                                        Ignore
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </>
                        )
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MalwareVulnerabilitiesData;
