import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import { getUserDecryptedData } from "../../../helpers/general";
import SlideContext from "../../context/slidePanelContexts";

import {
  callApiGetUserBusinessData,
  callApiGetBusinessData,
  callApiGetBusinessSslStatus,
} from "../../../services";

class SslScanResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      businessSslStatus: false,
      displayArrowDown: "none",
      displayArrowUp: "none",
      isSslActive: null,
    };
  }

  async componentDidMount() {
    const businessSslData = await callApiGetBusinessSslStatus({
      businessId: this.props.businessId,
    });

    this.setState({
      isLoading: false,
      businessSslStatus: businessSslData.data.businessSslStatus,
    });

    this.slideArrowUpDownDecision();
  }

  componentWillReceiveProps(props) {
    const { businessId, index } = this.props;
    const { ssl } = this.context;
    const sslContextBusinessId = ssl[index].businessId;
    const sslContextIsActive = ssl[index].isSslActive;
    this.setState({ isSslActive: sslContextIsActive });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isSslActive !== this.state.isSslActive) {
      this.checkSSLpanelisActive();
    }
  }

  checkSSLpanelisActive() {
    const { isSslActive } = this.state;

    if (isSslActive && isSslActive !== null) {
      this.setState({
        displayArrowDown: "none",
        displayArrowUp: "block",
      });
    } else {
      this.setState({
        displayArrowDown: "block",
        displayArrowUp: "none",
      });
    }
  }

  async slideArrowUpDownDecision() {
    const userBusinessData = await callApiGetUserBusinessData(
      getUserDecryptedData()
    );
    const { index } = this.props;
    const { ssl } = this.context;
    const sslContextIsActive = ssl[index].isSslActive;

    if (sslContextIsActive) {
      this.setState({
        displayArrowDown: "none",
        displayArrowUp: "block",
      });
    } else {
      this.setState({
        displayArrowDown: "block",
        displayArrowUp: "none",
      });
    }
  }

  render() {
    const {
      isLoading,
      businessSslStatus,
      displayArrowUp,
      displayArrowDown,
    } = this.state;

    // const sslContext = this.context;
    const { businessId, index } = this.props;

    return (
      <SlideContext.drawerContext.Consumer>
        {(value) => {
          let sslCertificateTextStatus,
            scanSslLinkClass,
            scanSslIconClass,
            scanSslStyle,
            targetData = null,
            sealTextStatus,
            sealLinkClass,
            sealIconClass,
            sealStyle;

          switch (businessSslStatus) {
            case "passed":
              sslCertificateTextStatus = "Verified";
              scanSslLinkClass = "tg-dashboard-passed-link sslscan-toggle";
              scanSslIconClass = "fa fa-check-circle h1-icon";
              scanSslStyle = { color: "#33981f" };
              break;
            case "failed":
              sslCertificateTextStatus = "Failed";
              scanSslLinkClass = "tg-dashboard-failed-link sslscan-toggle";
              scanSslIconClass = "fa fa-exclamation-circle h1-icon";
              scanSslStyle = { color: "#C01818" };
              break;
            case "pending":
              sslCertificateTextStatus = "Scan Pending";
              scanSslLinkClass = "tg-dashboard-failed-link sslscan-toggle";
              scanSslIconClass = "fa fa-clock h1-icon";
              scanSslStyle = { color: "#7f7f7f" };
              break;
            default:
              break;
          }

          return isLoading ? (
            <ContentLoader
              height={450}
              width={989}
              speed={1}
              primaryColor="#f3f3f3"
              secondaryColor="#e1dfdf">
              <circle cx="500" cy="145" r="115" />
              <rect x="275" y="350" rx="0" ry="0" width="450" height="35" />
            </ContentLoader>
          ) : (
            <button
              className={scanSslLinkClass}
              style={scanSslStyle}
              onClick={() => value.ssl[index].openSslPanel(businessId, index)}
              data-testid="dashboardToggleSlidePanelForSsl">
              <i className={scanSslIconClass} />

              <span
                style={{
                  display: "block",
                  clear: "both",
                  fontSize: "12px",
                  fontWeight: "200",
                  marginTop: "20px",
                }}>
                {sslCertificateTextStatus}
              </span>
              <i
                className="ssl fas fa-chevron-down"
                aria-hidden="true"
                style={{
                  display: displayArrowDown,
                }}
              />
              <i
                className="ssl-arrow-ident fas fa-sort-up"
                aria-hidden="true"
                style={{
                  display: displayArrowUp,
                  marginLeft: "-10px",
                }}
              />
            </button>
          );
        }}
      </SlideContext.drawerContext.Consumer>
    );
  }
}

SslScanResult.contextType = SlideContext.drawerContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SslScanResult);
