import React from "react";
import Aux from "../../hoc/containerAux";
import ModalButton from "./button/button";

const modalMalware = (props) => {
  return (
    <Aux>
      {props.showbutton ? (
        <ModalButton
          target={props.targetID}
          nofloat={props.buttonfloatdisable}
          label={props.buttonLabel}
          large={props.buttonlarge}
          small={props.buttonsmall}
        />
      ) : null}
      <div
        id={props.targetID}
        className="modal text-left"
        tabIndex="-1"
        role="dialog">
        <div
          className={props.wide ? "modal-dialog modal-lg" : "modal-dialog "}
          role="document">
          <div
            className="modal-content col-md-12"
            style={{ paddingBottom: "2rem" }}>
            <div className="modal-header">
              <h4 className="modal-title f800">{props.title}</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className={
                props.wide
                  ? "modal-body col-md-12 col-sm-12 col-xs-12"
                  : "modal-body"
              }>
              <div className="scrollbar" id="style-1">
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default modalMalware;
