import React from "react";

const scanTableContent = (props) => {
  return (
    <table
      className="table"
      style={{
        border: "0px solid #efefef",
        borderCollapse: " collapse",
        borderRadius: "5px",
        overflow: " hidden",
      }}
    >
      <thead className="thead-dark" style={{ fontSize: "12px" }}>
        <tr>
          <th className="text-center" width="15%" style={{ padding: "20px" }}>
            Add Subdomains / IPs:
          </th>
          <th className="text-center" width="17%" style={{ padding: "20px" }}>
            External Scan Options
          </th>
          <th className="text-center" width="15%" style={{ padding: "20px" }}>
            Malware Scan Options
          </th>
          <th className="text-center" width="14.5%" style={{ padding: "20px" }}>
            SSL Options
          </th>
          <th className="text-center" width="16%" style={{ padding: "20px" }}>
            Coverage
          </th>
          <th className="text-center" style={{ padding: "20px" }}>
            Seal Options
          </th>
        </tr>
      </thead>
      <tbody style={{ border: "20px solid #efefef", padding: "20px" }}>
        {props.children}
      </tbody>
      <tfoot className="thead-dark">
        <tr>
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "20px" }} />
        </tr>
      </tfoot>
    </table>
  );
};

export default scanTableContent;
