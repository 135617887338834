import React from "react";
import Aux from "../hoc/containerAux";
import ModalMalware from "../../components/ui/modal/modalMalware";
import MalwareVulnerabilityHeading from "./malwareVulnerabilityHeading";
import {
  callFindMalwareVulnerabilityByReportUrl,
  callGetDomainHost,
  callGetMalwares,
} from "../../services";

const abortController = new AbortController();
class MalwareAllModal extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      malwareInfoData: [],
      domainHost: "",
    };
  }

  async loadMalwareData() {
    this._isMounted = true;
    let malwareAPIdata = await callGetMalwares(this.props.malwareHistoryID);
    if (malwareAPIdata.success) {
      let malwareData = malwareAPIdata.data.domains;
      let malwareInfoData = [];
      let malwareData2 = malwareData
        .sort((a, b) => {
          return (
            new Date(a.scanFinish).getTime() - new Date(b.scanFinish).getTime()
          );
        })
        .reverse();

      for (let j = 0; j < malwareData2.length; j++) {
        const malwareDomainInfo = malwareData2[j];
        if (malwareDomainInfo !== null && malwareDomainInfo !== undefined) {
          if (malwareDomainInfo.hasOwnProperty("reportUrl")) {
            let response = await callFindMalwareVulnerabilityByReportUrl(
              malwareDomainInfo.reportUrl
            );
            let sendForReview = null;
            if (response.total > 0) {
              sendForReview = response.data[0].sentForReview;
            }

            let malInfo1 = { _id: malwareData2._id };

            const dataMalware = await callGetDomainHost(
              malwareDomainInfo.domainId
            );
            if (dataMalware) {
              this.setState({
                domainHost: dataMalware.host,
              });
            }

            malInfo1["domainID"] = malwareDomainInfo.domainId;
            malInfo1["domainHost"] = this.state.domainHost;
            malInfo1["scanStatus"] = malwareDomainInfo.status;
            malInfo1["reportUrl"] = malwareDomainInfo.reportUrl;
            malInfo1["sendForReview"] = sendForReview;
            malInfo1["ignoredVulnerabilities"] =
              dataMalware.ignoredVulnerabilities;

            malwareInfoData.push(malInfo1);
          }

          if (this._isMounted) {
            this.setState({
              malwareInfoData,
            });
          }
        }
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadMalwareData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    abortController.abort();
  }

  render() {
    const { malwareInfoData } = this.state;
    const {
      targetID,
      history,
      malwareHistoryID,
      changeClassNames,
      businessID,
    } = this.props;

    return (
      <Aux>
        <ModalMalware targetID={targetID} title="Vulnerabilities" wide>
          <table className="table bordered">
            {malwareInfoData.map((i) => (
              <MalwareVulnerabilityHeading
                key={Math.random()}
                domainID={i.domainID}
                scanStatus={i.scanStatus}
                reportUrl={i.reportUrl}
                sendForReview={i.sendForReview}
                history={history}
                domainHost={i.domainHost}
                ignoredVulnerabilities={i.ignoredVulnerabilities}
                malwareHistoryID={malwareHistoryID}
                changeClassNames={() => changeClassNames()}
                businessID={businessID}
              />
            ))}
          </table>
        </ModalMalware>
      </Aux>
    );
  }
}

export default MalwareAllModal;
