import React from "react";
import { callUpdateMalwareVulnerabilityStatus } from "../services/index";
import moment from "moment";
async function updateMalwareStatus(
  e,
  reportUrl,
  threat,
  fileName,
  scanStatus,
  malwareHistoryID,
  domainId,
  dumpMD5,
  businessId
) {
  let actionVul = e.target.value;
  let actionVulToast = e.target.value;
  if (actionVul === "Unignored" || actionVul === "Cancelled for review") {
    actionVul = "Action needed";
  }

  const result = await callUpdateMalwareVulnerabilityStatus(malwareHistoryID, {
    reportUrl: reportUrl,
    threat: threat,
    fileName: fileName,
    scanStatus: scanStatus,
    id: malwareHistoryID,
    actionVul: actionVul,
    domainId: domainId,
    dumpMD5: dumpMD5,
    businessId: businessId,
    purpose: "update_ignore",
  });

  return result;
}

export default updateMalwareStatus;
