import React from "react";
import MalwareModal from "../malwareModal";
import DomainMalwareTable from "../domainMalwareTable";

const malwareModalData = (props) => {
  return props.malwareInfoData.map((i) => (
    <tr
      key={Math.random()}
      style={{
        display: props.innerTable,
        background: "#f9f8f8",
        padding: "10px",
      }}
    >
      <td>
        <MalwareModal
          scanFinish={i.scanFinish}
          scanStatus={i.status}
          domainInfoID={i.domainInfoID}
          domainID={i.domainID}
          history={props.history}
          reportUrl={i.reportUrl}
          sendForReview={i.sendForReview}
          targetID={i.domainInfoID}
          malwareHistoryID={i.malwareHistoryID}
          changeClassNames={() => props.changeClassNames()}
        />
      </td>

      <DomainMalwareTable
        scanStatus={i.status}
        number={i.domainNumber}
        scanFinish={i.scanFinish}
        detection={i.detection}
        domainName={i.domainName}
        targetID={"#" + i.domainInfoID}
      />
    </tr>
  ));
};

export default malwareModalData;
