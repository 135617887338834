import React from "react";

class ControlPanelTabHeader extends React.Component {
  state = {};
  render() {
    return (
      <div id="mcontent-top" className="pull-left">
        <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
          <ul id="dashTab" className="nav menu-tab" id="controlPanelTab">
            <li className="nav-item active">
              <a
                className="nav-link"
                id="reports-tab"
                data-toggle="tab"
                href="#reports"
                role="tab"
                aria-controls="reports-tab"
                aria-selected="true"
              >
                Reports
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="advsearch-tab"
                data-toggle="tab"
                href="#advsearch"
                role="tab"
                aria-controls="advsearch-tab"
                aria-selected="true"
              >
                Advanced Search
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="create-account-tab"
                data-toggle="tab"
                href="#create-account"
                role="tab"
                aria-controls="create-account-tab"
                aria-selected="true"
              >
                Create Account
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="create-notifications-tab"
                data-toggle="tab"
                href="#create-notifications"
                role="tab"
                aria-controls="create-notifications-tab"
                aria-selected="true"
              >
                Email
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="one-time-email-tab"
                data-toggle="tab"
                href="#one-time-email"
                role="tab"
                aria-controls="one-time-email-tab"
                aria-selected="true"
              >
                One Time Emails
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="upload-scripts-tab"
                data-toggle="tab"
                href="#upload-scripts"
                role="tab"
                aria-controls="upload-scripts-tab"
                aria-selected="true"
              >
                Seal Scripts
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default ControlPanelTabHeader;
