import React from "react";
import SwitchButton from "../../../../../components/ui/switch/SwitchButton";
const gdprSwitch = ({ scanOptions, changeStatus }) => {
  return (
    <div className="row">
      <div className="col-sm-9 pl-0">
        <button className="tg-dashboard-link">
          <i
            className={
              scanOptions.scanReport.gdpr
                ? "text-success fas fa-circle mr-2"
                : "text-muted fas fa-circle mr-2"
            }
          />{" "}
          GDPR Scan
        </button>
      </div>
      <div className="col-sm-3 pr-0 text-right">
        <SwitchButton
          changeStatus={() => changeStatus("gdpr")}
          status={scanOptions.scanReport.gdpr}
        />
        <br />
      </div>
    </div>
  );
};

export default gdprSwitch;
