import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { push } from "connected-react-router";
import moment from "moment";
import Header from "../header";
import FalsePositive from "../../views/home/partials/falsePositive";
import TableContent from "../../components/dashboard/partials/tableContent";
import ScanResultRow from "../../components/dashboard/partials/scanResultRow";
import { getStorageItem } from "../../helpers/storage";
import BackDrop from "../../components/ui/backDrop";
import {
  callOnGetScanHistoryByOwner,
  callOnGetScanHistoryByOwnerAndDateRange,
  callCreateBeyondScan,
  callOnUpdateUserWithBSData,
} from "../../services/beyondSecurityService";
import { callOnGetBusinessByOwner } from "../../services/businessServiceCopy";
import { callOnFindVulnerabilitiesByScanId } from "../../services/vulnerabilityService";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class PCIScanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      startDate: moment().subtract(2, "years"),
      endDate: moment(),
      showBackDrop: false,
      filtereSearchActive: false,
      url: null,
      business: [],
      scanhistories: [],
      scanHistoryResults: null,
      activePage: 1,
      pageLimit: 10,
      scanResultsLength: 0,
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  async componentDidMount() {
    const scanHistory = await callOnGetScanHistoryByOwner(
      this.props.match.params.id
    );
    const business = await callOnGetBusinessByOwner(this.props.match.params.id);
    const businessInfo = business.data[0];

    let scanhistories = [...this.state.scanhistories];
    this.setState({
      scanhistories: scanhistories.concat(scanHistory.data),
      url: businessInfo.primaryDomain,
      business: businessInfo,
    });
    this.getCurrentScanHistories();
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date,
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
    });
  }

  filterDateSearch = async () => {
    const owner = this.props.match.params.id;
    const results = await callOnGetScanHistoryByOwnerAndDateRange(
      owner,
      this.state.startDate,
      this.state.endDate
    );
    this.setState({
      scanhistories: results.data,
      scanHistoryResults: results.data,
      filtereSearchActive: true,
    });
    this.getCurrentScanHistories();
  };

  getCurrentScanHistories = (pageNumber = 1) => {
    const pageResults = this.state.scanhistories;
    const pageLimit = this.state.pageLimit;
    const currentPage = pageNumber;
    const offset = (currentPage - 1) * pageLimit;
    let scanHistories = null;

    if (pageResults.length > 0) {
      scanHistories = pageResults.slice(offset, offset + pageLimit);
    } else {
      scanHistories = this.state.scanhistories;
    }

    this.setState({
      activePage: pageNumber,
      scanHistoryResults: scanHistories,
      scanResultsLength: pageResults.length,
    });
  };

  handlePageChange = (pageNumber) => {
    this.getCurrentScanHistories(pageNumber);
  };

  startScanDomainHandler = async (id) => {
    let userData = getStorageItem("userData");
    let userString = cryptr.decrypt(userData);
    let user = JSON.parse(userString);
    if (userData) {
      await callCreateBeyondScan({
        _id: this.state.business._id,
        userId: user._id,
        supportEmail: this.state.business.supportEmail,
        businessName: this.state.business.businessName,
        primaryDomain: this.state.business.primaryDomain,
        supportPhone: this.state.business.supportPhone,
        state: this.state.business.state,
        zip: this.state.business.zip,
        address: this.state.business.address,
        address2: this.state.business.address2,
        city: this.state.business.state,
        country: this.state.business.country,
        flag: "manual",
      });
      await callOnUpdateUserWithBSData(user._id);
      window.location.reload();
    }
  };

  submitFalsePostive = (id) => {
    this.setState({ toggledIndex: id });
  };

  getVulnerabilityDetail = async (owner, id, scandate, severity) => {
    this.setState({ showBackDrop: true });
    const vulnerabilities = await callOnFindVulnerabilitiesByScanId(
      id,
      owner,
      severity
    );
    this.setState({ showBackDrop: false });
    this.props.history.push({
      pathname: "/vulnerabilities/" + owner,
      state: {
        owner: owner,
        id,
        data: vulnerabilities,
        scanDate: scandate,
        domain: this.state.business.primaryDomain,
        severity: severity,
      },
    });
  };

  scanContent() {
    let scanContent = "";
    const {
      scanHistoryResults,
      activePage,
      pageLimit,
      scanResultsLength,
      business,
      filtereSearchActive,
    } = this.state;
    const scanResults = scanHistoryResults;
    if (Array.isArray(scanResults)) {
      scanContent = (
        <TableContent
          activePage={activePage}
          pageLimit={pageLimit}
          totalItems={scanResultsLength}
          pageChange={this.handlePageChange}
          isPagination={true}
        >
          {scanResults.map((i, idx) => {
            const scanDate = moment(i.scanDate).format("MM/DD/YYYY");
            const rowIndex = idx + 1;
            const high = +i.high;
            const medium = +i.medium;
            const low = +i.low;
            const total = high + medium + low;
            return (
              <ScanResultRow
                key={i._id}
                resultId={i._id}
                activePage={activePage}
                index={rowIndex}
                indexOf={idx}
                frequency={
                  i.flag === "manual"
                    ? i.status === "pending" ||
                      i.status === "running" ||
                      i.status === "finalizing"
                      ? "rescanning"
                      : "rescanned"
                    : "daily"
                }
                high={high}
                medium={medium}
                low={low}
                log={i.log}
                status={i.status}
                owner={i.owner}
                total={total}
                scandate={i.scanDate ? scanDate : "N/A"}
                rescan={() => this.startScanDomainHandler(0)}
                businessIdRescan={business._id}
                businessData={business}
                submitFalsePostive={() => this.submitFalsePostive(idx)}
                goToPageVulnerabilities={this.getVulnerabilityDetail}
                isSearching={filtereSearchActive}
              />
            );
          })}
        </TableContent>
      );
    }
    return scanContent;
  }

  render() {
    const { startDate, endDate, showBackDrop, url, business } = this.state;

    return (
      <div>
        <Header titlePage="PCI Scanning" bgColor="#F5F6F8" />
        <BackDrop show={showBackDrop} height="100%" />
        <div id="mcontent-wrapper">
          <div id="PCI-scan" className="">
            <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
              <div className="inner-ptab tab-content">
                <div id="scan" className="tab-pane fade in active">
                  <h4>Scan Reports</h4>
                  <p>
                    <a
                      href="/"
                      className="btn btn-secondary btn-sm mb-3"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.goToDashboard();
                      }}
                    >
                      <i className="fas fa-chevron-circle-left" />&nbsp; Go Back
                    </a>
                    <br />
                    Below you will find access to your scans including the
                    date/time, the duration and if the scan passed or failed.
                    You can also view pdf version of your scans at anytime. If
                    your scanning has stopped, you can resume the scan by
                    clicking the scan button. Scans will automatically stop if
                    you fail, to give you an opportunity to fix the issue,
                    before rescanning.
                  </p>
                  <br />
                  <br />

                  <FalsePositive
                    singlePage={true}
                    url={url}
                    businessDetails={business}
                    submitFalsePostive={() => this.submitFalsePostive(1)}
                  />

                  <div className="col col-md-6">
                    <div className="form-group">
                      From &nbsp;
                      <DatePicker
                        selected={startDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        onChange={this.handleChangeStart}
                        className="form-control"
                      />&nbsp;To &nbsp;
                      <DatePicker
                        selected={endDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        onChange={this.handleChangeEnd}
                        className="form-control"
                      />{" "}
                      &nbsp;{" "}
                      <button
                        className="btn btn-success"
                        onClick={this.filterDateSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="col col-md-6" />

                  {this.scanContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToDashboard: () =>
        push("/", {
          accountAdminView: true,
        }),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PCIScanning);
