import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_ACCOUNT_LOCKED,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
} from "../types/index";

const defaultState = {
  res: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_FAILURE:
      return {
        ...state,
        res: action.payload,
      };
    case LOGIN_ACCOUNT_LOCKED:
      return {
        ...state,
        res: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        res: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        res: action.payload,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        res: action.payload,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
