import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { onFalseWelcomePopup } from "../../modules/actions/index";
import WelcomePopupModal from "../modals/welcomePopupModal";
import AppContext from "../../components/context/appContext";

class WelcomePopup extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      welcomePopup: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.context;

    if (
      prevState.userId !== userData._id &&
      prevState.welcomePopup !== userData.welcomePopup
    ) {
      this.setState({
        welcomePopup: userData.welcomePopup,
      });
    }
  }

  displayWelcomePopup() {
    document.getElementById("openWelcomePopup").click();
  }

  render() {
    const { welcomePopup } = this.state;

    if (welcomePopup === true) {
      this.displayWelcomePopup();
    }

    return (
      <>
        <button
          id="openWelcomePopup"
          data-toggle="modal"
          data-target="#welcomePopup"
          data-backdrop="static"
          data-keyboard="false"
          style={{ display: "none" }}
        >
          Open Welcome Popup Modal
        </button>

        <WelcomePopupModal />
      </>
    );
  }
}

WelcomePopup.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomePopup);
