import React from "react";

class UserSmsInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { smsInfoModalClose } = this.props;

    return (
      <div
        id="smsInfo"
        className="modal"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title">SMS Notification</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                /* onClick={() => {
                  smsInfoModalClose();
                }} */
                data-testid="btnSmsInfoModalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h5 style={{ fontSize: "16px" }}>
                  Please note that SMS messages will be sent to the phone number
                  on your profile. (U.S. Numbers Only)
                </h5>
              </div>
            </div>
            <div className="modal-footer text-right pt-4">
              <button
                id="hideSmsInfo"
                type="button"
                className="btn-tglight2 btn btn-md mr-2"
                data-dismiss="modal"
                /* onClick={() => {
                  smsInfoModalClose();
                }} */
                data-testid="btnSmsInfoModalClose"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserSmsInfo;
