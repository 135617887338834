import timeZoneData from "./timeZoneData.json";
import moment from "moment-timezone";

function convertTimeTo(date, timeZone, format, label = true) {
  moment.tz.add(timeZoneData);
  moment.tz.setDefault("UTC");
  const timezone =
    timeZone !== undefined && timeZone !== null ? timeZone : "America/Denver";
  const formatDate =
    format !== undefined && format !== null ? format : "MM/DD/YYYY hh:mm a";
  if (date !== undefined) {
    const utcDate = moment.utc(date);
    if (utcDate !== undefined || utcDate !== undefined) {
      const displayUserTimeZoneDateFormat = utcDate
        .clone()
        .tz(timezone)
        .format(formatDate);
      return displayUserTimeZoneDateFormat;
    }
  }
}

function convert24HourFormat(userTime) {
  let inputTime = userTime;
  let timeMeridiam = "am";
  if (inputTime < 12) {
    timeMeridiam = "am";
  } else {
    if (inputTime === 24) {
      timeMeridiam = "am";
      inputTime = 12;
    } else {
      timeMeridiam = "pm";
      if (inputTime !== 12) {
        inputTime = userTime;
      } else {
        inputTime = inputTime - 12;
      }
    }
  }
  return inputTime + " " + timeMeridiam;
}

export { convertTimeTo, convert24HourFormat };
