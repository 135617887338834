import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { convertTimeTo } from "../../helpers/timeZone";
import {
  callUpdateMalwareVulnerability,
  callFindMalwareVulnerabilityByReportUrl,
  callGetMalwares,
  callMalwarePatchDomainStatus,
  callMalwarePatchBusiness,
  callMalwareSearchByBusinessIdAndLatestDate,
  callGetDomainHost,
  callUpdateDomainsDumpMD5,
  callOnIgnoreVulnerabilityContains,
} from "../../services";
import ToastMessage from "../../components/toast/toastMessage";
import { getUserDecryptedData } from "../../helpers/general";
import moment from "moment/moment";
import { getStorageItem, removeStorageItem } from "../../helpers/storage";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class MalwareNotCleanFiles extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      submitted: false,
      showContainField: false,
      containFieldSubmit: false,
      containFieldValue: "",
      actionValue: "",
      viewCodeDump: "none",
      spanCodeDump: false,
      userId: null,
    };
  }

  viewCodeDump() {
    if (!this.state.spanCodeDump === false) {
      this.setState({
        viewCodeDump: "none",
      });
    } else {
      this.setState({
        viewCodeDump: "block",
      });
    }
    this.setState({
      spanCodeDump: !this.state.spanCodeDump,
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    removeStorageItem("businessID");
    let user = {};
    let userData = getStorageItem("userData");
    if (userData) {
      let userString = cryptr.decrypt(userData);
      user = JSON.parse(userString);
      this.setState({
        userId: user._id,
      });
    }
  }

  async onChangeSetMalwareVul(e) {
    let actionVul = e.target.value;
    let actionVulToast = e.target.value;
    if (actionVul === "Unignored" || actionVul === "Cancelled for review") {
      actionVul = "Action needed";
    }

    if (actionVul === "IgnoredIfFoundPattern") {
      this.setState({
        showContainField: true,
      });
    } else {
      await callFindMalwareVulnerabilityByReportUrl(this.props.reportUrl).then(
        (data) => {
          let malwareVulnerabilityData = data.data;
          let domainStatus = this.props.threat.toLowerCase();
          if (malwareVulnerabilityData.length > 0) {
            let malwareVul = malwareVulnerabilityData[0].malwareVulnerabilities;
            let domainId = malwareVulnerabilityData[0].domainId;
            let ignoreCounter = 0;
            let dumpMD5 = [];

            malwareVul.forEach((element) => {
              if (element.fileName === this.props.fileName) {
                let actionVulnerabilitesHistory = actionVul;
                if (actionVul === "Action needed") {
                  actionVulnerabilitesHistory = "Unignored";
                }

                const malwareVulnerabilityHistoryData = {
                  memberId: this.state.userId,
                  status: actionVulnerabilitesHistory,
                  date: moment(new Date()),
                };

                if (element.history !== undefined) {
                  element.history.push(malwareVulnerabilityHistoryData);
                } else {
                  element.history = [];
                  element.history.push(malwareVulnerabilityHistoryData);
                }

                element.action = actionVul;

                callGetDomainHost(domainId).then((data) => {
                  dumpMD5 = data.ignoredVulnerabilities;

                  if (dumpMD5 === undefined) {
                    const arrayDumpMD5 = {
                      ignoredVulnerabilities: " ",
                    };

                    callUpdateDomainsDumpMD5(domainId, arrayDumpMD5).then(
                      (data) => {
                        dumpMD5 = data.ignoredVulnerabilities;

                        let dumpMDPRemove = [];

                        for (let x = 0; x < dumpMD5.length; x++) {
                          if (dumpMD5[x] !== " " || dumpMD5[x] !== "") {
                            dumpMDPRemove = dumpMDPRemove.concat(
                              element.dumpMD5
                            );
                          }
                        }

                        const arrayDumpMD5 = {
                          ignoredVulnerabilities: dumpMDPRemove,
                        };

                        callUpdateDomainsDumpMD5(domainId, arrayDumpMD5);
                      }
                    );
                  } else {
                    if (actionVul === "Action needed") {
                      domainStatus = element.threat.toLowerCase();

                      let dumpMDPRemove = [];

                      for (let x = 0; x < dumpMD5.length; x++) {
                        if (
                          element.dumpMD5 !== dumpMD5[x] &&
                          dumpMD5[x] !== " " &&
                          dumpMD5[x] !== ""
                        ) {
                          dumpMDPRemove = dumpMDPRemove.concat(dumpMD5[x]);
                        }
                      }

                      const arrayDumpMD5 = {
                        ignoredVulnerabilities: dumpMDPRemove,
                      };

                      callUpdateDomainsDumpMD5(domainId, arrayDumpMD5);
                    } else {
                      let ctr = 0;
                      for (let x = 0; x < dumpMD5.length; x++) {
                        if (element.dumpMD5 === dumpMD5[x]) {
                          ctr++;
                        }
                      }
                      if (ctr === 0) {
                        dumpMD5 = dumpMD5.concat([element.dumpMD5]);
                      }

                      const arrayDumpMD5 = {
                        ignoredVulnerabilities: dumpMD5,
                      };

                      callUpdateDomainsDumpMD5(domainId, arrayDumpMD5);
                    }
                  }
                });
              }

              if (this.props.scanStatus !== "clean") {
                if (element.action !== "Ignored") {
                  ignoreCounter++;
                }
              }
            });

            const malwareVulData = {
              malwareVulnerabilities: malwareVul,
            };

            callUpdateMalwareVulnerability(
              malwareVulnerabilityData[0]._id,
              malwareVulData
            );

            callGetMalwares(this.props.malwareHistoryID).then(
              (malwareAPIdata) => {
                if (malwareAPIdata.success) {
                  let notCleanStatus = null;
                  let malwareData2 = malwareAPIdata.data.domains;
                  let malwareInfoData = [];
                  if (malwareData2.length > 0) {
                    let statusCounter = 0;

                    for (let j = 0; j < malwareData2.length; j++) {
                      let malInfo = { _id: malwareData2._id };
                      const domainInfo = malwareData2[j];

                      if (ignoreCounter === 0) {
                        if (domainInfo.reportUrl === this.props.reportUrl) {
                          malInfo["status"] = "clean";
                        } else {
                          malInfo["status"] = domainInfo.status;
                        }
                        notCleanStatus = domainStatus;
                      } else {
                        if (domainInfo.reportUrl === this.props.reportUrl) {
                          malInfo["status"] = domainStatus;
                          notCleanStatus = domainStatus;
                        } else {
                          malInfo["status"] = domainInfo.status;
                        }
                      }

                      malInfo["scanStart"] = domainInfo.scanStart;
                      malInfo["scanFinish"] = domainInfo.scanFinish;
                      malInfo["domainId"] = domainInfo.domainId;
                      malInfo["scanType"] = domainInfo.scanType;
                      malInfo["reportUrl"] = domainInfo.reportUrl;
                      malwareInfoData.push(malInfo);

                      if (malInfo["status"] !== "clean") {
                        statusCounter++;
                      }
                    }

                    const domainsData = {
                      domains: malwareInfoData,
                    };

                    callMalwarePatchDomainStatus(
                      this.props.malwareHistoryID,
                      domainsData
                    );

                    let malwareHistoryId = "";
                    callMalwareSearchByBusinessIdAndLatestDate(
                      data.businessId
                    ).then((malwareAPIdata) => {
                      malwareHistoryId = malwareAPIdata.data.data[0]._id;
                      domainStatus = malwareAPIdata.data.data[0].scanStatus;
                      const businessId = malwareAPIdata.data.data[0].businessId;

                      if (statusCounter === 0) {
                        const domainsData = {
                          scanStatus: "clean",
                        };

                        callMalwarePatchDomainStatus(
                          this.props.malwareHistoryID,
                          domainsData
                        ).then(() => {
                          if (
                            this.props.malwareHistoryID === malwareHistoryId
                          ) {
                            const businessMalwareScanStatus = {
                              lastMalwareScanResult: "clean",
                              currentMalwareScanResult: "clean",
                            };

                            callMalwarePatchBusiness(
                              businessId,
                              businessMalwareScanStatus
                            ).then(() => {
                              // window.location.reload();
                            });
                          }
                        });
                      } else if (statusCounter === 1) {
                        const domainsData = {
                          scanStatus: notCleanStatus,
                        };

                        callMalwarePatchDomainStatus(
                          this.props.malwareHistoryID,
                          domainsData
                        ).then(() => {
                          if (ignoreCounter === 1) {
                            if (actionVul !== "Ignored") {
                              if (
                                this.props.malwareHistoryID === malwareHistoryId
                              ) {
                                const businessMalwareScanStatus = {
                                  lastMalwareScanResult: notCleanStatus,
                                  currentMalwareScanResult: notCleanStatus,
                                };

                                callMalwarePatchBusiness(
                                  businessId,
                                  businessMalwareScanStatus
                                ).then(() => {
                                  // window.location.reload();
                                });
                              }
                            }
                          }
                        });
                      }
                    });
                  }
                }
              }
            );
          }
        }
      );

      if (actionVulToast === "Action") {
        this.setState({
          submitted: false,
          actionValue: "",
        });
      } else {
        this.setState({
          submitted: true,
          actionValue: "File " + actionVulToast,
        });
      }
    } //end check ignore if contains check
  }

  statusActive(statusActive) {
    if (statusActive === "Action needed") {
      statusActive = "Active";
    } else {
      statusActive = this.props.actionStatus;
    }
    return statusActive;
  }

  async onSubmitContainPatternHandler() {
    this.setState({
      showContainField: false,
    });

    await callOnIgnoreVulnerabilityContains(
      this.props.vulnerabilityId,
      this.state.containFieldValue,
      this.props.malwareHistoryID,
      this.props.businessId,
      this.props.domainId,
      this.props.dumpMD5,
      this.props.fileName
    );

    window.location.reload();
  }

  containFieldValue = (e) => {
    this.setState({
      containFieldValue: e.target.value,
    });
  };

  render() {
    let alert = null;
    if (this.state.submitted) {
      alert = (
        <ToastMessage type="success" notifyMessage={this.state.actionValue} />
      );
    }
    const userData = getUserDecryptedData();
    let timeZone = "America/Denver";
    if (userData !== undefined && userData !== null) {
      timeZone = userData.hasOwnProperty("timezone")
        ? userData.timezone
        : "America/Denver";
    }

    const { actionStatus } = this.props;

    return (
      <tr>
        <td style={{ width: "70%" }}>
          {alert}
          <strong>Scan Completed:</strong>{" "}
          {convertTimeTo(
            this.props.scanFinish,
            timeZone,
            "MM/DD/YYYY hh:mm a",
            false
          )}
          <br />
          <strong>Filename:</strong> {this.props.fileName}
          <br />
          <strong>File Type:</strong> {this.props.fileTypeName}
          <br />
          <strong>Details:</strong>
          <span> {this.props.details}</span>
          <br />
          <strong>Reason:</strong> {this.props.reason}
          <br />
          <strong>Threat Detected:</strong> {this.props.threat}
          <br />
          <strong>Status:</strong> {this.statusActive(this.props.actionStatus)}
          <br />
          <strong
            style={{ color: "blue" }}
            className="pointer"
            onClick={this.viewCodeDump.bind(this)}
          >
            Code Dump
          </strong>{" "}
          <span style={{ display: this.state.viewCodeDump }}>
            {this.props.dump}
          </span>
          {this.state.showContainField ? (
            <>
              <br />
              <br />
              <div className="well">
                <div className="form-inline">
                  <div className="form-group">
                    <label>Text that Contains: &nbsp;&nbsp;</label>
                    <input
                      onChange={(e) => this.containFieldValue(e)}
                      type="text"
                      className="form-control"
                      id="regexcontains"
                      placeholder="Put text that match a pattern eg: &#60code&#62"
                    />
                  </div>
                  <button
                    style={{ marginTop: "-10px" }}
                    className="btn btn-success"
                    onClick={() => this.onSubmitContainPatternHandler()}
                  >
                    Ignore
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </td>

        <td>
          <select
            className="btn-default btn-md form-control"
            onChange={(e) => this.onChangeSetMalwareVul(e)}
            value={actionStatus}
          >
            <option className="bg-white" defaultValue>
              Action
            </option>
            {actionStatus === "Ignored" ? (
              <option value="Ignored">Ignored</option>
            ) : (
              <option value="Ignored">Ignore</option>
            )}

            <option value="IgnoredIfFoundPattern">Ignore if contains</option>
            {/*<option value="Sent for Review">Send for Review</option>*/}
          </select>
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareNotCleanFiles);
