import { api } from "./api";
import { callApi } from "../helpers/index";

export const callApiGetBusinessSubdomainData = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api
        .service("domains")
        .find({ query: { businessId: businessId, isSubDomain: true } });
    } else {
      return null;
    }
  });
};

export const callApiCreateBusinessSubdomain = async (domain) => {
  return callApi().then((token) => {
    if (domain) {
      return api.service("domains").create(domain);
    }
  });
};

export const callApiGetDomainInfoUsingBusinessId = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api.service("domains").find({ businessId: businessId });
    }
  });
};

export const callApiDeleteDomain = async (domainId, info) => {
  return callApi().then((token) => {
    return api.service("api/domains").remove(domainId, {
      query: {
        info,
        businessId: info.businessId,
        purpose: "remove_domain",
      },
    });
  });
};
