import React from "react";
import { capitalize } from "../../../helpers/capitalize";

const scanResultData = (props) => {
  let highCountStyle = {
    display: "block",
    width: "34px",
    background: "rgba(255, 255, 255, 0.1)",
    color: "black",
    border: "1px solid #d8d4d4",
  };

  let mediumCountStyle = {
    display: "block",
    width: "34px",
    background: "rgba(255, 255, 255, 0.1)",
    color: "black",
    border: "1px solid #d8d4d4",
  };

  let lowCountStyle = {
    display: "block",
    width: "34px",
    background: "rgba(255, 255, 255, 0.1)",
    color: "black",
    border: "1px solid #d8d4d4",
  };

  if (props.high > 0) {
    highCountStyle = {
      display: "block",
      width: "34px",
      cursor: "pointer",
    };
  }

  if (props.medium > 0) {
    mediumCountStyle = {
      display: "block",
      width: "34px",
      cursor: "pointer",
    };
  }

  if (props.low > 0) {
    lowCountStyle = {
      display: "block",
      width: "34px",
      cursor: "pointer",
    };
  }

  const riskFactors = [
    props.high > 0 ? 8 : 0,
    props.medium > 0 ? 4 : 0,
    props.low > 0 ? 1 : 0,
  ];

  return (
    <tr className="pciTable">
      <td />

      <td>{props.scandate}</td>
      <td>
        {props.frequency !== null
          ? props.status !== "invalid"
            ? capitalize(props.frequency)
            : "Invalid URL"
          : ""}
      </td>
      <td className="text-center px-0">
        <span
          style={{
            textAlign: "center",
            width: "42px",
            display: "inline-block",
            paddingLeft: "4px",
          }}
        >
          {props.high > 0 ? (
            <span
              className="bg bg-sm bg-serious"
              style={highCountStyle}
              onClick={() =>
                props.goToPageVulnerabilities(
                  props.owner,
                  props.resultId,
                  props.scandate,
                  riskFactors
                )
              }
            >
              {" "}
              {props.high}{" "}
            </span>
          ) : (
            <span className="bg bg-sm bg-serious" style={highCountStyle}>
              {" "}
              {props.high}{" "}
            </span>
          )}
        </span>
        <span
          style={{
            textAlign: "center",
            width: "43px",
            display: "inline-block",
            paddingLeft: "6px",
          }}
        >
          {props.medium > 0 ? (
            <span
              className="bg bg-sm bg-medium"
              style={mediumCountStyle}
              onClick={() =>
                props.goToPageVulnerabilities(
                  props.owner,
                  props.resultId,
                  props.scandate,
                  riskFactors
                )
              }
            >
              {" "}
              {props.medium}{" "}
            </span>
          ) : (
            <span className="bg bg-sm bg-medium" style={mediumCountStyle}>
              {" "}
              {props.medium}{" "}
            </span>
          )}
        </span>
        <span
          style={{
            textAlign: "center",
            width: "43px",
            display: "inline-block",
            paddingLeft: "6px",
          }}
        >
          {props.low > 0 ? (
            <span
              className="bg bg-sm bg-green"
              style={lowCountStyle}
              onClick={() =>
                props.goToPageVulnerabilities(
                  props.owner,
                  props.resultId,
                  props.scandate,
                  riskFactors
                )
              }
            >
              {" "}
              {props.low}{" "}
            </span>
          ) : (
            <span className="bg bg-sm bg-green" style={lowCountStyle}>
              {" "}
              {props.low}{" "}
            </span>
          )}
        </span>
        <span
          style={{
            textAlign: "center",
            width: "43px",
            display: "inline-block",
            paddingLeft: "6px",
          }}
        >
          {props.total > 0 ? (
            <span
              className="bg bg-sm bg-green"
              style={lowCountStyle}
              onClick={() =>
                props.goToPageVulnerabilities(
                  props.owner,
                  props.resultId,
                  props.scandate,
                  riskFactors
                )
              }
            >
              {" "}
              {props.total}{" "}
            </span>
          ) : (
            <span className="bg bg-sm bg-green" style={lowCountStyle}>
              {" "}
              {props.total}{" "}
            </span>
          )}
        </span>
      </td>
      <td className="pr-0">
        {props.downloadReportButton}
        {props.modalShowDownloadReportOpt}
      </td>
      <td className="text-success scan-btn">{props.rescanButton}</td>
    </tr>
  );
};

export default scanResultData;
