import React from "react";
import { isArray } from "util";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MalwareNotCleanFiles from "./malwareNotCleanFiles";
import ToastMessage from "../../components/toast/toastMessage";
import {
  callGetS3Data,
  callUpdatereviewMalwareVulnerability,
  callFindMalwareVulnerabilityByReportUrl,
  callOnMalwareCreateEmailHistory,
  callOnIgnoreAllVulnerability,
} from "../../services";
import _ from "lodash";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

const abortController = new AbortController();

class MalwareVulnerabilityHeading extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      malwareInfoData2: [],
      blacklist: { status: null, domains: [] },
      vulnerabilities: 0,
      reportUrl: this.props.reportUrl,
      domainID: null,
      submitted: false,
      actionValue: "",
      ignoredCounter: 0,
      displayButtonForReview: "none",
    };
  }

  async loadMalwareData() {
    this._isMounted = true;
    let response = await callFindMalwareVulnerabilityByReportUrl(
      this.props.reportUrl
    );

    const AWSS3data = await callGetS3Data(this.props.reportUrl);
    const ignoredFiles = [];
    const ignoredDumpMD5 = [];

    if (response.data[0] !== undefined) {
      const foundVulnerabilities = response.data[0].malwareVulnerabilities;
      if (isArray(foundVulnerabilities)) {
        if (foundVulnerabilities.length > 0) {
          for (let x = 0; x < foundVulnerabilities.length; x++) {
            if (foundVulnerabilities[x].action === "Ignored") {
              ignoredFiles.push(foundVulnerabilities[x].action);
              ignoredDumpMD5.push(foundVulnerabilities[x].dumpMD5);
            }
          }

          this.setState({
            vulnerabilities: foundVulnerabilities.length,
            ignoredCounter: ignoredFiles.length,
          });
        }
      }

      if (response.total > 0) {
        let malwareData = response.data[0].malwareVulnerabilities;
        let malwareInfoData2 = [];
        for (let x = 0; x < malwareData.length; x++) {
          let malInfo = { _id: malwareData._id };
          const domainInfo = malwareData[x];

          if (domainInfo.action !== "Ignored") {
            malInfo["fileName"] = domainInfo.fileName;
            malInfo["fileTypeName"] = domainInfo.fileType;
            malInfo["reason"] = domainInfo.reason;
            malInfo["details"] = domainInfo.details;
            malInfo["threat"] = domainInfo.threat;
            malInfo["scanStatus"] = domainInfo.threat;
            malInfo["scanFinish"] = domainInfo.date;
            malInfo["actionStatus"] = domainInfo.action;
            malInfo["dump"] = domainInfo.dump;
            malInfo["dumpMD5"] = domainInfo.dumpMD5;
            malInfo["reportUrl"] = this.props.reportUrl;
            malInfo["_id"] = domainInfo._id;
            malInfo["vulnerabilityId"] = response.data[0]._id;

            //if it has no dumpMD5 and there dumpMD5 is in ignoredFiles then status would be ignored
            // This means that there is a blacklisted domains
            if (
              domainInfo.dump === undefined &&
              domainInfo.dumpMD5 === "00000000000000000000000000000000" &&
              _.includes(ignoredDumpMD5, domainInfo.dumpMD5)
            ) {
              malInfo["actionStatus"] = "Ignored";

              this.setState({
                ignoredCounter: foundVulnerabilities.length,
              });
            }
            if (malInfo["actionStatus"] !== "Ignored") {
              malwareInfoData2.push(malInfo);
            }
          }
        }

        const blacklist = { ...this.state.blacklist };
        const blacklistedDomains = AWSS3data.report.blacklisted_domains;
        const domainsMalicious = [];
        blacklist["report"] = AWSS3data.report;
        blacklist["status"] =
          AWSS3data.report.blacklist_report.blacklist_status;

        const reportDomains = Object.keys(blacklistedDomains).map((host) => {
          return [
            ...Array({
              host: host,
              status: blacklistedDomains[host],
            }),
          ];
        });

        if (reportDomains.length > 0) {
          if (reportDomains[0].length > 0) {
            for (let i = 0; i < reportDomains[0].length; i++) {
              domainsMalicious.push(reportDomains[0][i]);
            }
            blacklist["domains"] = domainsMalicious;
          }
        }

        if (this._isMounted) {
          this.setState({
            malwareInfoData2,
            blacklist,
          });
        }
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadMalwareData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    abortController.abort();
  }

  hideSendForMalware = () => {
    this.setState({
      displayButtonForReview: "none",
      submitted: false,
      actionValue: "",
    });
  };

  displaySendForMalware = () => {
    this.setState({
      displayButtonForReview: "block",
      submitted: false,
      actionValue: "",
    });
  };
  async onChangeSetMalwareVulnerability(actionVul) {
    let actionVulToast = actionVul;

    let vulReport = await callFindMalwareVulnerabilityByReportUrl(
      this.state.reportUrl
    );

    const malwareVuls = [];
    if (vulReport.data.length > 0) {
      let actionVulnerability = false;

      if (actionVul === "Sent for review") {
        actionVulnerability = true;
      } else {
        actionVulnerability = false;
      }

      const malwareVulnerabilityData = {
        sentForReview: actionVulnerability,
        dateSentForReview: Date.now,
      };

      for (let i = 0; i < vulReport.data.length; i++) {
        const vuls = vulReport.data[i].malwareVulnerabilities;
        if (vuls.length > 0) {
          for (let x = 0; x < vuls.length; x++) {
            malwareVuls.push(vuls[x].fileName);
          }
        }
      }

      callUpdatereviewMalwareVulnerability(
        vulReport.data[0]._id,
        malwareVulnerabilityData
      );
    }

    let userId = null;
    let userData = window.localStorage.getItem("userData");
    let userString = cryptr.decrypt(userData);
    let user = JSON.parse(userString);
    if (userData) {
      userId = user._id;
    }

    let emailHistoryData = {
      action: "create_email_history_from_malware",
      contactEmail: user.email,
      site: this.props.domainHost,
      fileName: malwareVuls.join(", "),
      businessId: this.props.businessID,
      userId: userId,
    };

    await callOnMalwareCreateEmailHistory(emailHistoryData).then(
      (response) => {}
    );

    if (actionVulToast === "Sent for review") {
      this.setState({
        submitted: true,
        actionValue: "Support " + actionVulToast,
      });
    } else {
      this.setState({
        submitted: false,
        actionValue: "",
      });
    }
  }

  statusColor(status) {
    let detectionColor = "";
    let BlacklistMessage = "";
    let viewBlackListLink = null;
    if (this.state.blacklist !== null) {
      BlacklistMessage = "No Blacklisted Sites.";
      viewBlackListLink = BlacklistMessage;

      if (this.state.blacklist.status === "GenericMaliciousObject") {
        BlacklistMessage = "This Site is Blacklisted.";
        viewBlackListLink = (
          <>
            <a
              href="/"
              data-dismiss="modal"
              className="pointer"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/malware-scanned-files", {
                  reportUrl: this.props.reportUrl,
                  domainID: this.props.domainID,
                  domainHost: this.props.domainHost,
                  malwareHistoryID: this.props.malwareHistoryID,
                  isMalwareScannedFilesPage: true,
                  blacklistreport: this.state.blacklist,
                });
              }}
            >
              {BlacklistMessage}
            </a>
          </>
        );
      } else {
        if (this.state.blacklist.hasOwnProperty("domains")) {
          if (isArray(this.state.blacklist.domains)) {
            viewBlackListLink = "No Blacklisted Sites.";
            const blacklists = [];
            if (this.state.blacklist.domains.length > 0) {
              for (let x = 0; x < this.state.blacklist.domains.length; x++) {
                blacklists.push(this.state.blacklist.domains[x].host);
              }
              BlacklistMessage = "Blacklisted Sites: " + blacklists.join(", ");
              viewBlackListLink = (
                <>
                  <a
                    href="/"
                    data-dismiss="modal"
                    className="pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push("/malware-scanned-files", {
                        reportUrl: this.props.reportUrl,
                        domainID: this.props.domainID,
                        domainHost: this.props.domainHost,
                        malwareHistoryID: this.props.malwareHistoryID,
                        isMalwareScannedFilesPage: true,
                        blacklistreport: this.state.blacklist,
                        businessId: this.props.businessID,
                      });
                    }}
                  >
                    {BlacklistMessage}
                  </a>
                </>
              );
            }
          }
        }
      }
    }

    if (status === "malicious") {
      detectionColor = (
        <td className="text-danger">
          <p style={{ color: "#000" }}>
            <strong>{this.props.domainHost}</strong>
            <br />
            {viewBlackListLink}
          </p>
          <p>
            <i className="fas fa-exclamation-circle" /> Threat: Malicious
          </p>
        </td>
      );
    } else if (status === "potentially suspicious") {
      detectionColor = (
        <td style={{ color: "orange" }}>
          <p style={{ color: "#000" }}>
            <strong>{this.props.domainHost}</strong>
            <br />
            {viewBlackListLink}
          </p>
          <p>
            <i className="fas fa-exclamation-circle" /> Threat: Potentially
            Suspicious
          </p>
        </td>
      );
    } else if (status === "suspicious") {
      detectionColor = (
        <td style={{ color: "orange" }}>
          <p style={{ color: "#000" }}>
            <strong>{this.props.domainHost}</strong>
            <br />
            {viewBlackListLink}
          </p>
          <p>
            <i className="fas fa-exclamation-circle" /> Threat: Suspicious
          </p>
        </td>
      );
    } else if (status === "clean") {
      detectionColor = (
        <td style={{ color: "#33981f" }}>
          <p style={{ color: "#000" }}>
            <strong>{this.props.domainHost}</strong>
            <br />
            {viewBlackListLink}
          </p>

          <p>
            <i className="text-success fas fa-exclamation-circle" /> No
            Vulnerabilities Found
          </p>
          <p>
            {this.state.ignoredCounter > 0 ? (
              <button
                href=""
                className="pointer tg-dashboard-link"
                data-dismiss="modal"
                onClick={() => {
                  this.props.history.push("/malware-scanned-files", {
                    reportUrl: this.props.reportUrl,
                    domainID: this.props.domainID,
                    domainHost: this.props.domainHost,
                    malwareHistoryID: this.props.malwareHistoryID,
                    isMalwareScannedFilesPage: true,
                    blacklistreport: this.state.blacklist,
                    businessId: this.props.businessID,
                  });
                }}
              >
                {" "}
                {this.state.ignoredCounter}{" "}
                {this.state.ignoredCounter > 1 ? "Files" : "File"} Ignored
              </button>
            ) : null}
          </p>
        </td>
      );
    } else {
      detectionColor = (
        <td style={{ color: "#000" }}>
          <p style={{ color: "#000" }}>
            <strong>{this.props.domainHost}</strong>
          </p>
          <p>
            <i className="fas fa-exclamation-circle" /> Threat: Pending
          </p>
          <p>&nbsp;</p>
        </td>
      );
    }

    return detectionColor;
  }

  viewReviewButton(malStatus) {
    let sendForReviewButton = "";
    let sendForReviewButtonConfirmation = "";
    if (this.props.sendForReview === true || this.state.submitted) {
      sendForReviewButton = (
        <p>
          <button
            className="btn btn-sm btn-outline-danger btn-140-width disabled"
            style={{
              borderRadius: "0.2rem",
              padding: "0.25rem 0.25rem",
            }}
          >
            Sent for Review
          </button>
        </p>
      );
      sendForReviewButtonConfirmation = "";
    } else {
      if (
        malStatus === "malicious" ||
        malStatus === "potentially suspicious" ||
        malStatus === "suspicious"
      ) {
        sendForReviewButton = (
          <p>
            <button
              className="btn btn-sm btn-outline-danger btn-140-width"
              style={{
                borderRadius: "0.2rem",
                padding: "0.25rem 0.25rem",
              }}
              onClick={this.displaySendForMalware}
            >
              Send for Review
            </button>
          </p>
        );
        sendForReviewButtonConfirmation = (
          <p
            style={{
              display: this.state.displayButtonForReview,
              color: "#000",
            }}
          >
            Submit this malware for scan for review? Submitting this malware
            scan<br /> for review will send your results to Trust Guard so we
            can help you resolve<br /> this issue. We may require additional
            access like FTP
            <br />
            <br />
            <button
              type="button"
              onClick={() =>
                this.onChangeSetMalwareVulnerability("Sent for review")
              }
              className="btn-success btn-140-width btn-md"
              style={{ marginRight: "10px" }}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn-tglight2 btn-140-width"
              onClick={this.hideSendForMalware}
            >
              No
            </button>
          </p>
        );
      }
    }

    const reviewElement = (
      <tr>
        <td colSpan="2">
          {sendForReviewButton}
          {sendForReviewButtonConfirmation}
        </td>
      </tr>
    );

    if (
      malStatus === "malicious" ||
      malStatus === "potentially suspicious" ||
      malStatus === "suspicious"
    ) {
      return reviewElement;
    }
  }

  getScanStatus = (status) => {
    let malwareStatus = this.props.scanStatus;
    if (this.state.vulnerabilities !== undefined) {
      if (this.state.vulnerabilities > 0) {
        if (this.state.ignoredCounter > 0) {
          malwareStatus = "clean";
        } else {
          malwareStatus = this.props.scanStatus;
        }
      } else {
        malwareStatus = "clean";
      }
    }

    return malwareStatus;
  };

  ignoreAllFiles = async () => {
    await callOnIgnoreAllVulnerability(
      null,
      this.props.malwareHistoryID,
      this.props.domainID,
      this.props.businessID,
      this.props.reportUrl
    );
    window.location.reload();
  };

  render() {
    let alert = null;
    const {
      malwareInfoData2,
      blacklist,
      reportUrl,
      submitted,
      actionValue,
    } = this.state;

    const {
      scanStatus,
      history,
      domainHost,
      domainID,
      malwareHistoryID,
      businessID,
      changeClassNames,
    } = this.props;

    if (submitted) {
      alert = <ToastMessage type="success" notifyMessage={actionValue} />;
    }

    return (
      <tbody style={{ border: "1px solid #cccccc" }}>
        {alert}
        <tr>
          {this.statusColor(this.getScanStatus(scanStatus))}

          <td>
            <p>
              <button
                data-dismiss="modal"
                className="pointer pull-right tg-dashboard-link"
                onClick={() =>
                  history.push("/malware-scanned-files", {
                    reportUrl: reportUrl,
                    domainID: domainID,
                    domainHost: domainHost,
                    malwareHistoryID: malwareHistoryID,
                    isMalwareScannedFilesPage: true,
                    blacklistreport: blacklist,
                    businessId: businessID,
                  })
                }
              >
                View All Scanned Files
              </button>
            </p>
            <p>&nbsp;</p>
            {malwareInfoData2.length > 0 ? (
              <p>
                <button
                  className="btn btn-success btn-md pull-right tg-dashboard-link"
                  data-dismiss="modal"
                  onClick={() => this.ignoreAllFiles()}
                >
                  Ignore All
                </button>
              </p>
            ) : null}
          </td>
        </tr>
        {this.viewReviewButton(this.getScanStatus(scanStatus))}

        {this.props.scanStatus !== "clean"
          ? malwareInfoData2.map((i) => (
              <MalwareNotCleanFiles
                key={i._id}
                vulnerabilityId={i.vulnerabilityId}
                history={history}
                fileName={i.fileName}
                reason={i.reason}
                details={i.details}
                actionStatus={i.actionStatus}
                fileTypeName={i.fileTypeName}
                threat={i.threat}
                scanFinish={i.scanFinish}
                reportUrl={i.reportUrl}
                dump={i.dump}
                dumpMD5={i.dumpMD5}
                domainId={domainID}
                malwareHistoryID={malwareHistoryID}
                scanStatus={scanStatus}
                changeClassNames={() => changeClassNames()}
                businessId={businessID}
              />
            ))
          : null}
      </tbody>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareVulnerabilityHeading);
