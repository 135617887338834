import React, { Component } from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import Modal from "../../../ui/modal/modal";

class ModalDownloadOption extends Component {
  render() {
    const {
      targetID,
      defaultValueType,
      reportTypeHandler,
      reports,
      showRemediation,
      downloadReport,
      defaultValueFormat,
    } = this.props;

    return (
      <Modal targetID={targetID} title="Download Report Option">
        <h5>Please select report type and format to process download</h5>
        <div className="mt-4">
          <Row className="show-grid">
            <Col xs={6} md={6}>
              <strong>Select the Report Type</strong>
              <FormGroup>
                <select
                  name="reportType"
                  value={defaultValueType}
                  onChange={reportTypeHandler}
                >
                  <option value="executivesummary">Executive</option>
                  <option value="pci">PCI</option>
                  <option value="pcidss">PCI Attestation</option>
                  <option value="pcidss-summary">
                    PCI Attestation Summary
                  </option>
                  <option value="detailed">Detailed</option>
                  {reports}
                  <option value="iso27001">ISO27001</option>
                  <option value="sox">SOX</option>
                  {showRemediation ? (
                    <option value="remediation">Remediation</option>
                  ) : null}
                </select>
              </FormGroup>
            </Col>
            <Col xs={6} md={6}>
              <strong>Select the Format</strong>
              <FormGroup>
                <select
                  defaultValue={defaultValueFormat}
                  name="reportFormat"
                  onChange={reportTypeHandler}
                >
                  <option value="csv">CSV</option>
                  <option value="xml">XML</option>
                  <option value="html">HTML</option>
                  <option value="pdf">PDF</option>
                </select>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <br />
        <div className="text-right px-0 pt-4 modal-footer">
          <button
            type="button"
            className="btn-tglight2 btn-140-width btn-md mr-2"
            data-dismiss="modal"
            style={{ marginRight: "7px" }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn-success btn-140-width btn-md  "
            data-dismiss="modal"
            onClick={downloadReport}
          >
            Download
          </button>
        </div>
      </Modal>
    );
  }
}

export default ModalDownloadOption;
