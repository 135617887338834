import React from "react";
import ContentWrapper from "../ui/contentWrapper";
import FalsePositive from "../../views/home/partials/falsePositive";
import { Button } from "react-bootstrap";

const vulnerabilityDetailed = (props) => {
  const { state } = props.history.location;

  const falsePositiveModal = (
    <FalsePositive
      vulnerabilityPage={true}
      vulnerabilityName={state.detail.name}
      businessDetails={state.businessDetails}
    />
  );

  return (
    <ContentWrapper header="Detailed Vulnerability">
      {falsePositiveModal}
      <h3>
        Vulnerability Name:{" "}
        <span style={{ color: "#2fac66" }}> {state.detail.name}</span>
      </h3>
      <h3>
        Host: <span style={{ color: "#2fac66" }}>{state.detail.host}</span>
      </h3>
      <h3>
        Port: <span style={{ color: "#2fac66" }}>{state.detail.port}</span>
      </h3>
      {state.detail.risk !== null && state.detail.risk !== "" ? (
        <h3> Risk: {state.detail.risk}</h3>
      ) : null}
      <br />
      <div className="well other-info">
        <h4 className="tg">
          <i className="fas fas-lightbulb-o" /> Solution
        </h4>

        <p>
          {state.detail.solution} {state.detail.description}
        </p>
        <hr />
        <h4 className="tg">Additional Information</h4>
        <ul className="reference-list">
          <li ng-if="details.severity" className="ng-binding ng-scope">
            Severity: {state.detail.severity}
          </li>
        </ul>
      </div>
      {state.detail.severity !== "Low" ? (
        <div id="falsePositiveSection">
          <center>
            <Button
              bsStyle="danger"
              bsSize="large"
              data-toggle="modal"
              data-target="#dash-false-positive"
              style={{ marginRight: "10px" }}
            >
              {" "}
              <i className="fas fa-file-upload" />&nbsp; Waive Vulnerability
            </Button>
          </center>
        </div>
      ) : null}
    </ContentWrapper>
  );
};

export default vulnerabilityDetailed;
