import React from "react";

const malwareReportButton = ({ status, targetID, getMalwareInfo }) => {
  let boxColor = "";
  let btnStatus = "";
  let label = "";
  if (
    status === "suspicious" ||
    status === "potentially suspicious" ||
    status === "malicious"
  ) {
    btnStatus = "btnFail";
    label = "Failed Scan Report";
  } else if (status === "clean") {
    btnStatus = "btnPass";
    label = "Passed Scan Report";
  } else if (status === "running") {
    btnStatus = "btnRunning";
    label = "Running";
  }

  boxColor = (
    <>
      <button
        className={btnStatus}
        data-toggle="modal"
        data-target={"#malware-modal-" + targetID}
        onClick={() => getMalwareInfo(targetID)}
      >
        {label}
      </button>
    </>
  );

  return boxColor;
};

export default malwareReportButton;
