import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmailTemplateForm from "./emailTemplateForm";
import EmailTemplates from "./emailTemplates";

class EmailTab extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div id="create-notifications" className="tab-pane fade">
        <EmailTemplateForm />
        <EmailTemplates />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTab);
