import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../helpers/config";
import {
  updateBusinessAccountType,
  updateUserDetails,
  onClickSendMail,
  resetStatus,
} from "../../modules/actions/index";

class LockUnlockUser extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      businessId: "",
      selectedUserId: "",
      selectedUserData: "",
      userAccessRole: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      timezone: "",
      editable: false,
      submitted: false,
      notifyType: "",
      notifyMessage: "",
      businessInfoData: [],
      displayUserDetails: "none",
      editData: false,
      isPrimaryUser: false,
    };
  }

  onClickUpdateAccount = (id) => {
    this.goToUpdateAccount(id);
  };

  async goToUpdateAccount() {
    let targetUserBusinessDetails = this.state.selectedUserData.businessDetails;

    targetUserBusinessDetails.map((v, i) => {
      if (v.businessId === this.props.businessId) {
        v.groupType = this.state.userAccessRole;
      }
      return null;
    });

    let targetData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      timezone: this.state.timezone,
      businessDetails: targetUserBusinessDetails,
    };

    this.props.updateUserDetails(this.state.selectedUserId, targetData);

    this.props.controlPanelUserUpdated();
    this.props.populateBusinessUsersData(this.props.businessId);

    this.setState({
      editData: false,
    });
  }

  onChangeHandelUserInput(evt) {
    let name = evt.target.name;
    let value = evt.target.value;
    this.setState({
      [name]: value,
    });
  }

  resetPassword = (email) => {
    if (email) {
      let sendToMail = {
        action: "create_email_history",
        emailTag: "reset-password",
        query: {
          email: email,
        },
      };
      this.props.onClickSendMail(sendToMail);
    }
  };

  showEditUser(user) {
    let roleData = user.businessDetails.filter((v, i) => {
      return v.businessId === this.props.businessId ? v : null;
    });

    this.setState({
      selectedUserId: user._id,
      selectedUserData: user,
      editData: true,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      timezone: user.timezone,
      userAccessRole:
        roleData[0].groupType === "admin"
          ? "Account Admin"
          : roleData[0].groupType,
      isPrimaryUser: user.primaryUser,
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.mailCode === 1) {
      toast.info("Requested link sent to this email", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.props.resetStatus();
    }

    if (newProps.messageType === "control_panel_user_edited_success") {
      this.setState(
        {
          notifyType: "success",
          notifyMessage: newProps.message,
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (newProps.usersInBusiness) {
      newProps.usersInBusiness.map((v, i) => {
        let currentBusiness = v.businessDetails.filter((iv, ii) => {
          return v.businessId === this.props.busnessId ? v : null;
        });

        v.userAccessRole = currentBusiness[0].groupType;
        v.primaryUser = currentBusiness[0].primaryUser;

        return currentBusiness;
      });
    }
  }

  onChangeAccessRole = (event) => {
    this.setState({
      userAccessRole: event.target.value,
    });
  };

  setSubmittedFalse(id) {
    this.setState({
      submitted: false,
      displayUserDetails: "none",
    });
  }

  changeBusinessAccountType = (accountType) => {
    this.props.updateBusinessAccountType(this.props.businessId, {
      accountType: accountType,
    });
  };

  render() {
    const {
      businessId,
      selectedUserId,
      userAccessRole,
      firstName,
      lastName,
      phone,
      email,
      timezone,
      editData,
      isPrimaryUser,
    } = this.state;

    const { usersInBusiness } = this.props;

    return (
      <Aux>
        <div
          className="modal-content"
          id={businessId}
          style={{ border: "1px solid #000000" }}
        >
          <div className="modal-header">
            <div className="modal-title f800">
              <h3>
                {editData
                  ? "Edit User - " + firstName + " " + lastName
                  : "Account Security - Users"}
              </h3>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.setSubmittedFalse()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            style={
              editData
                ? { display: "block", marginTop: "45px" }
                : { display: "none" }
            }
          >
            <div className="col-md-6">
              <p>
                Firstname:
                <input
                  value={firstName}
                  type="text"
                  className="form-control"
                  name="firstName"
                  onChange={this.onChangeHandelUserInput.bind(this)}
                />
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Lastname:{" "}
                <input
                  value={lastName}
                  type="text"
                  className="form-control"
                  name="lastName"
                  onChange={this.onChangeHandelUserInput.bind(this)}
                />
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Email:{" "}
                <input
                  value={email}
                  type="text"
                  className="form-control"
                  name="email"
                  onChange={this.onChangeHandelUserInput.bind(this)}
                />
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Phone:{" "}
                <input
                  value={phone}
                  type="text"
                  className="form-control"
                  name="phone"
                  onChange={this.onChangeHandelUserInput.bind(this)}
                />
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Timezone:
                <select
                  className="form-control"
                  onChange={(evt) => {
                    this.setState({
                      timezone: evt.target.value,
                    });
                  }}
                  name="timezone"
                  value={timezone}
                >
                  <option>Select Timezone</option>
                  {config.timezones.map((v, i) => {
                    return (
                      <option key={i} value={v.timezone}>
                        {v.timezone}
                      </option>
                    );
                  })}
                </select>
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Security Access Level:
                <select
                  className="form-control"
                  value={userAccessRole}
                  name="groupType"
                  onChange={this.onChangeAccessRole}
                  disabled={isPrimaryUser}
                >
                  <option>Select A Role For User</option>
                  <option value="Account Admin">Account Admin</option>
                  <option value="Account Manager">Account Manager</option>
                  <option value="Scan Report Access">Scan Report Access</option>
                </select>
              </p>
            </div>
            <div className="col-sm-12">
              <p className="text-right">
                <button
                  id="cancelButton"
                  type="button"
                  className="btn-tglight2 btn-md btn-140-width mr-2"
                  style={{ marginRight: "7px" }}
                  onClick={() => {
                    this.setState({
                      editData: false,
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-success btn-md btn-140-width mt-2"
                  onClick={() => {
                    this.onClickUpdateAccount(selectedUserId);
                  }}
                >
                  Save
                  <br />
                </button>
              </p>
            </div>
          </div>
          {this.props.usersInBusiness !== undefined &&
          this.props.usersInBusiness ? (
            <div className="modal-body">
              <div
                className="table-wrapper-scroll-y"
                style={editData ? { display: "none" } : { display: "block" }}
              >
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Security Access Level</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersInBusiness.map((user) => {
                      return (
                        <Aux key={Math.random()}>
                          <tr>
                            <td>
                              {user.firstName} {user.lastName}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.userAccessRole}</td>
                            <td>{user.userStatus}</td>
                            <td>
                              <div className="dropdown">
                                <button
                                  className="btn btn-danger dropdown-toggle btn-sm"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  Option
                                  <span className="caret" />
                                </button>
                                <ul className="dropdown-menu">
                                  <li className="pointer">
                                    <button
                                      data-toggle="modal"
                                      data-target="#confirmModal"
                                      onClick={(e) => {
                                        this.props.saveUserNameAndId(
                                          user._id,
                                          user.firstName,
                                          user.userStatus
                                        );
                                      }}
                                      className="tg-dashboard-link"
                                    >
                                      {user.userStatus === "locked"
                                        ? "Unlock Account"
                                        : "Lock Account"}
                                    </button>
                                  </li>
                                  <li className="pointer">
                                    <button
                                      className="tg-dashboard-link"
                                      onClick={(e) => {
                                        this.resetPassword(user.email);
                                      }}
                                    >
                                      Reset Password
                                    </button>
                                  </li>
                                  <li className="pointer">
                                    <button
                                      className="tg-dashboard-link"
                                      onClick={(e) => {
                                        this.showEditUser(user);
                                      }}
                                    >
                                      Edit User Details
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </Aux>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn-danger pull-right btn-140-width btn-md"
              data-dismiss="modal"
              onClick={() => this.setSubmittedFalse()}
            >
              Close
            </button>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mailCode: state.loginStore.mailCode,
    usersInBusiness: state.loginStore.usersInBusiness,
    messageType: state.loginStore.messageType,
    message: state.loginStore.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateUserDetails,
      onClickSendMail,
      resetStatus,
      updateBusinessAccountType,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockUnlockUser);
