import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext from "../../components/context/appContext";
import { updateBusinessData } from "../../modules/actions/index";
import {
  callApiGetBusinessData,
  callApiGetBusinessUsersList,
  callApiUpdateCheckScanReportsData,
  callApiGetCheckScanReportsDataUsingBusinessId,
} from "../../services/index";

class BusinessUsersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessUsersList: [],
    };
  }

  componentDidMount() {}

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;

    if (currentBusinessId) {
      const businessUsersList = await callApiGetBusinessUsersList({
        businessId: currentBusinessId,
      });

      if (
        prevState.businessUsersList.length !== businessUsersList.data.length
      ) {
        this.setState({
          businessUsersList: businessUsersList.data,
        });
      }
    }
  }

  closeModal() {
    this.setState({
      note: "",
      reReview: "",
      customDate: null,
    });
  }

  render() {
    const { currentBusinessId } = this.context;
    const { businessUsersList } = this.state;

    return (
      <div
        id="businessUsersModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Business Users</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>
                      <label>Email</label>
                    </th>
                    <th>
                      <label>Email Body</label>
                    </th>
                    <th>
                      <label>Status</label>
                    </th>
                    <th>
                      <label>Created Date</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {businessUsersList && businessUsersList.length
                    ? businessUsersList.map((eachValue, eachIndex) => {
                        return (
                          <tr key={eachIndex}>
                            <td>
                              {eachValue.firstName + " " + eachValue.lastName}
                            </td>
                            <td>{eachValue.email}</td>
                            <td>
                              {eachValue.businessDetails.map((v, i) => {
                                return v.businessId === currentBusinessId
                                  ? v.groupType
                                  : "";
                              })}
                            </td>
                            <td>{moment(eachValue.createdAt).format("LLL")}</td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
            <div className="modal-footer text-right">
              <button
                id="btnCancelDelayFunctionModal"
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BusinessUsersModal.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateBusinessData }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessUsersModal);
