import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import Header from "../header";
import { getStorageItem } from "../../helpers/storage";
import ToastMessage from "../../components/toast/toastMessage";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../../views/charts/line";

import {
  getUserById,
  notificationSetSeen,
  save4xIntegrationStatus,
  getBusiness,
  getBusinessDetailsById,
  resetStatus,
} from "../../modules/actions/index";

import {
  callGetBusinessDetailsById,
  callGetBusinessUsers,
  callCreate4xAccount,
  callGet4xAccountOrders,
} from "../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class Account4X extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: "",
      /* eslint-disable */
      codeBox:
        '<script type="text/javascript">' +
        'var email = "__EMAIL__";' +
        'var name = "__NAME__";' +
        'var order_number = "";' +
        'var order_amount = "";' +
        'var address1 = "";' +
        'var address2 = "";' +
        'var city = "";' +
        'var state = "";' +
        'var zip = "";' +
        'var country = "";' +
        'var phone = "__PHONE__";' +
        'var key = "__CUSTOMER_KEY__";' +
        'var domain = "https://member.4xbuyerprotection.com/gener/capture.php?email="+email+"&name="+name+"&order_number="+order_number+"&order_amount="+order_amount+"&address1="+address1+"&address2="+address2+"&city="+city+"&state="+state+"&zip="+zip+"&country="+country+"&phone="+phone+"&key="+key;' +
        'document.write(\'<img src=""' +
        " +domain +" +
        '" height="1" width="1" />\');' +
        "</script>",
      /* eslint-enable */
      isCompleted: false,
      notifyType: "",
      notifyMessage: "",
      userBusinessList: [],
      websiteType: "other",
      currentBusiness4xData: "",
      ordersData: [0, 0, 0, 0, 0, 0, 0],
      registeredData: [0, 0, 0, 0, 0, 0, 0],
      last30DaysOrders: 0,
      last30DaysRegistered: 0,
      statisticsActive: false,
    };
  }

  async componentDidMount() {
    let user = {};
    let userData = getStorageItem("userData");
    if (userData) {
      let userString = cryptr.decrypt(userData);
      user = JSON.parse(userString);
      this.props.getUserById(user._id);

      this.setState({ userId: user._id });

      if (user.businessDetails && user.businessDetails.length > 0) {
        this.setState({ businessId: user.businessDetails[0].businessId });
      }

      let currentBusinessData = await callGetBusinessDetailsById(
        user.businessDetails[0].businessId
      );

      if (
        currentBusinessData &&
        !currentBusinessData.hasOwnProperty("details4X")
      ) {
        const userData = await callGetBusinessUsers(currentBusinessData._id);

        if (userData && userData.data && userData.data.length > 0) {
          let query = {
            action: "create-4x-account",
            query: {
              businessData: {
                businessId: currentBusinessData._id,
                name: currentBusinessData.businessName,
                website: currentBusinessData.primaryDomain,
                /* eslint-disable */
                contact_name:
                  userData.data[0].firstName + " " + userData.data[0].lastName,
                contact_email: userData.data[0].email,
                contact_phone: userData.data[0].phone,
                /* eslint-enable */
              },
            },
          };

          await callCreate4xAccount(query);

          currentBusinessData = await callGetBusinessDetailsById(
            user.businessDetails[0].businessId
          );
        }
      }

      if (
        currentBusinessData &&
        currentBusinessData.hasOwnProperty("details4X") &&
        currentBusinessData.details4X.hasOwnProperty("integrationStatus") &&
        currentBusinessData.details4X.integrationStatus
      ) {
        this.setState({
          isCompleted: true,
        });
      }

      if (
        currentBusinessData &&
        currentBusinessData.details4X &&
        currentBusinessData.details4X.hasOwnProperty("websiteType") &&
        currentBusinessData.details4X.websiteType !== ""
      ) {
        this.setState({
          websiteType: currentBusinessData.details4X.websiteType,
          currentBusiness4xData:
            currentBusinessData.details4X &&
            currentBusinessData.details4X.merchant
              ? currentBusinessData.details4X.merchant
              : "",
        });
      }

      if (currentBusinessData && currentBusinessData.details4X) {
        let ordersDataCoordinate = [],
          registeredDataCoordinate = [];
        Promise.all([
          Promise.all([
            await callGet4xAccountOrders({
              type: "4x-orders",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(6, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(6, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-orders",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(5, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(5, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-orders",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(4, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(4, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-orders",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(3, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(3, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-orders",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(2, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(2, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-orders",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(1, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(1, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-orders",
              businessId: this.state.businessId,
              startDate: moment()
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment()
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
          ]).then((result) => {
            result.map((v) => {
              ordersDataCoordinate.push(v.count.myCount);
              return null;
            });

            return null;
          }),
          Promise.all([
            await callGet4xAccountOrders({
              type: "4x-registered",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(6, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(6, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-registered",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(5, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(5, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-registered",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(4, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(4, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-registered",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(3, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(3, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-registered",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(2, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(2, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-registered",
              businessId: this.state.businessId,
              startDate: moment(
                moment()
                  .subtract(1, "months")
                  .format("YYYY-MM-DD")
              )
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment(
                moment()
                  .subtract(1, "months")
                  .format("YYYY-MM-DD")
              )
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
            await callGet4xAccountOrders({
              type: "4x-registered",
              businessId: this.state.businessId,
              startDate: moment()
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: moment()
                .endOf("month")
                .format("YYYY-MM-DD"),
            }),
          ]).then((result) => {
            result.map((v) => {
              registeredDataCoordinate.push(v.count.myCount);
              return null;
            });

            return null;
          }),

          await callGet4xAccountOrders({
            type: "4x-orders",
            businessId: this.state.businessId,
            startDate: moment(new Date())
              .add(-30, "day")
              .format("YYYY-MM-DD"),
            endDate: moment(new Date()).format("YYYY-MM-DD"),
          }),
          await callGet4xAccountOrders({
            type: "4x-registered",
            businessId: this.state.businessId,
            startDate: moment(new Date())
              .add(-30, "day")
              .format("YYYY-MM-DD"),
            endDate: moment(new Date()).format("YYYY-MM-DD"),
          }),
        ]).then((result) => {
          this.setState({
            currentBusiness4xData:
              currentBusinessData.details4X &&
              currentBusinessData.details4X.merchant
                ? currentBusinessData.details4X.merchant
                : "",
            ordersData: ordersDataCoordinate,
            registeredData: registeredDataCoordinate,
            last30DaysOrders: result[2].count.myCount,
            last30DaysRegistered: result[3].count.myCount,
          });
        });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.details4X &&
      newProps.details4X.hasOwnProperty("details4X") &&
      newProps.details4X.details4X.integrationStatus
    ) {
      this.setState({
        isCompleted: true,
      });
    }

    if (newProps.messageType && newProps.messageType === "4x-account-success") {
      this.setState(
        {
          notifyType: "success",
          notifyMessage: "Buyer protection account integrated successfully.",
        },
        () => {
          this.setState({
            notifyType: "",
            notifyMessage: "",
          });
        }
      );

      this.props.resetStatus();
    }

    if (newProps.loggedInUser && newProps.loggedInUser.businessDetails) {
      this.setState({
        userBusinessList: newProps.loggedInUser.businessDetails,
      });
    }
  }

  setBusiness = async (businessId) => {
    this.setState({
      businessId: businessId,
    });
    const currentBusinessData = await callGetBusinessDetailsById(businessId);

    if (
      currentBusinessData &&
      currentBusinessData.hasOwnProperty("details4X") &&
      currentBusinessData.details4X.hasOwnProperty("integrationStatus") &&
      currentBusinessData.details4X.integrationStatus
    ) {
      this.setState({
        isCompleted: true,
      });
    } else {
      this.setState({
        isCompleted: false,
      });
    }
  };

  setwebsiteType = (evt) => {
    this.setState({
      websiteType: evt.target.value,
    });
  };

  clickWebsiteTypeRadio = (thisValue) => {
    document.getElementById(thisValue).click();
  };

  render() {
    const {
      businessId,
      codeBox,
      isCompleted,
      notifyType,
      notifyMessage,
      userBusinessList,
      websiteType,
      currentBusiness4xData,
      ordersData,
      registeredData,
      statisticsActive,
      last30DaysOrders,
      last30DaysRegistered,
    } = this.state;

    const xLabels = [
      moment(
        moment()
          .subtract(6, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(5, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(4, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment().format("MMMM"),
    ];
    const yData = [
      {
        label: "Orders",
        data: ordersData,
      },
      {
        label: "Registered",
        data: registeredData,
      },
    ];

    return (
      <Aux>
        <div>
          <Header titlePage="Trust Guard Buyer Protection" bgColor="#F5F6F8" />

          <ToastMessage type={notifyType} notifyMessage={notifyMessage} />

          <Aux>
            {userBusinessList && userBusinessList.length > 1 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "12px 45px 12px 45px",
                }}
              >
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <label>Select Business:</label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <select
                    className="form-control"
                    onChange={(evt) => {
                      this.setBusiness(evt.target.value);
                    }}
                    value={businessId}
                  >
                    <option>Select business</option>
                    {userBusinessList.map((v, i) => {
                      return (
                        <option key={i} value={v.businessData._id}>
                          {v.businessData.primaryDomain}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
            {!isCompleted ? (
              <Aux>
                <div id="mcontent-top" className="pull-left">
                  <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
                    <ul id="dashTab" className="nav menu-tab">
                      <li key="1" className="active">
                        <a data-toggle="tab" href="#gettingStartedtab">
                          Buyer Protection
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div id="mcontent-wrapper">
                  <div
                    id="gettingStartedtab"
                    className="seal-content-wrapper tab-pane fade in active"
                  >
                    <p style={{ marginBottom: "25px" }}>
                      Welcome to Buyer Protection, where we insure your
                      products. To get started please follow the instructions
                      below.
                    </p>
                    <p>
                      Implementing Buyer Protection is usually pretty easy but
                      we need to add some code into your thank you page to get
                      started.
                    </p>
                    <p>
                      If you have any questions feel free to reach out to us at
                      <b> support@trust-guard.com</b>
                    </p>
                    <p style={{ marginTop: "25px" }}>
                      <b>Please select how your website is created:</b>
                    </p>
                    <p>
                      {/* <input
                        id="rdoWebsiteTypeShopify"
                        type="radio"
                        name="websiteType"
                        value="shopify"
                        checked={websiteType === "shopify" ? true : false}
                        onChange={(evt) => {
                          this.setwebsiteType(evt);
                        }}
                        style={{ cursor: "pointer" }}
                      />{" "}
                      <label
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                        onClick={() =>
                          this.clickWebsiteTypeRadio("rdoWebsiteTypeShopify")
                        }
                      >
                        Shopify
                      </label>
                      <br />
                      <br />
                      <input
                        id="rdoWebsiteTypeWooCommerce"
                        type="radio"
                        name="websiteType"
                        value="WooCommerce"
                        checked={websiteType === "WooCommerce" ? true : false}
                        onChange={(evt) => {
                          this.setwebsiteType(evt);
                        }}
                        style={{ cursor: "pointer" }}
                      />{" "}
                      <label
                        onClick={() =>
                          this.clickWebsiteTypeRadio(
                            "rdoWebsiteTypeWooCommerce"
                          )
                        }
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                      >
                        WooCommerce
                      </label>
                      <br />
                      <br /> */}
                      <input
                        id="rdoWebsiteTypeOther"
                        type="radio"
                        name="websiteType"
                        value="other"
                        checked={websiteType === "other" ? true : false}
                        onChange={(evt) => {
                          this.setwebsiteType(evt);
                        }}
                        style={{ cursor: "pointer" }}
                      />{" "}
                      <label
                        onClick={() =>
                          this.clickWebsiteTypeRadio("rdoWebsiteTypeOther")
                        }
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                      >
                        Custom Website
                      </label>
                    </p>
                    {websiteType === "other" ? (
                      <div>
                        <p style={{ marginTop: "25px" }}>
                          <b>
                            Please paste the following code into your websites
                            thank you page:
                          </b>
                        </p>
                        <div
                          className="well well-lg"
                          style={{
                            width: "750px",
                            backgroundColor: "rgb(255, 255, 255)",
                          }}
                        >
                          <textarea
                            style={{
                              width: "100%",
                              height: "350px",
                            }}
                            readOnly="readonly"
                            value={codeBox
                              .replace("__EMAIL__", "")
                              .replace("__NAME__", "")
                              .replace(
                                "__CUSTOMER_KEY__",
                                currentBusiness4xData.api_key
                                  ? currentBusiness4xData.api_key
                                  : ""
                              )
                              .replace("__PHONE__", "")}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <p>
                      <button
                        className="btn-success btn-md btn"
                        onClick={() => {
                          this.props.save4xIntegrationStatus(
                            businessId,
                            websiteType
                          );
                        }}
                      >
                        Completed
                      </button>
                    </p>
                  </div>
                </div>
              </Aux>
            ) : (
              <Aux>
                <div>
                  <div id="mcontent-top" className="pull-left">
                    <ul id="dashTab" className="nav menu-tab" role="tablist">
                      <li role="presentation" className="active">
                        <a
                          data-toggle="tab"
                          href="#statistics"
                          aria-controls="statistics"
                          role="tab"
                        >
                          Statistics
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          data-toggle="tab"
                          href="#implementation"
                          aria-controls="implementation"
                          role="tab"
                        >
                          Implementation
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          data-toggle="tab"
                          href="#sealCode"
                          aria-controls="sealCode"
                          role="tab"
                        >
                          Seal Code
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div id="mcontent-wrapper">
                    <div className="tab-content">
                      <div
                        role="tabpanel"
                        className="tab-pane active"
                        id="statistics"
                      >
                        <>
                          <div>
                            <h4>Last 30 days</h4>
                            <h4>Orders: {last30DaysOrders}</h4>
                            <h4>Registered: {last30DaysRegistered}</h4>
                          </div>
                          <hr />
                          <h4>
                            Statistics{" "}
                            <button className="tg-dashboard-link">
                              {statisticsActive ? (
                                <i className="fa fa-angle-up" />
                              ) : (
                                <i className="fa fa-angle-down" />
                              )}
                            </button>
                          </h4>
                          <>
                            <Line
                              xLabels={xLabels}
                              yData={yData}
                              changeColor={[null, "222,214,162"]}
                              changeColorByIndex={[1]}
                            />

                            <br />
                            <br />
                            <hr />
                            <br />
                            <br />
                          </>
                        </>
                      </div>
                      <div
                        role="tabpanel"
                        className="tab-pane fade"
                        id="implementation"
                      >
                        <p style={{ marginBottom: "25px" }}>
                          Welcome to Trust Guard Buyer Protection, where we
                          insure your products. To get started please follow the
                          instructions below.
                        </p>
                        <p>
                          Implementing Trust Guard Buyer Protection is usually
                          pretty easy but we need to add some code into your
                          thank you page to get started.
                        </p>
                        <p>
                          If you have any questions feel free to reach out to us
                          at
                          <b> support@trust-guard.com</b>
                        </p>
                        {/* <p style={{ marginTop: "25px" }}>
                          <b>Please select how your website is created:</b>
                        </p>
                        <p>
                          <input
                            id="rdoWebsiteTypeShopify"
                            type="radio"
                            name="websiteType"
                            value="shopify"
                            checked={websiteType === "shopify" ? true : false}
                            onChange={(evt) => {
                              this.setwebsiteType(evt);
                            }}
                            style={{ cursor: "pointer" }}
                            disabled={true}
                          />{" "}
                          <label
                            style={{ cursor: "pointer", marginLeft: "15px" }}
                            onClick={() =>
                              this.clickWebsiteTypeRadio(
                                "rdoWebsiteTypeShopify"
                              )
                            }
                          >
                            Shopify
                          </label>
                          <br />
                          <br />
                          <input
                            id="rdoWebsiteTypeWooCommerce"
                            type="radio"
                            name="websiteType"
                            value="WooCommerce"
                            checked={
                              websiteType === "WooCommerce" ? true : false
                            }
                            onChange={(evt) => {
                              this.setwebsiteType(evt);
                            }}
                            style={{ cursor: "pointer" }}
                            disabled={true}
                          />{" "}
                          <label
                            onClick={() =>
                              this.clickWebsiteTypeRadio(
                                "rdoWebsiteTypeWooCommerce"
                              )
                            }
                            style={{ cursor: "pointer", marginLeft: "15px" }}
                          >
                            WooCommerce
                          </label>
                          <br />
                          <br />
                          <input
                            id="rdoWebsiteTypeOther"
                            type="radio"
                            name="websiteType"
                            value="other"
                            checked={websiteType === "other" ? true : false}
                            onChange={(evt) => {
                              this.setwebsiteType(evt);
                            }}
                            style={{ cursor: "pointer" }}
                            disabled={true}
                          />{" "}
                          <label
                            onClick={() =>
                              this.clickWebsiteTypeRadio("rdoWebsiteTypeOther")
                            }
                            style={{ cursor: "pointer", marginLeft: "15px" }}
                          >
                            Other
                          </label>
                        </p> */}
                        {websiteType === "other" ? (
                          <div>
                            <p style={{ marginTop: "25px" }}>
                              <b>
                                Please paste the following code into your
                                websites thank you page:
                              </b>
                            </p>
                            <div
                              className="well well-lg"
                              style={{
                                width: "750px",
                                backgroundColor: "rgb(255, 255, 255)",
                              }}
                            >
                              <textarea
                                style={{
                                  width: "100%",
                                  height: "350px",
                                }}
                                readOnly="readonly"
                                value={codeBox
                                  .replace("__EMAIL__", "")
                                  .replace("__NAME__", "")
                                  .replace(
                                    "__CUSTOMER_KEY__",
                                    currentBusiness4xData.api_key
                                      ? currentBusiness4xData.api_key
                                      : ""
                                  )
                                  .replace("__PHONE__", "")}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        role="tabpanel"
                        className="tab-pane fade"
                        id="sealCode"
                      >
                        <SealComponent businessId={businessId} />
                      </div>
                    </div>
                  </div>
                </div>
              </Aux>
            )}
          </Aux>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.accountStore.loggedInUser,
  businessAccount: state.accountStore.businessAccount,
  details4X: state.accountStore.details4X,
  messageType: state.accountStore.messageType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserById,
      notificationSetSeen,
      save4xIntegrationStatus,
      getBusiness,
      getBusinessDetailsById,
      resetStatus,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account4X);
