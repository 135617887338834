function validate(params) {
  let rule = params.rule,
    value = params.value,
    field = params.field;

  let rulesData = rule.split("|");
  let tempData;

  try {
    for (let counter = 0; counter < rulesData.length; counter++) {
      switch (rulesData[counter]) {
        case "required":
          tempData = requiredValidation(value, field);
          break;
        case "email":
          tempData = emailValidation(value, field);
          break;
        default:
          break;
      }

      if (!tempData.success) {
        return { success: false, message: tempData.message };
      }
    }
  } catch (e) {
    return { success: false, message: "Something unwanted error occured." };
  }

  return { success: true, message: "" };
}

function requiredValidation(value, field) {
  if (value && value !== null && value !== "") {
    return { success: true, message: "" };
  }

  return { success: false, message: field };
}

function emailValidation(value) {
  /*eslint-disable*/
  if (
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,6}$/i.test(
      value
    )
  ) {
    return { success: true, message: "" };
  }
  /*eslint-enable*/

  return { success: false, message: "Please provide valid email" };
}

export { validate };
