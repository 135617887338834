import { api } from "./api";
import { callApi } from "../helpers/index";

function callDoLogin(email, password) {
  return api.authenticate({
    strategy: "local",
    email: email,
    password: password,
  });
}

function callAuthLogin(email, socialId, socialToken) {
  return api.authenticate({
    strategy: "social-token",
    email,
    socialId,
    socialToken,
  });
}

function callChangeUserPassword(email, password) {
  return api.authenticate({
    strategy: "local",
    email: email,
    password: password,
  });
}

function callOnClickSendMail(sendToMail) {
  return callApi().then((token) => {
    return api.service("general").create(sendToMail);
  });
}

function callOnClickSendMailForForgetPassword(sendToMail) {
  return api.service("general").create(sendToMail);
}

function callEmailCodeVerify(codeVerify) {
  return callApi().then((token) => {
    return api.service("create-mail").create(codeVerify);
  });
}

function callGetAllUsers(query) {
  return callApi().then((token) => {
    return api.service("users").find(query);
  });
}

function callUpdateUserProfile(_id, profileData) {
  return callApi().then((token) => {
    return api.service("users").patch(_id, profileData);
  });
}

function callResetPasswordCode(query) {
  return api.service("general").create(query);
}

function callCreateNotification(_id, data) {
  return callApi().then((token) => {
    return api.service("users").patch(_id, data);
  });
}

function callCreateBatchNotification(_id, data) {
  return callApi().then((token) => {
    api.service("users").patch(_id, data);
  });
}

function callFailedLoggedIn(query) {
  return api.service("general").create(query);
}

function callFindUsersInBusiness(query) {
  return api.service("users").find(query);
}

function callUnlockUserAccount(_id, unlockData) {
  return api.service("users").patch(_id, unlockData);
}

export {
  callDoLogin,
  callAuthLogin,
  callOnClickSendMail,
  callEmailCodeVerify,
  callGetAllUsers,
  callUpdateUserProfile,
  callChangeUserPassword,
  callResetPasswordCode,
  callCreateNotification,
  callFailedLoggedIn,
  callFindUsersInBusiness,
  callUnlockUserAccount,
  callCreateBatchNotification,
  callOnClickSendMailForForgetPassword,
};
