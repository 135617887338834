import { api } from "./api";
import { callApi } from "../helpers/index";

function callOnCreateBusinessAccount(businessData) {
  return callApi().then((token) => {
    return api.service("business").create(businessData);
  });
}

function callOnCreateAdminAccount(createAdmin) {
  return callApi().then((token) => {
    return api.service("users").create(createAdmin);
  });
}

function callOnCreateUserAccount(createUser) {
  return callApi().then((token) => {
    return api.service("users").create(createUser);
  });
}

function callGetUserAccount() {
  return callApi().then((token) => {
    return api.service("users").find();
  });
}

function callGetUserAccountByBusinessId(businessId) {
  return callApi().then((token) => {
    return api.service("users").find({
      query: {
        "businessDetails.businessId": businessId,
      },
    });
  });
}

function callGetBusiness() {
  return callApi().then((token) => {
    return api.service("business").find({});
  });
}

function callGetDomainsUsingStatus(domainQuery) {
  return callApi().then((token) => {
    return api.service("general").find({ query: domainQuery });
  });
}

function callCheckUserEmailExistOrNot(query) {
  return callApi().then((token) => {
    return api.service("users").find(query);
  });
}

function callUpdateExistUserWithNewBusiness(_id, createExistUser) {
  return callApi().then((token) => {
    return api.service("users").patch(_id, createExistUser);
  });
}

function callUpdateUserWelcomePopup(_id, data) {
  return callApi().then((token) => {
    return api.service("users").patch(_id, data);
  });
}

function callGetAdminDetails(_id) {
  return callApi().then((token) => {
    return api.service("users").get(_id);
  });
}

function callGetUserDetails(_id) {
  return callApi().then((token) => {
    return api.service("users").get(_id);
  });
}

function callGetBusinessDetailsById(_id) {
  return callApi().then((token) => {
    if (_id) {
      return api.service("business").get({ _id });
    }
  });
}

function callGet4xAccountOrders(id) {
  return callApi().then(async (token) => {
    if (id) {
      return api.service("general").get(id);
    }
  });
}

function callGetInsuranceData(id) {
  return callApi().then(async (token) => {
    if (id) {
      return api.service("general").get(id);
    }
  });
}

async function callGetBusinessDetailsByPrimaryDomain(businessData) {
  const bData = await api.service("general").find({ query: businessData });

  let domainData = Array.isArray(bData.data[0].domains)
    ? bData.data[0].domains[0]
    : bData.data[0].domains;

  let filteredBusinessData = {
    primaryDomain: bData.data[0].primaryDomain,
    malwareFrequency: bData.data[0].malwareFrequency,
    lastMalwareScan: bData.data[0].lastMalwareScan,
    lastMalwareScanResult: bData.data[0].lastMalwareScanResult,
    scanFrequency: bData.data[0].scanFrequency,
    lastPciScanDate: bData.data[0].lastPciScanDate,
    lastPciStatus: bData.data[0].lastPciStatus,
    sslStatus: domainData.sslInfo.status,
    sslProvider: domainData.sslInfo.sslProvider,
    sslExpireDate: domainData.sslInfo.expireDate,
    certificate: bData.data[0].certificate,
    certificateContact: bData.data[0].certificateContact,
    status: bData.data[0].status,
  };

  return filteredBusinessData;
}

function callGetUserById(_id) {
  return callApi().then((token) => {
    return api.service("users").get(_id);
  });
}

function callGetAllUsers() {
  return callApi().then((token) => {
    return api.service("users").find({ userStatus: "active" });
  });
}

function callOnSaveNotification(_id, data) {
  return callApi().then((token) => {
    return api.service("users").patch(_id, data);
  });
}

function callCreateDomain(domainData) {
  return callApi().then((token) => {
    return api.service("domains").create(domainData);
  });
}

function callGetDomains() {
  return callApi().then((token) => {
    return api.service("domains").find({ query: {} });
  });
}

function callGetBusinesses(businessQuery) {
  return callApi().then((token) => {
    return api.service("general").find({ query: businessQuery });
  });
}

function callCreate4xAccount(businessData) {
  return callApi().then((token) => {
    return api.service("general").create(businessData);
  });
}

function callGetDataSearchBy(searchData) {
  return callApi().then((token) => {
    return api.service("general").find(searchData);
  });
}

function callDomainSearchByBusiness() {
  return callApi().then((token) => {
    return api.service("business").find();
  });
}

function callUpdateEditedBusiness(_id, businessData) {
  return callApi().then((token) => {
    return api.service("business").patch(_id, businessData);
  });
}

function callDeleteSubDomain(_id) {
  return callApi().then((token) => {
    return api.service("domains").remove(_id);
  });
}

function callCancelAndActiveBusiness(_id, businessStatus) {
  return callApi().then((token) => {
    return api.service("business").patch(_id, businessStatus);
  });
}

function callSkippedActionByMember(_id, statusLogData) {
  return callApi().then((token) => {
    return api.service("business").patch(_id, { statusLog: statusLogData });
  });
}

function callGetFailedScanDomains(query) {
  return callApi().then((token) => {
    return api.service("check-scan-report").find(query);
  });
}

function callGet30DaysOldFreeBusinesses(query) {
  return callApi().then((token) => {
    return api.service("business").find({
      query: {
        accountType: "free",
        status: "active",
        createdAt: {
          $lt: new Date(
            new Date(new Date().setDate(new Date().getDate() - 31))
          ),
        },
      },
    });
  });
}

function callUpdateBusinessCertificate(_id, certificateData) {
  return callApi().then((token) => {
    return api.service("business").patch(_id, { certificate: certificateData });
  });
}

function callUpdateBusinessCertificateContact(_id, certificateContactData) {
  return callApi().then((token) => {
    return api
      .service("business")
      .patch(_id, { certificateContact: certificateContactData });
  });
}

function callUpdateOnboardingPopupData(businessDetails, status) {
  return callApi().then(async (token) => {
    for (let i = 0; i < businessDetails.length; i++) {
      let business = await api
        .service("business")
        .get(businessDetails[i].businessId);
      if (business && !business.hasOwnProperty("onboardingPopup")) {
        api.service("business").patch(businessDetails[i].businessId, {
          onboardingPopup: {
            views: 1,
            status: status,
          },
        });

        return true;
      } else {
        if (business.onboardingPopup.status === "view") {
          const newCount =
            status === "clicked" || status === "closed"
              ? business.onboardingPopup.views
              : business.onboardingPopup.views + 1;
          api.service("business").patch(businessDetails[i].businessId, {
            onboardingPopup: {
              views: newCount,
              status: status,
            },
          });

          return true;
        }
      }
    }
  });
}

function callOnCreateEmail(emailData) {
  return callApi().then((token) => {
    return api.service("emails").create(emailData);
  });
}

function callGetAllEmails(query) {
  return callApi().then((token) => {
    return api.service("emails").find(query);
  });
}

function callOnUpdateEmail(_id, updateData) {
  return callApi().then((token) => {
    return api.service("emails").patch(_id, updateData);
  });
}

function callOnDeleteEmail(_id) {
  return callApi().then((token) => {
    return api.service("emails").remove(_id);
  });
}

function callCreateDeletedEamil(createDeletedEmail) {
  return callApi().then((token) => {
    return api.service("deleted-emails").create(createDeletedEmail);
  });
}

function callGetCheckScanReports() {
  return callApi().then((token) => {
    return api.service("check-scan-report").find({});
  });
}

function callOnGetUserListUsingBusinessIdAndRoles(query) {
  return callApi().then((token) => {
    return api.service("users").find(query);
  });
}

function callOnGetUserListUsingBusinessId(query) {
  return callApi().then((token) => {
    return api.service("users").find(query);
  });
}

function callOnGetDomainDataUsingBusinessPrimaryDomain(domainQuery) {
  return callApi().then((token) => {
    return api.service("domains").find(domainQuery);
  });
}

function callOnGetEmailDataUsingEmailTag(emailQuery) {
  return callApi().then((token) => {
    return api.service("emails").find(emailQuery);
  });
}

function callOnCreateEmailHistory(thisData) {
  return callApi().then(async (token) => {
    return await api.service("email-history").create(thisData);
  });
}

function callOnFindBusinessTrustSeal(query) {
  return callApi().then((token) => {
    return api.service("trustseals").find(query);
  });
}

async function callOnCreateTrustSeal(data) {
  return callApi().then((token) => {
    return api.service("trustseal-service").create(data);
  });
}

async function callRegeneraeAllSeals() {
  return callApi().then((token) => {
    return api.service("trustseal-service").get("regenerate-all-seals");
  });
}

async function callGetSslRenewalDomains() {
  return callApi().then((token) => {
    return api
      .service("renewDomainSSLs")
      .find({ query: { status: "pending" } });
  });
}

async function callUpdateSslRenewalDomain(id, obj) {
  return callApi().then((token) => {
    obj.status = "completed";
    return api.service("renewDomainSSLs").patch(id, obj);
  });
}

function callOnUpdateTrustSeal(_id, data, removableSealSettingId) {
  return callApi().then((token) => {
    return api
      .service("trustseal-service")
      .update(_id, data, removableSealSettingId);
  });
}

function callOnCreateSealImage(data, primaryDomain) {
  return callApi().then((token) => {
    let thisData = {
      action: "create-seal-image",
      data: data,
      scanStatus: true,
      primaryDomain: primaryDomain,
    };

    api.service("general").create(thisData);
    return data;
  });
}

function callOnClickRemoveTrustSealsSettingsDate(query) {
  return callApi().then((token) => {
    return api.service("general").create(query);
  });
}

function callIsHostReachable(id) {
  return callApi().then((token) => {
    if (id) {
      return api.service("general").get(id);
    }
  });
}

function callGetEmailHistories(startDate, endDate) {
  return callApi().then((token) => {
    let thisData = {
      action: "get-email-statistics",
      startDate: startDate,
      endDate: endDate,
    };

    return api.service("general").find({ query: thisData });
  });
}

function callGetSmsHistories(startDate, endDate) {
  return callApi().then((token) => {
    let thisData = {
      action: "get-sms-statistics",
      startDate: startDate,
      endDate: endDate,
    };

    return api.service("general").find({ query: thisData });
  });
}

function callGetInsuranceHistories(startDate, endDate) {
  return callApi().then((token) => {
    let thisData = {
      action: "get-insurance-statistics",
      startDate: startDate,
      endDate: endDate,
    };

    return api.service("general").find({ query: thisData });
  });
}

function callGetEmailHistoryDetail(emailTag, startDate, endDate) {
  return callApi().then((token) => {
    let thisData = {
      action: "get-email-statistics-modal-details",
      emailTag: emailTag,
      startDate: startDate,
      endDate: endDate,
    };

    return api.service("general").find({ query: thisData });
  });
}

function callGetSmsHistoryDetail(emailTag, startDate, endDate) {
  return callApi().then((token) => {
    let thisData = {
      action: "get-sms-statistics-modal-details",
      emailTag: emailTag,
      startDate: startDate,
      endDate: endDate,
    };

    return api.service("general").find({ query: thisData });
  });
}

function callOnSignUpUser(data) {
  return api.service("signup").create(data);
}

function callOnClickSaveResolvedNote(id, note) {
  return api
    .service("business")
    .update({ _id: id }, { $push: { notes: note } });
}

function callOnClickSaveResolvedBy(
  businessId,
  reason,
  userId,
  reportId,
  checkScanReportId
) {
  return api.service("reports/check-scan-reports").update(checkScanReportId, {
    businessId: businessId,
    reason: reason,
    userId: userId,
    reportId: reportId,
  });
}

function callGetDeleteUser(userId, businessId) {
  return callApi().then((token) => {
    let thisData = { userId: userId, businessId: businessId };
    return api.service("signup").remove(thisData);
  });
}

function callGetBusinessUsers(businessId) {
  return callApi().then((token) => {
    return api.service("users").find({
      query: { businessDetails: { $elemMatch: { businessId: businessId } } },
    });
  });
}

function callUpdateUserData(id, userData) {
  return callApi().then((token) => {
    return api.service("users").patch(id, userData);
  });
}

function callUpdateBusinessAccountType(
  userId,
  businessId,
  currentStatus,
  newStatus
) {
  return callApi().then(async (token) => {
    let currentBusinessData = await api.service("business").get(businessId);
    let accountTypeChangeLog = null;

    if (
      currentBusinessData.hasOwnProperty("accountTypeChangeLog") &&
      currentBusinessData.accountTypeChangeLog.length > 0
    ) {
      accountTypeChangeLog = currentBusinessData.accountTypeChangeLog;
      accountTypeChangeLog.push({
        currentStatus: currentStatus,
        newStatus: newStatus,
        userId: userId,
        actionDate: new Date(),
      });
    } else {
      accountTypeChangeLog = {
        currentStatus: currentStatus,
        newStatus: newStatus,
        userId: userId,
        actionDate: new Date(),
      };
    }

    await api.service("business").patch(businessId, {
      accountType: newStatus,
      accountTypeChangeLog: accountTypeChangeLog,
      onBsUpdate: true,
      businessId: businessId,
    });

    return api.service("business").find({
      query: {
        $sort: { createdAt: -1 },
      },
    });
  });
}

function callInsuranceIndustryList() {
  return callApi().then((token) => {
    return api.service("insurance-service").get({ id: "industry" });
  });
}

function callPostInsuranceData(data) {
  return callApi().then(async (token) => {
    let insuranceResult = await api.service("insurance-service").create(data);

    if (insuranceResult) {
      await api
        .service("business")
        .patch(data.businessId, { insuranceStatus: "submitted" });

      return insuranceResult;
    }
  });
}

function callSaveSmsTiming(_id, data) {
  return callApi().then((token) => {
    return api.service("users").patch(_id, data);
  });
}

function callCreateCssHash(data) {
  return callApi().then((token) => {
    return api.service("upload-seal-scripts").create(data);
  });
}

function callCreateJsHash(data) {
  return callApi().then((token) => {
    return api.service("upload-seal-scripts").create(data);
  });
}

function callGetCssJsHashList(data) {
  return callApi().then((token) => {
    return api.service("upload-seal-scripts").find({});
  });
}

function callCreateOneTimeEmail(data) {
  return callApi().then((token) => {
    return api.service("one-time-emails").create(data);
  });
}

function callGetOneTimeEmails() {
  return callApi().then((token) => {
    return api
      .service("one-time-emails")
      .find({ query: { status: "pending" } });
  });
}

function callGetAllBusinessUsersForOtherMessages() {
  return callApi().then(async (token) => {
    return api.service("businesses/other-message-user-list").find({});
  });
}

function callsave4xIntegrationStatus(businessId, websiteType) {
  return callApi().then(async (token) => {
    return api.service("business").patch(businessId, {
      "details4X.integrationStatus": true,
      "details4X.websiteType": websiteType,
    });
  });
}

function callGetAdminEmails(businessId) {
  return callApi().then(async (token) => {
    return api
      .service("email-history")
      .find({ query: { businessId: businessId, fromAdmin: "yes" } });
  });
}

function callActionAgainstCancelledTrustSeals(sealId, primaryDomain, purpose) {
  return callApi().then(async (token) => {
    if (purpose === "hide") {
      return api.service("trustseal-service").get({
        action: "hide-seal",
        sealId: sealId,
        primaryDomain: primaryDomain,
      });
    }

    if (purpose === "delete") {
      return api.service("trustseal-service").get({
        action: "delete-seal",
        sealId: sealId,
        primaryDomain: primaryDomain,
      });
    }

    return null;
  });
}

export {
  callOnSignUpUser,
  callOnCreateBusinessAccount,
  callOnCreateAdminAccount,
  callGetUserAccount,
  callOnCreateUserAccount,
  callGetBusiness,
  callUpdateExistUserWithNewBusiness,
  callGetAdminDetails,
  callGetBusinessDetailsById,
  callGetUserById,
  callCheckUserEmailExistOrNot,
  callGetUserDetails,
  callOnSaveNotification,
  callCreateDomain,
  callGetDomains,
  callGetDataSearchBy,
  callDomainSearchByBusiness,
  callUpdateEditedBusiness,
  callDeleteSubDomain,
  callCancelAndActiveBusiness,
  callGetBusinesses,
  callGetFailedScanDomains,
  callUpdateBusinessCertificate,
  callOnCreateEmail,
  callGetAllEmails,
  callOnUpdateEmail,
  callOnDeleteEmail,
  callCreateDeletedEamil,
  callGetDomainsUsingStatus,
  callGetCheckScanReports,
  callOnGetUserListUsingBusinessId,
  callOnGetUserListUsingBusinessIdAndRoles,
  callOnGetDomainDataUsingBusinessPrimaryDomain,
  callOnGetEmailDataUsingEmailTag,
  callOnCreateEmailHistory,
  callOnFindBusinessTrustSeal,
  callOnCreateTrustSeal,
  callOnUpdateTrustSeal,
  callOnCreateSealImage,
  callOnClickRemoveTrustSealsSettingsDate,
  callGetBusinessDetailsByPrimaryDomain,
  callGetUserAccountByBusinessId,
  callUpdateBusinessCertificateContact,
  callGetEmailHistories,
  callSkippedActionByMember,
  callUpdateUserWelcomePopup,
  callGetEmailHistoryDetail,
  callGetDeleteUser,
  callOnClickSaveResolvedNote,
  callOnClickSaveResolvedBy,
  callGetBusinessUsers,
  callUpdateUserData,
  callUpdateBusinessAccountType,
  callInsuranceIndustryList,
  callPostInsuranceData,
  callGetInsuranceHistories,
  callCreateCssHash,
  callCreateJsHash,
  callGetCssJsHashList,
  callCreateOneTimeEmail,
  callGetOneTimeEmails,
  callGetAllBusinessUsersForOtherMessages,
  callGet30DaysOldFreeBusinesses,
  callRegeneraeAllSeals,
  callGetSmsHistories,
  callGetSmsHistoryDetail,
  callGetSslRenewalDomains,
  callUpdateSslRenewalDomain,
  callUpdateOnboardingPopupData,
  callGetAllUsers,
  callsave4xIntegrationStatus,
  callCreate4xAccount,
  callGetAdminEmails,
  callGet4xAccountOrders,
  callGetInsuranceData,
  callIsHostReachable,
  callSaveSmsTiming,
  callActionAgainstCancelledTrustSeals,
};
