import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import config from "../../helpers/config";
import AppContext from "../../components/context/appContext";
import { updateUserProfileData } from "../../modules/actions/index";

class UserSmsTiming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: "America/New_York",
      smsTimeZone: "America/New_York",
      sendSMSFrom: "1",
      sendSMSTo: "23",
      isDisabled: false,
    };
  }

  updateSmsTiming() {
    this.setState({
      isDisabled: true,
    });
    this.props.updateUserProfileData(this.context.userData._id, {
      purpose: "smsTiming",
      smsTimings: {
        smsTimeZone: this.state.smsTimeZone,
        sendSmsFrom: this.state.sendSMSFrom,
        sendSmsTo: this.state.sendSMSTo,
      },
    });

    setTimeout(() => {
      this.setState({
        isDisabled: false,
      });
    }, 2500);
  }

  render() {
    const {
      timezone,
      smsTimeZone,
      sendSMSFrom,
      sendSMSTo,
      isDisabled,
    } = this.state;

    return (
      <div className="modal fade" id="smsTiming" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" style={{ paddingLeft: "20px" }}>
                SMS Timing
              </h4>
            </div>

            <div className="modal-body text-center">
              <div className="row">
                <div className="col-md-4">
                  <div style={{ display: "block", clear: "both" }}>
                    <label htmlFor="smsTimeZone">Time Zone</label>
                  </div>
                  <div style={{ display: "block", clear: "both" }}>
                    <select
                      className="form-control"
                      id="smsTimeZone"
                      onChange={(e) => {
                        this.setState({
                          smsTimeZone: e.target.value,
                        });
                      }}
                      value={smsTimeZone}
                    >
                      <option value="0">Please Select Timezone</option>
                      {config.timezones.map((v, i) => {
                        return (
                          <option key={i} value={v.timezone}>
                            {v.timezone}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div style={{ display: "block", clear: "both" }}>
                    <label htmlFor="sendSMSFrom">From</label>
                  </div>
                  <div style={{ display: "block", clear: "both" }}>
                    <select
                      className="form-control"
                      value={sendSMSFrom}
                      onChange={(e) => {
                        this.setState({
                          sendSMSFrom: e.target.value,
                        });
                      }}
                    >
                      <option value="24">12:00 AM</option>
                      <option value="1">01:00 AM</option>
                      <option value="2">02:00 AM</option>
                      <option value="3">03:00 AM</option>
                      <option value="4">04:00 AM</option>
                      <option value="5">05:00 AM</option>
                      <option value="6">06:00 AM</option>
                      <option value="7">07:00 AM</option>
                      <option value="8">08:00 AM</option>
                      <option value="9">09:00 AM</option>
                      <option value="10">10:00 AM</option>
                      <option value="11">11:00 AM</option>
                      <option value="12">12:00 PM</option>
                      <option value="13">01:00 PM</option>
                      <option value="14">02:00 PM</option>
                      <option value="15">03:00 PM</option>
                      <option value="16">04:00 PM</option>
                      <option value="17">05:00 PM</option>
                      <option value="18">06:00 PM</option>
                      <option value="19">07:00 PM</option>
                      <option value="20">08:00 PM</option>
                      <option value="21">08:00 PM</option>
                      <option value="22">10:00 PM</option>
                      <option value="23">11:00 PM</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div style={{ display: "block", clear: "both" }}>
                    <label htmlFor="sendSMSTo">To</label>
                  </div>
                  <div style={{ display: "block", clear: "both" }}>
                    <select
                      className="form-control"
                      value={sendSMSTo}
                      onChange={(e) => {
                        this.setState({
                          sendSMSTo: e.target.value,
                        });
                      }}
                    >
                      <option value="24">12:00 AM</option>
                      <option value="1">01:00 AM</option>
                      <option value="2">02:00 AM</option>
                      <option value="3">03:00 AM</option>
                      <option value="4">04:00 AM</option>
                      <option value="5">05:00 AM</option>
                      <option value="6">06:00 AM</option>
                      <option value="7">07:00 AM</option>
                      <option value="8">08:00 AM</option>
                      <option value="9">09:00 AM</option>
                      <option value="10">10:00 AM</option>
                      <option value="11">11:00 AM</option>
                      <option value="12">12:00 PM</option>
                      <option value="13">01:00 PM</option>
                      <option value="14">02:00 PM</option>
                      <option value="15">03:00 PM</option>
                      <option value="16">04:00 PM</option>
                      <option value="17">05:00 PM</option>
                      <option value="18">06:00 PM</option>
                      <option value="19">07:00 PM</option>
                      <option value="20">08:00 PM</option>
                      <option value="21">08:00 PM</option>
                      <option value="22">10:00 PM</option>
                      <option value="23">11:00 PM</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                id="cancelDomainInAdvDismissModal"
                type="button"
                className="btn-md btn-tglight2 btn-140-width mr-2 btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.updateSmsTiming();
                }}
                disabled={isDisabled}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserSmsTiming.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfileData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSmsTiming);
