import {
  GET_USER_BUSINESS_DATA_SUCCESS,
  GET_USER_BUSINESS_DATA_FAILURE,
  GET_BUSINESS_DATA_SUCCESS,
  GET_BUSINESS_DATA_FAILURE,
  UPDATE_BUSINESS_DATA_SUCCESS,
  UPDATE_BUSINESS_DATA_FAILURE,
  CREATE_BUSINESS_USER_DATA_SUCCESS,
  CREATE_BUSINESS_USER_DATA_FAILURE,
} from "../types/index";

const defaultState = {
  userBusinessData: [],
  businesssData: {},
  businessUsersList: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_USER_BUSINESS_DATA_SUCCESS:
      return {
        ...state,
        userBusinessData: action.payload.data,
      };
    case GET_USER_BUSINESS_DATA_FAILURE:
      return {
        ...state,
      };
    case GET_BUSINESS_DATA_SUCCESS:
      return {
        ...state,
        businesssData: action.payload,
      };
    case GET_BUSINESS_DATA_FAILURE:
      return {
        ...state,
      };
    case UPDATE_BUSINESS_DATA_SUCCESS:
      return {
        ...state,
        businesssData: action.payload,
      };
    case UPDATE_BUSINESS_DATA_FAILURE:
      return {
        ...state,
      };
    case CREATE_BUSINESS_USER_DATA_SUCCESS:
      return {
        ...state,
        businessUsersList: action.payload,
      };
    case CREATE_BUSINESS_USER_DATA_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
