import React from "react";
import Aux from "../hoc/containerAux";
import LayoutHeaderNavigation from "./layoutHeaderNavigation";
import Header from "../../views/header";

const layoutContainer = (props) => (
  <Aux>
    <div style={props.mainContainerPadding}>
      <Header titlePage={props.titlePage} bgColor="#F5F6F8" />
      <LayoutHeaderNavigation tabs={props.tabs} />
      {props.children}
    </div>
  </Aux>
);

export default layoutContainer;
