import {
  RESET_MESSAGES,
  INSURANCE_CREATE_SUCCESS,
  INSURANCE_CREATE_FAILED,
  GET_INSURANCE_SUCCESS,
  GET_INSURANCE_FAILURE,
  CREATE_AUTO_RENEWING_SSL_SUCCESS,
  CREATE_AUTO_RENEWING_SSL_FAILED,
  GET_AUTO_RENEWING_SSL_SUCCESS,
  GET_AUTO_RENEWING_SSL_FAILED,
} from "../types/index";

const defaultState = {
  insurances: [],
  messageType: "",
  message: "",
  renewalInformations: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET_MESSAGES:
      return {
        ...state,
        messageType: "",
        message: "",
      };
    case INSURANCE_CREATE_SUCCESS:
      return {
        ...state,
        insurances: [...state.insurances, action.payload],
        messageType: "create-success",
      };
    case INSURANCE_CREATE_FAILED:
      return {
        ...state,
        messageType: "create_failed",
        message: action.message,
      };
    case GET_INSURANCE_SUCCESS:
      return {
        ...state,
        insurances: action.payload,
        messageType: "get_success",
      };
    case GET_INSURANCE_FAILURE:
      return {
        ...state,
        messageType: "get_failed",
        message: action.message,
      };
    case CREATE_AUTO_RENEWING_SSL_SUCCESS:
      return {
        ...state,
        messageType: "success",
        message: action.message,
        renewalInformations: [...state.renewalInformations, action.payload],
      };
    case CREATE_AUTO_RENEWING_SSL_FAILED:
      return {
        ...state,
        messageType: "error",
        message: action.message,
      };
    case GET_AUTO_RENEWING_SSL_SUCCESS:
      return {
        ...state,
        renewalInformations: action.payload,
      };
    case GET_AUTO_RENEWING_SSL_FAILED:
      return {
        ...state,
        messageType: "",
      };

    default:
      return state;
  }
};
