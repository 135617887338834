import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callApiGetBusinessSubdomainData } from "../../services/index";
import {
  GET_BUSINESS_SUBDOMAIN_DATA_SUCCESS,
  GET_BUSINESS_SUBDOMAIN_DATA_FAILURE,
} from "../types/index";

export const getBusinessSubdomainData = (businessId) => {
  return async (dispatch) => {
    return callApiGetBusinessSubdomainData(businessId)
      .then((data) => {
        dispatch({
          type: GET_BUSINESS_SUBDOMAIN_DATA_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BUSINESS_SUBDOMAIN_DATA_FAILURE,
        });
      });
  };
};
