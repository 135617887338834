import { api } from "./api";
import { callApi } from "../helpers/index";

export const callApiFindEmailData = async () => {
  return callApi().then((token) => {
    return api.service("emails").find({});
  });
};

export const callApiCreateEmailData = async (data) => {
  return callApi().then((token) => {
    if (data) {
      return api.service("emails").create(data);
    } else {
      return null;
    }
  });
};
