import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import { getUserDecryptedData } from "../../../helpers/general";
import SlideContext from "../../context/slidePanelContexts";
import AppContext from "../../context/appContext";
import ProgressScanAnimation from "../ProgressScanAnimation";

import {
  callApiGetUserBusinessData,
  callApiGetBusinessData,
  callApiGetBusinessSubdomainData,
} from "../../../services";

class PciScanResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userBusinessData: [],
      business: {},
      pciScanLinkClass: "tg-dashboard-passed-link pciscan-toggle",
      pciScanStyle: {},
      displayArrowDown: "none",
      displayArrowUp: "none",
      isPciActive: null,
    };
  }

  async componentDidMount() {
    this.pciScanDecision();
    this.slideArrowUpDownDecision();
  }

  componentWillReceiveProps(props) {
    const { businessId, index } = this.props;
    const { pci } = this.context;
    const pciContextBusinessId = pci[index].businessId;
    const pciContextIsActive = pci[index].isPciActive;

    if (pci.length === 1) {
      if (pciContextIsActive) {
        this.setState({
          isPciActive: pciContextIsActive,
          displayArrowDown: "none",
          displayArrowUp: "block",
        });
      } else {
        this.setState({ isPciActive: !pciContextIsActive });
      }
    } else {
      if (businessId === pciContextBusinessId) {
        this.setState({ isPciActive: pciContextIsActive });
      }
    }

    this.setState({ isPciActive: pciContextIsActive });
    this.checkPCIpanelisActive();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isPciActive !== this.state.isPciActive) {
      this.checkPCIpanelisActive();
    }
  }

  checkPCIpanelisActive() {
    const { isPciActive } = this.state;

    if (isPciActive && isPciActive !== null) {
      this.setState({
        displayArrowDown: "none",
        displayArrowUp: "block",
      });
    } else {
      this.setState({
        displayArrowDown: "block",
        displayArrowUp: "none",
      });
    }
  }

  async pciScanDecision() {
    const businessData = await callApiGetBusinessData(this.props.businessId);
    this.setState({
      business: businessData,
    });

    if (
      businessData.lastPciStatus === "fail" ||
      businessData.lastPciStatus === "invalid"
    ) {
      this.setState({
        pciScanLinkClass: "tg-dashboard-failed-link pciscan-toggle",
        pciScanStyle: { color: "#C01818" },
      });
    }
  }

  async slideArrowUpDownDecision() {
    const userBusinessData = await callApiGetUserBusinessData(
      getUserDecryptedData()
    );

    const { index } = this.props;
    const { pci } = this.context;
    const pciContextIsActive = pci[index].isPciActive;

    if (pciContextIsActive) {
      this.setState({
        displayArrowDown: "none",
        displayArrowUp: "block",
      });
    } else {
      this.setState({
        displayArrowDown: "block",
        displayArrowUp: "none",
      });
    }
  }

  render() {
    const {
      isLoading,
      business,
      pciScanLinkClass,
      pciScanStyle,
      displayArrowUp,
      displayArrowDown,
    } = this.state;
    const pciContext = this.context;
    const { businessId, index } = this.props;
    return (
      <AppContext.Consumer>
        {(value) => {
          return (
            <SlideContext.drawerContext.Consumer>
              {(drawer) => {
                return drawer.pci[index] !== undefined ? (
                  <button
                    className="tg-dashboard-passed-link pciscan-toggle"
                    onClick={() =>
                      drawer.pci[index].openPciPanel(businessId, index)
                    }
                  >
                    <ProgressScanAnimation
                      business={value.userBusinessData[index]}
                      scanning={drawer.pci[index].isPciScanning}
                    />
                    <i
                      className="false pci fas fa-chevron-down"
                      aria-hidden="true"
                      style={{
                        display: displayArrowDown,
                      }}
                    />
                    <i
                      className="pci-arrow-ident fas fa-sort-up"
                      aria-hidden="true"
                      style={{
                        display: displayArrowUp,
                      }}
                    />
                  </button>
                ) : null;
              }}
            </SlideContext.drawerContext.Consumer>
          );
        }}
      </AppContext.Consumer>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

PciScanResult.contextType = SlideContext.drawerContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PciScanResult);
