import {
  callCreateInsurance,
  callGetInsurance,
  callOnCreateAutoRenewingSSL,
  callOnGetAutoRenewingSSL,
} from "../../services/index";
import {
  RESET_MESSAGES,
  INSURANCE_CREATE_SUCCESS,
  INSURANCE_CREATE_FAILED,
  GET_INSURANCE_SUCCESS,
  GET_INSURANCE_FAILURE,
  CREATE_AUTO_RENEWING_SSL_SUCCESS,
  CREATE_AUTO_RENEWING_SSL_FAILED,
  GET_AUTO_RENEWING_SSL_SUCCESS,
  GET_AUTO_RENEWING_SSL_FAILED,
} from "../types/index";

export const resetStatus = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_MESSAGES,
    });
  };
};

export const createInsurance = (insuranceData) => {
  return async (dispatch) => {
    return callCreateInsurance(insuranceData)
      .then((data) => {
        dispatch({
          type: INSURANCE_CREATE_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: INSURANCE_CREATE_FAILED,
          message: err.message,
        });
      });
  };
};

export const getInsurance = (businesId) => {
  return async (dispatch) => {
    return callGetInsurance(businesId)
      .then((data) => {
        dispatch({
          type: GET_INSURANCE_SUCCESS,
          payload: data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INSURANCE_FAILURE,
          message: err.message,
        });
      });
  };
};

export const onCreateAutoRenewingSSL = (data) => {
  return async (dispatch) => {
    return callOnCreateAutoRenewingSSL(data)
      .then((autoRenewing) => {
        dispatch({
          type: CREATE_AUTO_RENEWING_SSL_SUCCESS,
          message:
            "Thank you for submitting your SSL information. Someone will contact you regarding the setup for you SSL soon.",
          payload: autoRenewing,
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_AUTO_RENEWING_SSL_FAILED,
          message: err.message,
        });
      });
  };
};

export const getAutoRenewingSSL = (query) => {
  return async (dispatch) => {
    return callOnGetAutoRenewingSSL(query)
      .then((thisData) => {
        dispatch({
          type: GET_AUTO_RENEWING_SSL_SUCCESS,
          payload: thisData.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_AUTO_RENEWING_SSL_FAILED,
          message: err.message,
        });
      });
  };
};
