import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getStorageItem, removeStorageItem } from "../../helpers/storage";
import FailedScans from "../../components/control-panel/failedScans";
import FreeBusinessList from "../../components/control-panel/freeBusinessList";
import AdvancedSearch from "../../components/control-panel/advancedSearch";
import SslScanReport from "../../components/control-panel/sslScanReport";
import EmailStatistics from "../../components/control-panel/emailStatistics";
import SmsStatistics from "../../components/control-panel/smsStatistics";
import InsuranceStatistics from "../../components/control-panel/insuranceStatistics";
import MalwareReports from "../../components/malware/malware-reports/malwareReports";
import Aux from "../../components/hoc/containerAux";
import LayoutContainer from "../../components/layout/layoutContainer";
import LayoutBody from "../../components/layout/layoutBody";
import CreateEmail from "../../components/createEmail/createEmail";
import UploadScripts from "../../components/control-panel/uploadScripts";
import OneTimeEmail from "../../components/control-panel/oneTimeEmail";
import Reports from "../../views/reports/reports";
import SealReport from "../../views/reports/sealReport";
import ControlButtonModal from "../../components/control-panel/controlButtonModal";
import ToastMessage from "../../components/toast/toastMessage";
import PageNotFound from "../../components/pageNotFound/pageNotFound";
import {
  onCreateAccount,
  resetStatus,
  getDataSearchBy,
  getDomains,
  domainSearchByBusiness,
  getUserById,
  getBusinessDetailsById,
  getBusinesses,
  findUsersInBusiness,
  getUserData,
} from "../../modules/actions/index";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeHandelUserInput = this.onChangeHandelUserInput.bind(this);
    this.state = {
      notifyType: "",
      notifyMessage: "",
      message: "" || this.props.messageType,
      tab: {
        showVerificationTab: true,
        showPciTab: false,
        showOtherTab: false,
        showSealTab: false,
        showMalwareTab: false,
        showSSLTab: false,
        showEmailTab: false,
        showInsuranceTab: false,
        showSmsTab: false,
      },
      accountType: "basic",
      primaryDomain: "",
      businessName: "",
      supportPhone: "",
      supportEmail: "",
      billingEmail: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      language: "English",
      userAccount: [],
      userFirstName: "",
      userLastName: "",
      userPhone: "",
      userEmail: "",
      userAccessRole: "",
      createUser: [],
      searchBy: "host",
      searchCriteria: "",
      domains: [],
      datePickerShow: "none",
      criteriaShow: "block",
      startDate: moment(),
      endDate: moment(),
      usersInBusiness: [],
      userId: "",
      userLocked: false,
      userAdmin: false,
      sslScanStatusRunningDomains: null,
      sslScanStatusPendingDomains: null,
      sslScanStatusInvalidDomains: null,
      sslScanStatusActiveDomains: null,
      selectedBusinessData: null,
      selectedBusinessId: null,
      advancedSearchResultLimit: 25,
      sendEmail: true,
      controlPanelUserUpdated: false,
      showCancelledSites: false,
      showInvalidSites: false,
      nonExistentScans: null,
      showType: "none",
      selType: "free",
      businessNameError: "",
      supportPhoneError: "",
      supportEmailError: "",
      billingEmailError: "",
      firstNameError: "",
      lastNameError: "",
      phoneError: "",
      emailError: "",
    };

    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  openTabVerification = () => {
    const tab = { ...this.state.tab };
    tab["showVerificationTab"] = true;
    tab["showEmailTab"] = false;
    tab["showInsuranceTab"] = false;
    tab["showMalwareTab"] = false;
    tab["showOtherTab"] = false;
    tab["showPciTab"] = false;
    tab["showSSLTab"] = false;
    tab["showSealTab"] = false;
    tab["showSmsTab"] = false;
    this.setState({ tab });
  };

  openTabEmail = () => {
    const tab = { ...this.state.tab };
    tab["showVerificationTab"] = false;
    tab["showEmailTab"] = true;
    tab["showInsuranceTab"] = false;
    tab["showMalwareTab"] = false;
    tab["showOtherTab"] = false;
    tab["showPciTab"] = false;
    tab["showSSLTab"] = false;
    tab["showSealTab"] = false;
    tab["showSmsTab"] = false;
    this.setState({ tab });
  };

  openTabSms = () => {
    const tab = { ...this.state.tab };
    tab["showVerificationTab"] = false;
    tab["showEmailTab"] = false;
    tab["showInsuranceTab"] = false;
    tab["showMalwareTab"] = false;
    tab["showOtherTab"] = false;
    tab["showPciTab"] = false;
    tab["showSSLTab"] = false;
    tab["showSealTab"] = false;
    tab["showSmsTab"] = true;
    this.setState({ tab });
  };

  openTabInsurance = () => {
    const tab = { ...this.state.tab };
    tab["showVerificationTab"] = false;
    tab["showEmailTab"] = false;
    tab["showInsuranceTab"] = true;
    tab["showMalwareTab"] = false;
    tab["showOtherTab"] = false;
    tab["showPciTab"] = false;
    tab["showSSLTab"] = false;
    tab["showSealTab"] = false;
    tab["showSmsTab"] = false;
    this.setState({ tab });
  };

  openTabMalware = () => {
    const tab = { ...this.state.tab };
    tab["showVerificationTab"] = false;
    tab["showEmailTab"] = false;
    tab["showInsuranceTab"] = false;
    tab["showMalwareTab"] = true;
    tab["showOtherTab"] = false;
    tab["showPciTab"] = false;
    tab["showSSLTab"] = false;
    tab["showSealTab"] = false;
    tab["showSmsTab"] = false;
    this.setState({ tab });
  };

  openTabOther = () => {
    const tab = { ...this.state.tab };
    tab["showVerificationTab"] = false;
    tab["showEmailTab"] = false;
    tab["showInsuranceTab"] = false;
    tab["showMalwareTab"] = false;
    tab["showOtherTab"] = true;
    tab["showPciTab"] = false;
    tab["showSSLTab"] = false;
    tab["showSealTab"] = false;
    tab["showSmsTab"] = false;
    this.setState({ tab });
  };

  openTabPCI = () => {
    const tab = { ...this.state.tab };
    tab["showVerificationTab"] = false;
    tab["showEmailTab"] = false;
    tab["showInsuranceTab"] = false;
    tab["showMalwareTab"] = false;
    tab["showOtherTab"] = false;
    tab["showPciTab"] = true;
    tab["showSSLTab"] = false;
    tab["showSealTab"] = false;
    tab["showSmsTab"] = false;
    this.setState({ tab });
  };

  openTabSSL = () => {
    const tab = { ...this.state.tab };
    tab["showVerificationTab"] = false;
    tab["showEmailTab"] = false;
    tab["showInsuranceTab"] = false;
    tab["showMalwareTab"] = false;
    tab["showOtherTab"] = false;
    tab["showPciTab"] = false;
    tab["showSSLTab"] = true;
    tab["showSealTab"] = false;
    tab["showSmsTab"] = false;

    this.setState({ tab });
  };

  openTabSeal = () => {
    const tab = { ...this.state.tab };
    tab["showVerificationTab"] = false;
    tab["showEmailTab"] = false;
    tab["showInsuranceTab"] = false;
    tab["showMalwareTab"] = false;
    tab["showOtherTab"] = false;
    tab["showPciTab"] = false;
    tab["showSSLTab"] = false;
    tab["showSealTab"] = true;
    tab["showSmsTab"] = false;

    this.setState({ tab });
  };

  handleChangeStart(date) {
    this.setState({
      startDate: date,
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
    });
  }

  onChangeHandelUserInput(evt) {
    let name = evt.target.name;
    let value = evt.target.value;
    this.setState({
      [name]: value,
    });
  }

  onChangeAccountType = (event) => {
    this.setState({ accountType: event.target.value });
  };

  onClickCreateAccount = () => {
    let phone = this.state.phone.replace(/-/g, "");
    const emailType = /^\w+([\D.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const re = /^[0-9\b]+$/;
    let errors = false;

    if (this.state.primaryDomain.length <= 0) {
      errors = true;
      this.setState(
        {
          notifyType: "error",
          notifyMessage: "Please enter primary domain name",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
    }

    if (this.state.businessName.length <= 0) {
      errors = true;
      this.setState(
        {
          notifyType: "error",
          notifyMessage: "Please enter business name",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
    }

    if (this.state.firstName.length <= 0) {
      errors = true;
      this.setState(
        {
          firstNameError: true,
          notifyType: "error",
          notifyMessage: "First name is required",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
    }

    if (this.state.lastName.length <= 0) {
      errors = true;
      this.setState(
        {
          lastNameError: true,
          notifyType: "error",
          notifyMessage: "Last name is required",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
    }
    if (re.test(phone) !== true || phone.length < 7) {
      errors = true;
      this.setState(
        {
          phoneError: true,
          notifyType: "error",
          notifyMessage: "Please enter a valid phone number",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
    } else {
      this.setState({
        phone: phone,
      });
    }

    if (emailType.test(this.state.email) !== true) {
      errors = true;
      this.setState(
        {
          emailError: true,
          notifyType: "error",
          notifyMessage: "Please enter valid email",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
    }

    if (this.state.zip.length > 0) {
      if (re.test(this.state.zip) !== true || this.state.zip.length <= 2) {
        errors = true;
        this.setState(
          {
            notifyType: "error",
            notifyMessage: "Please enter valid zip",
          },
          () => this.setState({ notifyType: "", notifyMessage: "" })
        );
      }
    }

    if (!errors) {
      this.goToCreateAccount();
    }
  };

  goToCreateAccount = () => {
    const data = {
      userId: this.state.userId,
      key: "J9l4j4923n4bcsg7Df466gddjhxdhfn5Okfekoef",
      accountType: this.state.accountType,
      primaryDomain: this.state.primaryDomain,
      businessName: this.state.businessName,
      supportPhone: this.state.supportPhone,
      supportEmail: this.state.supportEmail,
      billingEmail: this.state.billingEmail,
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zip: this.state.zip,
      certificate: [
        {
          slug: "website-security",
          status: true,
        },
        {
          slug: "no-malware",
          status: true,
        },
        {
          slug: "active-ssl",
          status: true,
        },
      ],
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      language: this.state.language,
      sendEmail: this.state.sendEmail,
      isBackEnd: true,
    };

    this.props.onCreateAccount(data);
  };

  componentDidMount() {
    let user = JSON.parse(cryptr.decrypt(getStorageItem("userData")));
    if (user) {
      this.props.getUserData(user);
      this.setState({ userAdmin: user.isAdmin, userId: user.userId });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userAccount) {
      this.setState({
        userAccount: newProps.userAccount,
      });
    }

    if (newProps.messageType === "create-success") {
      this.setState(
        {
          notifyType: "success",
          notifyMessage: "Created successfully",
          primaryDomain: "",
          businessName: "",
          supportPhone: "",
          supportEmail: "",
          billingEmail: "",
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          language: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          country: "",
          zip: "",
          userFirstName: "",
          userLastName: "",
          userPhone: "",
          userEmail: "",
          userAccessRole: "",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (newProps.messageType === "do-success") {
      this.setState(
        {
          notifyType: "success",
          notifyMessage: "Note created successfully",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (newProps.messageType === "create_failed" && newProps.code !== 404) {
      this.setState(
        {
          notifyType: "error",
          notifyMessage: newProps.message,
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (newProps.messageType === "success") {
      this.setState(
        {
          notifyType: newProps.messageType,
          notifyMessage: newProps.message,
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (newProps.messageType === "error") {
      this.setState(
        {
          notifyType: newProps.messageType,
          notifyMessage: newProps.message,
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (
      newProps.notificationData === "control_panel_user_edited_success" &&
      this.state.controlPanelUserUpdated
    ) {
      this.setState(
        {
          notifyType: "success",
          notifyMessage: "User updated successfully.",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (newProps.messageType === "email-sent-success") {
      this.setState(
        {
          notifyType: "success",
          notifyMessage: "Email sent successfully.",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (newProps.domains) {
      let businessId = newProps.currentBusiness._id;
      let domains = newProps.domains.filter(
        (domain) => domain.businessId === businessId
      );
      this.setState({
        domains: domains,
      });
    }

    if (newProps.usersInBusiness) {
      this.setState({
        usersInBusiness: newProps.usersInBusiness,
      });
    }

    if (newProps.currentBusiness) {
      this.setState({
        selectedBusinessData: newProps.currentBusiness,
      });
    }

    if (newProps.businesData) {
      this.setState({
        selectedBusinessData: newProps.currentBusiness,
      });
    }

    if (newProps.messageType === "status-completed") {
      this.setState(
        {
          notifyType: "success",
          notifyMessage: "Record marked as completed",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }
  }

  onChangeAccessRole = (event) => {
    this.setState({
      userAccessRole: event.target.value,
    });
  };

  getBusinessDetailsForCancelOrActiveBusiness = (businessId) => {
    this.props.getBusinessDetailsById(businessId);
  };

  handleChange = (evt) => {
    let eventValue = evt.target.value;

    switch (eventValue) {
      case "businessCreatedAt":
        this.setState({
          datePickerShow: "block",
          criteriaShow: "none",
          showType: "none",
          searchBy: evt.target.value,
        });
        break;

      case "businessType":
        this.setState({
          showType: "block",
          criteriaShow: "none",
          searchBy: evt.target.value,
        });
        break;
      default:
        this.setState({
          datePickerShow: "none",
          criteriaShow: "block",
          showType: "none",
          searchBy: eventValue !== "mostRecent" ? evt.target.value : "",
        });
        break;
    }
  };

  onClickAdvanceSearch = () => {
    let businessQuery, businessStatus;

    if (this.state.showCancelledSites) {
      businessStatus = "canceled";
    } else {
      businessStatus = "active";
    }

    switch (this.state.searchBy) {
      case "host":
        businessQuery = {
          action: "get-businesses-filtered-by-host",
          searchKeyword: this.state.searchCriteria,
          limit: this.state.advancedSearchResultLimit,
          status: businessStatus,
        };
        if (this.state.showInvalidSites) {
          businessQuery["checkInvalid"] = true;
        }
        break;
      case "businessId":
        businessQuery = {
          action: "get-businesses-filtered-by-business-id",
          searchKeyword: this.state.searchCriteria,
          limit: this.state.advancedSearchResultLimit,
          status: businessStatus,
        };
        if (this.state.showInvalidSites) {
          businessQuery["checkInvalid"] = true;
        }
        break;
      case "userEmail":
        businessQuery = {
          action: "get-businesses-filtered-by-user-email",
          searchKeyword: this.state.searchCriteria,
          limit: this.state.advancedSearchResultLimit,
          status: businessStatus,
        };
        if (this.state.showInvalidSites) {
          businessQuery["checkInvalid"] = true;
        }
        break;
      case "userPhone":
        businessQuery = {
          action: "get-businesses-filtered-by-user-phone",
          searchKeyword: this.state.searchCriteria,
          limit: this.state.advancedSearchResultLimit,
          status: businessStatus,
        };
        if (this.state.showInvalidSites) {
          businessQuery["checkInvalid"] = true;
        }
        break;
      case "userFullName":
        businessQuery = {
          action: "get-businesses-filtered-by-user-full-name",
          searchKeyword: this.state.searchCriteria,
          limit: this.state.advancedSearchResultLimit,
          status: businessStatus,
        };
        if (this.state.showInvalidSites) {
          businessQuery["checkInvalid"] = true;
        }
        break;
      case "businessCreatedAt":
        businessQuery = {
          action: "get-businesses-filtered-by-business-created-at",
          searchKeyword: this.state.searchCriteria,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          limit: this.state.advancedSearchResultLimit,
          status: businessStatus,
        };
        if (this.state.showInvalidSites) {
          businessQuery["checkInvalid"] = true;
        }
        break;
      case "cancelled-sites":
        businessQuery = {
          action: "get-cancelled-businesses",
          searchKeyword: this.state.searchCriteria,
          limit: this.state.advancedSearchResultLimit,
          status: businessStatus,
        };
        if (this.state.showInvalidSites) {
          businessQuery["checkInvalid"] = true;
        }
        break;

      case "businessType":
        businessQuery = {
          action: "get-businesses-as-per-type",
          searchKeyword: this.state.selType,
          limit: this.state.advancedSearchResultLimit,
          status: businessStatus,
        };
        break;
      default:
        businessQuery = {
          action: "get-businesses-and-domains",
          searchKeyword: this.state.searchCriteria,
          limit: this.state.advancedSearchResultLimit,
          status: businessStatus,
        };
        if (this.state.showInvalidSites) {
          businessQuery["checkInvalid"] = true;
        }
        break;
    }
    this.props.getBusinesses(businessQuery);
  };

  findUsersAccountOnBusiness = (businessId) => {
    const query = {
      query: {
        businessDetails: { $elemMatch: { businessId: businessId } },
      },
    };
    this.props.findUsersInBusiness(query);
  };

  onTextKeyPress(evt) {
    if (evt.key === "Enter") {
      this.onClickAdvanceSearch(evt);
    }
  }

  populateDelayModalData = (businessId) => {
    this.setState({
      selectedBusinessId: businessId,
    });
  };

  populateBusinessUsersData = (businessId) => {
    this.setState(
      {
        selectedBusinessId: businessId,
      },
      () => {
        this.findUsersAccountOnBusiness(businessId);
        this.getBusinessDetailsForCancelOrActiveBusiness(businessId);
      }
    );
  };

  controlPanelUserUpdated = () => {
    this.setState({
      controlPanelUserUpdated: true,
    });
  };

  render() {
    removeStorageItem("businessID");
    const tablinks = [
      { name: "Reports", target: "reports", dataTestId: "ancCtlPnlRep" },
      {
        name: "Advanced Search",
        target: "advsearch",
      },
      {
        name: "Create Account",
        target: "create-account",
      },
      { name: "Email", target: "create-notifications" },
      { name: "One Time Emails", target: "one-time-email" },
      { name: "Seal Scripts", target: "upload-scripts" },
    ];

    const {
      notifyType,
      notifyMessage,
      message,
      tab,
      primaryDomain,
      businessName,
      supportPhone,
      supportEmail,
      billingEmail,
      address,
      address2,
      city,
      state,
      country,
      zip,
      firstName,
      lastName,
      phone,
      email,
      language,
      userFirstName,
      userLastName,
      userPhone,
      userEmail,
      userAccessRole,
      searchBy,
      datePickerShow,
      criteriaShow,
      startDate,
      endDate,
      usersInBusiness,
      userId,
      userAdmin,
      selectedBusinessData,
      selectedBusinessId,
      advancedSearchResultLimit,
      sendEmail,
      showCancelledSites,
      showInvalidSites,
      showType,
      selType,
      businessNameError,
      supportPhoneError,
      supportEmailError,
      billingEmailError,
      firstNameError,
      lastNameError,
      phoneError,
      emailError,
    } = this.state;

    if (this.props.loggedInUserData) {
      return (
        <Aux>
          <ControlButtonModal
            selectedBusinessId={selectedBusinessId}
            userId={userId}
            usersInBusiness={usersInBusiness}
            selectedBusinessData={selectedBusinessData}
            populateBusinessUsersData={this.populateBusinessUsersData}
            controlPanelUserUpdated={this.controlPanelUserUpdated}
          />

          {userAdmin ? (
            <LayoutContainer titlePage="Control Panel" tabs={tablinks}>
              <LayoutBody>
                <div
                  id="adminadduser"
                  className="modal"
                  tabIndex="-1"
                  role="dialog"
                >
                  <div className="modal-dialog " role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title f800">Add User</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="col-lg-12 col-md-12 text-left">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <h5>First Name</h5>
                            <input
                              type="text"
                              placeholder="First Name"
                              className="dblock"
                              name="userFirstName"
                              value={userFirstName}
                              onChange={this.onChangeHandelUserInput.bind(this)}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <h5>Last Name</h5>
                            <input
                              type="text"
                              placeholder="Last Name"
                              className="dblock"
                              name="userLastName"
                              value={userLastName}
                              onChange={this.onChangeHandelUserInput.bind(this)}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <h5>Phone</h5>
                            <input
                              type="text"
                              placeholder="Phone Number"
                              className="dblock"
                              name="userPhone"
                              value={userPhone}
                              onChange={this.onChangeHandelUserInput.bind(this)}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <h5>Email</h5>
                            <input
                              type="text"
                              placeholder="example@gmail.com"
                              className="dblock"
                              name="userEmail"
                              value={userEmail}
                              onChange={this.onChangeHandelUserInput.bind(this)}
                            />
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="col-md-12">
                          <label>Security Access Level</label>
                          <br />
                          <select
                            className="pull-left"
                            value={userAccessRole}
                            onChange={this.onChangeAccessRole}
                          >
                            <option select="false">
                              Select A Role For User
                            </option>
                            <option value="Account Admin">Account Admin</option>
                            <option value="Account Manager">
                              Account Manager
                            </option>
                            <option value="Scan Report Access">
                              Scan Report Access
                            </option>
                          </select>
                          <button
                            type="button"
                            className="btn-tglight2 pull-right btn-140-width btn-md"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn-success pull-right btn-140-width btn-md"
                            onClick={() => {
                              this.createUserRole();
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ToastMessage type={notifyType} notifyMessage={notifyMessage} />
                <div id="reports" className="tab-pane fade in active">
                  <ul className="nav nav-tabs" id="reportTab" role="tablist">
                    <li className="nav-item active">
                      <a
                        className="nav-link"
                        id="verification-report-tab"
                        data-toggle="tab"
                        href="#verification-report"
                        role="tab"
                        aria-controls="verification-report"
                        aria-selected="true"
                        onClick={() => this.openTabVerification()}
                      >
                        Verification
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="pci-scan-report-tab"
                        data-toggle="tab"
                        href="#pci-scan-report"
                        role="tab"
                        aria-controls="pci-scan-report"
                        aria-selected="true"
                        onClick={() => this.openTabPCI()}
                      >
                        PCI Scan
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="seals-report-tab"
                        data-toggle="tab"
                        href="#seals-report"
                        role="tab"
                        aria-controls="seals-report"
                        aria-selected="false"
                        onClick={() => this.openTabSeal()}
                      >
                        Seals
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="external-malware-scan-tab"
                        data-toggle="tab"
                        href="#external-malware-scan"
                        role="tab"
                        aria-controls="external-malware-scan"
                        aria-selected="false"
                        onClick={() => this.openTabMalware()}
                      >
                        Malware Scan
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="ssl-scan-report-tab"
                        data-toggle="tab"
                        href="#ssl-scan-report"
                        role="tab"
                        aria-controls="ssl-scan-report"
                        aria-selected="false"
                        onClick={() => this.openTabSSL()}
                      >
                        SSL Scan
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="30-days-old-free-businesses-tab"
                        data-toggle="tab"
                        href="#30-days-old-free-businesses"
                        role="tab"
                        aria-controls="30-days-old-free-businesses-report"
                        aria-selected="true"
                        onClick={() => this.openTabOther()}
                      >
                        Other
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="email-statistics-tab"
                        data-toggle="tab"
                        href="#email-statistics"
                        role="tab"
                        aria-controls="email-statistics"
                        aria-selected="false"
                        onClick={() => this.openTabEmail()}
                      >
                        Email Statistics
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="sms-statistics-tab"
                        data-toggle="tab"
                        href="#sms-statistics"
                        role="tab"
                        aria-controls="sms-statistics"
                        aria-selected="false"
                        onClick={() => this.openTabSms()}
                      >
                        SMS Statistics
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="insurance-statistics-tab"
                        data-toggle="tab"
                        href="#insurance-statistics"
                        role="tab"
                        aria-controls="insurance"
                        aria-selected="true"
                        onClick={() => this.openTabInsurance()}
                      >
                        Insurance Statistics
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="reportTabContent">
                    <div
                      className="tab-pane fade in active"
                      id="verification-report"
                      role="tabpanel"
                      aria-labelledby="verification-report-tab"
                    >
                      {tab.showVerificationTab ? (
                        <div id="mcontent-wrapper">
                          <FailedScans
                            history={this.props.history}
                            getBusinessDetails={
                              this.getBusinessDetailsForCancelOrActiveBusiness
                            }
                            populateDelayModalData={this.populateDelayModalData}
                            populateBusinessUsersData={
                              this.populateBusinessUsersData
                            }
                          />
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="tab-pane fade in"
                      id="30-days-old-free-businesses"
                      role="tabpanel"
                      aria-labelledby="30-days-old-free-businesses-tab"
                    >
                      <div id="mcontent-wrapper">
                        <FreeBusinessList
                          history={this.props.history}
                          getBusinessDetails={
                            this.getBusinessDetailsForCancelOrActiveBusiness
                          }
                          populateDelayModalData={this.populateDelayModalData}
                          populateBusinessUsersData={
                            this.populateBusinessUsersData
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pci-scan-report"
                      role="tabpanel"
                      aria-labelledby="pci-scan-report-tab"
                    >
                      {tab.showPciTab ? (
                        <Reports
                          history={this.props.history}
                          getBusinessDetails={
                            this.getBusinessDetailsForCancelOrActiveBusiness
                          }
                          populateDelayModalData={this.populateDelayModalData}
                          populateBusinessUsersData={
                            this.populateBusinessUsersData
                          }
                        />
                      ) : null}

                      <br />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="seals-report"
                      role="tabpanel"
                      aria-labelledby="seals-report-tab"
                    >
                      {tab.showSealTab ? (
                        <div id="mcontent-wrapper">
                          {" "}
                          <SealReport />{" "}
                        </div>
                      ) : null}

                      <br />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="external-malware-scan"
                      role="tabpanel"
                      aria-labelledby="external-malware-scan-tab"
                    >
                      {" "}
                      {tab.showMalwareTab ? (
                        <div id="mcontent-wrapper">
                          {" "}
                          <MalwareReports />{" "}
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="ssl-scan-report"
                      role="tabpanel"
                      aria-labelledby="ssl-scan-report-tab"
                    >
                      {tab.showSSLTab ? (
                        <div id="mcontent-wrapper">
                          <SslScanReport />{" "}
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="tab-pane fade"
                      id="email-statistics"
                      role="tabpanel"
                      aria-labelledby="email-statistics"
                    >
                      {tab.showEmailTab ? (
                        <div id="mcontent-wrapper">
                          <EmailStatistics />{" "}
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="tab-pane fade"
                      id="sms-statistics"
                      role="tabpanel"
                      aria-labelledby="sms-statistics"
                    >
                      {tab.showSmsTab ? (
                        <div id="mcontent-wrapper">
                          <SmsStatistics />{" "}
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="tab-pane fade"
                      id="insurance-statistics"
                      role="tabpanel"
                      aria-labelledby="insurance-statistics"
                    >
                      {tab.showInsuranceTab ? (
                        <div id="mcontent-wrapper">
                          {" "}
                          <InsuranceStatistics />{" "}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div id="advsearch" className="tab-pane fade">
                  <h4 style={{ marginBottom: "25px" }}>ADVANCED SEARCH</h4>

                  <div className="row no-pad">
                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <label>Search By:</label>
                      <select
                        id="dateRange"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option value="host" defaultValue="host">
                          Website
                        </option>
                        <option value="mostRecent">Most Recent</option>
                        <option value="businessType">Type</option>
                        <option value="businessId">ID</option>
                        <option value="userEmail">Email</option>
                        <option value="userPhone">Phone</option>
                        <option value="userFullName">Name</option>
                        <option value="businessCreatedAt">Date Range</option>
                      </select>
                    </div>

                    {searchBy ? (
                      <div
                        id="AdvSearchBy"
                        style={{ display: criteriaShow }}
                        className="col-lg-3 col-md-3 col-sm-12"
                      >
                        <label>Search Criteria:</label>
                        <input
                          data-testid="inputSearchBox"
                          type="text"
                          className="form-control"
                          onChange={(evt) => {
                            this.setState({
                              searchCriteria: evt.target.value,
                            });
                          }}
                          onKeyPress={this.onTextKeyPress.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      id="AdvSearchFrom"
                      style={{ display: datePickerShow }}
                      className="col-lg-4 col-md-3 col-sm-12"
                    >
                      <label style={{ display: "block" }}>From - To:</label>
                      <div className="input-group">
                        <DatePicker
                          selected={startDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          onChange={this.handleChangeStart}
                          className="form-control"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <DatePicker
                          selected={endDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          onChange={this.handleChangeEnd}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div style={{ display: showType }}>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>Type</label>
                        <select
                          id="selBusinessType"
                          className="form-control"
                          defaultValue={selType}
                          onChange={(evt) => {
                            this.setState({
                              selType: evt.target.value,
                            });
                          }}
                        >
                          <option value="free">Free</option>
                          <option value="basic">Full</option>
                          <option value="security">Security</option>
                          <option value="90-day-trial">90 Day Trial</option>
                        </select>
                      </div>
                    </div>

                    <div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>Show Result:</label>
                        <select
                          id="paginateResult"
                          className="form-control"
                          defaultValue={advancedSearchResultLimit}
                          onChange={(evt) => {
                            this.setState({
                              advancedSearchResultLimit: parseInt(
                                evt.target.value
                              ),
                            });
                          }}
                        >
                          <option value="25">Last 25</option>
                          <option value="50">Last 50</option>
                          <option value="100">Last 100</option>
                        </select>
                      </div>
                    </div>

                    <button
                      data-testid="btnSearch"
                      style={{
                        position: "relative",
                        top: "25px",
                      }}
                      className="col-lg-2 col-md-2 col-sm-12 btn-140-width btn-success btn-md"
                      onClick={() => this.onClickAdvanceSearch()}
                    >
                      Search
                    </button>

                    <div
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        top: "32px",
                        marginLeft: "45px",
                      }}
                    >
                      <input
                        type="checkbox"
                        value={showCancelledSites}
                        name="pciScanCheckedEmail"
                        checked={showCancelledSites}
                        onChange={(evt) =>
                          this.setState(
                            {
                              showCancelledSites: !showCancelledSites,
                            },
                            () => {
                              this.onClickAdvanceSearch();
                            }
                          )
                        }
                      />
                      <label
                        style={{
                          cursor: "pointer",
                          marginLeft: "15px",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              showCancelledSites: !showCancelledSites,
                            },
                            () => {
                              this.onClickAdvanceSearch();
                            }
                          )
                        }
                      >
                        Show Cancelled Sites
                      </label>
                      &nbsp;&nbsp;
                      <input
                        type="checkbox"
                        value={showInvalidSites}
                        name="pciScanCheckedEmail"
                        checked={showInvalidSites}
                        onChange={(evt) =>
                          this.setState(
                            {
                              showInvalidSites: !showInvalidSites,
                            },
                            () => {
                              this.onClickAdvanceSearch();
                            }
                          )
                        }
                      />
                      <label
                        style={{
                          cursor: "pointer",
                          marginLeft: "15px",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              showInvalidSites: !showInvalidSites,
                            },
                            () => {
                              this.onClickAdvanceSearch();
                            }
                          )
                        }
                      >
                        Show Invalid Sites
                      </label>
                    </div>
                  </div>

                  <AdvancedSearch
                    history={this.props.history}
                    getBusinessDetails={
                      this.getBusinessDetailsForCancelOrActiveBusiness
                    }
                    populateDelayModalData={this.populateDelayModalData}
                    populateBusinessUsersData={this.populateBusinessUsersData}
                    resultLimit={advancedSearchResultLimit}
                  />
                </div>
                <div id="create-account" className="tab-pane fade">
                  <h4>Create Account</h4>
                  {message ? (
                    <div className="message" id="aap">
                      <h4>Create Account Success</h4>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-lg-12 no-pad">
                    <div className="col-lg-9 col-md-9 col-md-12 col-sm-12 text-left no-pad">
                      <div className="Rtable-cell">
                        <span className="Rparent-label-mobile">
                          ACCOUNT TYPE
                        </span>
                        <br />
                        <br />
                        <input
                          type="radio"
                          name="accountType"
                          id="free"
                          className="account-type-radio"
                          value="free"
                          onClick={this.onChangeAccountType}
                          data-testid="inpCrtAccFrmFreeAccType"
                        />
                        <label htmlFor="free">Free</label>
                        <input
                          type="radio"
                          name="accountType"
                          id="basic"
                          className="account-type-radio"
                          value="basic"
                          defaultChecked
                          onClick={this.onChangeAccountType}
                          data-testid="inpCrtAccFrmBasicAccType"
                        />
                        <label htmlFor="basic">Full</label>
                        <input
                          type="radio"
                          name="accountType"
                          id="security"
                          className="account-type-radio"
                          value="security"
                          onClick={this.onChangeAccountType}
                          data-testid="inpCrtAccFrmSecAccType"
                        />
                        <label htmlFor="security">Security</label>

                        <input
                          type="radio"
                          name="accountType"
                          id="90-day-trial"
                          className="account-type-radio"
                          value="90-day-trial"
                          onClick={this.onChangeAccountType}
                          data-testid="inpCrtAccFrm90DayTrialAccType"
                        />
                        <label htmlFor="90-day-trial">90 Day Trial</label>

                        <br />
                        <br />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-md-12 col-sm-12 text-left  no-pad" />
                  </div>
                  <br />
                  <h5 className="">BUSINESS INFO</h5>
                  <br />
                  <div className="Rtable Rtable--6cols Rtable--collapse">
                    <div className="Rtable-cell p-sm">
                      <span className="Rparent-label-mobile">
                        Primary Domain URL
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control p-sm"
                        placeholder="192.33.334.4"
                        name="primaryDomain"
                        value={primaryDomain}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmPriDmn"
                      />
                    </div>
                    <div className="Rtable-cell p-sm">
                      <span
                        className={
                          businessNameError
                            ? "Rparent-label-mobile inputError"
                            : "Rparent-label-mobile"
                        }
                      >
                        Business Name
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Business Name"
                        name="businessName"
                        value={businessName}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmBusiName"
                      />
                    </div>

                    <div className="Rtable-cell p-sm">
                      <span
                        className={
                          supportPhoneError
                            ? "Rparent-label-mobile inputError"
                            : "Rparent-label-mobile"
                        }
                      >
                        Support Phone
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Support Phone"
                        value={supportPhone}
                        name="supportPhone"
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmSupPhn"
                      />
                    </div>
                    <div className="Rtable-cell p-sm">
                      <span
                        className={
                          supportEmailError
                            ? "Rparent-label-mobile inputError"
                            : "Rparent-label-mobile"
                        }
                      >
                        Support Email
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Support Email"
                        name="supportEmail"
                        value={supportEmail}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmSupEmail"
                      />
                    </div>
                    <div className="Rtable-cell p-sm">
                      <span
                        className={
                          billingEmailError
                            ? "Rparent-label-mobile inputError"
                            : "Rparent-label-mobile"
                        }
                      >
                        Billing Email
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Billing Email"
                        name="billingEmail"
                        value={billingEmail}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmBilEmail"
                      />
                    </div>
                  </div>
                  <div className="Rtable Rtable--6cols Rtable--collapse">
                    <div className="Rtable-cell p-sm">
                      <span className="Rparent-label-mobile">
                        Business Address
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control p-sm"
                        placeholder="Business Address"
                        name="address"
                        value={address}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmBusiAddr1"
                      />
                    </div>
                    <div className="Rtable-cell p-sm">
                      <span className="Rparent-label-mobile">
                        Business Address 2
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Business Address 2"
                        name="address2"
                        value={address2}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmBusiAddr2"
                      />
                    </div>

                    <div className="Rtable-cell p-sm">
                      <span className="Rparent-label-mobile">City</span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        name="city"
                        value={city}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                      />
                    </div>

                    <div className="Rtable-cell p-sm">
                      <span className="Rparent-label-mobile">State</span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        name="state"
                        value={state}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmState"
                      />
                    </div>
                    <div className="Rtable-cell p-sm">
                      <span className="Rparent-label-mobile">Country</span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Country"
                        name="country"
                        value={country}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmCntry"
                      />
                    </div>
                    <div className="Rtable-cell p-sm">
                      <span className="Rparent-label-mobile">Zip</span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Zip"
                        name="zip"
                        value={zip}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmZip"
                      />
                    </div>
                  </div>
                  <br />
                  <h5 className="">ACCOUNT ADMIN INFO</h5>
                  <br />
                  <div className="Rtable Rtable--6cols Rtable--collapse">
                    <div className="Rtable-cell p-sm">
                      <span
                        className={
                          firstNameError
                            ? "Rparent-label-mobile inputError"
                            : "Rparent-label-mobile"
                        }
                      >
                        First Name
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control p-sm"
                        placeholder="First Name"
                        name="firstName"
                        value={firstName}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmFrstName"
                      />
                    </div>
                    <div className="Rtable-cell p-sm">
                      <span
                        className={
                          lastNameError
                            ? "Rparent-label-mobile inputError"
                            : "Rparent-label-mobile"
                        }
                      >
                        Last Name
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Last Name"
                        name="lastName"
                        value={lastName}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmLastName"
                      />
                    </div>

                    <div className="Rtable-cell p-sm">
                      <span
                        className={
                          phoneError
                            ? "Rparent-label-mobile inputError"
                            : "Rparent-label-mobile"
                        }
                      >
                        Phone
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        name="phone"
                        value={phone}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmPhn"
                      />
                    </div>
                    <div className="Rtable-cell p-sm">
                      <span
                        className={
                          emailError
                            ? "Rparent-label-mobile inputError"
                            : "Rparent-label-mobile"
                        }
                      >
                        Email
                      </span>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="example@gmail.com"
                        name="email"
                        value={email}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                        data-testid="inpCrtAccFrmEmail"
                      />
                    </div>
                    <div className="Rtable-cell p-sm">
                      <span className="Rparent-label-mobile">Language</span>
                      <br />
                      <select
                        className="form-control"
                        name="language"
                        onChange={(e) =>
                          this.setState({ language: e.target.value })
                        }
                        value={language}
                      >
                        <option disabled="disabled">-- Select --</option>
                        <option value="english">English</option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div className="Rtable Rtable--6cols Rtable--collapse">
                    <div
                      className="Rtable-cell p-sm"
                      onClick={() => {
                        document.getElementById("inpSendEmailOption").click();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        id="inpSendEmailOption"
                        type="checkbox"
                        checked={sendEmail}
                        style={{ cursor: "pointer" }}
                        onChange={() => {
                          this.setState({
                            sendEmail: !sendEmail,
                          });
                        }}
                      />{" "}
                      &nbsp;&nbsp;{" "}
                      <span
                        className="Rparent-label-mobile"
                        style={{ marginLeft: "20px" }}
                      >
                        Send Email
                      </span>
                    </div>
                  </div>

                  <hr />
                  <br />

                  <div style={{ display: "none" }}>
                    <h5>BILLING INFO</h5>
                    <br />
                    <br />
                    <div className="col-lg-12 col-md-12 col-sm-12 no-pad">
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <p>Price:</p>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <p>Affiliate ID:</p>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-md-12 no-pad-r">
                        <br />
                        <input
                          type="checkbox"
                          id="billingaddmatch"
                          name="billingmatch"
                          defaultChecked
                        />
                        <label htmlFor="billingaddmatch">
                          &nbsp; Billing address same as business address.
                        </label>
                        <button className="btn-success btn-md btn-140-width pull-right">
                          Add Card
                        </button>

                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>

                  <div
                    id="billing-fields"
                    style={{ display: "none" }}
                    className="col-lg-12 col-md-12 col-sm-12 no-pad"
                  >
                    <div className="Rtable Rtable--6cols Rtable--collapse">
                      <div className="Rtable-cell p-sm">
                        <span className="Rparent-label-mobile">
                          Billing Address
                        </span>
                        <br />
                        <input type="text" className="form-control" />
                      </div>
                      <div className="Rtable-cell p-sm">
                        <span className="Rparent-label-mobile">
                          Billing Address 2
                        </span>
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="Rtable-cell p-sm">
                        <span className="Rparent-label-mobile">State</span>
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="Rtable-cell p-sm">
                        <span className="Rparent-label-mobile">Country</span>
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 no-pad">
                    <div
                      className="table-responsive"
                      style={{ display: "none" }}
                    >
                      <table className="table table-hover">
                        <thead className="table-dark">
                          <tr>
                            <th width="60%">Type</th>
                            <th>Name on Card</th>
                            <th>Expiry Date</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="radio"
                                id="cardType1"
                                className="pointer"
                                defaultChecked
                                name="cardType"
                              />&nbsp;{" "}
                              <label htmlFor="cardType1" className="pointer">
                                <b>Visa</b> ending in 2490
                              </label>
                            </td>
                            <td>Eric Brandley</td>
                            <td>11/2019</td>
                            <td>
                              <span className="a-pointer">
                                <i className="fa fa-edit" />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="radio"
                                id="cardType2"
                                className="pointer"
                                name="cardType"
                              />&nbsp;{" "}
                              <label htmlFor="cardType2" className="pointer">
                                <b>Debit Card</b> ending in 0019
                              </label>
                            </td>
                            <td>Eric Brandley</td>
                            <td>11/2019</td>
                            <td>
                              <span className="a-pointer">
                                <i className="fa fa-edit" />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <button
                      className="btn-success btn-md btn-140-width pull-right"
                      onClick={() => this.onClickCreateAccount()}
                      data-testid="btnCrtAccFrmSbmt"
                    >
                      Create Account
                    </button>
                  </div>
                </div>
                <div id="create-notifications" className="tab-pane fade">
                  <CreateEmail />
                </div>
                <div id="one-time-email" className="tab-pane fade">
                  <OneTimeEmail />
                </div>
                <div id="upload-scripts" className="tab-pane fade">
                  <UploadScripts />
                </div>
                <div id="billing" className="tab-pane fade">
                  <h4>Manage Subdomains/IPs</h4>
                  <p>Some content in menu 3.</p>
                </div>
              </LayoutBody>
            </LayoutContainer>
          ) : (
            <PageNotFound code={"401"} />
          )}
        </Aux>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUserData: state.userStore.loggedInUserData,
    loggedInUser: state.accountStore.loggedInUser,
    userAccount: state.accountStore.userAccount,
    messageType: state.accountStore.messageType,
    message: state.accountStore.message,
    code: state.accountStore.code,
    businessAccount: state.accountStore.businessAccount,
    domains: state.accountStore.domains,
    currentBusiness: state.accountStore.currentBusiness,
    usersInBusiness: state.loginStore.usersInBusiness,
    notificationData: state.loginStore.notificationData,
    businesData: state.accountStore.businesData,
    renewSslDomains: state.accountStore.renewSslDomains,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserData,
      onCreateAccount,
      resetStatus,
      getDataSearchBy,
      getDomains,
      domainSearchByBusiness,
      getUserById,
      getBusinessDetailsById,
      getBusinesses,
      findUsersInBusiness,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlPanel);
