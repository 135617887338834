import * as ActionType from "../types/index";

const scanInitialStates = {
  scanhistories: [],
};

const scanHistoryReducer = (state = scanInitialStates, action) => {
  switch (action.type) {
    case ActionType.ADD_NEW_SCAN:
      return { ...state, scanhistories: action.payload };
    default:
      return state;
  }
};

export default scanHistoryReducer;
